import {
  Avatar,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { WorkspacePermissionDoc } from "shared/workspaceTypes";
import { getWorkspacePermissionsCollection } from "src/db/workspace";
import { useActiveWorkspaceId } from "src/stores/userWorkspaces";
import { formatDateToRelative, formatTimestamp } from "../Utils/dateutils";
import RoleMenu from "./RoleMenu";

const SortableTableHeader = ({
  label,
  field,
  sortField,
  sortOrder,
  onSort,
}: {
  label: string;
  field: string;
  sortField: string;
  sortOrder: string;
  onSort: (field: string) => void;
}) => (
  <Th onClick={() => onSort(field)} cursor="pointer">
    <Flex
      alignItems="center"
      gap="8px"
      color="#666666"
      textTransform="capitalize"
    >
      {label}
      {sortField === field &&
        (sortOrder === "asc" ? (
          <IoMdArrowDropup color="black" fontSize="12px" />
        ) : (
          <IoMdArrowDropdown color="black" fontSize="12px" />
        ))}
    </Flex>
  </Th>
);

const MemberRow = ({ permission }: { permission: WorkspacePermissionDoc }) => (
  <Tr key={permission.uid}>
    <Td>
      <Flex alignItems="center">
        <Avatar size="sm" name={permission.email} mr={3} />
        <Box>
          <Text fontWeight="medium" fontSize="14px" color="primary">
            {permission.displayName}
          </Text>
          <Text fontSize="10px" fontWeight="bold" color="gray.500">
            {permission.email}
          </Text>
        </Box>
      </Flex>
    </Td>
    <Td fontSize="12px" color="primary" fontWeight="400">
      {formatDateToRelative(formatTimestamp(permission.createdAt))}
    </Td>
    <Td>
      <RoleMenu permission={permission} />
    </Td>
  </Tr>
);

const useWorkspacePermissions = (activeWorkspaceId: string | null) => {
  return useQuery({
    queryKey: [`workspacePermissionsData-${activeWorkspaceId}`],
    queryFn: async () => {
      if (!activeWorkspaceId) return;
      const workspacePermissions = await getDocs(
        getWorkspacePermissionsCollection(activeWorkspaceId)
      );
      return workspacePermissions.docs.map((doc) => doc.data());
    },
  });
};

const WorkspaceMemberTable = () => {
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const activeWorkspaceId = useActiveWorkspaceId();

  const { data: workspacePermissionsData, isLoading } =
    useWorkspacePermissions(activeWorkspaceId);

  if (isLoading) return <div>Loading...</div>;

  if (!workspacePermissionsData) return <div>No data</div>;

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortedPermissions = [...workspacePermissionsData].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <Box maxW="1100px">
      <Table variant="simple">
        <Thead>
          <Tr>
            <SortableTableHeader
              label="Name"
              field="name"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={handleSort}
            />
            <SortableTableHeader
              label="Join date"
              field="joinDate"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={handleSort}
            />
            <Th textTransform="capitalize">Role</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedPermissions.map((permission) => (
            <MemberRow key={permission.uid} permission={permission} />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default WorkspaceMemberTable;
