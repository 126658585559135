// A modal with project settings

import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { RiSettings3Fill } from "react-icons/ri";
import ProjectSettingsForm from "./ProjectSettingsForm";

const ProjectSettings = ({ projectData }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <IconButton
        colorScheme="primary"
        variant="ghost"
        size="sm"
        aria-label="Project Settings"
        icon={<RiSettings3Fill />}
        onClick={onOpen}
      />
      <Modal
        size="xl"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* warning text that this is only for power users */}
            <Text mb={4} color="red.500">
              Warning: This is only for power users. Changing these settings can
              break your project.
            </Text>
            <Text mb={4}>Leave a field empty to use the default value.</Text>
            <ProjectSettingsForm projectData={projectData} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProjectSettings;
