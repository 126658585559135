import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { Component, ErrorInfo, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    // You can also log the error to an error reporting service here
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.renderErrorUI();
    }

    return this.props.children;
  }

  renderErrorUI() {
    const { error, errorInfo } = this.state;

    return (
      <Box textAlign="center" py={10} px={6}>
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Oops! Something went wrong.
        </Heading>
        <Text color={"gray.500"} mb={6}>
          {this.getErrorMessage(error)}
        </Text>
        <Button
          colorScheme="primary"
          color="primary"
          onClick={() => (window.location.href = "/")}
        >
          Return to Home page
        </Button>
        {process.env.NODE_ENV === "development" && (
          <Box mt={4} textAlign="left">
            <Heading as="h3" size="md" mb={2}>
              Error Details (shown only in development mode):
            </Heading>
            <Text whiteSpace="pre-wrap">
              {error && error.toString()}
              {errorInfo && errorInfo.componentStack}
            </Text>
          </Box>
        )}
        <Flex alignItems="center" justifyContent="center" gap={4}></Flex>
      </Box>
    );
  }

  getErrorMessage(error: Error | null): string {
    if (!error) return "An unexpected error occurred.";

    // Example: Handling different types of errors
    if (error instanceof TypeError) {
      return "A type error occurred. Please check your input.";
    } else if (error instanceof RangeError) {
      return "A range error occurred. Please check your input values.";
    } else if (error instanceof EvalError) {
      return "An eval error occurred. This is likely a code issue.";
    } else if (error instanceof ReferenceError) {
      return "A reference error occurred. This is likely a code issue.";
    } else if (error instanceof SyntaxError) {
      return "A syntax error occurred. This is likely a code issue.";
    } else if (error instanceof URIError) {
      return "A URI error occurred. Please check your URL.";
    }

    // Example: Handling custom error types
    // Assuming we have a custom error type called APIError
    // if (error instanceof APIError) {
    //   return `An API error occurred: ${error.message}`;
    // }

    // Default case
    return error.message || "An unexpected error occurred.";
  }
}

export default ErrorBoundary;
