import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { useAuth } from "src/Auth/AuthProvider";
import { getWorkspacePermissionsCollection } from "src/db/workspace";
import { getUserId } from "src/services/UserSrv";
import {
  useActiveWorkspaceId,
  useActiveWorkspacePermission,
} from "src/stores/userWorkspaces";
import { isValidEmail } from "../ProjectPage/SharingView/SharingSettingModal";
import WorkspaceInvitedMemberTable from "./InvitedMemberTable";
import WorkspaceMemberTable from "./MemberTable";

const MenuBttn = ({ text, action, icon, isSelected }) => {
  const Icon = icon;
  return (
    <Button
      fontSize="12px"
      p="8px"
      w="fit-content"
      h="fit-content"
      bg={isSelected ? "#E3E3E3" : "transparent"}
      color={isSelected ? "primary" : "#5C5F6C"}
      onClick={action}
      leftIcon={<Icon fontSize="13px" />}
    >
      {text}
    </Button>
  );
};

const InviteWithLink = () => {
  const [linkCopied, setLinkCopied] = useState(false);
  return (
    <Flex pt="30px" flexDir="column" gap="8px">
      <Text color="#666666" fontSize="12px">
        Copy workspace invite link
      </Text>
      <FormControl
        bg="white"
        display="flex"
        w="540px"
        maxW="540px"
        borderRadius="8px"
        alignItems="center"
        p="6px"
      >
        <Input
          border="none"
          _hover={{
            background: "transparent",
          }}
          _focusVisible={{
            background: "transparent",
          }}
          fontSize="12px"
          fontWeight="400"
          _placeholder={{
            color: "primary",
          }}
          p="0"
          h="fit-content"
          type="email"
          isDisabled={true}
          value=""
        />
        <Button
          p="6px"
          w="71px"
          height="fit-content"
          bg="primary"
          color={"white"}
          fontSize="12px"
          onClick={() => {
            navigator.clipboard.writeText("");
            setLinkCopied(true);
            setTimeout(() => {
              setLinkCopied(false);
            }, 2000);
          }}
        >
          {linkCopied ? "Copied!" : "Copy"}
        </Button>
      </FormControl>
    </Flex>
  );
};

const InviteWithEmail = () => {
  const [inviteEmail, setInviteEmail] = useState("");
  const activeWorkspaceId = useActiveWorkspaceId();
  const { currentUser } = useAuth();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: inviteMember, isPending } = useMutation({
    mutationFn: async (email: string) => {
      if (!activeWorkspaceId) {
        throw new Error("No active workspace");
      }
      const newUserId = await getUserId(email);
      await setDoc(
        doc(getWorkspacePermissionsCollection(activeWorkspaceId), newUserId),
        {
          role: "member",
          email: email,
          uid: newUserId,
          displayName: "",
          createdAt: Date.now(),
          createdBy: currentUser?.uid,
        }
      );
    },
    onSuccess: () => {
      toast({
        title: "Invited member",
        description: "Member invited successfully",
        status: "success",
      });
      setInviteEmail("");
      queryClient.invalidateQueries({
        queryKey: [`workspacePermissionsData-${activeWorkspaceId}`],
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
      });
    },
  });

  return (
    <Flex pt="30px" flexDir="column" gap="8px">
      <Text color="#666666" fontSize="12px">
        Invitee email
      </Text>
      <FormControl
        bg="white"
        display="flex"
        w="540px"
        maxW="540px"
        borderRadius="8px"
        alignItems="center"
        p="6px"
      >
        <Input
          border="none"
          _hover={{
            background: "transparent",
          }}
          _focusVisible={{
            background: "transparent",
          }}
          value={inviteEmail}
          fontSize="12px"
          fontWeight="400"
          _placeholder={{
            color: "primary",
          }}
          onChange={(e) => setInviteEmail(e.target.value)}
          p="0"
          h="fit-content"
          type="email"
        />
        <Button
          isDisabled={!inviteEmail || !isValidEmail(inviteEmail)}
          p="6px"
          px="12px"
          w="fit-content"
          height="fit-content"
          bg="primary"
          color={"white"}
          fontSize="12px"
          onClick={() => {
            inviteMember(inviteEmail);
          }}
          isLoading={isPending}
        >
          {!inviteEmail || isValidEmail(inviteEmail)
            ? "Invite"
            : "Invalid email"}
        </Button>
      </FormControl>
    </Flex>
  );
};

export const InvitationSection = () => {
  const [selectedInviteType, setSelectedInviteType] = useState("email");

  return (
    <Flex flexDir="column" pb="42px">
      <Heading fontSize="16px" color="primary" pb="8px">
        Invite members
      </Heading>
      <Flex gap="16px" pt="24px">
        <MenuBttn
          text="Invite with email"
          action={() => {
            setSelectedInviteType("email");
          }}
          icon={FaRegEnvelope}
          isSelected={selectedInviteType === "email"}
        />
        {/* <MenuBttn
          text="Invite with link"
          action={() => {
            setSelectedInviteType("link");
          }}
          icon={IoLinkSharp}
          isSelected={selectedInviteType === "link"}
        /> */}
      </Flex>

      {selectedInviteType === "email" ? (
        <InviteWithEmail />
      ) : (
        <InviteWithLink />
      )}
    </Flex>
  );
};

export const WorkspaceMembers = () => {
  const [invitedMembersType, setInvitedMembersType] = useState("activeMembers");
  const activeWorkspacePermission = useActiveWorkspacePermission();

  return (
    <Flex flexDir="column" pb="24px">
      <Heading fontSize="24px" color="primary" pb="24px">
        Workspace Members
      </Heading>
      {activeWorkspacePermission?.role === "owner" && <InvitationSection />}
      <Flex flexDir="column" gap="24px">
        <Heading fontSize="16px" color="primary" pb="8px">
          Members
        </Heading>

        <Flex gap="16px">
          <MenuBttn
            text="Active members"
            action={() => {
              setInvitedMembersType("activeMembers");
            }}
            icon={HiUsers}
            isSelected={invitedMembersType === "activeMembers"}
          />
          {/* <MenuBttn
            text="Invitations"
            action={() => {
              setInvitedMembersType("invitations");
            }}
            icon={FaRegEnvelope}
            isSelected={invitedMembersType === "invitations"}
          /> */}
        </Flex>

        {invitedMembersType === "activeMembers" ? (
          <WorkspaceMemberTable />
        ) : (
          <WorkspaceInvitedMemberTable />
        )}
      </Flex>
    </Flex>
  );
};
