import { MAX_FILE_SIZE } from "../../data/constants";
import { saveFile } from "../../services/firebaseStorageSrv";

export const uploadImage = async (file, projectId) => {
  const path = `project_data/${projectId}/manualInsightImages/`;
  try {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error("File size is too large");
    }
    const downloadURL = await saveFile({
      file,
      path: path,
    });
    return downloadURL;
  } catch (err) {
    console.log(err);
  }
};
