import React from "react";
import { Icon } from "@chakra-ui/react";

const CompressIcon = (props) => {
  return (
    <Icon viewBox="0 0 14 14" fill="none" {...props}>
        <path
          d="M1.03325 13.6666L0.333252 12.9666L5.29992 7.99992H1.99992V6.99992H6.99992V11.9999H5.99992V8.69992L1.03325 13.6666ZM6.99992 6.99992V1.99992H7.99992V5.29992L12.9666 0.333252L13.6666 1.03325L8.69992 5.99992H11.9999V6.99992H6.99992Z"
          fill="black"
        />
    </Icon>
  );
};

export default CompressIcon;





