
import React from "react";
import { Icon } from "@chakra-ui/react";

const StopGenerationIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M16 8V16H8V8H16ZM18 6H6V18H18V6Z" fill="white" />
    </Icon>
  );
};

export default StopGenerationIconComponent;
