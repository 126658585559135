import {
  Card,
  CardBody,
  Center,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { RiInboxArchiveLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ProjectDoc } from "shared/projectTypes";
import { useAuth } from "../../Auth/AuthProvider";
import { archiveProject, updateUserPinnedProjectsArr } from "../../db/project";
import { db, doc, updateDoc } from "../../firebase";
import useNotification from "../../hooks/useNotification";
import ProjectModal from "../Elements/NewProjectModal";
import SharedWithAvatar from "../Elements/SharedWithAvatar";
import EditIconComponent from "../Icons/EditIconComponent";
import FolderIconComponent from "../Icons/FolderIconComponent";
import MenuIconComponent from "../Icons/MenuIconComponent";
import PinIcon from "../Icons/PinIcons";
import UnPinIcon from "../Icons/UnpinIcon";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

function Pin() {
  return (
    <>
      <PinIcon color="white" />
      <chakra.span fontSize="12px" color="white" ml={3}>
        {"Pin Project"}
      </chakra.span>
    </>
  );
}

function Unpin() {
  return (
    <>
      <UnPinIcon color="white" />
      <chakra.span fontSize="12px" color="white" ml={3}>
        {"Unpin Project"}
      </chakra.span>
    </>
  );
}

const ArchiveMenuItem = ({ projectId }) => {
  const { currentUser, userDocRef } = useAuth();
  const handleArchive = () => {
    if (currentUser) {
      archiveProject({ projectId, userDocRef });
    }
  };

  return (
    <MenuItem
      backgroundColor="primary"
      onClick={() => {
        handleArchive();
      }}
    >
      <Icon as={RiInboxArchiveLine} color="white" />
      <chakra.span fontSize="12px" color="white" ml={3}>
        Archive
      </chakra.span>
    </MenuItem>
  );
};

const PinUnpinMenuItem = ({ projectId, isPinned }) => {
  const { currentUser } = useAuth();
  return (
    <MenuItem
      backgroundColor="primary"
      onClick={() => {
        updateUserPinnedProjectsArr(currentUser.uid, projectId, isPinned);
      }}
      alignItems="end"
      display="flex"
    >
      {isPinned ? <Unpin /> : <Pin />}
    </MenuItem>
  );
};

const RenameMenuItem = ({ shared, projectId, projectName }) => {
  const { notify } = useNotification();

  const [showRenameInput, setShowRenameInput] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const submitNewName = (projectTitle) => {
    if (projectTitle) {
      setIsSaving(true);
      const projectDocRef = doc(db, "projects/" + projectId);
      updateDoc(projectDocRef, {
        name: projectTitle,
      }).then(() => {
        notify({
          title: "Project name updated",
          status: "success",
        });
        setIsSaving(false);
        setShowRenameInput(false);
      });
    }
  };

  return (
    <>
      <Tooltip label={shared ? "You cannot modify a shared project" : ""}>
        <MenuItem
          backgroundColor="primary"
          onClick={() => setShowRenameInput(true)}
          isDisabled={shared}
        >
          <Icon as={EditIconComponent} color="white" />
          <chakra.span fontSize="12px" color="white" ml={3}>
            {" "}
            Rename
          </chakra.span>
        </MenuItem>
      </Tooltip>
      {showRenameInput && (
        <ProjectModal
          projectName={projectName}
          isOpen={showRenameInput}
          onClose={() => setShowRenameInput(false)}
          onSubmit={(projectTitle) => submitNewName(projectTitle)}
          isSaving={isSaving}
          title="Rename project"
          type="rename"
        />
      )}
    </>
  );
};

const OverflowMenu = ({ projectData, isPinned }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={IconButton}
            icon={<MenuIconComponent />}
            fontSize="20px"
            pr="1"
          />
          <MenuList minW="120px" backgroundColor="primary">
            <RenameMenuItem
              shared={projectData.shared}
              projectId={projectData.uid}
              projectName={projectData.name}
            />
            <PinUnpinMenuItem projectId={projectData.uid} isPinned={isPinned} />
            <ArchiveMenuItem projectId={projectData.uid} />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

const CreatedByBlock = ({
  authorPhotoURL,
  authorDisplayName,
}: {
  authorPhotoURL: ProjectDoc["authorPhotoURL"];
  authorDisplayName: ProjectDoc["authorDisplayName"];
}) => {
  ({ authorDisplayName, authorPhotoURL } = handleProductNameAndIcon(
    authorDisplayName,
    authorPhotoURL
  ));
  return (
    <Flex pl="3" pt="3">
      <SharedWithAvatar
        displayName={authorDisplayName || "Anonymous user"}
        photoURL={authorPhotoURL || ""}
        size="2xs"
        tooltipPrefix="Created by "
      />
    </Flex>
  );
};

const FolderIconAndName = ({ uid, name }) => {
  return (
    <VStack>
      <Link to={`/${uid}?sortBy=date+added-desc`}>
        <Center>
          <Icon as={FolderIconComponent} width="40px" height="40px" mt="10px" />
        </Center>
        <Text
          noOfLines={2}
          fontSize="12px"
          fontWeight="400"
          textAlign="center"
          px={4}
          pt={6}
          wordBreak="break-word"
        >
          {name}
        </Text>
      </Link>
    </VStack>
  );
};

/**
 * @param projectData - The project data
 * @param isFirstItem - Whether the project is the first item in the list (used for Product Tour)
 * @param isPinned - Whether the project is pinned
 * @returns The project list card
 */
export const ProjectListCard = ({
  projectData,
  isFirstItem,
  isPinned,
}: {
  projectData: ProjectDoc;
  isFirstItem: boolean;
  isPinned: boolean;
}) => {
  const { currentUser } = useAuth();
  return (
    <Card
      variant="unstyled"
      rounded="md"
      className={isFirstItem ? "isFirstItem" : ""}
      width="164px"
      height="164px"
    >
      <CardBody>
        <Flex>
          {currentUser.uid !== projectData.createdBy && (
            <CreatedByBlock
              authorDisplayName={projectData.authorDisplayName}
              authorPhotoURL={projectData.authorPhotoURL}
            />
          )}
          <Spacer />
          <OverflowMenu projectData={projectData} isPinned={isPinned} />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <FolderIconAndName uid={projectData.uid} name={projectData.name} />
          <Spacer />
        </Flex>
      </CardBody>
    </Card>
  );
};

export function handleProductNameAndIcon(
  authorDisplayName: string | undefined,
  authorPhotoURL: string | undefined
) {
  if (authorDisplayName === "$PRODUCT_NAME") {
    authorDisplayName = customization.name;
  }
  if (authorPhotoURL === "$PRODUCT_ICON") {
    authorPhotoURL = customization.meta.icon;
  }
  return { authorDisplayName, authorPhotoURL };
}
