import { getCustomizationData } from "src/Components/Utils/customizationUtils";
import {
  copyFormattedText,
  generateMarkdownHtml,
} from "src/Components/Utils/FormatCopiedText";

const customization = getCustomizationData();

/**
 * Creates a map of article IDs to reference numbers based on the provided reference data and message content.
 *
 * @param {Array} referenceData - The reference data containing information about each chunk.
 * @param {string} messageContent - The message content to search for list prefixes to know which articles are cited.
 * @returns {Object} - The map of article IDs to reference numbers.
 */
export const makeArticleIdToReferenceNumberMap = (
  referenceData,
  messageContent
) => {
  if (!referenceData) {
    return {};
  }
  let articleCounter = 1;
  const articleIdToReferenceNumberMap = {};
  // for each chunk
  // console.log("referenceData: ", referenceData);
  referenceData.forEach((chunkData) => {
    // if the messageContent includes the listPrefix, which is [1](#1) for example, then
    if (messageContent.includes(chunkData.listPrefix)) {
      // if the articleId is not in the map yet, add it with increasing counter
      if (!articleIdToReferenceNumberMap[chunkData.articleId]) {
        articleIdToReferenceNumberMap[chunkData.articleId] = articleCounter;
        articleCounter++;
      }
    }
  });
  return articleIdToReferenceNumberMap;
};

export const makeChunkToArticleReferenceNumberMap = (
  referenceData,
  articleIdToReferenceNumberMap
) => {
  if (!referenceData) {
    return {};
  }
  const chunkToArticleReferenceNumberMap = {};
  // for each chunk
  referenceData.forEach((chunkData) => {
    // if the articleId is in the map
    if (articleIdToReferenceNumberMap[chunkData.articleId]) {
      // add the chunkId to the map with the value of the article reference number (e.g. 1, 2, etc.)
      chunkToArticleReferenceNumberMap[chunkData.referenceId] =
        articleIdToReferenceNumberMap[chunkData.articleId];
    }
  });
  return chunkToArticleReferenceNumberMap;
};

// Types for better readability
type MessageData = {
  content: string;
  referenceData: Array<{
    articleId: string;
    articleTitle: string;
    articleUrl: string;
  }>;
};

type ReferenceMap = Record<string, number>;

const processReferences = (
  words: string[],
  chunkToArticleReferenceNumberMap: ReferenceMap
): string => {
  let formattedText = "";

  words.forEach((word) => {
    const matchPattern = /\[(\d+)\]\(#(\d+)\)/g;
    const updatedWord = word.replace(matchPattern, (_match, p1) => {
      const refNumber = chunkToArticleReferenceNumberMap[`#${p1}`];
      return `[${refNumber}]`;
    });
    formattedText += updatedWord + " ";
  });

  return formattedText;
};

const generateCitations = (
  articleIdToReferenceNumberMap: ReferenceMap,
  referenceData: MessageData["referenceData"]
): string => {
  let citedRefText = "";

  Object.entries(articleIdToReferenceNumberMap).forEach(
    ([articleId, referenceNumber]) => {
      const articleData = referenceData.find((r) => r.articleId === articleId);
      if (articleData) {
        if (!customization.assistant.removeReferenceLinks) {
          citedRefText += `<p>[${referenceNumber}] ${articleData.articleTitle}<br/>${articleData.articleUrl}</p>\n`;
        } else {
          citedRefText += `<p>[${referenceNumber}] ${articleData.articleTitle}</p>\n`;
        }
      }
    }
  );

  return citedRefText;
};

export const replaceReferenceCiteWithMatchingReferenceLink = ({
  msg,
  chunkToArticleReferenceNumberMap,
  articleIdToReferenceNumberMap,
}) => {
  // console.log("msg: ", msg.content);
  if (!msg.content) return "";

  let formattedText = processReferences(
    msg.content.split(" "),
    chunkToArticleReferenceNumberMap
  );

  // Add citations if they exist
  if (Object.entries(articleIdToReferenceNumberMap).length > 0) {
    const citations = generateCitations(
      articleIdToReferenceNumberMap,
      msg.referenceData
    );
    formattedText = `${formattedText}\n\n<div class="citation-section"><h3>Citations:</h3>${citations}</div>`;
  }

  const markdownHtml = generateMarkdownHtml(formattedText);
  // copyToClipboard(formattedText, markdownHtml);
  copyFormattedText(formattedText);

  return {
    plainText: formattedText,
    html: markdownHtml,
  };
};
