import { Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { saveRequestModule } from "../../db/moduleRequest";
import RequestModuleIcon from "../Icons/RequestModuleIcon";
import RequestModuleModal from "./RequestModuleModal";

export const RequestModuleBttn = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const userSubmittedRequest = (data) => {
    saveRequestModule(data);
  };

  const currentSearchParams = new URLSearchParams(history.location.search);
  const isOnAiModulesTab = currentSearchParams.get("tab") === "custom-ai";
  const isOnLibraryPage = history.location.pathname === "/library";

  if (!isOnAiModulesTab && !isOnLibraryPage) {
    return null;
  }
  return (
    <Flex
      position="fixed"
      bottom="3%"
      right="3%"
      maxW="245px"
      minW="245px"
      w="100%"
      userSelect="none"
      cursor="pointer"
      borderRadius="4px"
      onClick={onOpen}
    >
      <Flex w="100%" bg="white" p="16px" flexDir="column" gap="8px">
        <Flex fontSize="12px" align="center" gap="8px">
          <RequestModuleIcon />
          <Heading fontSize="12px" fontWeight="500">
            Request support for a module
          </Heading>
        </Flex>
        <Text color="#191D30" fontSize="10px" opacity="0.8">
          {` Provide us with the specifics, and we'll build the perfect module for
          you.`}
        </Text>
      </Flex>
      <RequestModuleModal
        onClose={onClose}
        isOpen={isOpen}
        userSubmittedRequest={userSubmittedRequest}
      />
    </Flex>
  );
};
