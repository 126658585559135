import {
  CollectionReference,
  DocumentReference,
  FirestoreDataConverter,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { AiModulePermission } from "shared/aiModule";
import { v4 } from "uuid";
import { db } from "../../firebase"; // Ensure your firebase config and initialization is correctly imported
import { MultistepModuleDefinition } from "./multistepAiModuleTypes";

// Main aiModules collection
interface AiModule {
  title: string;
  description: string;
  activeVersionId: string;
  versions: CollectionReference<Version>;
  nVersions: number;
  aiModulePermissions: CollectionReference<AiModulePermission>;
}

// Version document
export interface Version {
  versionNumber: number;
  createdAt: number;
  createdBy: string;
}

// Presentation data
export interface Presentation {
  title: string;
  description: string;
  userMessage: string;
  variables?: { [key: string]: { description: string; optional: boolean } };
}

// Instruction data
export interface Instruction {
  systemMessage: string;
  temperature: number;
  fullTextMode: boolean;
}

// ModuleData collection documents
type ModuleData = Presentation | Instruction | MultistepModuleDefinition;

const aiModuleConverter: FirestoreDataConverter<AiModule> = {
  toFirestore: (aiModule: AiModule) => aiModule,
  fromFirestore: (snapshot) => snapshot.data() as AiModule,
};

export const presentationConverter: FirestoreDataConverter<Presentation> = {
  toFirestore: (presentation: Presentation) => presentation,
  fromFirestore: (snapshot) => snapshot.data() as Presentation,
};

export const definitionConverter: FirestoreDataConverter<MultistepModuleDefinition> =
  {
    toFirestore: (definition: MultistepModuleDefinition) => definition,
    fromFirestore: (snapshot) => snapshot.data() as MultistepModuleDefinition,
  };

const instructionConverter: FirestoreDataConverter<Instruction> = {
  toFirestore: (instruction: Instruction) => instruction,
  fromFirestore: (snapshot) => snapshot.data() as Instruction,
};

export const aiModulesCollection: CollectionReference<AiModule> = collection(
  db,
  "aiModules"
).withConverter(aiModuleConverter);

export const versionsSubCollection = (
  moduleId: string
): CollectionReference<Version> =>
  collection(
    doc(aiModulesCollection, moduleId),
    "versions"
  ) as CollectionReference<Version>;

export const permissionsSubCollection = (
  moduleId: string
): CollectionReference<AiModulePermission> =>
  collection(
    doc(aiModulesCollection, moduleId),
    "aiModulePermissions"
  ) as CollectionReference<AiModulePermission>;

export const moduleDataSubCollection = (
  moduleId: string,
  versionId: string,
  docType: "presentation" | "instruction" | "definition"
): DocumentReference<ModuleData> =>
  doc(
    collection(doc(versionsSubCollection(moduleId), versionId), "moduleData"),
    docType
  ) as DocumentReference<ModuleData>;

export const getModuleDefinitionRef = (
  moduleId: string,
  versionId: string
): DocumentReference<MultistepModuleDefinition> =>
  moduleDataSubCollection(moduleId, versionId, "definition").withConverter(
    definitionConverter
  );

// Example Functions
export async function addNewVersion(moduleId: string, version: Version) {
  const versionRef: DocumentReference<Version> = doc(
    versionsSubCollection(moduleId)
  );
  await setDoc(versionRef, version);
}

export async function getModulePermissions(moduleId: string) {
  const permissionsSnapshot = await getDocs(permissionsSubCollection(moduleId));
  return permissionsSnapshot.docs.map(
    (doc) => doc.data() as AiModulePermission
  );
}

// Function to create a new module
export async function createNewModule({
  title,
  description,
  systemMessage,
  userMessage,
  temperature,
  variable,
  variableDescription,
  variableIsOptional,
  userId,
  userEmail,
}: {
  title: string;
  description: string;
  systemMessage: string;
  userMessage: string;
  temperature: number;
  variable?: string;
  variableDescription?: string;
  variableIsOptional?: boolean;
  userId: string;
  userEmail: string;
}) {
  const moduleId = v4();

  // Create a permission document for the user
  const userPermission: AiModulePermission = {
    role: "owner",
    userId,
    addedBy: userId,
    addedAt: Date.now(),
    userEmail,
  };
  const permissionRef = doc(permissionsSubCollection(moduleId), userId);
  await setDoc(permissionRef, userPermission);

  // Create an initial version for the module
  const versionId = v4();
  const initialVersion: Version = {
    versionNumber: 1,
    createdAt: Date.now(),
    createdBy: userId,
  };

  // Add the initial version to the versions sub-collection
  const versionRef = doc(versionsSubCollection(moduleId), versionId);
  await setDoc(versionRef, initialVersion);

  // Define the new module object
  const newModule: Omit<AiModule, "versions" | "aiModulePermissions"> = {
    title,
    description,
    nVersions: 1,
    activeVersionId: versionRef.id,
  };

  // Add the new module to the aiModules collection
  const moduleRef = doc(aiModulesCollection, moduleId);
  await setDoc(moduleRef, newModule);

  // Add Presentation document to the moduleData sub-collection
  const presentation: Presentation = {
    title,
    description,
    userMessage,
  };
  if (variable && variableDescription && variableIsOptional !== undefined) {
    presentation.variables = {
      [variable]: {
        description: variableDescription,
        optional: variableIsOptional,
      },
    };
  }
  const presentationRef = moduleDataSubCollection(
    moduleId,
    versionId,
    "presentation"
  ).withConverter(presentationConverter);
  await setDoc(presentationRef, presentation);

  // Add Instruction document to the moduleData sub-collection
  const instruction: Instruction = {
    systemMessage,
    temperature,
    fullTextMode: true,
  };

  const instructionRef = moduleDataSubCollection(
    moduleId,
    versionId,
    "instruction"
  ).withConverter(instructionConverter);
  await setDoc(instructionRef, instruction);
}

export const releaseNewModuleVersion = async ({
  moduleId,
  title,
  description,
  systemMessage,
  userMessage,
  temperature,
  variable,
  variableDescription,
  variableIsOptional,
  userId,
}: {
  moduleId: string;
  title: string;
  description: string;
  systemMessage: string;
  userMessage: string;
  temperature: number;
  variable?: string;
  variableDescription?: string;
  variableIsOptional?: boolean;
  userId: string;
}) => {
  // Get the active version of the module
  const moduleRef = doc(aiModulesCollection, moduleId);
  const moduleSnapshot = await getDoc(
    moduleRef.withConverter(aiModuleConverter)
  );
  const moduleData = moduleSnapshot.data();

  if (!moduleData) {
    throw new Error("Module not found");
  }
  // Create a new version
  const newVersion: Version = {
    // if moduleData.nVersions is NaN, assume that there was only one version
    versionNumber: isNaN(moduleData.nVersions) ? 2 : moduleData.nVersions + 1,
    createdAt: Date.now(),
    createdBy: userId,
  };
  const newVersionRef = await addDoc(
    versionsSubCollection(moduleId),
    newVersion
  );

  // Add Presentation document to the moduleData sub-collection
  const presentation: Presentation = {
    title,
    description,
    userMessage,
  };
  if (variable && variableDescription && variableIsOptional !== undefined) {
    presentation.variables = {
      [variable]: {
        description: variableDescription,
        optional: variableIsOptional,
      },
    };
  }
  const presentationRef = moduleDataSubCollection(
    moduleId,
    newVersionRef.id,
    "presentation"
  ).withConverter(presentationConverter);
  await setDoc(presentationRef, presentation);

  // Add Instruction document to the moduleData sub-collection
  const instruction: Instruction = {
    systemMessage,
    temperature,
    fullTextMode: true,
  };
  const instructionRef = moduleDataSubCollection(
    moduleId,
    newVersionRef.id,
    "instruction"
  ).withConverter(instructionConverter);
  await setDoc(instructionRef, instruction);

  // Update the module's active version
  await updateDoc(moduleRef, {
    activeVersionId: newVersionRef.id,
    nVersions: newVersion.versionNumber,
  });
};
