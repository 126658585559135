import React, { createContext, useContext } from "react";
import { ProjectPermissionDoc } from "shared/projectTypes";
import { UserPermissions } from "src/data/PermissionData";
import { useProjectContext } from "./ProjectContext";

export const isOwner = (permission: ProjectPermissionDoc | undefined) => {
  if (!permission) {
    return false;
  }
  return permission.permission === "owner";
};

export const canEdit = (permission: ProjectPermissionDoc | undefined) => {
  if (!permission) {
    return false;
  }
  return permission.permission === "canEdit" || isOwner(permission);
};

export const canRead = (permission: ProjectPermissionDoc | undefined) => {
  if (!permission) {
    return false;
  }
  return permission.permission === "canView" || canEdit(permission);
};

export const isNone = (permission: ProjectPermissionDoc | undefined) => {
  if (!permission) {
    return true;
  }
  return false;
};

type UserPermissionContextType = {
  userPermission: (typeof UserPermissions)[keyof typeof UserPermissions];
  isReader: () => boolean;
  isEditor: () => boolean;
  isOwner: () => boolean;
  isNone: () => boolean;
};

const UserPermissionContext = createContext<
  UserPermissionContextType | undefined
>(undefined);

export const UserPermissionProvider = ({ children }) => {
  const { currentProjectData } = useProjectContext();
  let userPermission = UserPermissions.LOADING;
  if (currentProjectData && currentProjectData.permission) {
    userPermission = currentProjectData.permission.permission;
  } else {
    userPermission = UserPermissions.NONE;
  }

  if (!currentProjectData) {
    return <></>;
  }

  return (
    <UserPermissionContext.Provider
      value={{
        userPermission,
        isReader: () => canRead(currentProjectData.permission),
        isEditor: () => canEdit(currentProjectData.permission),
        isOwner: () => isOwner(currentProjectData.permission),
        isNone: () => isNone(currentProjectData.permission),
      }}
    >
      {children}
    </UserPermissionContext.Provider>
  );
};

export const useUserPermission = () => {
  const context = useContext(UserPermissionContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserPermissionProvider");
  }
  return context;
};

export default UserPermissionContext;
