import { Button, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useNotification from "../../hooks/useNotification";
// import { useLimitations } from "../../hooks/useLimitations";
import {
  createCheckoutSession,
  getPrice,
} from "@invertase/firestore-stripe-payments";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { payments, useDeleteSubscription } from "src/services/StripeSvc";
import {
  useActiveWorkspaceId,
  useActiveWorkspaceSubscription,
} from "src/stores/userWorkspaces";
import RocketShipIcon from "../Icons/RocketShip";
import { Plan, plansList } from "../UserPage/Plans/plansLists";
import {
  PlanButton,
  WorkspacePlanCard,
} from "../UserPage/Plans/WorkspacePlanCard";
import { formatDateStripe } from "../Utils/dateutils";
import { WorkspaceMembers } from "../Workspace/Members";
import { WorkspaceOverview } from "../Workspace/Overview";
import { WorkSpaceMenuToggle } from "../Workspace/ToggleMenu";
// import { handleStripeAiCreditsPayment } from "../../services/StripeSvc";

export const Workspace = () => {
  const history = useHistory();
  const location = useLocation(); // Use useLocation to get the current location
  const { notify } = useNotification();
  const searchParams = location.search;
  const query = new URLSearchParams(history.location.search);
  const [workSpacetab, setWorkSpacetab] = useState(query.get("workspacetab"));
  const paymentStatus = query.get("payment");

  useEffect(() => {
    if (paymentStatus === "success") {
      notify({
        title: "Success",
        description: "Payment successful",
        status: "success",
      });
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("payment");
      history.replace(newUrl.pathname + newUrl.search);
    }

    if (paymentStatus === "cancel") {
      notify({
        title: "Error",
        description: "Payment cancelled by user",
        status: "error",
      });
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("payment");
      history.replace(newUrl.pathname + newUrl.search);
    }
  }, [paymentStatus]);

  useEffect(() => {
    setWorkSpacetab(query.get("workspacetab"));
  }, [searchParams]);

  return (
    <Flex flexDir="column" w="100%">
      <WorkSpaceMenuToggle />
      {workSpacetab === "members" ? (
        <WorkspaceMembers />
      ) : (
        <>
          <WorkspaceOverview display={workSpacetab === "overview"} />
          <PlansSection />
        </>
      )}
    </Flex>
  );
};

const useInitiatePlanPayment = () => {
  const activeWorkspaceId = useActiveWorkspaceId();
  if (!activeWorkspaceId) {
    throw new Error("No active workspace id");
  }
  return useMutation({
    mutationFn: async (plan: Plan) => {
      console.log(
        `Getting price for ${plan.name}; productId: ${plan.productId}; priceId: ${plan.priceId}`
      );
      const price = await getPrice(payments, plan.productId!, plan.priceId!);
      console.log(
        `Creating checkout session for ${plan.name}, price: ${price.id}`
      );
      const session = await createCheckoutSession(payments, {
        price: price.id,
        success_url: `${window.location.origin}/user/profile/workspace?payment=success`,
        cancel_url: `${window.location.origin}/user/profile/workspace?payment=cancel`,
        metadata: {
          workspaceId: activeWorkspaceId,
        },
      });
      console.log("Checkout session created:", session);
      if (session) {
        console.log("Redirecting to checkout session:", session.url);
        window.location.assign(session.url);
      } else {
        throw new Error("Error creating subscription.");
      }
    },
    onError: (error) => {
      console.error("Payment initiation failed:", error);
      // You can add error handling logic here, such as showing a notification
    },
  });
};

function PlansDetails() {
  // const [isSubscribing, setIsSubscribing] = useState(false);
  // const {
  //   isOpen: isOpenDowngrade,
  //   onOpen: onOpenDowngrade,
  //   onClose: onCloseDowngrade,
  // } = useDisclosure();

  const { activePlan, subscription, subscriptionId } =
    useActiveWorkspaceSubscription();
  const { mutateAsync: deleteSubscription, isPending: isDeletingSubscription } =
    useDeleteSubscription();
  const { mutateAsync: initiatePayment, isPending: isInitiatingPayment } =
    useInitiatePlanPayment();

  const currentPlan = plansList[activePlan || "free"];

  return (
    <Flex
      gap={0}
      w={{
        base: "90%",
        "2xl": "75%",
        lg: "80%",
      }}
      align="center"
    >
      <WorkspacePlanCard
        icon={plansList.free.icon}
        title={"No plan"}
        // priceMonth={plansList.free.priceMonthUsd}
        priceSection={
          <Text pb="26px" fontSize="35px" letterSpacing="0" fontWeight="medium">
            Free
          </Text>
        }
        perks={plansList.free.perks}
        isCurrentPlan={currentPlan.id === plansList.free.id}
        actionButtonWhenCurrent={<PlanButton isDisabled>Current</PlanButton>}
        actionButtonWhenNotCurrent={
          subscription?.cancel_at_period_end ? null : (
            <PlanButton
              onClick={async () => {
                await deleteSubscription({
                  subscriptionId,
                });
              }}
              isLoading={isDeletingSubscription}
            >
              Downgrade
            </PlanButton>
          )
        }
      />
      <WorkspacePlanCard
        icon={plansList.starter.icon}
        title={plansList.starter.name}
        // priceMonth={plansList.starter.priceMonthUsd}
        priceSection={
          <Flex align="center" gap="2px" pb="26px">
            <Text fontSize="35px" letterSpacing="0" fontWeight="medium">
              ${plansList.starter.priceMonthUsd}
            </Text>
            <Text fontSize="13px" color="#727272">
              /month
            </Text>
          </Flex>
        }
        perks={plansList.starter.perks}
        isCurrentPlan={currentPlan.id === plansList.starter.id}
        actionButtonWhenCurrent={<PlanButton isDisabled>Current</PlanButton>}
        actionButtonWhenNotCurrent={
          <PlanButton
            onClick={async () => {
              console.log("initiating payment for starter plan");
              return await initiatePayment(plansList.starter);
            }}
            isLoading={isInitiatingPayment}
          >
            Upgrade
          </PlanButton>
        }
      />
      <WorkspacePlanCard
        icon={(props) => <RocketShipIcon {...props} />}
        title={"Enterprise"}
        priceSection={
          <Text pb="26px" fontSize="35px" letterSpacing="0" fontWeight="medium">
            Bespoke
          </Text>
        }
        perks={[
          {
            value: "Workshops",
          },
          {
            value: "Audit & strategy",
          },
          {
            value: "Buy platform, components, or white label",
          },
          {
            value: "Buy & deploy the assistant only on your existing stack",
          },
          {
            value: "Project based customization",
          },
          {
            value: "Custom integrations with your stack",
          },
          {
            value: "Custom AI capabilities",
          },
        ]}
        isCurrentPlan={false}
        actionButtonWhenNotCurrent={
          <PlanButton
            onClick={() =>
              window.open(
                "https://calendly.com/mosaiqlabs/consultation-call-with-mosaiq",
                "_blank"
              )
            }
          >
            Book a call
          </PlanButton>
        }
      />
      {/* <DowngradePlanModal
        isOpen={isOpenDowngrade}
        onClose={onCloseDowngrade}
        currentPlan={currentPlan}
        newPlan={newPlan}
        onConfirm={cancelSubscription}
      /> */}
    </Flex>
  );
}

const PlansSection = () => {
  return (
    <React.Fragment>
      <Flex pb="16px">
        <Heading fontSize="24px">Plan</Heading>
      </Flex>
      <Flex flexDir="column">
        <Flex flexDir="column" w="696px">
          <Text fontSize="14px" fontWeight="semibold" pb="14px">
            Workspace subscription
          </Text>
          <CurrentWorkspacePlan />
        </Flex>
        <PlansDetails />
      </Flex>
    </React.Fragment>
  );
};

function CurrentWorkspacePlan() {
  const { subscription, activePlan, subscriptionId } =
    useActiveWorkspaceSubscription();
  const { mutateAsync: deleteSubscription, isPending: isDeletingSubscription } =
    useDeleteSubscription();

  if (!subscription) {
    return null;
  }

  if (!subscription.current_period_end) {
    return null;
  }

  const currentPeriodEnd = subscription.current_period_end.seconds * 1000;

  return (
    <Flex pb="56px" w="100%">
      <Grid
        templateColumns="120px 1fr"
        templateRows="auto auto"
        gap="8px"
        bg="white"
        py="16px"
        px="20px"
        borderRadius="md"
        w="100%"
        alignItems="start"
      >
        <GridItem>
          <Text fontWeight="semibold" fontSize="13px">
            Your current plan
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="12px">
            {subscription.cancel_at_period_end
              ? `Subscription is cancelled. Premium status will expire on ${formatDateStripe(
                  currentPeriodEnd
                )}`
              : `Your next bill is due on ${formatDateStripe(
                  currentPeriodEnd
                )}`}
          </Text>
        </GridItem>

        <GridItem alignSelf={"flex-end"}>
          <Text fontSize="12px" opacity="0.54">
            {plansList[activePlan].name}
          </Text>
        </GridItem>

        <GridItem
          alignSelf={"end"}
          alignItems={"end"}
          alignContent={"end"}
          p={0}
        >
          {!subscription.cancel_at_period_end && (
            <Button
              variant="unstyled"
              textDecoration="underline"
              fontSize="10px"
              onClick={async () => {
                await deleteSubscription({
                  subscriptionId,
                });
              }}
              isLoading={isDeletingSubscription}
              height="fit-content"
              w="fit-content"
              padding="0"
              fontWeight="normal"
            >
              Cancel subscription
            </Button>
          )}
        </GridItem>
      </Grid>
    </Flex>
  );
}
