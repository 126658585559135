import {
  Alert,
  AlertDescription,
  Box,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIconComponent from "../Components/Icons/ArrowBackIconComponent";
import Layout from "../Components/Layout";
import InsightSide, { PAGES } from "../Components/insights/InsideSide";
import { useArticle } from "../Context/ArticleContext";
import { useAssistantContext } from "../Context/AssistantContext";
import { getArticleManualHighlights } from "../db/article";
import PdfExclusiveViewer from "./PdfExclusiveViewer";

const ViewerPage = () => {
  const history = useHistory();
  const searchParams = history.location?.state?.params ?? "";
  let { projectId, articleId } = useParams();
  // const location = useLocation();
  const [manualHighlights, setManualHighlights] = useState([]);
  const { assistantIsOpen, setAssistantIsOpen } = useAssistantContext();
  // temporary change set to true
  const [menuIsOpen, setMenuIsOpen] = useState(!assistantIsOpen);
  // temporary change set to true
  const [menuIsExpanded, setMenuIsExpanded] = useState(!assistantIsOpen);
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const { activeArticle } = useArticle();

  // load manual extractions for the opened article
  useEffect(() => {
    const unsubscribe = getArticleManualHighlights({
      projectId,
      articleId,
      callback: setManualHighlights,
    });
    return () => {
      console.log("Unsubscribing from manual extractions");
      unsubscribe();
    };
  }, [projectId, articleId]);

  // handle changing the URL to a different articles
  const routerHandler = (article) => {
    history.push({
      pathname: `/${projectId}/${article.uid}/viewer`,
      state: {
        articleData: article,
        params: searchParams,
      },
    });
  };

  useEffect(() => {
    if (assistantIsOpen) {
      setMenuIsOpen(false);
      setMenuIsExpanded(false);
    } else {
      setMenuIsOpen(true);
      setMenuIsExpanded(true);
    }
  }, [assistantIsOpen]);

  useEffect(() => {
    if (menuIsExpanded) {
      setAssistantIsOpen(false);
    }
  }, [menuIsExpanded]);

  if (!activeArticle) {
    return null;
  }

  return (
    <Layout>
      <Box bgColor="#F8F9FA" mt={3} overflowY="hidden">
        <Grid
          templateColumns="repeat(48, 1fr)"
          height="81vh"
          className="webkit-scrollbar-display-none"
          backgroundColor="transparent"
        >
          <GridItem colSpan={1} mx={0}>
            <VStack align="start" color="black" py="1">
              <Flex
                gap="18px"
                cursor="pointer"
                color="primary"
                fontWeight="medium"
                onClick={() => {
                  history.push(`/${projectId}/${searchParams}`);
                }}
                mb={8}
                pt={2}
                pb={1}
                w="full"
                backgroundColor="transparent"
                pl={8}
              >
                <ArrowBackIconComponent color="primary" fontSize="24px" />
              </Flex>
              <Flex pl={2}>
                <InsightSide
                  routerHandler={(article) => routerHandler(article)}
                  onMenuOpen={() => setMenuIsOpen(true)}
                  onMenuClose={() => setMenuIsOpen(false)}
                  menuIsOpen={menuIsOpen}
                  setMenuIsExpanded={setMenuIsExpanded}
                  defaultState="BUTTON"
                  page={PAGES.VIEWER}
                  menuIsExpanded={menuIsExpanded}
                />
              </Flex>
            </VStack>
          </GridItem>
          <GridItem colSpan={47}>
            <Flex direction="row" justify="space-between">
              <Box w="100%" py="1" className="viewerBox">
                <Box
                  // mt="4"
                  h="98vh"
                  overflowY="scroll"
                  className="webkit-scrollbar-display-none"
                  pb="10"
                >
                  <Flex w="100%">
                    <PdfExclusiveViewer
                      url={activeArticle.url}
                      pathToPdf={activeArticle.pathToPdf}
                      manualHighlights={manualHighlights}
                    />
                  </Flex>
                </Box>
              </Box>
              {isVisible && (
                <Alert
                  w="auto"
                  style={{
                    zIndex: 1,
                    position: "fixed",
                    bottom: "3%",
                    right: "1%",
                    transition: "right 0.3s",
                  }}
                  bg="white"
                  shadow="lg"
                  rounded="lg"
                >
                  <Box>
                    <AlertDescription>
                      <Text fontSize="12px" fontWeight="500">
                        {" "}
                        Not loading? Open page in{" "}
                        <Link
                          href={activeArticle?.url}
                          textDecoration="none"
                          isExternal
                          _hover={{ textDecoration: "none" }}
                        >
                          <chakra.span color="#08B2E3">browser</chakra.span>
                        </Link>
                      </Text>
                    </AlertDescription>
                  </Box>
                  <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    right={-2}
                    onClick={onClose}
                  />
                </Alert>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Layout>
  );
};

export default ViewerPage;
