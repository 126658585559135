import { Icon } from "@chakra-ui/react";
import React from "react";

const PreviewIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M1.33333 16C0.966667 16 0.652778 15.8694 0.391667 15.6083C0.130556 15.3472 0 15.0333 0 14.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H14.6667C15.0333 0 15.3472 0.130556 15.6083 0.391667C15.8694 0.652778 16 0.966667 16 1.33333V14.6667C16 15.0333 15.8694 15.3472 15.6083 15.6083C15.3472 15.8694 15.0333 16 14.6667 16H1.33333ZM1.33333 14.6667H14.6667V3.11111H1.33333V14.6667ZM8.00091 12.3333C6.81512 12.3333 5.75556 12.0137 4.82222 11.3744C3.88889 10.7351 3.20741 9.9055 2.77778 8.88553C3.20741 7.86555 3.88859 7.03704 4.82131 6.4C5.75404 5.76296 6.8133 5.44444 7.99909 5.44444C9.18488 5.44444 10.2444 5.76408 11.1778 6.40336C12.1111 7.04264 12.7926 7.87227 13.2222 8.89224C12.7926 9.91223 12.1114 10.7407 11.1787 11.3778C10.246 12.0148 9.1867 12.3333 8.00091 12.3333ZM8 11.2222C8.84444 11.2222 9.62184 11.0161 10.3322 10.6039C11.0426 10.1917 11.5985 9.62 12 8.88889C11.5985 8.15778 11.0426 7.58611 10.3322 7.17389C9.62184 6.76167 8.84444 6.55556 8 6.55556C7.15556 6.55556 6.37816 6.76167 5.6678 7.17389C4.95744 7.58611 4.40151 8.15778 4 8.88889C4.40151 9.62 4.95744 10.1917 5.6678 10.6039C6.37816 11.0161 7.15556 11.2222 8 11.2222ZM8.00262 10C8.31199 10 8.57407 9.89172 8.78889 9.67516C9.0037 9.45861 9.11111 9.19565 9.11111 8.88627C9.11111 8.5769 9.00283 8.31481 8.78627 8.1C8.56972 7.88519 8.30676 7.77778 7.99738 7.77778C7.68801 7.77778 7.42593 7.88606 7.21111 8.10262C6.9963 8.31917 6.88889 8.58213 6.88889 8.89151C6.88889 9.20087 6.99717 9.46296 7.21373 9.67778C7.43028 9.89259 7.69324 10 8.00262 10Z" />
    </Icon>
  );
};

export default PreviewIconComponent;
