import React from "react";

import { AssistantDrawer } from "./AssistantDrawer";
import { OpenAssistantFloatingButton } from "./OpenAssistantFloatingButton";

const Assistant = () => {
  return (
    <>
      <OpenAssistantFloatingButton />
      <AssistantDrawer />
    </>
  );
};

export default Assistant;
