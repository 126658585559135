import { Box } from "@chakra-ui/react";
import React from "react";

const ColorBar = ({ value }) => {
  // Ensure the value is within 0 to 10
  const normalizedValue = Math.min(Math.max(value, 0), 10);

  // Calculate the width percentage
  const fillWidth = (normalizedValue / 10) * 100;

  // Determine the color based on the value
  const getBarColor = (value) => {
    if (value <= 3) {
      return "pink.300"; // Pastel red
    } else if (value > 3 && value < 7) {
      return "yellow.300"; // Pastel yellow
    } else {
      return "green.300"; // Pastel green
    }
  };

  return (
    <Box
      width="25px"
      height="4px"
      bg="gray.200"
      borderRadius="5px"
      overflow="hidden"
    >
      <Box
        width={`${fillWidth}%`}
        height="100%"
        bg={getBarColor(normalizedValue)}
        transition="width 0.3s ease-in-out"
      />
    </Box>
  );
};

export default ColorBar;
