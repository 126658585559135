import { Flex, GridItem, Text, Th } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

function getSortField() {
  const currentSearchParams = new URLSearchParams(location.search);
  const sortBy = currentSearchParams.get("sortBy");
  const sortField = sortBy?.split("-")[0].toLowerCase();
  return { sortBy, sortField, currentSearchParams };
}

/**
 * Updates the "sortBy" URL parameter with the given text and returns the updated search params string.
 * If the current "sortBy" parameter already starts with the given text, it toggles the sort order between ascending and descending.
 * If the current "sortBy" parameter is already the same as the given text with descending order, it removes the "sortBy" parameter.
 * @param {string} headerText - The text to update the "sortBy" parameter with.
 * @param {object} history - The history object from React Router.
 * @returns {string} - The updated search params string.
 */
const updateSortByParamsWithText = (headerText, history) => {
  const { sortBy, sortField, currentSearchParams } = getSortField();

  const headerTextLower = headerText.toLowerCase();

  if (
    // If the sortBy parameter is not set, or the sortField is different from the header text, or the sort order is descending
    // Set the sortBy parameter to the header text
    !sortBy ||
    sortField !== headerTextLower ||
    sortBy == `${headerTextLower}-desc`
  ) {
    currentSearchParams.set("sortBy", headerTextLower);
  } else if (sortBy.toLowerCase() === headerTextLower) {
    // If the sort order is already on the field ascending, set the sort order to descending
    currentSearchParams.set("sortBy", `${headerTextLower}-desc`);
  }
  history.replace(`?${currentSearchParams.toString()}`);
  return currentSearchParams.toString();
};

/**
 * Renders a table header cell with sorting functionality.
 * @param {string} text - The text to display in the header cell.
 * @param {boolean} props.notSortAble - Determines if the header cell is sortable or not.
 * @param {function} props.updateSortByParams - The function to call when sorting is updated.
 * @param {Object} props.history - The history object from React Router.
 * @returns {JSX.Element} - The TableHead component.
 */
export const TableHead = ({
  text,
  notSortAble,
  history,
  colSpan,
  isSelected,
  gridItemProps = {},
}) => {
  const [sortIsActive, setSortIsActive] = useState(false);
  const [updatedSortByText, setUpdatedSortByText] = useState();

  useEffect(() => {
    const { sortField } = getSortField();
    setSortIsActive(sortField === text.toLowerCase());
  }, [window.location.search]);

  // Extract the logic to determine the icon into a separate function
  const getSortIcon = () => {
    if (notSortAble) return null;

    if (!sortIsActive) {
      return <FiChevronDown cursor="pointer" fontSize="12px" color="#727272" />;
    }

    const isDescending = updatedSortByText?.split("-")[1] === "desc";

    if (isDescending) {
      return <FiChevronUp cursor="pointer" fontSize="12px" color="#727272" />;
    }

    return <FiChevronDown cursor="pointer" fontSize="12px" color="#727272" />;
  };

  /**
   * Updates sorting parameters based on the header text.
   * @param {string} headerText - The text of the header being sorted.
   */
  const updateSorting = (headerText) => {
    const res = updateSortByParamsWithText(headerText, history);
    const formattedResult = formatSortResponse(res);
    const newSortBy = extractSortByParam(formattedResult);
    setUpdatedSortByText(newSortBy);
  };

  /**
   * Formats the sorting response to remove unnecessary parameters.
   * @param {string} response - The response string from sorting.
   * @returns {string} - The formatted response.
   */
  const formatSortResponse = (response) => {
    return response.includes("&") ? response.split("&")[1] : response;
  };

  /**
   * Extracts the 'sortBy' parameter from the response.
   * @param {string} response - The response string.
   * @returns {string} - The 'sortBy' parameter.
   */
  const extractSortByParam = (response) => {
    return response.split("=")[1];
  };

  return (
    <GridItem
      userSelect="none"
      pb="10px"
      as={Th}
      colSpan={colSpan}
      overflow="hidden"
      pl="0"
      pr="0"
      {...gridItemProps}
    >
      <Flex
        align="center"
        justify="flex-start"
        gap={"2px"}
        onClick={notSortAble ? null : () => updateSorting(text)}
        cursor={notSortAble ? "default" : "pointer"}
      >
        <Text
          fontSize="12px"
          fontWeight={isSelected ? "600" : "normal"}
          textTransform="capitalize"
          letterSpacing="0"
          color={isSelected ? "black" : "#212121"}
        >
          {text}
        </Text>
        <Flex pt="2px">{getSortIcon()}</Flex>
      </Flex>
    </GridItem>
  );
};
