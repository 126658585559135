import { Center, Box, Heading, Text } from '@chakra-ui/react'
import Loader from "react-loader-spinner";
import React from 'react'

const PageLoader = ({title, body}) => {
    return (
        <Center mt={10}>
            <Box textAlign="center">
                <Center>
                    <Loader
                        type="ThreeDots"
                        color="#6B7280"
                        height="100"
                        width="100"
                    />
                </Center>
                <Heading fontSize="22px" fontWeight="bold">
                    {title}
                </Heading>
                <Text>{body}</Text>
            </Box>
        </Center>
    )
}

export default PageLoader
