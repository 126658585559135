import { createContext, useContext, useState, useEffect } from "react";

const ZoomContext = createContext();

export const ZoomContextProvider = ({ children }) => {
    const [zoomLevel, setZoomLevel] = useState(1);

    const implementZoom = (zoom) => {
        document.body.style.zoom = zoom;
    }

    useEffect(() => {
      const savedZoom = localStorage.getItem("ZOOM_LEVEL");
      console.log("Retrieved zoom level:", savedZoom);
      if (savedZoom) {
        const zoomValue = parseFloat(savedZoom);
        if (!isNaN(zoomValue)) {
          setZoomLevel(zoomValue);
          implementZoom(zoomValue);
        }
      }
    }, []);

    const updateZoom = (newZoom) => {
      localStorage.setItem("ZOOM_LEVEL", newZoom);
      setZoomLevel(newZoom);
      implementZoom(newZoom);
    };

  return (
    <ZoomContext.Provider
      value={{
        zoomLevel,
        updateZoom,
      }}
    >
       {children}
    </ZoomContext.Provider>
  );
};

export const useZoomContext = () => {
  const context = useContext(ZoomContext);
  if (context === undefined) {
    throw new Error("context must be used within a ZoomProvider");
  }
  return context;
};

export default ZoomContext;
