import { Button, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, doc, getDoc, updateDoc } from "../../firebase";

// const SETTINGS_DESCRIPTION = {
//   chunkSize: "Max number of tokens in 1 chunk",
//   chunkOverlap: "Number of tokens to overlap between consecutive chunks",
//   numChunks: "Number of chunks to load on each assistant response",
//   model: "Model used for assistant responses",
// };
const _SETTINGS_DESCRIPTION = {
  // chunkSize: "Max number of tokens in 1 chunk",
  // chunkOverlap: "Number of tokens to overlap between consecutive chunks",
  // numChunks: "Number of chunks to load on each assistant response",
  // model: "Model used for assistant responses",
  chunkSize: {
    name: "chunkSize",
    description: "Max number of tokens in 1 chunk",
    type: "number",
    order: 1,
  },
  chunkOverlap: {
    name: "chunkOverlap",
    description: "Number of tokens to overlap between consecutive chunks",
    type: "number",
    order: 2,
  },
  numChunks: {
    name: "numChunks",
    description: "Number of chunks to load on each assistant response",
    type: "number",
    order: 3,
  },
  model: {
    name: "model",
    description: "Model used for assistant responses",
    type: "enum",
    values: [
      "default",
      "llama3-70b-8192",
      "mixtral-8x7b-32768",
      "gemma-7b-it",
      "llama3-8b-8192",
      "llama2-70b-4096",
      "gemini-1.5-pro-001",
    ],
    order: 4,
  },
};

// Moved these utility functions outside of the component to avoid cluttering
// the component's main logic.
const updateProjectSettings = async ({ projectId, settings }) => {
  const projectRef = doc(db, `projects/${projectId}`);
  await updateDoc(projectRef, {
    settings: Object.fromEntries(
      Object.entries(settings).map(([key, value]) => [key, value.value])
    ),
  });
};

// Moved SettingControl outside of the main component for better separation of concerns.
// This will also potentially improve performance since SettingControl isn't re-created on every render.
const SettingControl = ({ setting, handleChange }) => {
  if (!setting) {
    return null;
  }

  if (setting.type === "number") {
    return (
      <FormControl>
        <FormLabel htmlFor={setting.name}>{setting.description}</FormLabel>

        <FormLabel htmlFor={setting.name} fontSize="xs" mb={1}>
          {setting.name}
        </FormLabel>
        <Input
          id={setting.name}
          name={setting.name}
          value={setting.value}
          onChange={handleChange}
          type="number"
        />
      </FormControl>
    );
  }

  if (setting.type === "enum") {
    console.log("setting :>> ", setting);
    return (
      <FormControl>
        <FormLabel htmlFor={setting.name}>{setting.description}</FormLabel>
        <Input
          id={setting.name}
          name={setting.name}
          value={setting.value}
          onChange={handleChange}
          as="select"
        >
          {setting.values.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Input>
      </FormControl>
    );
  }
};

const getDefaultSettings = async () => {
  // Removed unnecessary console.log
  const settingsDoc = await getDoc(doc(db, "settings/projectSettings"));
  return settingsDoc.exists() ? settingsDoc.data() : {};
};

const getProjectSpecificSettings = async (projectId) => {
  const projectDoc = await getDoc(doc(db, `projects/${projectId}`));
  return projectDoc.exists() ? projectDoc.data().settings : {};
};

const ProjectSettingsForm = ({ projectData, onClose }) => {
  const [settings, setSettings] = useState(projectData.settings || {});
  const [loading, setLoading] = useState(true);
  const { projectId } = useParams();

  useEffect(() => {
    const loadDefaultSettings = async () => {
      const defaultSettings = await getDefaultSettings();
      const projectSettings = await getProjectSpecificSettings(projectId);
      const currentSettings = { ..._SETTINGS_DESCRIPTION };

      for (const key in defaultSettings) {
        if (key in currentSettings) {
          currentSettings[key].value = defaultSettings[key];
        }
      }

      // set values from project settings
      for (const key in projectSettings) {
        if (key in currentSettings) {
          currentSettings[key].value = projectSettings[key];
        }
      }

      setSettings(currentSettings);
      setLoading(false);
    };

    loadDefaultSettings();
  }, []);

  // Removed localProject, as it's not needed. The projectData prop should not be mutated directly.

  const handleChange = (e) => {
    console.log("e :>> ", e);
    const { name, value } = e.target;
    const numberValue = isNaN(parseFloat(value)) ? value : parseFloat(value);
    // change value in settings object

    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: {
        ...prevSettings[name],
        value: numberValue,
      },
    }));
  };

  const handleSubmit = async () => {
    let updatedSettings = { ...settings };
    for (const key in updatedSettings) {
      if (updatedSettings[key].value === "") {
        delete updatedSettings[key];
      }
    }
    await updateProjectSettings({ projectId, settings });
    onClose();
  };

  console.log("settings :>> ", settings);

  if (loading) {
    return null;
  }

  return (
    <Stack spacing={4}>
      {/* {!loading &&
        Object.keys(settings)
          .sort()
          .map((setting) => (
            <SettingControl
              key={setting}
              id={setting}
              value={settings[setting]}
              label={setting}
              handleChange={handleChange}
            />
          ))} */}
      {Object.entries(settings)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([key, setting]) => (
          <SettingControl
            key={key}
            setting={setting}
            handleChange={handleChange}
          />
        ))}
      <Button
        borderColor="primary"
        variant="outline"
        color="primary"
        borderRadius="8px"
        fontSize="sm"
        size="sm"
        fontWeight="bold"
        bg="white"
        onClick={handleSubmit}
      >
        Save Settings
      </Button>
    </Stack>
  );
};

export default ProjectSettingsForm;
