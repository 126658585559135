export const Input = {
  variants: {
    filled: {
      field: {
        bg: 'white',
        _focus: {
          bg: 'white',
          borderColor: 'white',
        },
        _hover: {
          bg: 'white',
        },
        color: 'black',
      },
    },
  },
}
