import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  contents: [],
  curatedContents: [],
}

export const curatedSlice = createSlice({
  name: 'curateds',
  initialState,
  reducers: {
    saveProjectsContents: (state, action) => {
      state.contents = action.payload
    },
    saveCuratedContents: (state, action) => {
      state.curatedContents = action.payload
    },
  },
})

export const { saveProjectsContents, saveCuratedContents} = curatedSlice.actions

export default curatedSlice.reducer