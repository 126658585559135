import { Flex, Heading, Link, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

export const UserPageMenuBttn = ({
  buttonName,
  buttonId,
  BttnIcon,
  isComingSoon,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const currentId = pathname.split("/").pop();
  return (
    <Link
      as={isComingSoon ? "" : ReactRouterLink}
      to={
        buttonId !== "profile" ? `/user/profile/${buttonId}` : "/user/profile"
      }
      _hover={{ bg: "transparent" }}
      color={
        isComingSoon
          ? "gray.300"
          : currentId === `${buttonId}`
          ? "primary"
          : "textGrey"
      }
      px="0"
      py="2"
      w="fit-content"
    >
      <Tooltip hasArrow placement="right" label={isComingSoon && "Coming soon"}>
        <Flex align="center" h="fit-content" gap={4}>
          <BttnIcon fontSize="16px" />
          <Heading fontSize="13px" fontWeight="600">
            {buttonName}
          </Heading>
        </Flex>
      </Tooltip>
    </Link>
  );
};
