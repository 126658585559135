import React, { createContext, useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { v4 } from "uuid";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { ViewMode } from "../data/AssistantData";
import { db, doc, onSnapshot } from "../firebase";
import { useArticle } from "./ArticleContext";
import {
  OrganizerContextProvider,
  useOrganizerContext,
} from "./OrganizerContext";
import { useProjectContext } from "./ProjectContext";
import { TemplateContextProvider } from "./TemplateContext";

const customization = getCustomizationData();

const AssitantContext = createContext();

const enrichReferenceDataWithArticleData = (referenceData, articles) => {
  if (!referenceData) {
    return {};
  }
  const enrichedReferenceData = [];
  referenceData.forEach((chunkData) => {
    const articleData = articles.find((a) => a.uid === chunkData.articleId);
    enrichedReferenceData.push({
      ...chunkData,
      articleTitle: articleData?.title,
      articleUrl: articleData?.url,
      articleSource: articleData?.source,
      articleIcon: articleData?.icon,
      articleType: articleData?.type,
    });
  });
  return enrichedReferenceData;
};

/**
 * Adds article data to the reference data, so that the assistant can display the
 * article title, url, etc in the references.
 *
 * @param {*} messages
 * @param {*} referenceData
 * @param {*} articles
 * @returns
 */
export const injectReferenceData = (messages, referenceData, articles) => {
  if (!referenceData) {
    return messages;
  }

  const messagesWithReferenceData = messages.map((message, index) => {
    const enrichedReferenceData = enrichReferenceDataWithArticleData(
      referenceData[index],
      articles
    );
    if (referenceData[index]) {
      return {
        ...message,
        referenceData: enrichedReferenceData,
      };
    } else {
      return message;
    }
  });
  return messagesWithReferenceData;
};

export const injectFeedbackData = (messages, messageFeedback) => {
  if (!messageFeedback) {
    return messages;
  }
  const messagesWithFeedbackData = messages.map((message, index) => {
    if (messageFeedback[index]) {
      return {
        ...message,
        feedback: messageFeedback[index],
      };
    } else {
      return message;
    }
  });
  return messagesWithFeedbackData;
};

const DragDropWrapper = ({ children }) => {
  const { onDragEnd } = useOrganizerContext();
  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      {children}
    </DragDropContext>
  );
};
export const AssistantContextProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState("");
  const [conversationId, setConversationId] = useState("");
  // when subscribeToConversationDoc is true, it triggers subscription to the conversation doc
  // in the database
  const [subscribeToConversationDoc, setSubscribeToConversationDoc] =
    useState(false);
  const [conversationData, setConversationData] = useState(null);
  const [viewMode, setViewMode] = useState(ViewMode.CHAT);
  // const [hover, setHover] = useState(true);
  const [messages, setMessages] = useState([]);
  const [assistantIsOpen, setAssistantIsOpen] = useState(
    customization.assistant.isOpen
  );
  const [assistantWidth, setAssistantWidth] = useState(650);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { allUnfilteredArticles: articles } = useArticle();
  const [useOnlyChat, setUseOnlyChat] = useState(false);
  const [assistantIsFullScreen, setAssistantIsFullScreen] = useState(false);
  const { currentProjectId: projectId } = useProjectContext();

  useEffect(() => {
    // We can only subscribe to the conversation doc when the conversation has started.
    // This is because users can only access conversations with their userId (enforced by firestore.rules).
    // The conversation doc is created when first user message is sent, and this is when userId is set there.
    // For that reason we need to check if the conversation has started before subscribing to the doc,
    // otherwise we will get a database permission error.
    // Also, HISTORY mode switches conversationStarted to true, so we need to check
    // for that as well. santop 2023-09-04 Mon 17:56:14
    if (
      articles &&
      subscribeToConversationDoc &&
      viewMode !== ViewMode.HISTORY
    ) {
      console.log("Fetching messages for conversationId: ", conversationId);
      const conversationDocRef = doc(
        db,
        "projects",
        projectId,
        "conversations",
        conversationId
      );
      // Fetch messages for the given conversationId
      const unsubscribe = onSnapshot(conversationDocRef, (doc) => {
        if (!doc.exists()) {
          console.error("Empty conversation");
        } else {
          setConversationData(doc.data());
          let messages = doc.data().messages;
          messages = injectReferenceData(
            doc.data().messages,
            doc.data().referenceData,
            articles
          );
          messages = injectFeedbackData(messages, doc.data().messageFeedback);
          // inject "continue" flag to the last message
          // it is used for animating a loading spinner on the last message
          messages[messages.length - 1].continue = doc.data().continue;
          setMessages(messages);
        }
      });
      return () => unsubscribe();
    }
  }, [conversationId, articles, subscribeToConversationDoc]);

  useEffect(() => {
    if (!conversationId) {
      const newConversationId = v4();
      setConversationId(newConversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    const introMsg = customization.assistant.openingMessage;
    if (messages.length === 0) {
      setMessages([
        { role: "assistant", content: introMsg, disableFeedback: true },
      ]);
    }
  }, [messages]);

  // if there are no messages, add the intro message

  return (
    <AssitantContext.Provider
      value={{
        inputValue,
        setInputValue,
        conversationId,
        setConversationId,
        subscribeToConversationDoc,
        setSubscribeToConversationDoc,
        conversationData,
        setConversationData,
        viewMode,
        setViewMode,
        messages,
        setMessages,
        assistantIsOpen,
        setAssistantIsOpen,
        assistantWidth,
        setAssistantWidth,
        useOnlyChat,
        setUseOnlyChat,
        showUploadModal,
        setShowUploadModal,
        assistantIsFullScreen,
        setAssistantIsFullScreen,
      }}
    >
      <TemplateContextProvider>
        <OrganizerContextProvider>
          <DragDropWrapper>{children}</DragDropWrapper>
        </OrganizerContextProvider>
      </TemplateContextProvider>
    </AssitantContext.Provider>
  );
};

export const useAssistantContext = () => {
  const context = useContext(AssitantContext);
  if (context === undefined) {
    throw new Error("context must be used within a AssistantProvider");
  }
  return context;
};

export default AssitantContext;
