import {
  arrayUnion,
  auth,
  collection,
  db,
  doc,
  onSnapshot,
  or,
  q,
  setDoc,
  where,
} from "../firebase";

export const updateConversation = async (
  projectId,
  conversationId,
  message
) => {
  const response = await setDoc(
    doc(db, "projects", projectId, "conversations", conversationId),
    {
      messages: arrayUnion(message),
    },
    { merge: true }
  );
  return response;
};

export const saveAssistantChatRequest = async (
  projectId,
  conversationId,
  messageId
) => {
  const response = await setDoc(doc(db, "assistantChatRequest", messageId), {
    projectId,
    conversationId,
    userId: auth.currentUser.uid,
  });
  return response;
};

export const getAssistantConversations = (projectId, callback) => {
  let conversationsQuery = q(
    collection(db, `projects/${projectId}/conversations`),
    or(where("userId", "==", auth.currentUser.uid), where("shared", "==", true))
  );
  const unsubscribe = onSnapshot(conversationsQuery, (querySnapshot) => {
    const conversationsData = [];
    querySnapshot.forEach((doc) => {
      conversationsData.push({ ...doc.data(), uid: doc.id });
    });
    // sort by timestamp of the last message
    conversationsData.sort((a, b) => {
      const aLastMessage = a.messages[a.messages.length - 1];
      const bLastMessage = b.messages[b.messages.length - 1];
      return bLastMessage.createdAt - aLastMessage.createdAt;
    });

    console.log(
      "Updated conversations data from db for project",
      projectId,
      conversationsData
    );
    callback(conversationsData);
  });
  return unsubscribe;
};
