import { Box, Link, Tag } from "@chakra-ui/react";
import { getCustomizationData } from "../../Utils/customizationUtils";

const customization = getCustomizationData();

const CitationChips = ({ referenceData, articleIdToReferenceNumberMap }) => {
  if (!referenceData) {
    return null;
  }

  const chipsArray = [];
  Object.entries(articleIdToReferenceNumberMap).forEach((articleReference) => {
    const articleId = articleReference[0];
    const referenceNumber = articleReference[1];
    const articleData = referenceData.find((r) => r.articleId === articleId);
    let chipComponent = (
      <Tag
        size="md"
        m="1.5"
        textColor="gray.500"
        bg="white"
        borderRadius="30px"
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <span className="reference-bubble" style={{ marginRight: 4 }}>
            {referenceNumber}
          </span>
          {articleData?.articleTitle}
        </div>
      </Tag>
    );

    if (!customization.assistant.removeReferenceLinks) {
      chipComponent = (
        <Link href={articleData?.articleUrl} isExternal key={referenceNumber}>
          {chipComponent}
        </Link>
      );
    }

    chipsArray.push(chipComponent);
  });

  return <Box pt="4">{chipsArray}</Box>;
};

export default CitationChips;
