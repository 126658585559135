import { Button, Flex, Icon } from "@chakra-ui/react";
import React from "react";

import { MdAdd } from "react-icons/md";
import { useOrganizerContext } from "../../Context/OrganizerContext";

export function AddSectionBttn({ newSectionName, projectId }) {
  const { createNewSection } = useOrganizerContext();
  const newSectionData = {
    name: newSectionName,
    id: projectId,
  };
  return (
    <Flex justify="end" w="100%" mt="2">
      <Button
        border="1px solid #dcdcdc"
        w="fit-content"
        h="fit-content"
        px="16px"
        py="8px"
        fontSize="10px"
        color="black"
        leftIcon={<Icon as={MdAdd} />}
        onClick={() => {
          createNewSection(newSectionData);
        }}
      >
        Add Section
      </Button>
    </Flex>
  );
}
