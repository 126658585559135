import {
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import HowItWorksChart from "../../assets/howItWorksChart.png";

import { MdCircle } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useCustomAiModules } from "../../Context/CustomAiModuleContext";
import { updateOnboardingCompleted } from "../../db/projectTour";
import { AiModuleBox, StaticAiModuleBoxSample } from "../AiModules/AiModuleBox";
import { getCustomizationData } from "../Utils/customizationUtils";
import { addUrlParam } from "../Utils/urlUtils";
import { TestimonialCard } from "./components/TestimonialCard";
import { OnboardingCustomForm } from "./OnboardingCustomForm";
import { OnboardingFlowWrapper } from "./OnboardingFlowWrapper";

const BoldText = ({ children }) => {
  return (
    <Text as="span" fontWeight="black">
      {children}
    </Text>
  );
};

const customization = getCustomizationData();

export const Welcome = ({ setAllowForwardNav }) => {
  const { currentUser } = useAuth();
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  return (
    <OnboardingFlowWrapper
      height="auto"
      heading="lets get started!"
      intro={`Nice to meet you ${currentUser?.displayName}, welcome to ${customization.name}!`}
    />
  );
};

export const UsingMosaiQ = ({
  setAllowForwardNav,
  onFormChange,
}: {
  setAllowForwardNav: (allow: boolean) => void;
  onFormChange: ({
    formKey,
    checkedOptions,
  }: {
    formKey: string;
    checkedOptions: Record<string, string | boolean>;
  }) => void;
}) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  return (
    <OnboardingFlowWrapper
      heading="mosaiq labs"
      intro={`How will you use ${customization.name}?`}
    >
      <Flex pt="87px">
        <OnboardingCustomForm
          title={[
            {
              content: "What do you want to use the product for?",
              bold: true,
            },
          ]}
          optionsKey="motivationToUseProduct"
          allowNextStep={setAllowForwardNav}
          onFormChange={onFormChange}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const Industry = ({ setAllowForwardNav, onFormChange }) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  return (
    <OnboardingFlowWrapper heading="Hello!" intro="What industry are you in?">
      <Flex w="100%" mt="39px">
        <OnboardingCustomForm
          title={[
            {
              content: "What industry are you in?",
              bold: true,
            },
            {
              content:
                "We ask this to give you the best experience from the get go",
              bold: false,
              italic: true,
            },
          ]}
          optionsKey="inWhatIndustry"
          allowNextStep={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              console.log("hfhf");
              setAllowForwardNav(false);
            }
          }}
          onFormChange={onFormChange}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const HowCanWeHelp = ({
  setAllowForwardNav,
  onFormChange,
}: {
  setAllowForwardNav: (allow: boolean) => void;
  onFormChange: ({
    formKey,
    checkedOptions,
  }: {
    formKey: string;
    checkedOptions: Record<string, string | boolean>;
  }) => void;
}) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  return (
    <OnboardingFlowWrapper heading="MosaiQ Labs" intro="How can we help?">
      <Flex w="100%" mt="39px">
        <OnboardingCustomForm
          title={[
            {
              content: "If you had a magic wand,",
              bold: true,
            },
            {
              content: "what would you like AI to help you with in your work?",
              bold: false,
              italic: true,
            },
          ]}
          optionsKey="howCanAiHelp"
          allowNextStep={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              console.log("hfhf");
              setAllowForwardNav(false);
            }
          }}
          onFormChange={onFormChange}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      color="#191D30"
      textAlign="center"
      fontFamily="Inter"
      fontSize="12px"
      fontStyle="normal"
      fontWeight="400"
      lineHeight="normal"
      opacity="0.8"
    >
      {children}
    </Text>
  );
};

export const Testimonials = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const testimonial = [
    {
      nameAndTitle: "Lena - PR & Communications",
      headline: "80%",
      subHeadline: "Faster on Repetitive Tasks",
      testimony: () => {
        return (
          <TestimonialText>
            &quot;We&apos;ve managed to cover{" "}
            <BoldText>10x more content</BoldText> using MosaiQ&apos;s AI
            modules, and it has improved our brand strategy and audits
            significantly. The modules have really challenged our thinking and
            helped improve the quality of our work.&quot;
          </TestimonialText>
        );
      },
    },
    {
      nameAndTitle: "Mike - Consulting & Advisory",
      headline: "5x faster",
      subHeadline: "Proposal and deliverable creation",
      testimony: () => {
        return (
          <TestimonialText>
            &quot;We used to spend an entire day preparing intelligence reports
            and client deliverables, but with MosaiQ&apos;s AI modules, we now
            finish them up to <BoldText>5x faster</BoldText>, often in less than
            2 hours. It&apos;s made a huge difference in both our efficiency and
            the quality of our outputs.&quot;
          </TestimonialText>
        );
      },
    },
    {
      nameAndTitle: "John - Innovation Consultancy",
      headline: "3-10x",
      subHeadline: "More Content Covered",
      testimony: () => {
        return (
          <TestimonialText>
            &quot;With MosaiQ, we&apos;ve become <BoldText>80% faster</BoldText>{" "}
            on repetitive tasks like drafting PR reports and preparing key
            messages <BoldText>within the first three months</BoldText>.
            It&apos;s freed up so much time for me to focus on more strategic
            and creative work.&quot;
          </TestimonialText>
        );
      },
    },
  ];

  return (
    <OnboardingFlowWrapper
      heading="Testimonials"
      intro={`How others using ${customization.name}`}
      width="900x"
    >
      <Flex pt="40px">
        <Flex
          w="988px"
          gap="66px"
          bg="white"
          p="23px 32px"
          pt="39px"
          borderRadius="4px"
        >
          {testimonial.map((item, index) => (
            <TestimonialCard
              key={index}
              nameAndTitle={item.nameAndTitle}
              headline={item.headline}
              subHeadline={item.subHeadline}
              testimony={item.testimony}
            />
          ))}
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const AIAssistantRelevance = ({
  setAllowForwardNav,
  onFormChange,
}: {
  setAllowForwardNav: (allow: boolean) => void;
  onFormChange: ({
    formKey,
    checkedOptions,
  }: {
    formKey: string;
    checkedOptions: Record<string, string | boolean>;
  }) => void;
}) => {
  useEffect(() => {
    setAllowForwardNav(false);
  }, []);

  return (
    <OnboardingFlowWrapper
      heading="Your AI Assistant"
      intro="How urgent is it for you to adopt AI?  "
    >
      <Flex w="100%" mt="39px">
        <OnboardingCustomForm
          title={[
            {
              content: "Let's get into the magic.",
              bold: true,
            },
            {
              content: "You can select one or more options",
              bold: false,
              italic: true,
            },
          ]}
          optionsKey="aiAssistantRelevance"
          allowNextStep={(value) => {
            if (value) {
              setAllowForwardNav(true);
            } else {
              setAllowForwardNav(false);
            }
          }}
          onFormChange={onFormChange}
        />
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const OverviewOfMosaiQ = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const scalingPoints = [
    "A plug and play platform that supports your workflows",
    "A library of task-specific AI modules to chose from, matching your most precise needs",
    "A tool to create custom AI modules with zero technical knowledge enabling affordable and scalable customization",
    "A bundle of custom AI module creation, tailored by MosaiQ to fit your exact workflow needs; included in each plan",
  ];

  return (
    <OnboardingFlowWrapper
      heading={`Overview of the ${customization.name} ecosystem`}
      intro="A platform to scale AI in your work"
      width="fit-content"
      marginTop="-10%"
    >
      <Flex w="100%" mt="64px" gap="38px">
        <UnorderedList
          w="700px"
          minWidth="700px"
          maxWidth="700px"
          p="42px"
          bg="white"
          borderRadius="3.6px"
          gap="20px"
          display="flex"
          flexDir="column"
          ml="0 !important"
          styleType="none"
        >
          {scalingPoints.map((item, index) => (
            <ListItem
              fontSize="16px"
              key={index}
              position="relative"
              pl="20px"
              _before={{
                content: '""',
                position: "absolute",
                left: 0,
                top: "8px",
                width: "8px",
                height: "8px",
                backgroundColor: "#CCC9DC",
                borderRadius: "50%",
              }}
            >
              <Text opacity="0.54" lineHeight={"22px"}>
                {item}
              </Text>
            </ListItem>
          ))}
        </UnorderedList>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const HowItWorks = ({ setAllowForwardNav, onOpen }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  return (
    <OnboardingFlowWrapper
      heading={`Overview of the ${customization.name} ecosystem`}
      intro="How It works"
      showDemoBtt={true}
      width="900px"
      onOpen={onOpen}
      marginTop="-20%"
    >
      <Flex w="100%" mt="39px" gap="38px">
        <Flex bg="white" p="36px" borderRadius="4px">
          <Image src={HowItWorksChart} w="830px" h="100%" />
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const CustomModules = ({ setAllowForwardNav, onOpen }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);
  const customModulePoints = [
    "A module is special AI capability that plugs into the platform and workflow to accomplish tasks that you would normally do, the way you would do them",
    "Modules can range from simple assistants that handle repetitive, straightforward tasks to expert modules that support complex workflows with multiple steps",
    "Think of them as task-specific AI assistants, tailored to your company and employees, designed to enhance productivity & analysis by adapting to your unique processes",
  ];
  const sampleCustomModule = [
    {
      title: "Competitive analysis",
      description:
        "Identify leaders and analyze the opportunities and threats in this industry",
      accentColor: "#CCC9DC",
      width: "100%",
    },
    {
      title: "Sentiment analysis ",
      description:
        "Analyse the trend and sentiment of stakeholders towards the desired topic",
      accentColor: "#CCC9DC",
      width: "100%",
    },
    {
      title: "Legal Analysis",
      description:
        "Run an analysis of the sector to identify potential regulatory bottlenecks",
      accentColor: "#CCC9DC",
      width: "100%",
    },

    {
      title: "Investment memo draft",
      description:
        "Draft an investment memo that follows our framework based on this project's data",
      accentColor: "#CCC9DC",
      width: "100%",
    },
    {
      title: "Market and trends",
      description:
        "Analyse the market to identify the relevance of this company's offering ",
      accentColor: "#CCC9DC",
      width: "100%",
    },
  ];

  return (
    <OnboardingFlowWrapper
      heading={`Overview of the ${customization.name} ecosystem`}
      intro="What is a custom AI module?"
      showDemoBtt={true}
      width="1100px"
      onOpen={onOpen}
      marginTop="-20%"
    >
      <Flex minW="1100px" mt="39px" gap="38px">
        <Flex
          w="100%"
          bg="white"
          px="70px"
          py="44px"
          borderRadius="4px"
          gap="45px"
        >
          <Flex align="center" gap="25px" w="502px">
            <Flex flexDir="column" gap="31px">
              {sampleCustomModule.slice(0, 3).map((item, index) => (
                <StaticAiModuleBoxSample
                  key={index}
                  title={item.title}
                  description={item.description}
                  accentColor={item.accentColor}
                  width={item.width}
                />
              ))}
            </Flex>
            <Flex flexDir="column" gap="31px" h="fit-content">
              {sampleCustomModule.slice(3, 6).map((item, index) => (
                <StaticAiModuleBoxSample
                  key={index}
                  title={item.title}
                  description={item.description}
                  accentColor={item.accentColor}
                  width={item.width}
                />
              ))}
            </Flex>
          </Flex>

          <Flex w="50%" alignItems="center" justifyContent="center">
            <List
              w="100%"
              gap="24px"
              display="flex"
              flexDir="column"
              ml="0 !important"
            >
              {customModulePoints.map((item, index) => (
                <ListItem
                  opacity="0.7"
                  fontWeight="400"
                  fontSize="16px"
                  color="#191D30"
                  key={index}
                  letterSpacing="-0.64px"
                  lineHeight="95%"
                  display="flex"
                  fontFamily="Inter"
                  fontStyle="normal"
                >
                  <ListIcon
                    as={MdCircle}
                    color="#464959"
                    fontSize="7px"
                    marginTop="1"
                  />
                  {item}
                </ListItem>
              ))}
            </List>
          </Flex>
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const PublicModules = ({ setAllowForwardNav, onOpen }) => {
  const { publicCustomAiModules, userCustomAiModules } = useCustomAiModules();

  // for all public custom ai modules which are also added by user, add a flag "in_user_library"
  const publicCustomAiModulesInUserLibrary = publicCustomAiModules.map(
    (publicModule) => {
      const isAddedByUser = userCustomAiModules.some(
        (userModule) => userModule.uid === publicModule.uid
      );
      return { ...publicModule, in_user_library: isAddedByUser };
    }
  );

  useEffect(() => {
    setAllowForwardNav(true);
  }, []);

  const displayedModules = publicCustomAiModulesInUserLibrary.slice(0, 6);

  return (
    <OnboardingFlowWrapper
      heading="OVERVIEW OF THE MOSAIQ ECOSYSTEM"
      intro="Module Library"
      showDemoBtt={true}
      subParagraphWithText={() => {
        return (
          <Text color="#191919" fontSize="13px">
            <Text display="inline-block" fontWeight="black">
              You can select modules from the library.
            </Text>{" "}
            <Text display="inline-block" fontStyle="italic">
              If any of the options below interest you, simply click
              &apos;+&apos; to add them directly to your environment
            </Text>
          </Text>
        );
      }}
      width="900px"
      onOpen={onOpen}
      marginTop="-20%"
    >
      <Flex w="100%" mt="39px" gap="38px">
        <Grid
          w="100%"
          bg="white"
          px="90px"
          py="35px"
          borderRadius="4px"
          gap="10px"
          columnGap="10px"
          rowGap="10px"
          gridTemplateColumns="33% 33% 33%"
        >
          {displayedModules.map((moduleData) => {
            return (
              <AiModuleBox
                key={moduleData.uid}
                moduleData={moduleData}
                isLoading={false}
                actionAlwaysOn={true}
                actionOnlyOnIcon={true}
                action={
                  moduleData.in_user_library ? "ALREADY_ADDED_INDICATOR" : "ADD"
                }
                width="100%"
              />
            );
          })}
        </Grid>
      </Flex>
    </OnboardingFlowWrapper>
  );
};

export const GetStarted = ({ setAllowForwardNav }) => {
  useEffect(() => {
    setAllowForwardNav(true);
  }, []);
  const { currentUser } = useAuth();
  const history = useHistory();

  return (
    <OnboardingFlowWrapper
      heading=""
      intro=""
      showDemoBtt={false}
      subParagraph=""
      width="900px"
    >
      <Flex
        w="100%"
        mt="39px"
        px="203px"
        pt="95px"
        pb="64px"
        bg="white"
        borderRadius="3.6px"
      >
        <Flex w="100%" gap="24px" align="center" flexDir="column">
          <Heading fontSize="30px">You&apos;re all set</Heading>
          <Flex flexDir="column" align="center" gap="32px">
            <Button
              fontSize="14px"
              color="primary"
              bg="#CCC9DC"
              onClick={() => {
                updateOnboardingCompleted(currentUser.uid);
                history.replace(addUrlParam("/user", "fromOnboarding", "true"));
              }}
            >
              Get started now!
            </Button>
            <Link
              color="#191D30"
              fontSize="14px"
              textDecor="underline"
              opacity="0.7"
              href="https://calendly.com/mosaiqlabs/intro-call"
              target="_blank"
            >
              Request support at any time to learn more or get guidance
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </OnboardingFlowWrapper>
  );
};
