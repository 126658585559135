import React from "react";
import { useParams } from "react-router-dom";
import FloatingWindow from "../Elements/SaveToInsightsFloatingWindow";
import { makeArticleIdToReferenceNumberMap } from "../assistant/Message/messageUtils";
import { useSaveSelectionToInsights } from "./useTextSelection";

function extractDigits(inputString) {
  // regex to match digits in the format [1]{1}, [2]{2}, etc.
  const regex = /\[(\d)\]\{\1\}/g;

  // Find all matches in the string
  const matches = inputString.match(regex);

  // Extract just the digits from the matches
  const digits = matches ? matches.map((match) => match[1]) : [];

  return digits;
}

/**
 * Retrieves source articles based on the provided message and selected text.
 * Inside the selected text, the function looks for reference numbers in the format [1]{1}, [2]{2}, etc.
 *
 * These are linked back to the reference data in the message to get the article IDs and finally
 * the article data from the message referenceData.
 *
 * Explanation
 * -----------
 * This somewhat convoluted process (first creating references from referenceData when we render the message,
 * and then finding the referenceData from the selectedText) is necessary because selectedText only contains
 * the rendered text, but we modify the initial message content to show reference bubbles. But from the bubble
 * text (which is only 1 number, like "1", "2", etc) there is no reliable way to get the article ID. To solve
 * this, invisible reference numbers ([1]{1}, [2]{2}, etc) are added to the message content. In this function we are
 * extracting these numbers from the selected text, and then using them to get the article IDs. The invisible
 * reference numbers are later removed from the selected text before it is saved to insights.
 *
 * @param {object} msg - The message object containing reference data and content.
 * @param {string} selectedText - The selected text to extract digits from.
 * @returns {Array<object>} - An array of unique source articles with their uid, title, url, and icon.
 */
function getSourceArticles(msg, selectedText) {
  const articleIdToReferenceNumberMap = makeArticleIdToReferenceNumberMap(
    msg.referenceData,
    msg.content
  );

  const digits = extractDigits(selectedText).map((digit) =>
    parseInt(digit, 10)
  );

  const uniqueDigits = [...new Set(digits)];

  // reverse articleIdToReferenceNumberMap
  const articleIdToReferenceNumberMapReverse = {};
  Object.entries(articleIdToReferenceNumberMap).forEach(([key, value]) => {
    articleIdToReferenceNumberMapReverse[value] = key;
  });

  // get articleIds from reference numbers
  const articleIds = uniqueDigits.map((digit) => {
    return articleIdToReferenceNumberMapReverse[digit];
  });

  // make an object with articleData
  const sourceArticles = msg.referenceData?.filter((article) =>
    articleIds.includes(article.articleId)
  );

  // sourceArticles can contain multiple copies of the data for the same article, so we need to reduce it to unique articles
  const uniqueSourceArticles = sourceArticles?.reduce((acc, current) => {
    const x = acc.find((item) => item.uid === current.articleId);
    if (!x) {
      return acc.concat([
        {
          uid: current.articleId,
          title: current.articleTitle,
          url: current.articleUrl,
          icon: current.articleIcon,
        },
      ]);
    } else {
      return acc;
    }
  }, []);

  return uniqueSourceArticles;
}

/*
  This is a higher order component that wraps a component with the functionality
  to save a text selection to insights. It returns a component that takes the
  same props as the original component, but also takes a handleMouseUp prop.
  The handleMouseUp prop should be passed to the onMouseUp event handler of the
  component that is wrapped by this higher order component.
*/
export const withSaveSelectionToInsights = (Component, title) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { projectId } = useParams();
    const {
      selectedText,
      selectionPosition,
      handleMouseUp,
      clearSelection,
      handleButtonClick,
    } = useSaveSelectionToInsights(projectId, title);

    const sourceArticles = getSourceArticles(props.msg, selectedText);

    // from selectedText, remove [1]{1}1 and [2]{2}2 etc (with space before)
    const selectedTextWithoutReferenceNumbers = selectedText.replace(
      / \[\d\]\{\d\}\d/g,
      ""
    );

    return (
      <>
        <Component {...props} handleMouseUp={handleMouseUp} />
        {selectedText && (
          <FloatingWindow
            text={selectedTextWithoutReferenceNumbers}
            position={selectionPosition}
            onClickAway={clearSelection}
            onButtonClick={handleButtonClick}
            sourceArticles={sourceArticles}
          />
        )}
      </>
    );
  };
};
