import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import useNotification from "../../hooks/useNotification";
import { saveFile } from "../../services/firebaseStorageSrv";

export const EditProfileImg = ({ newProfilePhoto }) => {
  const fileInputRef = useRef(null);
  const [uploadedInProgess, setUploadedInProgress] = useState(false);
  const handleRefUpdate = () => {
    fileInputRef.current.click();
  };
  const { notify } = useNotification();
  const { currentUser } = useAuth();

  const handleImgSelected = async (e) => {
    //Show error if file selected is more than 2MB
    if (e.target.files[0].size / 1024 / 1024 > 2) {
      notify({
        title: "File size too large.",
        description: "Please select a file less than 2MB.",
        status: "error",
      });
    } else {
      setUploadedInProgress(true);
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop();
      const downloadURL = await saveFile({
        file,
        path: `user_data/${currentUser.uid}`,
        newFileName: `profile_img.${fileExtension}`,
        isProfilePicture: true,
      });
      setUploadedInProgress(false);
      newProfilePhoto(downloadURL);
    }
  };
  return (
    <FormControl>
      <FormLabel htmlFor={fileInputRef}>
        <Button
          border="3px solid #e7e7e7"
          w="fit-content"
          bg="transparent"
          p={3}
          color="primary"
          onClick={handleRefUpdate}
          leftIcon={uploadedInProgess && <Spinner mr={2} size="sm" />}
        >
          Upload Photo
        </Button>
        <Text color="#a6a6a6" fontSize="12px">
          Up to 1mb
        </Text>
      </FormLabel>
      <Input
        accept=".png, .jpeg, .jpg"
        ref={fileInputRef}
        onChange={handleImgSelected}
        type="file"
        display="none"
      />
    </FormControl>
  );
};
