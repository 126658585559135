import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useUserPermission } from "../Context/UserPermissionContext";

export function NoArticlesPlaceholder() {
  const { isReader } = useUserPermission();

  let SecondBlock = (
    <Flex align={"center"} gap="4px">
      <Text opacity={0.54} fontSize={"12px"}>
        {"Click on "}
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {" “+ Add content” "}
        </span>
        {"next to the search bar to start populating your project"}
      </Text>
    </Flex>
  );

  if (isReader()) {
    SecondBlock = (
      <Flex align={"center"} gap="4px">
        <Text opacity={0.54} fontSize={"12px"}>
          You do not have permission to add content to this project
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      h="240px"
      w="100%"
      direction={"column"}
      align="center"
      justify="flex-end"
      gap="16px"
    >
      <Text fontSize={"16px"} fontWeight={600} fontFamily={"Inter"}>
        No files added yet
      </Text>
      {SecondBlock}
    </Flex>
  );
}
