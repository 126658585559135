import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BsHighlighter } from "react-icons/bs";
import { FaLeaf } from "react-icons/fa";
import { MdArrowRight, MdBookmark, MdBookmarkBorder, MdContentCopy, MdOutlinePostAdd } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useTemplateContext } from "../../Context/TemplateContext";
import { subToTemplateSections } from "../../db/organizer";
import { createSnippet, deleteSnippet, updateSnippet } from "../../db/snippet";
import { db } from "../../firebase";
import PermissionWrapper from "../HigherOrderComponents/PermissionWrapper";
import { getCustomizationData } from "../Utils/customizationUtils";
import { handleOnSaveHighlightOnTooltip } from "../Utils/viewerUtils";

const customization = getCustomizationData()


const TemplateMenuItem = ({
    template,
    projectId,
    alreadySavedHighlight,
    articleId,
    position,
    highlightId,
    closeParentMenu,
  }) => {
    const { name: templateName } = template;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sectionData, setSectionData] = useState([]);
    const {currentUser} = useAuth();
  
    useEffect(() => {
      const unsubscribe = subToTemplateSections(
        projectId,
        template.id,
        (templateSectionData) => {
          setSectionData(templateSectionData);
        }
      );
  
      return () => {
        unsubscribe();
      };
    }, [template.id]);
  
    const handleSaveSnippetToSection = (sectionId) => {
      let snippetId = highlightId;
      if (!alreadySavedHighlight) {
        snippetId = uuidv4();
        const payload = {
          projectId,
          articleId,
          articleUrl: location.state?.articleUrl,
          articleTitle: location.state?.articleData.title,
          snippetId: snippetId,
          snippetType: "manualHighlight",
          text: window.getSelection().toString(),
          authorId: currentUser.uid,
          id: snippetId,
          position: position,
          curated: true,
        };
        createSnippet(payload);
      } else {
        snippetId = highlightId;
        updateSnippet({ projectId, snippetId, curated: true });
      }
      const sectionRef = doc(
        db,
        `/projects/${projectId}/templates/${template.id}/organizerSections/${sectionId}`
      );
      updateDoc(sectionRef, {
        snippets: arrayUnion({
          snippetId: snippetId,
          cardType: "addedByUser",
        }),
      });
    };
  
    return (
      <MenuItem
        w="100%"
        _hover={{
          bg: "transparent",
        }}
        pl="0"
      >
        <Menu isOpen={isOpen} placement="right-start">
          <MenuButton
            align="start"
            justify="start"
            key={templateName}
            p="0"
            border="none"
            color="#757575"
            cursor="pointer"
            onMouseEnter={() => {
              onOpen();
            }}
            onMouseLeave={() => {
              onClose();
            }}
            w="100%"
            _hover={{
              color: "primary",
            }}
          >
            <Flex justify="space-between">
              <Text fontSize="10px">{templateName}</Text>
              <MdArrowRight width="20px" />
            </Flex>
          </MenuButton>
          <MenuList
            // We use margin here to move menu close to avoid the mouseleave event.
            // This is a temporary solution. Margins shouldn't be used for this purpose.
            ml="-0.5rem"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            pl="10px"
          >
            {sectionData.length > 0 ? (
              sectionData
                .sort((a, b) => a.order - b.order)
                .map((section) => (
                  <MenuItem
                    key={section.id}
                    onClick={() => {
                      handleSaveSnippetToSection(section.id);
                      onClose();
                      closeParentMenu();
                    }}
                    fontSize="10px"
                    _hover={{
                      bg: "white",
                    }}
                  >
                    {section.name}
                  </MenuItem>
                ))
            ) : (
              <Flex flexDir="column" w="100%" justify="center" align="center">
                <FaLeaf fontSize="10px" />
                <Text fontSize="10px">{`${customization.template.name} has no sections`}</Text>
              </Flex>
            )}
          </MenuList>
        </Menu>
      </MenuItem>
    );
  };

  
function TemplateMenuItems({
    templateList,
    projectId,
    alreadySavedHighlight,
    articleId,
    highlightId,
    position,
    curated,
    onClose,
    closeParentMenu,
  }) {
    if (templateList.length === 0) {
      return (
        <Flex flexDir="column" w="100%" justify="center" align="center">
          <FaLeaf fontSize="10px" />
          <Text fontSize="10px">{`Create you first ${customization.template.name} in Assistant -> ${customization.template.namePlural}`}</Text>
        </Flex>
      );
    }
  
    return templateList.map((template) => (
      <TemplateMenuItem
        key={template.id}
        template={template}
        projectId={projectId}
        alreadySavedHighlight={alreadySavedHighlight}
        articleId={articleId}
        highlightId={highlightId}
        position={position}
        curated={curated}
        closeParentMenu={() => {
          onClose();
          closeParentMenu();
        }}
      />
    ));
  }

  
const TemplateMenu = ({
    value,
    projectId,
    alreadySavedHighlight,
    articleId,
    highlightId,
    position,
    curated,
    closeParentMenu,
  }) => {
    const { templateList } = useTemplateContext();
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <Menu isOpen={isOpen}>
        <MenuButton
          w="35.2px"
          align="center"
          justify="center"
          key={value}
          p="0"
          border="none"
          color="#757575"
          cursor="pointer"
          display="flex"
          alignContent="center"
          justifyContent="center"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <Flex w="100%" justify="center">
            <MdOutlinePostAdd width="20px" />
          </Flex>
        </MenuButton>
        <MenuList px="15px" onMouseEnter={onOpen} onMouseLeave={onClose}>
          <TemplateMenuItems
            templateList={templateList}
            projectId={projectId}
            alreadySavedHighlight={alreadySavedHighlight}
            articleId={articleId}
            highlightId={highlightId}
            position={position}
            curated={curated}
            onClose={onClose}
            closeParentMenu={closeParentMenu}
          />
        </MenuList>
      </Menu>
    );
  };
  
export const HighlightSelectTools = ({
    position,
    content,
    projectId,
    articleId,
    alreadySavedHighlight,
    snippetId = null,
    curated = false,
    isSmartImage,
  }) => {
    const [display, setDisplay] = useState(
      isSmartImage && !content.text ? false : true
    );
    const closeMenu = () => {
      setDisplay(false);
    };
  
    const handleSaveHighlight = () => {
      if (alreadySavedHighlight) {
        if (curated) {
          updateSnippet({ projectId, snippetId, curated: false });
        } else {
          updateSnippet({ projectId, snippetId, curated: true });
        }
      } else {
        handleOnSaveHighlightOnTooltip(
          content,
          position,
          projectId,
          articleId,
          true
        );
      }
    };
  
    const HIGHLIGHT_BUTTONS = [
      {
        label: "Delete",
        value: "delete",
        toolTip: "Delete highlight",
        icon: <DeleteIcon width="16px" />,
        action: () => {
          deleteSnippet({
            projectId: projectId,
            snippetId: snippetId,
          });
          closeMenu();
        },
        toBeDisplayed: alreadySavedHighlight,
        disabledForViewers: true,
      },
      {
        label: "Highlight",
        value: "highlight",
        toolTip: "Highlight",
        icon: <BsHighlighter width="16px" />,
        action: () => {
          handleOnSaveHighlightOnTooltip(content, position, projectId, articleId);
          closeMenu();
        },
        toBeDisplayed: !alreadySavedHighlight && !isSmartImage,
        disabledForViewers: true,
      },
      {
        label: "Copy",
        value: "copy",
        toolTip: "Copy",
        icon: <MdContentCopy width="16px" />,
        action: () => {
          navigator.clipboard.writeText(content.text);
          closeMenu();
        },
        toBeDisplayed: true,
        disabledForViewers: false,
      },
      {
        label: "Save",
        value: "save",
        toolTip: curated ? "Unsave insight" : "Save insight",
        icon: curated ? (
          <MdBookmark width="16px" />
        ) : (
          <MdBookmarkBorder width="16px" />
        ),
        action: () => {
          handleSaveHighlight();
          closeMenu();
        },
        toBeDisplayed: true,
        disabledForViewers: true,
      },
      {
        label: customization.template.name,
        value: "template",
        toolTip: `Add to ${customization.template.name}`,
        elem: (
          <TemplateMenu
            value="template"
            projectId={projectId}
            alreadySavedHighlight={alreadySavedHighlight}
            articleId={articleId}
            highlightId={snippetId}
            position={position}
            curated={curated}
            closeParentMenu={closeMenu}
          />
        ),
        action: () => {
          console.log("clicked on template");
        },
        toBeDisplayed: true,
        disabledForViewers: true,
      },
    ];
  
    return (
      <Flex
        bg="white"
        w="fit-content"
        border="1px solid #E0E0E0"
        borderRadius="4"
        h="32px"
        display={display ? "flex" : "none"}
        pos={isSmartImage ? "fixed" : "initial"}
        left={isSmartImage && position.x}
        top={isSmartImage && position.y}
      >
        <PermissionWrapper>
          {(hasEditPermission) =>
            HIGHLIGHT_BUTTONS.map((button) => {
              const hide = button.disabledForViewers && !hasEditPermission;
              return button.value === "template" ? (
                <Flex key={button.value} display={hasEditPermission ? "flex" : "none"}>
                  {button.elem}
                </Flex>
              ) : (
                <Tooltip key={button.value} label={button.toolTip} aria-label={button.toolTip}>
                  <Flex
                    w="35.2px"
                    align="center"
                    justify="center"
                    p="0"
                    border="none"
                    onClick={() => {
                      button.action();
                    }}
                    color="#757575"
                    cursor="pointer"
                    display={button.toBeDisplayed && !hide ? "flex" : "none"}
                  >
                    {button.icon}
                  </Flex>
                </Tooltip>
              );
            })
          }
        </PermissionWrapper>
      </Flex>
    );
  };