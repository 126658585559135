import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsBookmark } from "react-icons/bs";
import { ProjectSwtichBttn, SimpleTourMessage } from "./Index";
import { TourComponent } from "./TourComponent";

export const ProjectPageTour = () => {
  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: (
          <SimpleTourMessage
            text="Add Web Pages and PDFs"
            heading="Build your project database"
          />
        ),
        placement: "center",
        target: "#addContent",
        spotlight: false,
        heading: 3,
      },
      {
        content: (
          <SimpleTourMessage
            text={
              <>
                Web Pages and PDFs you add appear in{" "}
                <ProjectSwtichBttn
                  color="#5F616F"
                  mx={0}
                  size="16px"
                  text="All Content"
                  ml={1}
                  icon={<AiOutlineUnorderedList />}
                />
                , insights you save can be found in{" "}
                <ProjectSwtichBttn
                  color="#5F616F"
                  mx={1}
                  fontSize="140px"
                  size="16px"
                  text="Saved Insights"
                  icon={<BsBookmark />}
                />
              </>
            }
            heading={
              <>
                Switch between{" "}
                <ProjectSwtichBttn
                  mx={2}
                  color="primary"
                  size="24px"
                  text="All Content"
                  icon={<AiOutlineUnorderedList />}
                />{" "}
                and{" "}
                <ProjectSwtichBttn
                  mx={2}
                  fontSize="140px"
                  color="primary"
                  size="24px"
                  text="Saved Insights"
                  icon={<BsBookmark />}
                />
              </>
            }
          />
        ),
        placement: "center",
        target: "#projectPageSwitch",
        spotlight: false,
        heading: 4,
      },
      {
        content: (
          <SimpleTourMessage
            heading="Read your documents and highlight insights "
            text={
              <>
                Document highlights can be saved to{" "}
                <ProjectSwtichBttn
                  color="#5F616F"
                  mx={1}
                  fontSize="140px"
                  size="16px"
                  text="Saved Insights"
                  icon={<BsBookmark />}
                />
              </>
            }
          />
        ),
        placement: "center",
        target: ".articleTitle",
        spotlight: false,
        heading: 5,
      },
      {
        content: (
          <SimpleTourMessage
            heading="Access AI insights from individual documents"
            text={
              <>
                Save the ones you find relevant to{" "}
                <ProjectSwtichBttn
                  color="#5F616F"
                  mx={1}
                  fontSize="140px"
                  size="16px"
                  text="Saved Insights"
                  icon={<BsBookmark />}
                />
              </>
            }
          />
        ),
        placement: "center",
        target: ".aiInsightBttn",
        spotlight: false,
        heading: 6,
      },
      {
        content: (
          <Flex justify="center" w="100%">
            <SimpleTourMessage
              text="Get insights from multiple documents at once"
              heading="Click here to open your AI assistant"
            />
            <Flex
              bg="white"
              w="10%"
              h="60px"
              pos="absolute"
              right="0"
              bottom="0"
              zIndex="10"
              borderRadius="0 0 20px 0"
            ></Flex>
          </Flex>
        ),
        placement: "center",
        target: "#assistantBttn",
        spotlight: true,
        heading: 7,
      },
    ],
  });
  return (
    <TourComponent
      skipWelcomeMessage={true}
      steps={steps}
      run={run}
      tourType="projectPage"
    />
  );
};
