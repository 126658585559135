export function getIndexingError(articleData) {
  return articleData.indexingStatus &&
    articleData.indexingStatus.status === "error"
    ? articleData.indexingStatus.error
    : null;
}

export function getIsIndexing(articleData) {
  return (
    articleData.indexingStatus && articleData.indexingStatus.status != "done"
  );
}
