import React from "react";
import { Icon } from "@chakra-ui/react";

const TextIconComponent = (props) => {
  return (
    <Icon width="20" height="21" viewBox="0 0 19 21" fill="none" {...props}>
        <path
          d="M11.8083 4.66667L15.8333 8.69167V16.3333H4.16667V4.66667H11.8083ZM11.8083 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V8.69167C17.5 8.25 17.325 7.825 17.0083 7.51667L12.9833 3.49167C12.675 3.175 12.25 3 11.8083 3ZM5.83333 13H14.1667V14.6667H5.83333V13ZM5.83333 9.66667H14.1667V11.3333H5.83333V9.66667ZM5.83333 6.33333H11.6667V8H5.83333V6.33333Z"
          fill="white"
        />
    </Icon>
  );
};

export default TextIconComponent;
