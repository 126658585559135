import { Flex, HStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArticleDoc } from "shared/articleTypes";
import { useArticle } from "../../../Context/ArticleContext";
import { useAssistantContext } from "../../../Context/AssistantContext";
import {
  ArticleTypeCount,
  calculateAndSetVisibleItems,
  getArticleTypes,
  updateSelectedTypedArr,
} from "../../Utils/ProjectPageUtil/tableUtils";
import { MoreMenuBttn } from "./FilterArticleTypeComponents";
import { ArticleTypeBttn } from "./HeaderSwitches";

export const FilteredTypesMenu = () => {
  const { allUnfilteredArticles }: { allUnfilteredArticles: ArticleDoc[] } =
    useArticle();
  const routerHistory = useHistory();
  const { assistantWidth, assistantIsOpen } = useAssistantContext();
  const [sortedArticleType, setSortedArticleType] = useState(
    [] as ArticleTypeCount[]
  );
  const [visibleItems, setVisibleItems] = useState([] as ArticleTypeCount[]);
  const [hiddenItems, setHiddenItems] = useState([] as ArticleTypeCount[]);

  const menuContainerRef = useRef(null);

  useEffect(() => {
    if (!allUnfilteredArticles) return;
    const articleTypes = getArticleTypes(allUnfilteredArticles);

    const sortedArticleType = Object.values(articleTypes).sort((a, b) => {
      return b.count - a.count;
    });
    setSortedArticleType(sortedArticleType);
    setVisibleItems(sortedArticleType);
  }, [allUnfilteredArticles]);

  useEffect(() => {
    const handleResize = () => {
      calculateAndSetVisibleItems({
        menuContainerRef,
        sortedArticleType,
        setVisibleItems,
        setHiddenItems,
      });
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [assistantWidth, sortedArticleType, assistantIsOpen]);

  const currentSearchParams = new URLSearchParams(location.search);
  const articleTypeParam = currentSearchParams.get("articleTypes");
  const articleTypeArray = articleTypeParam?.split(",");
  const selectedArticleTypes = articleTypeArray || [];

  return (
    <Flex gap={2} flexDir="column" w="100%">
      <HStack gap={2}>
        <Flex ref={menuContainerRef} gap={2} flexDir="row" w="100%">
          {visibleItems?.map((articleType) => {
            return (
              <HStack key={articleType.id}>
                <ArticleTypeBttn
                  text={articleType.type}
                  isSelected={selectedArticleTypes.includes(articleType.id)}
                  updateSelectedTypedArr={() =>
                    updateSelectedTypedArr(
                      articleType.id,
                      selectedArticleTypes,
                      routerHistory
                    )
                  }
                  typeCount={
                    allUnfilteredArticles.filter(
                      (article) => article.type === articleType.id
                    ).length
                  }
                />
              </HStack>
            );
          })}
          <MoreMenuBttn
            visibleItems={visibleItems}
            sortedArticleType={sortedArticleType}
            hiddenItems={hiddenItems}
            selectedArticleTypes={selectedArticleTypes}
            routerHistory={routerHistory}
            allUnfilteredArticles={allUnfilteredArticles}
            updateSelectedTypedArr={updateSelectedTypedArr}
          />
        </Flex>
      </HStack>
    </Flex>
  );
};
