import { Flex, Heading, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "../../assets/emptystate.png";

export const EmptyCuratedContentPage = () => {
  return (
    <Flex h="70vh" align="center" justify="center" flexDir="column" gap={2}>
      <Image src={Logo} />
      <Heading>Nothing to see; for now.</Heading>
      <Text>When you save content on insights they will appear here</Text>
      <Link
        textDecor="underline"
        href="https://www.loom.com/share/e88d44e1c37f4dd1aa273cbbf30dabaf"
      >
        Watch this tutorial
      </Link>
    </Flex>
  );
};
