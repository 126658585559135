import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { getCustomizationData } from "../Utils/customizationUtils";
import { RequestModuleForm } from "./RequestModuleForm";
import { RequestModuleSuccess } from "./RequestModuleSuccess";
import { RequestModuleVideo } from "./RequestModuleVideo";

const customization = getCustomizationData();

const RequestModuleModal = ({ isOpen, onClose, userSubmittedRequest }) => {
  const [dataSubmitted, setDataSubmitted] = useState(false);

  const handleUserSubmittedRequest = (data) => {
    userSubmittedRequest(data);
    setDataSubmitted(true);
  };

  const handleClose = () => {
    setDataSubmitted(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent h="100%" bg="none">
        <ModalBody
          as={Flex}
          h="100%"
          align="center"
          justify="center"
          p="12px"
          position="relative"
        >
          <Flex position="absolute" top="12px" right="12px">
            <ModalCloseButton />
          </Flex>
          {dataSubmitted ? (
            <Flex align="center" h="100%" w="100%" justify="center" bg="white">
              <RequestModuleSuccess onClose={handleClose} />
            </Flex>
          ) : (
            <Flex align="center" h="100%" justify="center" bg="white">
              <Flex gap="40px" flexDir="column" w="30%" h="500px" justify="end">
                <Flex flexDir="column" gap="10px">
                  <Heading fontSize="16px">Request a module</Heading>
                  <Text fontSize="14px" opacity="0.54">
                    A module in {customization.name} is a tailored AI-driven
                    component designed to integrate with and enhance existing
                    workflows. It leverages company data and technology to
                    improve efficiency, provide insights, and achieve specific
                    business goals through data analysis, process automation,
                    and decision support.
                  </Text>
                </Flex>
                <RequestModuleForm
                  userSubmittedRequest={handleUserSubmittedRequest}
                />
              </Flex>
              <Flex
                h="50vh"
                justify="center"
                align="center"
                w="1px"
                bg="black"
                mx="98px"
                opacity="0.2"
              />

              <RequestModuleVideo />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RequestModuleModal;
