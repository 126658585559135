import React from "react";
import { Icon } from "@chakra-ui/react";

const FileIconComponent = (props) => {
  return (
    <Icon width="19" height="21" viewBox="0 0 19 21" fill="none" {...props}>

        <g clipPath="url(#clip0_9179_17484)">
          <path
            d="M16.8931 3.345L14.7757 1.47667C14.066 0.853352 13.1061 0.50237 12.1048 0.5L3.83333 0.5C3.08189 0.5 2.36122 0.763392 1.82986 1.23223C1.29851 1.70107 1 2.33696 1 3V20.5H18V5.70167C17.9973 4.81812 17.5995 3.97121 16.8931 3.345V3.345ZM15.5577 4.52333C15.6049 4.56902 15.6493 4.61686 15.6908 4.66667H13.2778V2.5375C13.3342 2.57412 13.3884 2.61334 13.4402 2.655L15.5577 4.52333ZM2.88889 18.8333V3C2.88889 2.77899 2.98839 2.56702 3.16551 2.41074C3.34263 2.25446 3.58285 2.16667 3.83333 2.16667H11.3889V6.33333H16.1111V18.8333H2.88889ZM11.3889 14.6667H14.2222V16.3333H11.3889V14.6667ZM12.3333 13V11.5775C12.3345 10.7489 12.1345 9.92971 11.7468 9.175L11.2538 8.21417C11.1153 7.90709 10.8764 7.64328 10.5676 7.45628C10.2587 7.26928 9.89383 7.16753 9.51927 7.16395C9.14472 7.16038 8.77741 7.25515 8.46405 7.43622C8.15068 7.61728 7.9054 7.87647 7.75939 8.18083L7.25033 9.17583C6.86358 9.93041 6.66455 10.7493 6.66667 11.5775V13H8.55556V12.1667H10.4444V13H12.3333ZM9.517 8.8675L10.0261 9.86083C10.125 10.0678 10.2046 10.2816 10.2641 10.5H8.73594C8.79527 10.2818 8.87487 10.0683 8.97394 9.86167L9.517 8.8675ZM9.06556 13.8675L10.4067 15.0408L8.64433 16.6108C8.24509 16.9622 7.70421 17.1595 7.14031 17.1595C6.57641 17.1595 6.03552 16.9622 5.63628 16.6108L4.81178 15.8683L6.16044 14.7017L6.97833 15.4383C7.02308 15.4752 7.08234 15.4952 7.14354 15.4941C7.20473 15.493 7.26303 15.4709 7.30606 15.4325L9.06556 13.8675Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_9179_17484">
            <rect
              width="19"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
    </Icon>
  );
};

export default FileIconComponent;
