import { CircularProgress, Tooltip } from "@chakra-ui/react";
import React from "react";
import { ARTICLE_TYPE_DATA } from "../../../data/articleTypeData";

export function IndexingStatus({ indexingError, isIndexing, articleData }) {
  if (ARTICLE_TYPE_DATA[articleData.type].skipIndexing) return null;

  if (indexingError) return null;

  if (!isIndexing) return null;

  return (
    <Tooltip label="Assistant is getting ready to work on your document.">
      <CircularProgress
        isIndeterminate
        color="rgb(249, 185, 242)"
        size="12px"
        trackColor="rgb(71,74,89)"
        thickness={13}
      />
    </Tooltip>
  );
}
