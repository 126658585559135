import React from "react";
import { Icon } from "@chakra-ui/react";

const SummaryIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.15 5.65C5.28333 5.65 5.4 5.6 5.5 5.5C5.6 5.4 5.65 5.28333 5.65 5.15C5.65 5.01667 5.6 4.9 5.5 4.8C5.4 4.7 5.28333 4.65 5.15 4.65C5.01667 4.65 4.9 4.7 4.8 4.8C4.7 4.9 4.65 5.01667 4.65 5.15C4.65 5.28333 4.7 5.4 4.8 5.5C4.9 5.6 5.01667 5.65 5.15 5.65ZM5.15 8.5C5.28333 8.5 5.4 8.45 5.5 8.35C5.6 8.25 5.65 8.13333 5.65 8C5.65 7.86667 5.6 7.75 5.5 7.65C5.4 7.55 5.28333 7.5 5.15 7.5C5.01667 7.5 4.9 7.55 4.8 7.65C4.7 7.75 4.65 7.86667 4.65 8C4.65 8.13333 4.7 8.25 4.8 8.35C4.9 8.45 5.01667 8.5 5.15 8.5ZM5.15 11.35C5.28333 11.35 5.4 11.3 5.5 11.2C5.6 11.1 5.65 10.9833 5.65 10.85C5.65 10.7167 5.6 10.6 5.5 10.5C5.4 10.4 5.28333 10.35 5.15 10.35C5.01667 10.35 4.9 10.4 4.8 10.5C4.7 10.6 4.65 10.7167 4.65 10.85C4.65 10.9833 4.7 11.1 4.8 11.2C4.9 11.3 5.01667 11.35 5.15 11.35ZM3 14C2.73333 14 2.5 13.9 2.3 13.7C2.1 13.5 2 13.2667 2 13V3C2 2.73333 2.1 2.5 2.3 2.3C2.5 2.1 2.73333 2 3 2H10.7L14 5.3V13C14 13.2667 13.9 13.5 13.7 13.7C13.5 13.9 13.2667 14 13 14H3ZM3 13H13V5.85H10.65C10.5083 5.85 10.3896 5.80208 10.2938 5.70625C10.1979 5.61042 10.15 5.49167 10.15 5.35V3H3V13ZM3 5.85715V13V3V5.85715Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default SummaryIconComponent;
