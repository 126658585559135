import { Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SimpleTourMessage } from "./Index";
import { TourComponent } from "./TourComponent";

export const AIChatTour = () => {
  const [{ steps }, setState] = useState({
    steps: [
      {
        content: (
          <Flex justify="center" w="100%">
            <SimpleTourMessage
              text="It analyses your project content & saves you hours of skimming"
              heading="Ask your assistant anything"
              link={undefined}
              linkText={undefined}
            />
            <Flex
              bg="white"
              w="100%"
              h="60px"
              pos="absolute"
              right="0"
              bottom="0"
              zIndex="10"
              align="center"
              justify="center"
              borderRadius="20px"
              gap={4}
            >
              <Button
                to="/"
                as={Link}
                h="0"
                fontSize="12px"
                py="4"
                px="4"
                color="primary"
              >
                Create your own project
              </Button>
              <Button
                h="0"
                onClick={() => {
                  setState({ steps: [] });
                }}
                fontSize="12px"
                py="4"
                px="4"
                bg="primary"
                color="white"
                borderRadius="10px"
              >
                Test in this project
              </Button>
            </Flex>
          </Flex>
        ),
        placement: "center",
        target: "#chatBoxTour",
        spotlight: false,
        heading: 8,
      },
    ],
  });
  return (
    <TourComponent steps={steps} tourType="aiChatTour" moveToLeft={undefined} />
  );
};
