import { CheckIcon } from "@chakra-ui/icons";
import { Box, CircularProgress, Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";

const getArticlesIndexing = (articles) => {
  // find articles which have status field
  const articlesWithStatusNotDone = articles
    .filter((article) => article.type != "supportingLink")
    .filter((article) => {
      return article.indexingStatus
        ? !["done", "error"].includes(article.indexingStatus.status)
        : false;
    });
  return articlesWithStatusNotDone;
};



const IndexingStatus = ({ articles }) => {
  const articlesInProgress = getArticlesIndexing(articles);

  const numInProgress = articlesInProgress.length;
  const [isOpen, setIsOpen] = useState(numInProgress > 0);
  const [showCheckMark, setShowCheckMark] = useState(false);
  const prevNumInProgress = useRef(numInProgress);

  useEffect(() => {
    if (numInProgress < 1 && prevNumInProgress.current >= 1) {
      // Transition from loading to completed
      setShowCheckMark(true);
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    } else if (numInProgress >= 1) {
      // Currently loading
      setIsOpen(true);
      setShowCheckMark(false);
    }
    // Update the ref to the current value at the end
    prevNumInProgress.current = numInProgress;
  }, [numInProgress]);

  return (
    <Box
      display={isOpen ? "flex" : "none"}
      flexDirection="row"
      justifyContent="right"
    >
      <Box
        position="fixed"
        p={6}
        bg="primary"
        color="white"
        boxShadow="lg"
        borderRadius="lg"
        w="400px"
        justifyContent={"center"}
        top="80%"
      >
        <Flex alignItems="center">
          {showCheckMark ? (
            <Icon
              as={CheckIcon}
              color="rgb(249, 185, 242)"
              fontSize={23}
              mr={4}
            />
          ) : (
            <CircularProgress
              isIndeterminate
              color="rgb(249, 185, 242)"
              size="30px"
              mr={4}
              trackColor="rgb(71,74,89)"
              thickness={13}
            />
          )}
          <Flex flexDirection="column">
            <Text fontSize="14px" fontWeight="bold">
              {showCheckMark
                ? "AI is ready"
                : `Getting your latest documents AI-ready`}
            </Text>
            <Text color="rgba(255, 255, 255, 0.70)" fontSize="12px">
              {showCheckMark
                ? "Assistant now has access to your newly added documents."
                : "Keep working while your latest data is made available to the Assistant."}
            </Text>
          </Flex>
        </Flex>
        {/* close button */}
        <Box
          position="absolute"
          top="10px"
          right="10px"
          cursor="pointer"
          onClick={() => setIsOpen(false)}
        >
          <Icon color="white" as={MdClose} fontSize={20} />
        </Box>
      </Box>
    </Box>
  );
};

export default IndexingStatus;
