import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { uploadImage } from "../Utils/uploadUtil";

export const SmartImageUploadComponent = ({ onUpload, projectId }) => {
  const inputRef = useRef(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = async (file) => {
    try {
      if (fileIsUploading) return;
      setFileIsUploading(true);
      const downloadURL = await uploadImage(file, projectId);
      setImageUrl(downloadURL);
      setFileIsUploading(false);
      onUpload(downloadURL);
    } catch (err) {
      console.log(err);
      setFileIsUploading(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    handleImageUpload(file);
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        handleImageUpload(file);
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleClick = () => inputRef.current?.click();

  return (
    <>
      <input
        type="file"
        multiple={false}
        hidden
        accept="image/*"
        ref={inputRef}
        onChange={handleFileUpload}
      />
      <Flex
        onClick={handleClick}
        py={16}
        fontSize="xs"
        textAlign="center"
        cursor="pointer"
        gap="4px"
        flexDir="column"
        border="1px dotted #93959d"
        borderRadius="4px"
        bg={`url(${imageUrl}) no-repeat center`}
        bgPos="center"
        bgSize="contain"
        h="201px"
        maxH="201px"
      >
        {fileIsUploading ? (
          <Box>
            <Center>
              <Spinner size="sm" />
            </Center>
            <Text mt="2" fontSize="xs" textAlign="center" fontWeight="black">
              Uploading file ...
            </Text>
          </Box>
        ) : (
          <Box>
            <Text opacity={imageUrl ? "0" : "1"} fontWeight="black">
              copy paste / choose image
            </Text>
            <Text opacity={imageUrl ? "0" : "1"}>
              (JPG, SVG, PNG, Screenshot)
            </Text>
          </Box>
        )}
      </Flex>
    </>
  );
};
