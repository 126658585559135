import { Flex, OrderedList, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import ImageWithIconFallback from "../../Elements/ImageWithIconFallback";

const RenderUnlistedSources = ({ sources }) => {
  return (
    <Flex zIndex={900}>
      <OrderedList mt={2} fontSize="10px" p="0">
        {sources
          ? sources.map((item, index) => {
              return (
                <li fontSize="10px" key={index}>
                  <Text fontSize="10px">{item.title}</Text>
                </li>
              );
            })
          : null}
      </OrderedList>
    </Flex>
  );
};

const ShowUnRendedSourceCount = ({ count, counterGap }) => {
  return (
    <Flex
      w="20px"
      h="20px"
      borderRadius="full"
      bg="#59808D"
      color="white"
      fontSize="0.75rem"
      fontWeight="bold"
      alignItems="center"
      justifyContent="center"
      zIndex="1"
      mt={counterGap || "-13%"}
    >
      +{count}
    </Flex>
  );
};
export const ManualInsightSourceList = ({
  iconsLists,
  iconsGaps,
  // showBorder,
  iconsToRender,
  isTemplate,
  counterGap,
}) => {
  if (iconsLists?.length <= 0) return null;

  const slicedIconsList = iconsLists
    ? iconsLists.slice(0, iconsToRender)
    : null;

  return (
    <Flex mt={!isTemplate ? "-5%" : "2%"} w="20px">
      <Tooltip
        placement="bottom-start"
        bg="white"
        label={
          <Flex
            flexDir="column"
            w="fit-content"
            pr="4"
            bg="white"
            color="primary"
          >
            {/* <Text fontSize="10px">Sources used by the Assistant:</Text> */}
            <RenderUnlistedSources sources={iconsLists} />
          </Flex>
        }
      >
        <Flex
          flexDir="column"
          align="center"
          justify="center"
          justifyContent="center"
        >
          {slicedIconsList
            ? slicedIconsList.map((iconList, index) => {
                return (
                  <ImageWithIconFallback
                    key={index}
                    iconSrc={iconList.icon}
                    // zIndex={iconsLists?.length - index}
                    mt={iconsGaps || "-10%"}
                  />
                );
              })
            : null}
          <Flex
            display={iconsLists?.length > iconsToRender ? "flex" : "none"}
            w="20px"
          >
            <ShowUnRendedSourceCount
              count={iconsLists?.length - iconsToRender}
              iconGap={iconsGaps}
              counterGap={counterGap}
            />
          </Flex>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
