import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getArticlesCollection } from "src/db/article";

/**
 * A custom hook that manages the search overlay state and functionality.
 * This hook handles the visibility of the search overlay and manages URL parameters
 * for popped articles in the search functionality.
 *
 * @returns {Object} An object containing:
 *   - hasOverlay: boolean indicating if the search overlay is visible
 *   - setHasOverlay: function to set the overlay visibility
 *   - removePoppedArticle: function to remove the poppedArticle parameter from URL
 *   - queryTags: string containing the title of the popped article
 */
const useSearchOverlay = () => {
  const [hasOverlay, setHasOverlay] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();
  const currentSearchParams = new URLSearchParams(location.search);
  const poppedArticle = currentSearchParams.get("poppedArticle");
  const [queryTags, setQueryTags] = useState<string>("");
  const history = useHistory();

  const removePoppedArticle = (): void => {
    currentSearchParams.delete("poppedArticle");
    history.replace(`?${currentSearchParams.toString()}`);
  };

  useEffect(() => {
    if (projectId && poppedArticle) {
      setHasOverlay(true);
      getDoc(doc(getArticlesCollection(projectId), poppedArticle)).then((doc) =>
        setQueryTags(doc.data()?.title || "")
      );
      return;
    }
    setHasOverlay(false);
  }, [hasOverlay, poppedArticle]);

  return {
    hasOverlay,
    setHasOverlay,
    removePoppedArticle,
    queryTags,
  };
};

export default useSearchOverlay;
