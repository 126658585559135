import React from "react";
import { Icon } from "@chakra-ui/react";

const SearchIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M13.2667 13.9833L8.88333 9.6C8.55 9.88889 8.16111 10.1139 7.71667 10.275C7.27222 10.4361 6.8 10.5167 6.3 10.5167C5.1 10.5167 4.08333 10.1 3.25 9.26667C2.41667 8.43334 2 7.42778 2 6.25C2 5.07223 2.41667 4.06667 3.25 3.23334C4.08333 2.4 5.09444 1.98334 6.28333 1.98334C7.46111 1.98334 8.46389 2.4 9.29167 3.23334C10.1194 4.06667 10.5333 5.07223 10.5333 6.25C10.5333 6.72778 10.4556 7.18889 10.3 7.63334C10.1444 8.07778 9.91111 8.49445 9.6 8.88334L14 13.25L13.2667 13.9833ZM6.28333 9.51667C7.18333 9.51667 7.95 9.19723 8.58333 8.55834C9.21667 7.91945 9.53333 7.15 9.53333 6.25C9.53333 5.35 9.21667 4.58056 8.58333 3.94167C7.95 3.30278 7.18333 2.98334 6.28333 2.98334C5.37222 2.98334 4.59722 3.30278 3.95833 3.94167C3.31944 4.58056 3 5.35 3 6.25C3 7.15 3.31944 7.91945 3.95833 8.55834C4.59722 9.19723 5.37222 9.51667 6.28333 9.51667Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default SearchIconComponent;
