import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Text,
  Link,
  Img,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiFillWarning } from "react-icons/ai";
import { auth } from "../../firebase";
import useNotification from "../../hooks/useNotification";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const DeleteUser = () => {
  const { notify } = useNotification();
  const { isOpen, onOpen, onClose: baseOnClose } = useDisclosure();
  const [userData, setuserData] = useState();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const user = auth.currentUser;

  const onClose = () => {
    setAcceptedTerms(false); // reset the acceptedTerms state
    baseOnClose(); // close the modal
  };

  useEffect(() => {
    const user = auth.currentUser;
    setuserData(user);
  }, [user]);

  return (
    <Flex my={2} w="100%">
      <Button
        w="fit-content"
        h="fit-content"
        color="primary"
        fontSize="13px"
        border="none"
        bg="transparent"
        p="0"
        onClick={onOpen}
        fontWeight="600"
      >
        Delete account
      </Button>
      <Modal size="2xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading fontSize="32px" fontWeight="black" color="primary">
              Delete your {customization.name} Account?
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Text>
                Deleting your account is permanent and cannot be undone. Are you
                sure that you would like to continue? We are here to help if you
                have an issue, you can contact us{" "}
                <Link color="link" href="https://www.mosaiqlabs.com/contact">
                  here
                </Link>
                .
              </Text>
            </Flex>
            <Flex mt={4}>
              <Text>Your account:</Text>
            </Flex>
            <Flex
              mt={2}
              borderRadius="8px"
              bg="#FED1D1"
              px="6"
              pt={2}
              flexDir="column"
            >
              <Flex
                justify="end"
                gap={4}
                align="center"
                borderBottom="1px solid grey"
              >
                <Img
                  bg="primary"
                  borderRadius="100px"
                  boxSize="35px"
                  src={userData?.photoURL}
                />
                <Flex flexDir="column" mb={4} w="70%">
                  <Text p="0" mb="-0.3rem" fontSize="16px" fontWeight="black">
                    {userData?.displayName}
                  </Text>
                  <Text mb="-0.3rem" fontSize="16px">
                    {userData?.email}
                  </Text>
                </Flex>
                <Flex flexDir="column">
                  <Button
                    px="2"
                    py="2"
                    h="fit-content"
                    fontSize="10px"
                    w="fit-content"
                    leftIcon={<AiFillWarning />}
                    color="#E13636"
                    border="1px solid #E13636"
                  >
                    Will be deleted
                  </Button>
                </Flex>
              </Flex>
              <Button
                color="#E13636"
                p="2"
                h="fit-content"
                fontSize="10px"
                w="fit-content"
                leftIcon={<AiFillWarning />}
              >
                You will lose access to all of your projects and their content
              </Button>
            </Flex>

            <Flex flexDir="column" mt={8}>
              <Text>Please acknowledge and confirm:</Text>
              <Flex
                mt={2}
                p={2}
                borderRadius={6}
                gap={4}
                align="center"
                bg="#E1E2E7"
              >
                <Checkbox
                  value={acceptedTerms}
                  onChange={() => {
                    setAcceptedTerms(!acceptedTerms);
                  }}
                  bg="#D9D9D9"
                  borderColor="rgba(0, 0, 0, 0.18)"
                />
                <Text fontSize="12px">
                  I understand that deleting my account is permanent,
                  irreversible, and I have reviewed and accepted what will
                  happen to my account, project and content{" "}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              w="fit-content"
              color="black"
              fontWeight="black"
              colorScheme="blue"
              mr={8}
              onClick={onClose}
              p="0"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                user
                  .delete()
                  .then(() => {
                    //Send user to login page
                    window.location.replace("/");
                  })
                  .catch((error) => {
                    if (error.code === "auth/requires-recent-login") {
                      notify({
                        title: "Please reauthenticate.",
                        description:
                          "For security reasons, please logout and login again to enable deleting your account.",
                        status: "error",
                      });
                    }
                  });
              }}
              _hover={{ bg: "transparent" }}
              isDisabled={!acceptedTerms}
              p="0"
              w="fit-content"
              color="#E13636"
            >
              Yes, delete my account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
