import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { deleteField, updateDoc } from "firebase/firestore";
import React from "react";
import { useHistory } from "react-router-dom";
import { TOKENS_PER_PAGE } from "shared/constants";
import { AiStorageLimitationWarning } from "shared/userTypes";
import { useAuth } from "./AuthProvider";

const ArticleLimitations = ({
  limitations,
}: {
  limitations: AiStorageLimitationWarning[];
}) => {
  return (
    <Flex flexDir="column" as="ul" pl={4}>
      {limitations.map((limitation) => (
        <SingleArticleLimitationItem
          limitation={limitation}
          key={limitation.articleId}
        />
      ))}
    </Flex>
  );
};

const SingleArticleLimitationItem = ({
  limitation,
}: {
  limitation: AiStorageLimitationWarning;
}) => {
  const numPages = Math.ceil(limitation.tokensRequired / TOKENS_PER_PAGE);
  return (
    <Text as="li" fontSize="12px" fontWeight="normal" color="#212121">
      {`Article "${limitation.articleTitle}" in project "${
        limitation.projectName
      }" needs at least ${numPages} AI-${numPages === 1 ? "page" : "pages"}`}
    </Text>
  );
};

const TokenLimitationModal = () => {
  const history = useHistory();
  const { userDoc, userDocRef } = useAuth();
  const onClose = () => {
    updateDoc(userDocRef, {
      aiStorageLimitationWarnings: deleteField(),
    });
  };

  if (!userDoc?.aiStorageLimitationWarnings) return null;

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={!!userDoc.aiStorageLimitationWarnings}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent gap={2} px="2">
        <ModalHeader mt={4} pb="0">
          <Text fontSize="14px" fontWeight="normal" color="#212121">
            {"AI-ready storage limit reached"}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex pb="12px">
            <ArticleLimitations
              limitations={userDoc.aiStorageLimitationWarnings}
            />
          </Flex>
          <Text fontWeight={500} fontSize="14px">
            {"Purchase additional AI storage to upload more documents"}
          </Text>
        </ModalBody>

        <ModalFooter pb="8" pt="3">
          <Button
            as={Link}
            w="100%"
            border="1px solid"
            borderRadius="8px"
            borderColor="primary"
            colorScheme="blue"
            h="32px"
            mr={3}
            onClick={() => {
              onClose();
              history.push("/user/profile/workspace?workspacetab=overview");
            }}
            color="primary"
            fontSize="12px"
          >
            {"Purchase additional AI storage"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TokenLimitationModal;
