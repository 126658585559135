import { Avatar, Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { auth } from "../../firebase";
import { updateUserDisplayName } from "../Utils/auth";
import { ChangePassword } from "./ChangePassword";
import { DeleteUser } from "./DeleteUser";
import { EditDisplayName } from "./EditDisplayName";
import { EditProfileImg } from "./EditProfileImg";

export const UserProfileActions = () => {
  const [userData, setUserData] = useState();
  const [showeditDisplayName, setShowEditDisplayName] = useState(false);
  const [newProfilePhoto, setNewProfilePhoto] = useState();
  //Only users that logged in with email and password can change their password
  const [isEmailAndPasswordUser, setIsEmailAndPasswordUser] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const providerArr = user.providerData;
        providerArr.some((provider) => {
          if (provider.providerId === "password") {
            setIsEmailAndPasswordUser(true);
            return true;
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserData(user);
      }
    });
  }, [JSON.stringify(newProfilePhoto)]);
  return (
    <Flex flexDir="column" w="100%" display={"flex"}>
      <Flex align="center" h="fit-content" gap={4}>
        {showeditDisplayName ? (
          <EditDisplayName
            key={userData}
            closeEditDisplayName={() => {
              setShowEditDisplayName(false);
            }}
            displayName={userData?.displayName}
            updateNewDisplayName={(newDisplayName) => {
              updateUserDisplayName(newDisplayName).then(() => {
                setShowEditDisplayName(false);
              });
            }}
          />
        ) : (
          <Heading fontSize="24px">{userData?.displayName}</Heading>
        )}
        <BiPencil
          fontSize="24px"
          cursor="pointer"
          onClick={() => {
            setShowEditDisplayName(true);
          }}
          visibility={showeditDisplayName ? "hidden" : "visible"}
        />
      </Flex>

      <Flex mt={12} flexDir="column" gap={4}>
        <Avatar
          bg="primary"
          boxSize="80px"
          name={userData?.displayName}
          src={newProfilePhoto || userData?.photoURL}
        />
        <EditProfileImg
          newProfilePhoto={(url) => {
            setNewProfilePhoto(url);
          }}
        />
      </Flex>

      <Flex color="rgba(0, 0, 0, 0.54)" mt={12} flexDir="column">
        <Flex gap={2} align="center">
          <AiOutlineMail fontSize="14px" />
          <Text fontSize="13px">Email</Text>
        </Flex>
        <Text cursor="not-allowed" fontSize="13px">
          {userData?.email}
        </Text>
        <Divider
          display={isEmailAndPasswordUser ? "block" : "none"}
          py={4}
          orientation="horizontal"
        />
        <ChangePassword />
      </Flex>
      <Divider py={4} orientation="horizontal" />
      <Flex align="center" gap={12} pt={4}>
        <Button
          onClick={() => {
            auth.signOut();
            window.location.replace("/");
          }}
          borderRadius="6px"
          border="1px solid rgba(80, 80, 80, 0.5)"
          color="primary"
          fontSize="13px"
        >
          Logout
        </Button>
        <DeleteUser />
      </Flex>
    </Flex>
  );
};
