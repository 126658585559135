import {
  Box,
  Card,
  CardBody,
  Flex,
  FocusLock,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiMenuLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useArticle } from "../../Context/ArticleContext";
import { ACTIONS, ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import CompressIcon from "../Icons/CompressIcon";
import ExpandIcon from "../Icons/ExpandIcon";
import {
  getIndexingError,
  getIsIndexing,
} from "../ProjectPage/TableComponents/indexingUtils";
import { sortArticles } from "../Utils/articleUtil";

export const PAGES = {
  INSIGHTS: "INSIGHTS",
  VIEWER: "VIEWER",
  ARTICLE_DETAIL: "ARTICLE_DETAIL",
};

const SectionCard = ({
  insightData,
  routerHandler,
  maxH = "70vh",
  setDisplayType,
  type,
  icon,
  setMenuIsExpanded,
  onClose,
  page,
  setMaxH,
}) => {
  const { articleId } = useParams();

  const handleIconClick = () => {
    if (page === "VIEWER") {
      setMaxH(maxH === "70vh" ? "40vh" : "70vh");
      setMenuIsExpanded(maxH === "70vh" ? false : true);
      if (maxH === "70vh") {
        onClose();
      }
      return;
    }
    setDisplayType(type === "BUTTON" ? "FULL" : "BUTTON");
    setMenuIsExpanded(type === "BUTTON" ? true : false);
    if (type !== "BUTTON") {
      onClose();
    }
  };

  return (
    <Card
      shadow="sm"
      bg="white"
      color="primary"
      maxH={maxH}
      overflowY="scroll"
      w="100%"
    >
      <CardBody w="100%">
        <Flex mb={1}>
          <Spacer />
          <Icon
            cursor="pointer"
            onClick={handleIconClick}
            as={icon}
            boxSize="4"
            position="absolute"
            right="10px"
          />
        </Flex>
        {insightData?.map((articleType) => (
          <Box mb="4" key={articleType.name}>
            <Flex flex="1" gap="2" alignItems="center" flexWrap="wrap">
              <Icon as={articleType.icon} boxSize="4" />
              <Box>
                <Text fontWeight="bold" fontSize="10px">
                  {articleType.name}
                </Text>
              </Box>
            </Flex>
            <Box mt="2">
              <Stack divider={<StackDivider bg="#c3c8cb" />} spacing="1">
                {articleType?.articles?.map((article) => (
                  <Box
                    onClick={() =>
                      routerHandler ? routerHandler(article) : null
                    }
                    cursor="pointer"
                    key={article.uid}
                    py="2"
                  >
                    <Text
                      fontSize="xs"
                      fontWeight={articleId === article.uid ? "bold" : "normal"}
                      noOfLines={1}
                    >
                      {article?.title ? article.title : article.url}
                    </Text>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
        ))}
      </CardBody>
    </Card>
  );
};

const InsightSide = ({
  routerHandler,
  // insightData,
  onMenuClose,
  onMenuOpen,
  menuIsOpen,
  setMenuIsExpanded,
  defaultState = "FULL",
  page,
  menuIsExpanded,
}) => {
  const firstFieldRef = React.useRef(null);
  // temporary change to display type
  const [displayType, setDisplayType] = useState(defaultState);
  const [maxH, setMaxH] = useState("40vh");
  const { allUnfilteredArticles } = useArticle();

  let insightData;

  if (page === PAGES.INSIGHTS) {
    insightData = Object.entries(ARTICLE_TYPE_DATA)
      .map(([key, value]) => {
        if (!value.actions.includes(ACTIONS.AI_INSIGHT)) return null;

        let articles = sortArticles(
          allUnfilteredArticles.filter((article) => article.type === key)
        );

        // filter out articles that are currently being indexed or have indexing errors
        articles = articles.filter(
          (article) =>
            !getIsIndexing(article) &
            !getIndexingError(article) &
            article.summaryReady
        );

        if (articles.length === 0) return null;

        return {
          name: value.uiNamePlural,
          articles,
          icon: value.icon || null,
        };
      })
      .filter((item) => item);
  } else if (page === PAGES.VIEWER) {
    insightData = Object.entries(ARTICLE_TYPE_DATA)
      .map(([key, value]) => {
        if (!value.actions.includes(ACTIONS.OPEN_VIEWER)) return null;

        let articles = sortArticles(
          allUnfilteredArticles.filter((article) => article.type === key)
        );

        // filter out articles which don't have url or pathToPdf
        articles = articles.filter(
          (article) => article.url || article.pathToPdf
        );

        if (articles.length === 0) return null;

        return {
          name: value.uiNamePlural,
          articles,
          icon: value.icon || null,
        };
      })
      .filter((item) => item);
  } else if (page === PAGES.ARTICLE_DETAIL) {
    insightData = Object.entries(ARTICLE_TYPE_DATA)
      .map(([key, value]) => {
        if (!value.actions.includes(ACTIONS.AI_INSIGHT)) return null;

        let articles = sortArticles(
          allUnfilteredArticles.filter((article) => article.type === key)
        );

        if (articles.length === 0) return null;

        return {
          name: value.uiNamePlural,
          articles,
          icon: value.icon || null,
        };
      })
      .filter((item) => item);
  }

  useEffect(() => {
    if (!menuIsOpen) setDisplayType("BUTTON");
  }, [menuIsOpen]);

  return (
    <>
      {displayType !== "BUTTON" ? (
        <SectionCard
          insightData={insightData}
          routerHandler={routerHandler}
          type={displayType}
          setDisplayType={setDisplayType}
          icon={CompressIcon}
          setMenuIsExpanded={setMenuIsExpanded}
          onClose={onMenuClose}
          page={page}
        />
      ) : (
        <Box>
          <Popover
            isOpen={menuIsOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onMenuOpen}
            onClose={onMenuClose}
            placement="right"
            closeOnBlur={menuIsExpanded && page === "VIEWER" ? false : true}
            trigger={menuIsExpanded && page === "VIEWER" ? "click" : "hover"}
          >
            <PopoverTrigger>
              <Box
                w={10}
                h="104px"
                bg="white"
                shadow="md"
                rounded="lg"
                position="absolute"
                bottom="45%"
                justifyContent="center"
                display="flex"
                alignItems="center"
                cursor="pointer"
                zIndex={1}
              >
                {" "}
                <Icon as={RiMenuLine} />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              ml={-12}
              // changes the width and height of the expanded menu on the viewer page.
              // on the insights page it is controlled by the gridview layout
              w={page === "VIEWER" && menuIsExpanded ? "211px" : "80"}
              h={page === "VIEWER" && menuIsExpanded ? "fit-content" : null}
              position="relative"
              zIndex={2}
            >
              <FocusLock returnFocus persistentFocus={false}>
                <SectionCard
                  insightData={insightData}
                  routerHandler={routerHandler}
                  maxH={maxH}
                  setMaxH={setMaxH}
                  type={displayType}
                  setDisplayType={setDisplayType}
                  icon={menuIsExpanded ? CompressIcon : ExpandIcon}
                  setMenuIsExpanded={setMenuIsExpanded}
                  onClose={onMenuClose}
                  page={page}
                />
              </FocusLock>
            </PopoverContent>
          </Popover>
        </Box>
      )}
    </>
  );
};
export default InsightSide;
