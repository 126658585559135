export const SMART_CARD_TYPE = {
  source: "source",
  m_number: "m_number",
  m_rating: "m_rating",
  date: "date",
  forthcoming: "forthcoming",
  precise: "precise",
};

export const SMART_TABLE_COLUMNS = [
  {
    id: "1",
    name: "Sub-source",
    key: SMART_CARD_TYPE.source,
    textAlign: "left",
  },
  {
    id: "2",
    name: "S Number",
    key: SMART_CARD_TYPE.m_number,
    textAlign: "right",
  },
  {
    id: "3",
    name: "S Rating",
    key: SMART_CARD_TYPE.m_rating,
    textAlign: "right",
  },
  {
    id: "4",
    name: "Date",
    key: SMART_CARD_TYPE.date,
    textAlign: "right",
  },
];

export const SMART_CARD_METADATA = [
  {
    displayName: "Sub-source",
    key: SMART_CARD_TYPE.source,
    format: "string",
    position: "top",
    showName: false,
  },
  {
    displayName: "Forthcoming",
    key: SMART_CARD_TYPE.forthcoming,
    format: "bar",
    position: "bottom",
    showName: true,
  },
  {
    displayName: "Precise",
    key: SMART_CARD_TYPE.precise,
    format: "bar",
    position: "bottom",
    showName: true,
  },
  {
    displayName: "S Rating",
    key: SMART_CARD_TYPE.m_rating,
    format: "string",
    position: "bottom",
    showName: true,
  },
  {
    displayName: "S Number",
    key: SMART_CARD_TYPE.m_number,
    format: "string",
    position: "bottom",
    showName: true,
  },
  {
    displayName: "Date",
    key: SMART_CARD_TYPE.date,
    format: "string",
    position: "bottom",
    showName: true,
  },
];
