import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";

export const LimitationsWarningModal = ({
  title,
  subHeading,
  bodyText,
  primaryBttnText,
  primaryBttnLink,
}: {
  title: string;
  subHeading: string;
  bodyText: string;
  primaryBttnText: string;
  primaryBttnLink: string;
}) => {
  const { userDoc, userDocRef } = useAuth();

  const handleClose = () => {
    updateDoc(userDocRef, {
      showLimitationsWarning: false,
    });
  };

  if (!userDoc?.showLimitationsWarning) return null;

  return (
    <Modal isCentered isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minW="466px" gap={2} px="2">
        <ModalHeader mt={4} pb="0">
          <Text fontSize="14px" fontWeight="normal" color="#212121">
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight={500} fontSize="14px" pb="4">
            {subHeading}
          </Text>
          <Text fontSize="12px">{bodyText}</Text>
        </ModalBody>

        <ModalFooter pb="8" pt="3">
          <Button
            as={Link}
            w="100%"
            border="1px solid"
            borderRadius="8px"
            borderColor="primary"
            colorScheme="blue"
            h="32px"
            mr={3}
            onClick={handleClose}
            color="primary"
            fontSize="12px"
            to={primaryBttnLink}
          >
            {primaryBttnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
