import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Link,
  CircularProgress,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { auth, sendPasswordResetEmail } from "../../firebase";
import { checkIfUserEmailIsInDb } from "../Utils/auth";
import { validateEmail } from "../Utils/validateUrl";
import { Link as ReactRouterLink } from "react-router-dom";

export const ResetPassword = () => {
  const [resetEmail, setResetEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [emailIsNotRegistered, setEmailIsNotRegistered] = useState(false);
  const [showConfirmMssg, setShowConfirmMssg] = useState(false);

  const startEmailResetProcess = () => {
    if (validateEmail(resetEmail)) {
      checkIfUserEmailIsInDb(resetEmail).then((res) => {
        if (res) {
          setShowConfirmMssg(true);
          sendPasswordResetEmail(auth, resetEmail).then(() => {});
        } else {
          setEmailIsNotRegistered(true);
        }
      });
    }
  };
  return (
    <Flex
      w={{ base: "55%", lg: "30%" }}
      m="0 auto"
      h="80vh"
      align="center"
      justify="center"
      flexDir="column"
    >
      <Heading fontSize="24px" color="#1D1D1D">
        Reset Password
      </Heading>
      <Flex
        display={showConfirmMssg ? "none" : "flex"}
        flexDir="column"
        justify="center"
        align="center"
      >
        <Text m="0 auto" w="60%" mt={8} fontSize="15px">
          Enter your email below and we’ll send you a link to reset it
        </Text>
        <FormControl w="60%" mt={4}>
          <FormLabel fontWeight="400" color="tertiary" fontSize="14px" m="0">
            Email
          </FormLabel>
          <Input
            m="0 auto"
            fontSize="14px"
            type="email"
            border="1px solid rgba(0, 0, 0, 0.12)"
            borderColor="rgba(0, 0, 0, 0.12)"
            value={resetEmail}
            onChange={(e) => {
              //Check if it is valid email
              setEmailIsNotRegistered(false);
              if (!validateEmail(e.target.value)) {
                setInvalidEmail(true);
              } else {
                setInvalidEmail(false);
              }
              setResetEmail(e.target.value);
            }}
          />
          <Text
            fontSize="14px"
            display={emailIsNotRegistered ? "block" : "none"}
          >
            This email hasn&rsquo;t been registered.{" "}
            <Link color="link" as={ReactRouterLink} to="/">
              Please signup here
            </Link>
          </Text>
          <Text
            visibility={
              invalidEmail === false
                ? "hidden"
                : resetEmail.length === 0
                ? "hidden"
                : "visible"
            }
            color="red"
            fontSize="14px"
          >
            Invalid email
          </Text>
        </FormControl>
        <Button
          fontSize="14px"
          w="60%"
          mt={6}
          bg="primary"
          isDisabled={invalidEmail}
          onClick={() => {
            startEmailResetProcess();
          }}
        >
          <CircularProgress
            display={"none"}
            isIndeterminate
            color="white"
            mr={4}
            size="20px"
          />
          Send password reset link
        </Button>
        <Text mt={10} color="tertiary" fontSize="14px">
          Remembered your password?{" "}
          <Link color="link" as={ReactRouterLink} to="/">
            Sign in
          </Link>
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        align="center"
        display={showConfirmMssg ? "flex" : "none"}
      >
        <Text mt={12} align="center">
          We sent a password reset link to{" "}
          <Text display="inline" fontWeight="black">
            {resetEmail}
          </Text>
          . Check your email and click the link to reset your password.
        </Text>
        <Text mt={6} fontSize="13px">
          Password Reset Successful?{" "}
          <Link
            as={ReactRouterLink}
            to="/"
            color="link"
            display="inline"
            cursor="pointer"
          >
            Sign in
          </Link>
        </Text>
        <Text mt={6} fontSize="13px">
          Didn&apos;t get a link?{" "}
          <Text
            color="link"
            display="inline"
            onClick={() => {
              setShowConfirmMssg(false);
            }}
            cursor="pointer"
          >
            Send again
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
