import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";

const LoadingBox = ({ animationDelay }) => {
  return (
    <Box
      w="20px"
      h="20px"
      borderRadius="5px"
      sx={{
        animation: `colorChange 2s infinite ease-in-out`,
        animationDelay: `${animationDelay}s`,
        backgroundColor: "#191D30",
      }}
    />
  );
};

const CustomProgress = ({ value, max }) => {
  const percentage = (value / max) * 100;
  return (
    <Box
      w="160px"
      h="8px"
      bg="#e0e0e0"
      borderRadius="4px"
      overflow="hidden"
      sx={{
        position: "relative",
        transition: "width 5s ease, background-color 1s ease",
      }}
    >
      <Box
        h="100%"
        bg="#191d30"
        borderRadius="4px"
        sx={{
          width: `${percentage}%`,
          transition: "width 5s ease, background-color 1s ease",
        }}
      />
    </Box>
  );
};

export const BoxLoading = ({ loadingText, progress }) => {
  return (
    <>
      <Global
        styles={css`
          @keyframes colorChange {
            0% {
              background-color: #191d30;
            }
            50% {
              background-color: #59808d;
            }
            100% {
              background-color: #191d30;
            }
          }
        `}
      />
      <Flex
        h="100%"
        gap="28px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SimpleGrid columns={2} spacing="8px" w="50px">
          <LoadingBox animationDelay={0} />
          <LoadingBox animationDelay={0.5} />
          <LoadingBox animationDelay={1.5} />
          <LoadingBox animationDelay={1} />
        </SimpleGrid>
        {progress && <CustomProgress value={progress} max={100} />}
        <Text color="#737373" fontSize="12px">
          {loadingText}
        </Text>
      </Flex>
    </>
  );
};
