import { Flex, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  DisplaySingleArticle,
  MultiDocSelectMenuOnChat,
} from "../../Utils/assistantMessageDisplay";
import style from "../../react-markdown.module.css";
import { CustomAIDocQueryMessage } from "./CustomAIDocQueryMessage";
import { MessageIsRestrictedToChatIndicator } from "./MessageIsRestrictedToChatIndicator";
import TemplateMessageType from "./TemplateMessageType";

const MessageTypeIndicator = ({ type, selectedArticles, title }) => {
  if (type === "custom") {
    return (
      <CustomAIDocQueryMessage
        selectedArticles={selectedArticles}
        title={title}
      />
    );
  }

  if (type === "template") {
    return <TemplateMessageType templateTitle={title} />;
  }

  return null;
};

const ArticleSelectedIndicator = ({
  selectedArticles,
  toggleSelectedArticleList,
  showSelectedArticleList,
}) => {
  if (selectedArticles.length === 0) {
    return null;
  } else if (selectedArticles.length === 1) {
    return <DisplaySingleArticle selectedArticle={selectedArticles[0]} />;
  } else {
    return (
      <MultiDocSelectMenuOnChat
        toggleSelectedArticleList={toggleSelectedArticleList}
        selectedArticles={selectedArticles}
        showSelectedArticleList={showSelectedArticleList}
      />
    );
  }
};

export const UserMessage = ({
  userMessage,
  type,
  title,
  selectedArticles,
  useOnlyChat,
}) => {
  const [showSelectedArticleList, setShowSelectedArticleList] = useState(false);
  const toggleSelectedArticleList = () => {
    setShowSelectedArticleList(!showSelectedArticleList);
  };

  return (
    <Flex
      borderRadius="lg"
      gap="8px"
      bg="#F1F1F1"
      align="top"
      pl="1"
      color="#191D30 !important"
    >
      <VStack align="start" gap={1} w="100%">
        <ReactMarkdown className={style.reactMarkDown}>
          {userMessage}
        </ReactMarkdown>
        <MessageTypeIndicator
          type={type || ""}
          title={title}
          selectedArticles={selectedArticles}
        />
        {useOnlyChat && <MessageIsRestrictedToChatIndicator />}
        {type !== "template" ? (
          <ArticleSelectedIndicator
            selectedArticles={selectedArticles}
            toggleSelectedArticleList={toggleSelectedArticleList}
            showSelectedArticleList={showSelectedArticleList}
          />
        ) : null}
      </VStack>
    </Flex>
  );
};
