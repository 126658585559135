import { configureStore } from '@reduxjs/toolkit'
import projectReducer from './slices/project-slice'
import contentReducer from './slices/curated-slice'
import gptReducer from './slices/gpt-slice'

export const store = configureStore({
  reducer: {
    project: projectReducer,
    curatedContents: contentReducer,
    gpt: gptReducer
  },
})