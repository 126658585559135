import { Icon } from "@chakra-ui/react";
import React from "react";

const UnPinIcon = (props) => {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path
        d="M10.2201 4.66668V8.00001C10.2201 8.74668 10.4667 9.44001 10.8867 10H6.88673C7.32006 9.42668 7.5534 8.73334 7.5534 8.00001V4.66668H10.2201ZM12.2201 3.33334L5.5534 3.33334C5.18673 3.33334 4.88673 3.63334 4.88673 4.00001C4.88673 4.36668 5.18673 4.66668 5.5534 4.66668H6.22006V8.00001C6.22006 9.10668 5.32673 10 4.22006 10L4.22006 11.3333H8.20006V16L8.86673 16.6667L9.5334 16V11.3333H13.5534V10C12.4467 10 11.5534 9.10668 11.5534 8.00001V4.66668H12.2201C12.5867 4.66668 12.8867 4.36668 12.8867 4.00001C12.8867 3.63334 12.5867 3.33334 12.2201 3.33334Z"
        fill={props.color}
      />
      <path
        d="M1.88672 1L16.3953 15.5086"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
};

export default UnPinIcon;
