import { Flex } from "@chakra-ui/react";
import React from "react";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { ContextIndividualFloatingTemplate } from "./ContextIndividualFloatingTemplate";

export const FloatingTemplateList = ({
  highlightedText,
  onClickAway,
  sourceArticles,
  ...props
}) => {
  //Use template context to get the list of templates
  const { templateList } = useTemplateContext();
  return (
    <Flex mt="6.5px" {...props} flexDir="column">
      {templateList.map((template, index) => (
        <ContextIndividualFloatingTemplate
          key={index}
          text={template.name}
          id={template.id}
          highlightedText={highlightedText}
          onClickAway={onClickAway}
          sourceArticles={sourceArticles}
        />
      ))}
    </Flex>
  );
};
