import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { RiFileCopy2Fill } from "react-icons/ri";
import { useOrganizerContext } from "../../Context/OrganizerContext";
import { useTemplateContext } from "../../Context/TemplateContext";
import { generateTemplateContentText } from "../Utils/curatedContent";

const OrganizerPreviewModal = ({ isOpen, onClose }) => {
  const { currentTemplate } = useTemplateContext();
  const { filteredSectionsData } = useOrganizerContext();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      const textToCopy = generateTemplateContentText(filteredSectionsData);
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <Modal
        size="4xl"
        // blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentTemplate?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="60vh" overflowY="scroll">
            {Object.entries(filteredSectionsData).map(([key, section]) => (
              <Box key={key} mb="4">
                <Heading size="md">{section.name}</Heading>
                {section.cards?.map((card) => (
                  <Text key={card.uid} mt="4">
                    {card.text}
                  </Text>
                ))}
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button
              rightIcon={<RiFileCopy2Fill />}
              colorScheme="primary"
              variant="outline"
              onClick={handleCopyClick}
            >
              {isCopied ? "Copied" : "Copy"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrganizerPreviewModal;
