import { Button, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { LiaVideoSolid } from "react-icons/lia";

export function BookSupportCallButton() {
  return (
    <Link
      href="https://calendly.com/mosaiqlabs/get-help-with-custom-modules/"
      isExternal
    >
      <Button
        bg="primary"
        color="white"
        fontSize="16px"
        fontWeight="500"
        px="12px"
        py="8.5px"
        w="fit-content"
        rightIcon={<LiaVideoSolid fontSize="16px" />}
      >
        Book a support call
      </Button>
    </Link>
  );
}

export const RequestModuleVideo = () => {
  return (
    <Flex
      flexDir="column"
      w="35%"
      // uncomment when we have video to have buttons
      // for request module and book a support call on the same level
      // h="500px"
      gap="24px"
      justify="end"
    >
      <Flex flexDir="column" gap="24px">
        {/* Uncomment when we have video ready */}
        {/* <Flex flexDir="column" gap="26px">
          <Box fontSize="14px" fontWeight="500">
            Watch video of{" "}
            <Text display="inline" opacity="0.54">
              ‘Request a module’
            </Text>
          </Box>
          <Flex h="308px" bg="#EDEDED" borderRadius="4px" w="500px">
            <iframe
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
              src="https://www.youtube.com/embed/DIToNTcBmw8"
              title="Request Module Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Flex>
        </Flex> */}

        <Text fontSize="16px" fontWeight="400">
          Need more help? Book a call, and we’ll build your modules together.
        </Text>
      </Flex>
      <Flex w="100%" justifyContent="flex-start">
        <BookSupportCallButton />
      </Flex>
    </Flex>
  );
};
