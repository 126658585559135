import {
  Center,
  Flex,
  FlexProps,
  Icon,
  InputGroup,
  InputLeftElement,
  Spinner,
  chakra,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchResult } from "shared/search";
import { callApi } from "src/services/serviceUtils";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import useSearchOverlay from "../../hooks/useSearchOverlay";
import SearchIconComponent from "../Icons/SearchIconComponent";
import { ArticleSearchList } from "./ArticleSearchList";
import { ProjectSearchList } from "./ProjectSearchList";
import SearchOverlay from "./SearchOverlay";

export const SEARCH_LIMIT = 5;

const searchApiCall = async ({ query }: { query: string }) => {
  if (!process.env.REACT_APP_SEARCH_API_URL) {
    throw new Error("REACT_APP_SEARCH_API_URL is not defined");
  }
  const response = await callApi<SearchResult>(
    process.env.REACT_APP_SEARCH_API_URL,
    "POST",
    { query }
  );
  if (response.error) {
    throw new Error(response.error);
  }
  return response.data;
};

const useSearchQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [results, setResults] = useState<SearchResult | undefined>(undefined);
  const [query, setQuery] = useState("");

  /**
   * Handle debouncing of search query. If the user stops typing for 500ms,
   * then we will call the search API.
   */
  useEffect(() => {
    if (!query) {
      setResults(undefined);
      return;
    }
    setIsLoading(true);
    const timeout = setTimeout(async () => {
      const response = await searchApiCall({ query });
      setResults(response);
      setIsEmpty(!response?.projects?.length && !response?.articles?.length);
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [query]);

  return { isLoading, isEmpty, results, query, setQuery };
};

const titleStyles: FlexProps = {
  fontSize: "xs",
  fontWeight: "medium",
  color: "rgba(0,0,0,0.5)",
  textTransform: "initial",
};

const SearchInput = () => {
  const { isLoading, isEmpty, results, query, setQuery } = useSearchQuery();
  const { hasOverlay, removePoppedArticle } = useSearchOverlay();
  const router = useHistory();

  const NoResultsFound = (
    <Flex direction="column" gap="6" align="center" justify="center" py="2">
      <chakra.span fontWeight="semibold" fontSize="12px">
        No Result Found
      </chakra.span>
    </Flex>
  );

  const renderSearchResults = () => {
    return (
      <>
        <AutoCompleteGroup>
          <AutoCompleteGroupTitle {...titleStyles}>
            Projects
          </AutoCompleteGroupTitle>
          {/* Nested components don't seem to work in auto complete, so calling as a function */}
          {ProjectSearchList({ results, router, setQuery })}
        </AutoCompleteGroup>
        {Object.entries(ARTICLE_TYPE_DATA).map(([type, data]) => (
          <AutoCompleteGroup key={type}>
            <AutoCompleteGroupTitle {...titleStyles}>
              {data.uiNamePlural}
            </AutoCompleteGroupTitle>
            {/* Nested components don't seem to work in auto complete, so calling as a function */}
            {ArticleSearchList({ results, articleType: type, setQuery })}
          </AutoCompleteGroup>
        ))}
      </>
    );
  };

  const renderSearchOverlay = hasOverlay && (
    <SearchOverlay removePoppedArticle={removePoppedArticle} />
  );

  const renderAutoCompleteListContent = () => {
    if (isLoading) {
      return (
        <Center py="9">
          <Spinner color="#63B3ED" />
        </Center>
      );
    }
    if (isEmpty) {
      return NoResultsFound;
    }
    if (results) {
      return renderSearchResults();
    }
    return null;
  };

  console.log({ isLoading, results });

  return (
    <AutoComplete
      openOnFocus
      closeOnSelect={false}
      suggestWhenEmpty
      emptyState={<></>}
    >
      <InputGroup position="relative">
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIconComponent} mt={1} />
        </InputLeftElement>
        <AutoCompleteInput
          rounded="8px"
          placeholder={hasOverlay ? "" : "Search for a project or article"}
          _placeholder={{
            opacity: 0.54,
            fontWeight: "400",
            fontSize: "12px",
          }}
          bg="white"
          border="none"
          flex="1"
          maxW={560}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          fontSize="12px"
        />
        {renderSearchOverlay}
      </InputGroup>
      <AutoCompleteList
        mt={-1}
        h="auto"
        shadow="lg"
        display={isLoading || results ? "block" : "none"}
      >
        {renderAutoCompleteListContent()}
      </AutoCompleteList>
    </AutoComplete>
  );
};

export default memo(SearchInput);
