import React from "react";
import ReactDOM from "react-dom";
import { BiBookmark } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import { updateXfdString } from "../../db/article";
import { createSnippet, deleteSnippet, updateSnippet } from "../../db/snippet";
import { arrayUnion, auth, db, doc, updateDoc } from "../../firebase";

export function makeSnippetFromAnnotation(annotation) {
  return {
    uid: annotation.Id,
    text: annotation.getCustomData("trn-annot-preview"),
  };
}
export function createExtractHighlightsMenu(onOpen, instance) {
  const createMenuItem = (name) => {
    let text = document.createElement("h3");
    //Add className to text
    text.className = "extractHighlightsMenu";
    text.style.display = "flex";
    text.style.alignItems = "center";
    text.style.fontSize = "14px";
    text.style.color = "#878E95";
    text.style.cursor = "pointer";
    text.style.padding = "0.5rem 1rem";
    text.style.borderRadius = "4px";
    const icon = React.createElement(BiBookmark, {
      size: 16,
      style: { marginRight: "0.5rem" },
    });
    const iconContainer = document.createElement("div");
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(icon, iconContainer);
    text.appendChild(iconContainer.firstChild);
    text.appendChild(document.createTextNode(name));
    //Add class name to text

    text.onclick = () => {
      onOpen();
    };
    return text;
  };
  const iframeDoc = instance?.iframeWindow?.document;
  const header = iframeDoc?.querySelector(".Header > .HeaderItems");
  header.appendChild(createMenuItem("Save Highlights"));
}

export function saveXfdString({ annotationManager, projectId, articleId }) {
  annotationManager
    .exportAnnotations({
      links: false,
      widgets: false,
      generateInlineAppearances: true,
    })
    .then((xfdString) => {
      console.log("saveXfdString", xfdString);
      // xfdString is used for re-rendering the highlights when the user navigates back to the article
      updateXfdString({
        projectId,
        articleId,
        xfdString,
      });
    });
}

/**
 * This handles syncing annotations with db (creating, deleting and modifying snippets).
 * For more details, see https://docs.apryse.com/documentation/web/guides/annotation/annotationmanager/events/
 */
export const handleAnnotationChanged = ({
  projectId,
  articleId,
  articleTitle,
  articleUrl,
  authorId,
  annotationManager,
  annotations,
  action,
  info,
}) => {
  // ignore annotations that were imported using Xfd string
  if (info.imported) {
    return;
  }

  // these annotations will be ignored, and not synced to the database
  const IGNORE_ANNOTATIONS_SUBJECTS = ["Link", "Annotation"];
  // filter out IGNORE_ANNOTATIONS_SUBJECTS, we are not interested in syncing these
  annotations = annotations.filter(
    (annot) => !IGNORE_ANNOTATIONS_SUBJECTS.includes(annot.Subject)
  );

  // check how many annotations were changed, if more than one, throw an error.
  // If we find a behaviour that changes more than one annotation, we need to
  // handle it differently.
  let snippet;
  if (annotations.length === 0) {
    return;
  } else if (annotations.length > 1) {
    throw new Error(
      "More than one annotation was changed, this is not foreseen behavior, contact the developer"
    );
  } else {
    snippet = makeSnippetFromAnnotation(annotations[0]);
  }

  // console.log("handleAnnotationChanged", action, annotations);

  if (action === "add") {
    createSnippet({
      projectId,
      articleId,
      // TODO this will not work without state, need to get article data from db
      articleUrl,
      articleTitle: articleTitle,
      snippetId: snippet.uid,
      snippetType: "manualHighlight",
      text: snippet.text,
      authorId: authorId,
    });
  } else if (action === "delete") {
    deleteSnippet({
      projectId,
      snippetId: snippet.uid,
    });
  } else if (action === "modify") {
    updateSnippet({
      projectId,
      snippetId: snippet.uid,
      text: snippet.text,
      authorId: authorId,
    });
  }

  saveXfdString({
    annotationManager,
    projectId: projectId,
    articleId: articleId,
  });
};

// this updates the UI when clicking on the annotation (e.g. highlight)
export const handleAnnotationSelected = (instance) => {
  setTimeout(() => {
    const iframeDoc = instance.iframeWindow.document;
    const elementsToRemove = [
      "annotationCommentButton",
      "annotationStyleEditButton",
      "linkButton",
    ];
    elementsToRemove.forEach((element) => {
      try {
        iframeDoc.querySelector(`[data-element="${element}"]`).remove();
      } catch (error) {
        // ignore
      }
    });
  }, 5);
};

export function customizeHeader({ instance, noTools }) {
  // see documentation for WebViewer UI customization here:
  // https://docs.apryse.com/documentation/web/guides/customizing-header/
  let toolbarGroupAnnotate;
  if (noTools) {
    toolbarGroupAnnotate = [];
  } else {
    toolbarGroupAnnotate = [
      {
        type: "spacer",
      },
      {
        type: "toolGroupButton",
        toolGroup: "underlineTools",
        dataElement: "underlineToolGroupButton",
        title: "annotation.underline",
      },
      {
        type: "toolGroupButton",
        toolGroup: "highlightTools",
        dataElement: "highlightToolGroupButton",
        title: "annotation.highlight",
      },
      {
        type: "spacer",
        // hidden: ["tablet", "mobile", "small-mobile"],
      },
    ];
  }

  instance.UI.setHeaderItems((header) => {
    header.getHeader("toolbarGroup-Annotate").update(toolbarGroupAnnotate);
  });
  // hide the ribbons
  instance.UI.disableElements(["ribbons"]);
  // set the default toolbar group to the Annotate group
  instance.UI.setToolbarGroup("toolbarGroup-Annotate");
}

export const getPdfInViewPageNumber = () => {
  const allElements = document.getElementsByClassName("page");
  const allPdfPages = Array.from(allElements);

  let mostVisiblePage = null;
  let maxVisibleArea = 0;

  allPdfPages.forEach((element) => {
    const rect = element.getBoundingClientRect();
    const visibleArea =
      Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);

    if (visibleArea > maxVisibleArea) {
      maxVisibleArea = visibleArea;
      mostVisiblePage = element;
    }
  });

  return mostVisiblePage?.dataset?.pageNumber;
};

export const getElementsInView = (number) => {
  const currentPdfPageNum = getPdfInViewPageNumber();
  setTimeout(() => {
    scrollToPage(number || currentPdfPageNum);
  }, 20);
};

export const scrollToPage = (pageNumber) => {
  const selector = `[data-page-number='${pageNumber}']`;
  const pageElement = document.querySelector(selector);
  if (pageElement) {
    pageElement.scrollIntoView();
  }
};

export const handleOnSaveHighlightOnTooltip = (
  content,
  position,
  projectId,
  articleId,
  curated
) => {
  const highlightData = {
    content,
    position,
    id: uuidv4(),
  };

  if (highlightData.content.text === "") return;
  createSnippet({
    projectId,
    articleId,
    articleUrl: location.state?.articleUrl,
    articleTitle: location.state?.articleData.title,
    snippetId: highlightData.id,
    snippetType: "manualHighlight",
    text: highlightData.content.text,
    authorId: auth.currentUser.uid,
    id: highlightData.id,
    position: highlightData.position,
    curated: curated || false,
  });
};

export const createSnippetAndAddToSection = ({
  projectId,
  articleId,
  position,
  templateId,
  sectionId,
}) => {
  const snippetId = uuidv4();
  const payload = {
    projectId,
    articleId,
    articleUrl: location.state?.articleUrl,
    articleTitle: location.state?.articleData.title,
    snippetId: snippetId,
    snippetType: "manualHighlight",
    text: window.getSelection().toString(),
    authorId: auth.currentUser.uid,
    id: snippetId,
    position: position,
    curated: true,
  };
  createSnippet(payload);
  // Add snippet to section
  const sectionRef = doc(
    db,
    `/projects/${projectId}/templates/${templateId}/organizerSections/${sectionId}`
  );
  updateDoc(sectionRef, {
    snippets: arrayUnion({ snippetId, cardType: "addedByUser" }),
  });
};

export const handleDownload = async (url, title) => {
  const fileExtension = getFileExtension(url);
  const filename = `${title
    .replace(/[^a-z0-9]/gi, "_")
    .toLowerCase()}.${fileExtension}`.split(".")[0];
  try {
    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const getFileExtension = (url) => {
  const extension = url.split(".").pop();
  return extension.toLowerCase();
};

export const getFileTypeFromExtension = (extension) => {
  switch (extension) {
    case "pdf":
      return "PDF";
    case "docx":
      return "Word Document";
    case "csv":
      return "CSV File";
    // Add more cases as needed for other file types
    default:
      return "File";
  }
};
