import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Divider,
  Flex,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Perk } from "./plansLists";

const PerksList = ({ perks }) => (
  <List w="100%" spacing={"9px"}>
    {perks
      .filter(({ value }) => value)
      .map((perk) => (
        <ListItem
          w="100%"
          display="flex"
          alignContent="center"
          key={perk.value}
        >
          <ListIcon color="#59808D" fontSize="11px" as={CheckIcon} />
          <Text fontSize="11px" color="#212121">
            {perk.value}
          </Text>
        </ListItem>
      ))}
  </List>
);

export const PlanButton = ({ children, ...props }: ButtonProps) => (
  <Button
    bg="primary"
    size="sm"
    h="fit-content"
    py="2"
    mt="12"
    fontSize="12px"
    w="215px"
    {...props}
  >
    {children}
  </Button>
);

export const WorkspacePlanCard = ({
  icon,
  title,
  priceSection,
  perks,
  isCurrentPlan,
  actionButtonWhenCurrent,
  actionButtonWhenNotCurrent,
}: {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  priceSection: React.ReactNode;
  perks: Perk[];
  isCurrentPlan: boolean;
  actionButtonWhenCurrent?: React.ReactNode;
  actionButtonWhenNotCurrent?: React.ReactNode;
}) => {
  return (
    <Flex
      align="center"
      w="338px"
      minW="338px"
      flexDir="column"
      h="100%"
      pos="relative"
      justify="space-between"
      bg={isCurrentPlan ? "#EFEFEF" : "white"}
      px="2"
      py="40px"
      borderRadius="md"
    >
      <Flex gap={2} flexDir="column" align="center">
        <Text
          visibility={isCurrentPlan ? "visible" : "hidden"}
          fontSize="12px"
          fontWeight="bold"
        >
          CURRENT
        </Text>
        <Icon as={icon} color="primary" fontSize="42.5px" fontWeight="bolder" />
        <Text fontSize="18px" fontWeight="medium">
          {title}
        </Text>
        {priceSection}
      </Flex>
      <Divider mb="25.5px" w="65%" borderColor="#BCBCBC" />
      <Flex w="65%" h="100%">
        <PerksList perks={perks} />
      </Flex>
      {isCurrentPlan ? actionButtonWhenCurrent : actionButtonWhenNotCurrent}
    </Flex>
  );
};
