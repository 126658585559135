import React from "react";
import { Icon } from "@chakra-ui/react";

const AddIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M12.6667 8.66665H8.66668V12.6666H7.33334V8.66665H3.33334V7.33331H7.33334V3.33331H8.66668V7.33331H12.6667V8.66665Z"
          fill="black"
        />
    </Icon>
  );
};

export default AddIconComponent;
