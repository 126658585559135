import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

export const VideoModal = ({
  isOpen,
  onClose,
  videoUrl,
}: {
  isOpen: boolean;
  onClose: () => void;
  videoUrl?: string;
}) => {
  if (!videoUrl) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        minW="80vw"
        minH="80vh"
        display="flex"
        flexDirection="column"
      >
        <ModalCloseButton />
        <iframe
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            borderRadius: "4px",
          }}
          src={videoUrl}
          title="MosaiqLabs Product Demo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </ModalContent>
    </Modal>
  );
};
