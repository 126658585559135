import { Divider, Flex, Image, Link } from "@chakra-ui/react";
import React from "react";
import {
  SecondaryTitleVideoAndTextsSection,
  TitleVideoAndTextsSection,
} from "../../Components/Help/TitleVideoAndTextsSection";
import { LAYOUT_PROPS } from "../../Components/Layout";
import { getCustomizationData } from "../../Components/Utils/customizationUtils";

const customization = getCustomizationData();

function Logo() {
  return (
    <Flex>
      <Flex
        gap={4}
        w="full"
        maxW={LAYOUT_PROPS.maxWidth}
        justify="space-between"
      >
        <Link href="/">
          <Image w="100px" minW="72px" src={customization.logo} />
        </Link>
      </Flex>
    </Flex>
  );
}

export const HelpAndSupport = () => {
  return (
    <Flex px="24px" py="37px" flexDir="column">
      <Logo />
      <Flex mt="60px" maxW="995px" m="0 auto" flexDir="column">
        <TitleVideoAndTextsSection
          title="General overview"
          paragraphs={[
            "At MosaiQ, we specialize in integrating AI into workflows for businesses like yours, offering powerful customization to enhance productivity and efficiency. Our platform serves as a central tool to scale AI use cases, providing tools, services, and training programs. Viewers are encouraged to try the platform for free or book a consultation for personalized advice. The video demonstrates how to create projects, import data, analyze information using AI assistants, and structure insights efficiently. The custom AI modules automate complex tasks, enhancing workflow automation significantly.",
          ]}
          videoParams={{
            src: "https://www.youtube.com/embed/oxceK8fbVSk?cc_load_policy=1",
            title: "MosaiQ Labs Overview",
            height: "560px",
          }}
        />
        <Divider borderColor="#000000" opacity="0.1" my="36px" />
        <Flex gap="24px" flexDir="column">
          <SecondaryTitleVideoAndTextsSection
            title="What are custom AI modules"
            paragraphs={[
              "In this video, I explain how AI modules can enhance workflows by delegating tasks to AI assistants. I showcase examples of simple and advanced modules in action, such as a press release preparation and AI market analysis for an M&A boutique. Viewers are encouraged to see the practical applications of AI modules and consider integrating them into their own workflows for efficiency and productivity.",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/c4AttbFrsH8?cc_load_policy=1",
              title: "What are custom AI modules",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="What is the AI module library"
            paragraphs={[
              "In this video, I explain the concept of AI modules and how they can enhance your workflow. AI modules are specialized capabilities that assist in task completion, ranging from simple to complex tasks. I introduce the module builder tool for creating custom AI capabilities without technical skills. Additionally, I discuss the AI module library, which provides pre-made modules by domain experts for easy integration into projects.",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/n4p32i803Gg?cc_load_policy=1",
              title: "What is the AI module library",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="Case study finance"
            paragraphs={[
              "In this video, I demonstrate how an M&A boutique utilizes a platform for AI market analysis during due diligence. The process involves creating a project, aggregating internal and external data, using custom AI modules for analysis like SWOT analysis and competitive benchmarking, and integrating outcomes into templates efficiently. Notably, the use of basic modules significantly reduced time spent, improved content coverage, and identified analysis gaps. Viewers are encouraged to explore the platform, test modules, and reach out to begin their personalized AI library.",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/PPSmHra_QUE?cc_load_policy=1",
              title:
                "How an M&A boutique used MosaiQ for an AI market analysis in the context of Due Diligence",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="Case study consulting"
            paragraphs={[
              "In this video, I showcase how a consulting company utilizes a specific analysis framework for AI implementation. We create a project, aggregate external and internal data, and access custom AI modules for tasks like competitive analysis and industry benchmarking. The demonstration includes running a comparative analysis on selected documents to derive insights. Note that the actual framework used is proprietary to the consultancy.",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/4KklcXstKqU?cc_load_policy=1",
              title:
                "How a consultancy company used the platform to support an AI implementation strategy for clients",
              height: "240px",
              width: "426px",
            }}
          />
          <SecondaryTitleVideoAndTextsSection
            title="Case study PR & Comms"
            paragraphs={[
              "In this video, I demonstrate how a PR company utilizes the platform to create press releases and offer advice within the context of a data bridge at Microsoft. We start by creating a project, aggregating internal and external data, and utilizing AI modules for stakeholder recommendations and actions. The video showcases the process of using the Press Release Module to draft press releases efficiently. Viewers are encouraged to explore the platform, access the module library, and personalize their own modules for effective PR strategies.",
            ]}
            videoParams={{
              src: "https://www.youtube.com/embed/jCGo5Yg14ws?cc_load_policy=1",
              title: "PR company response preparation to a data breach",
              height: "240px",
              width: "426px",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
