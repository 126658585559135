import React from "react";
import { Icon } from "@chakra-ui/react";

const ExpandIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path
          d="M0 12V7H1V10.3L10.3 1H7V0H12V5H11V1.7L1.7 11H5V12H0Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default ExpandIcon;





