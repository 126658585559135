import { borders } from './borders'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { radii } from './radius.js'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { spacing } from './spacing'
import { transition } from './transition'
import { typography } from './typography'
import { zIndices } from './z-index'
import { layerStyles } from './layerStyles'

export const foundations = {
  breakpoints,
  zIndices,
  radii,
  colors,
  layerStyles,
  ...typography,
  sizes,
  shadows,
  space: spacing,
  borders,
  transition,
}
