import { WorkspacePlanIds } from "./workspaceTypes";

export const COL_PROJECTS = "projects";
export const COL_ARTICLES = "articles";
export const COL_SNIPPETS = "snippets";
export const COL_CONVERSATIONS = "conversations";
export const COL_TEMPLATES = "templates";
export const COL_PROJECT_PERMISSIONS = "projectPermissions";
export const COL_RETIRED_PROJECT_PERMISSIONS = "retiredProjectPermissions";
export const COL_WORKSPACES = "workspaces";
export const COL_WORKSPACE_PERMISSIONS = "workspacePermissions";
export const COL_RETIRED_WORKSPACE_PERMISSIONS = "retiredWorkspacePermissions";
export const COL_AI_MODULES = "aiModules";
export const COL_AI_MODULE_PERMISSIONS = "aiModulePermissions";
export const COL_RETIRED_AI_MODULE_PERMISSIONS = "retiredAiModulePermissions";
export const COL_USERS = "users";

export const MONTHLY_AI_CREDITS_PER_PLAN: Record<WorkspacePlanIds, number> = {
  free: 0,
  starter: 1000,
};

/**
 * Number of tokens in a page of text. Used to calculate the number of pages in an article.
 */
export const TOKENS_PER_PAGE = 650;

export const NEW_WORKSPACE_AI_CREDITS_EXTRA = 100;
/**
 * Total number of tokens for a new workspace (free plan).
 */
export const NEW_WORKSPACE_AI_TOKEN_COUNT_TOTAL = 15_000 * TOKENS_PER_PAGE;
