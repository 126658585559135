import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { updateSnippet } from "../../db/snippet";
import { useParams } from "react-router-dom";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import HighlightIconComponent from "../Icons/HighlightIconComponent";
import PermissionWrapper from "../HigherOrderComponents/PermissionWrapper";
import { PERMISSION_ERROR_MSG } from "src/data/PermissionData";
// import { BiHighlight } from "react-icons/bi";

const ExtractedText = ({ text, curated, uid }) => {
  const { projectId } = useParams();
  return (
    <Flex
      rounded="md"
      gap="2"
      my="1"
      w="100%"
      bg="#282c3e"
      color="white"
      p="1rem 0.5rem"
    >
      <Icon
        as={HighlightIconComponent}
        mt={1}
        mx={3}
        color="gray"
        boxSize={4}
      />
      <Text w="95%" fontSize="12px" mt="1">
        {text}
      </Text>
      <PermissionWrapper>
        {(hasEditPermission) =>
          curated ? (
            <Icon
              onClick={() => {
                if (!hasEditPermission) return;
                updateSnippet({ projectId, snippetId: uid, curated: false });
              }}
              cursor="pointer"
              as={BsBookmarkFill}
              mt={1}
              mx={3}
              boxSize={4}
              title={!hasEditPermission ? PERMISSION_ERROR_MSG : ""}
            />
          ) : (
            <Icon
              onClick={() => {
                if (!hasEditPermission) return;
                updateSnippet({ projectId, snippetId: uid, curated: true });
              }}
              cursor="pointer"
              as={BsBookmark}
              mt={1}
              mx={3}
              boxSize={4}
              title={!hasEditPermission ? PERMISSION_ERROR_MSG : ""}
            />
          )
        }
      </PermissionWrapper>
    </Flex>
  );
};

const ExtractTextModal = ({ manualHighlights, onClose, isOpen, title }) => {
  const { projectId } = useParams();

  const extractAllText = () => {
    manualHighlights.forEach((item) => {
      if (!item.curated) {
        updateSnippet({ projectId, snippetId: item.uid, curated: true });
      }
    });
  };

  const [allHighlightsAdded, setAllHighlightsAdded] = useState(false);

  useEffect(() => {
    if (manualHighlights?.length > 0) {
      setAllHighlightsAdded(manualHighlights.every((item) => item.curated));
    } else {
      setAllHighlightsAdded(false);
    }
  }, [manualHighlights]);

  return (
    <Modal
      key={allHighlightsAdded}
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg="primary" py={4}>
        <ModalHeader w="95%" alignItems="center" display="flex">
          <Flex flexDir="column">
            <Heading
              noOfLines={1}
              color="white"
              fontWeight="bold"
              fontSize="16px"
            >
              Manual highlights from &ldquo;{title}&rdquo;
            </Heading>
            <Text mt="2" color="grey" fontWeight="500" fontSize="12px">
              Save selected or all highlights to saved insights
            </Text>
          </Flex>

          <ModalCloseButton bgColor="#282C3E" color="white" mt={4} />
        </ModalHeader>
        <ModalBody>
          <Flex
            key={allHighlightsAdded}
            h="60vh"
            overflowY="scroll"
            flexDir="column"
          >
            {manualHighlights?.map((item) => {
              return (
                <ExtractedText
                  text={item.text}
                  curated={item.curated}
                  uid={item.uid}
                  key={item.uid}
                />
              );
            })}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex>
            {
              <PermissionWrapper>
                {(hasEditPermission) => (
                  <Button
                    leftIcon={
                      <Icon
                        as={allHighlightsAdded ? BsBookmarkFill : BsBookmark}
                      />
                    }
                    bg="#282c3e"
                    rounded="full"
                    onClick={() => {
                      if (!allHighlightsAdded) {
                        extractAllText();
                      }
                    }}
                    fontSize="12px"
                    isDisabled={!hasEditPermission}
                    title={!hasEditPermission ? PERMISSION_ERROR_MSG : ""}
                  >
                    {allHighlightsAdded
                      ? "All highlights saved"
                      : "Save all highlights"}
                  </Button>
                )}
              </PermissionWrapper>
            }
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExtractTextModal;
