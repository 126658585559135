import { Icon } from "@chakra-ui/react";
import React from "react";

const BinIcon = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="black" {...props}>
      <path d="M9.4135 6.98L8.00016 8.39333L6.58016 6.98L5.64016 7.92L7.06016 9.33333L5.64683 10.7467L6.58683 11.6867L8.00016 10.2733L9.4135 11.6867L10.3535 10.7467L8.94016 9.33333L10.3535 7.92L9.4135 6.98ZM10.3335 2.66667L9.66683 2H6.3335L5.66683 2.66667H3.3335V4H12.6668V2.66667H10.3335ZM4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V4.66667H4.00016V12.6667ZM5.3335 6H10.6668V12.6667H5.3335V6Z" />
    </Icon>
  );
};

export default BinIcon;
