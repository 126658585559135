import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import { isOnViewerOrInsightPage } from "../Utils/assistant";

const MENU_OPTIONS = {
  ALL_CONTENT: "Project content",
};

const MenuItem = ({ text, canSelect, showMenuOption }) => {
  const { useOnlyChat } = useAssistantContext();
  const isAllContent = text === MENU_OPTIONS.ALL_CONTENT;
  const toolTipLabel = useOnlyChat
    ? ""
    : "Tip: If you select individual documents among project content, it will limit assistant responses to these documents";
  const defaultTooltip = useOnlyChat ? "" : text;
  return (
    <Flex
      color="#5e616e"
      fontWeight="medium"
      align="center"
      gap={2}
      pl={"1"}
      maxW="160px"
      userSelect="none"
    >
      <Flex gap={2} w="100%">
        {canSelect && <HiOutlineDocumentText fontSize="15px" />}
        <Flex justify="space-between" w="160px" overflow="hidden">
          <Tooltip
            label={
              showMenuOption
                ? null
                : isAllContent
                ? toolTipLabel
                : defaultTooltip
            }
            placement="top"
          >
            <Text
              fontSize="10px"
              w={canSelect ? "80%" : "100%"}
              isTruncated
              noOfLines={1}
            >
              {text}
            </Text>
          </Tooltip>

          {canSelect &&
            (showMenuOption ? (
              <BiChevronUp color="black" fontSize="15px" />
            ) : (
              <BiChevronDown color="black" fontSize="15px" />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const generateMenuItemText = (selectedArticlesData) => {
  if (selectedArticlesData.length == 0) {
    return MENU_OPTIONS.ALL_CONTENT;
  }

  const articlesCount = selectedArticlesData.length;

  if (articlesCount === 1) {
    return selectedArticlesData[0].title || "";
  }

  return `${articlesCount} files`;
};

/**
 * Renders a component that allows the user to select an article to chat with.
 * @param {Object} props - The component props.
 * @param {string} props.display - The display property for the component.
 * @param {Function} props.updateSelectedArticle - A function to update the selected article.
 * @param {Array} props.selectedArticles - An array of selected articles.
 * @returns {JSX.Element} - The JSX element for the component.
 */

function SelectorMenu({
  showMenuOption,
  clearSelectedArticles,
  selectArticle,
  activeArticle,
  setShowMenuOption,
  selectedArticlesData,
  useOnlyChat,
}) {
  let onClick;
  let menuItemText;
  if (selectedArticlesData.length > 0) {
    onClick = () => {
      clearSelectedArticles();
      setShowMenuOption(false);
    };
    menuItemText = MENU_OPTIONS.ALL_CONTENT;
  } else {
    onClick = () => {
      selectArticle(activeArticle.uid);
      setShowMenuOption(false);
    };
    menuItemText = activeArticle?.title;
  }

  return (
    <Flex
      bg="white"
      py="2"
      cursor="pointer"
      position="absolute"
      top="-100%"
      left="0"
      pr="2"
      display={showMenuOption ? "flex" : "none"}
      onClick={onClick}
    >
      <MenuItem
        text={menuItemText}
        canSelect={false}
        showMenuOption={showMenuOption}
        useOnlyChat={useOnlyChat}
      />
    </Flex>
  );
}

function SelectorIndicator({
  showMenuOption,
  setShowMenuOption,
  useOnlyChat,
  selectedArticlesData,
}) {
  const handleClick = () => {
    if (useOnlyChat) return;
    if (selectedArticlesData.length > 0) {
      setShowMenuOption(!showMenuOption);
    }
  };

  return (
    <Flex
      bg="white"
      py="2"
      pr="2"
      cursor={useOnlyChat ? "not-allowed" : "cursor"}
      onClick={handleClick}
    >
      <MenuItem
        text={generateMenuItemText(selectedArticlesData)}
        canSelect={selectedArticlesData.length > 0 && isOnViewerOrInsightPage}
        showMenuOption={showMenuOption}
      />
    </Flex>
  );
}

const Wrapper = ({ children, useOnlyChat }) => {
  const wrpperStyle = {
    bg: "#FBFBFB",
    px: "4",
    align: "center",
    gap: 2,
    borderRadius: "md",
    display: "flex",
    w: "fit-content",
    py: "6px",
    opacity: useOnlyChat ? 0.5 : 1,
    // pointerEvents: useOnlyChat ? "none" : "auto",
    cursor: useOnlyChat ? "not-allowed" : "pointer",
  };
  return (
    <Flex>
      {useOnlyChat ? (
        <Tooltip
          label="Information used is currently restricted to the current chat answers. Click on the toggle button to enrich conversation with select documents."
          placement="top"
        >
          <Flex {...wrpperStyle}>{children}</Flex>
        </Tooltip>
      ) : (
        <Flex {...wrpperStyle}>{children}</Flex>
      )}
    </Flex>
  );
};

export const ChatToArticleOnAssistantMenu = () => {
  const [showMenuOption, setShowMenuOption] = useState(false);
  const {
    clearSelectedArticles,
    selectedArticlesData,
    activeArticle,
    selectArticle,
  } = useArticle();
  const { useOnlyChat } = useAssistantContext();
  return (
    <Wrapper useOnlyChat={useOnlyChat}>
      <Text fontSize="10px" color="primary" fontWeight="medium">
        Chat to
      </Text>
      <Flex position="relative">
        <SelectorIndicator
          useOnlyChat={useOnlyChat}
          showMenuOption={showMenuOption}
          setShowMenuOption={setShowMenuOption}
          selectedArticlesData={selectedArticlesData}
        />
        <SelectorMenu
          useOnlyChat={useOnlyChat}
          showMenuOption={showMenuOption}
          setShowMenuOption={setShowMenuOption}
          clearSelectedArticles={clearSelectedArticles}
          activeArticle={activeArticle}
          selectArticle={selectArticle}
          selectedArticlesData={selectedArticlesData}
        />
      </Flex>
    </Wrapper>
  );
};
