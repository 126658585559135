import React from "react";
import { Icon } from "@chakra-ui/react";

const NewProjectIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path
          d="M8 7.5C8 7.63261 7.94732 7.75979 7.85355 7.85355C7.75979 7.94732 7.63261 8 7.5 8H6.5V9C6.5 9.13261 6.44732 9.25979 6.35355 9.35355C6.25979 9.44732 6.13261 9.5 6 9.5C5.86739 9.5 5.74021 9.44732 5.64645 9.35355C5.55268 9.25979 5.5 9.13261 5.5 9V8H4.5C4.36739 8 4.24021 7.94732 4.14645 7.85355C4.05268 7.75979 4 7.63261 4 7.5C4 7.36739 4.05268 7.24021 4.14645 7.14645C4.24021 7.05268 4.36739 7 4.5 7H5.5V6C5.5 5.86739 5.55268 5.74021 5.64645 5.64645C5.74021 5.55268 5.86739 5.5 6 5.5C6.13261 5.5 6.25979 5.55268 6.35355 5.64645C6.44732 5.74021 6.5 5.86739 6.5 6V7H7.5C7.63261 7 7.75979 7.05268 7.85355 7.14645C7.94732 7.24021 8 7.36739 8 7.5ZM12 4V9C11.9992 9.6628 11.7356 10.2982 11.2669 10.7669C10.7982 11.2356 10.1628 11.4992 9.5 11.5H2.5C1.8372 11.4992 1.20178 11.2356 0.73311 10.7669C0.264441 10.2982 0.000793929 9.6628 0 9L0 3C0.000793929 2.3372 0.264441 1.70178 0.73311 1.23311C1.20178 0.764441 1.8372 0.500794 2.5 0.5H3.764C3.9967 0.500193 4.2262 0.554273 4.4345 0.658L6.0125 1.45C6.08222 1.48346 6.15867 1.50056 6.236 1.5H9.5C10.1628 1.50079 10.7982 1.76444 11.2669 2.23311C11.7356 2.70178 11.9992 3.3372 12 4ZM1 3V3.5H10.908C10.805 3.20855 10.6144 2.95605 10.3623 2.77704C10.1103 2.59804 9.80913 2.50128 9.5 2.5H6.236C6.0033 2.49981 5.7738 2.44573 5.5655 2.342L3.9875 1.5525C3.91798 1.51817 3.84153 1.50022 3.764 1.5H2.5C2.10218 1.5 1.72064 1.65804 1.43934 1.93934C1.15804 2.22064 1 2.60218 1 3ZM11 9V4.5H1V9C1 9.39782 1.15804 9.77936 1.43934 10.0607C1.72064 10.342 2.10218 10.5 2.5 10.5H9.5C9.89782 10.5 10.2794 10.342 10.5607 10.0607C10.842 9.77936 11 9.39782 11 9Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default NewProjectIconComponent;
