import { Box, Flex, chakra } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import { useOrganizerContext } from "../../Context/OrganizerContext";
import { useTemplateContext } from "../../Context/TemplateContext";
import { TEMPLATE_MODE, ViewMode } from "../../data/AssistantData";
import { arrayUnion, db, doc, setDoc } from "../../firebase";
import { generateTemplateContentText } from "../Utils/curatedContent";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantChatInput from "./AssistantChatInput";
import AssistantTemplateList from "./AssistantTemplateList";
import { ChatToArticleOnAssistantMenu } from "./ChatToArticleOnAssistantMenu";
import AssistantSmartTemplate from "./SmartTemplate/AssistantSmartTemplate";

const customization = getCustomizationData();

function SingleTemplateView({ selectedArticlesData, initiateTemplateChat }) {
  return (
    <Box w="full" position="relative" h="full">
      <Flex direction="column" h="full" pb={12}>
        <Flex
          direction={"column"}
          flex="1"
          rounded="md"
          overflowY="auto"
          pt={2}
          pb={5}
          pl={2}
          pr={2}
          bg="#FAFAFA"
          className="webkit-scrollbar-display-none"
        >
          {/* {customization.template.smartTemplates ? ( */}
          <AssistantSmartTemplate />
          {/* ) : ( */}
          {/* <AssistantTemplateDetail /> */}
          {/* )} */}
        </Flex>

        <Flex h="12px" />
        <Flex direction="column">
          {selectedArticlesData.length > 0 ? (
            <Flex pb="12px">
              <ChatToArticleOnAssistantMenu />
            </Flex>
          ) : null}
          <AssistantChatInput
            assistantDisabled={false}
            handleSendClick={initiateTemplateChat}
            inputFieldPlaceholderText={`What would you like ${customization.assistant.nameCasual} to do with this information?`}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

const AssistantTemplate = () => {
  const { currentUser } = useAuth();
  const { templateState, currentTemplate } = useTemplateContext();
  const { sections } = useOrganizerContext();
  const { projectId } = useParams();
  const {
    setViewMode,
    setSubscribeToConversationDoc,
    setConversationId,
    inputValue,
    setInputValue,
  } = useAssistantContext();

  const { selectedArticlesData } = useArticle();

  const createTemplateGenerationRequest = async (conversationId, payload) => {
    const chatGptDocRef = doc(
      db,
      `templateGenerationRequests/${conversationId}`
    );
    await setDoc(chatGptDocRef, payload).catch((e) => {
      console.error(e);
    });
  };

  /**
   * Initiate a chat with the assistant using the selected template.
   *
   * - Get current template data
   * - Construct the "sections" map
   * - Create a request doc with the template data
   * - Open the new conversation to subscribe to the doc which
   * will be the output of the backend
   */
  const initiateTemplateChat = async () => {
    // `sections` is map we need to store in the request doc

    const formattedOrganizerTexts = generateTemplateContentText(sections);
    const prompt = `${formattedOrganizerTexts}\n\n${inputValue}`;
    const conversationId = uuidv4();

    await setDoc(
      doc(db, "projects", projectId, "conversations", conversationId),
      {
        messages: arrayUnion({
          content: prompt,
          role: "user",
          createdAt: Date.now(),
          type: "template",
          title: currentTemplate.name,
          userMessage: inputValue,
          inputValue: inputValue,
        }),
        userId: currentUser.uid,
        type: "template",
        continue: true,
      },
      { merge: true }
    );

    const payload = {
      prompt,
      userId: currentUser.uid,
      projectId: projectId,
      userRequest: inputValue,
      conversationId: conversationId,
      sections,
    };

    await createTemplateGenerationRequest(conversationId, payload);

    setConversationId(conversationId);
    setSubscribeToConversationDoc(true);
    setViewMode(ViewMode.CHAT);
    setInputValue("");
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     event.preventDefault();
  //     initiateTemplateChat();
  //   }
  // };

  return (
    <chakra.div flex={1} w="full" h="full" position="relative">
      {templateState == TEMPLATE_MODE.LIST ? (
        <AssistantTemplateList />
      ) : (
        <SingleTemplateView
          selectedArticlesData={selectedArticlesData}
          initiateTemplateChat={initiateTemplateChat}
        />
      )}
    </chakra.div>
  );
};

export default AssistantTemplate;
