import { Link, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { ARTICLE_TYPE_DATA, TITLE_OPENS } from "../../../data/articleTypeData";
/**
 * Renders a title component with optional link and tooltip.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.notInteractive - Flag indicating if the component should be interactive or not.
 * @param {string} props.title - The title of the article.
 * @param {string} props.uid - The unique identifier of the article.
 * @returns {JSX.Element} The rendered title component.
 */
const TitleComponent = ({ notInteractive, articleData }) => {
  return (
    <Tooltip label={articleData.title} openDelay={300} placement="top">
      <Text
        _hover={{ textDecoration: notInteractive ? "none" : "underline" }}
        key={articleData.uid}
        fontSize="12px"
      >
        {articleData.title}
      </Text>
    </Tooltip>
  );
};

/**
 * Renders a title component with or without a link based on the provided type.
 *
 * @param {string} type - The type of the article.
 * @param {string} projectId - The ID of the project.
 * @param {string} uid - The UID of the article.
 * @param {Object} articleData - The data of the article.
 * @param {string} url - The URL of the article.
 * @returns {JSX.Element} The rendered title component.
 */
export function TitleWithOrWithoutLink({
  projectId,
  uid,
  type,
  url,
  articleData,
}) {
  if (ARTICLE_TYPE_DATA[type].titleOpens === TITLE_OPENS.url) {
    return (
      <Link href={url} isExternal>
        <TitleComponent articleData={articleData} />
      </Link>
    );
  }
  return (
    <RouterLink
      to={{
        pathname: `/${projectId}/${uid}/articledetail/`,
      }}
    >
      <TitleComponent articleData={articleData} />
    </RouterLink>
  );
}
