import { Icon } from "@chakra-ui/react";
import React from "react";

const DocxIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="black" {...props}>
      <path d="M3.5 8.5H7V7.5H3.5V8.5ZM3.5 6.5H8.5V5.5H3.5V6.5ZM3.5 4.5H8.5V3.5H3.5V4.5ZM2.5 10.5C2.225 10.5 1.98958 10.4021 1.79375 10.2063C1.59792 10.0104 1.5 9.775 1.5 9.5V2.5C1.5 2.225 1.59792 1.98958 1.79375 1.79375C1.98958 1.59792 2.225 1.5 2.5 1.5H9.5C9.775 1.5 10.0104 1.59792 10.2063 1.79375C10.4021 1.98958 10.5 2.225 10.5 2.5V9.5C10.5 9.775 10.4021 10.0104 10.2063 10.2063C10.0104 10.4021 9.775 10.5 9.5 10.5H2.5ZM2.5 9.5H9.5V2.5H2.5V9.5Z" />
    </Icon>
  );
};

export default DocxIcon;
