import { getCustomizationData } from "../Components/Utils/customizationUtils";

export const PDF_AI_INSIGHTS_PAGE_LIMIT = 2_500;

export const MONTHLY_AI_CREDITS = 555;

export const MONTHLY_PDF_PAGES_LIMIT = 50_000;

// in bytes
export const MAX_FILE_SIZE = 100_000_000;

export const ARTICLE_TYPE_NAMES = {
  pdf: "PDF",
  html: "Web article",
  supportingLink: "Link",
};

const customization = getCustomizationData();

export const FAQ = [
  {
    question: `What happens with my data in ${customization.name}?`,
    answer: `Your data security is our priority.

AI services are provided through the Microsoft Azure OpenAI services.  Please note that your prompts, and AI responses & insights:
- are NOT available to other customers
- are NOT available to OpenAI
- are NOT used to improve OpenAI models.
- are NOT used to improve any Microsoft or 3rd party products or services
- are NOT used for automatically improving Azure OpenAI models for your use in your resource

The Azure OpenAI Service is fully controlled by Microsoft; Microsoft hosts the OpenAI models in Microsoft’s Azure environment and the Service does NOT interact with any services operated by OpenAI (e.g. ChatGPT, or the OpenAI API).

Our Infrastructure and storage is operated on Google Cloud Technologies. Our storage is set up on the cloud, is located in Europe, and all data is encrypted.`,
  },
  {
    question: `Can I connect my existing drives to ${customization.name}?`,
    answer: `We understand the importance of seamlessly integrating your existing knowledge. You will be able to connect both Google Drive and Microsoft OneDrive to ${customization.name} in the future.`,
  },
  {
    question: `Does ${customization.name} have an enterprise offering?`,
    answer: `Yes, we do offer an enterprise solution tailored to larger organizations and their specific needs. 
      
Not only can you benefit from the enhanced features of our platform, but we can also assist your team in navigating and leveraging AI effectively using our suite of tools.

For more details or to get started with our enterprise solution, please reach out to us directly via the Enterprise or Pricing pages on our website.`,
  },
];

export const PDF_PAGE_LIMIT_MSG = {
  premium: {
    title: `You’ve reached the limit of ${MONTHLY_PDF_PAGES_LIMIT} PDF pages AI processed`,
    body: `Next month you will get ${MONTHLY_PDF_PAGES_LIMIT} PDF pages more. However, if you want to analyze more pages now, please drop us a message at support@mosaiqlabs.com`,
  },
  free: {
    title: "You’ve reached the limit of 2,500 PDF pages AI processed",
    body: "AI is used prepare documents for AI insights analysis. Upgrade to Premium to get 100,000 PDF pages/month & additional AI credits",
  },
};
