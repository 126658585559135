export const Avatar = {
  sizes: {
    '2xs': {
      container: {
        boxSize: '1rem',
      },
      label: {
        fontSize: '0.5625rem',
      },
    },
  },
}
