import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ViewMode } from "../../data/AssistantData";
import BoxMenuIcon from "../Icons/BoxMenuIcon";

export const ShowHideCustomPromptsButton = ({ setShowCustomPrompts }) => {
  const { userDoc } = useAuth();
  const { viewMode } = useAssistantContext();

  if (viewMode === ViewMode.TEMPLATE) return null;

  const showCustomPromptsButton =
    (userDoc.hasCustomPrompts ||
      process.env.REACT_APP_CUSTOM_PROMPTS_FLAG === "enable") &&
    process.env.REACT_APP_NEW_CUSTOM_PROMPTS_FLOW !== "enable";

  if (!showCustomPromptsButton) return null;

  return (
    <Flex align="center" justify="center" pt="0">
      <Tooltip
        hasArrow
        bg="primary"
        p="2"
        px="4"
        borderRadius="8px"
        label="AI Modules"
        placement="top"
      >
        <span>
          <Icon
            pt={2}
            pl={2}
            mt={0}
            as={BoxMenuIcon}
            cursor="pointer"
            color="white"
            boxSize={10}
            onClick={() => {
              setShowCustomPrompts((prev) => !prev);
            }}
          />
        </span>
      </Tooltip>
    </Flex>
  );
};
