import { useState } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { useCustomAiModules } from "../Context/CustomAiModuleContext";
import { addDoc, collection, db } from "../firebase";

const usePrivateCustomPrompts = () => {
  const { userCustomAiModules, loadingUserCustomAiModules } =
    useCustomAiModules();
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  const createPromptRequest = async ({
    conversationId,
    promptId,
    projectId,
  }) => {
    // console.log("promptId :>> ", promptId);
    try {
      // Find the prompt by id
      const prompt = userCustomAiModules.find((p) => p.uid === promptId);
      if (!prompt) {
        throw new Error("Prompt not found");
      }
      // console.log("prompt :>> ", prompt);
      // Add a new document in 'customPromptRequests' collection with the prompt data
      await addDoc(collection(db, "customPromptChatRequest"), {
        promptId: prompt.uid,
        title: prompt.title,
        description: prompt.description,
        timestamp: Date.now() / 1000,
        userId: currentUser.uid,
        conversationId,
        projectId,
      });
    } catch (err) {
      console.error("err :>> ", err);
      setError(err);
    }
  };

  return {
    customPrompts: userCustomAiModules,
    createPromptRequest,
    loading: loadingUserCustomAiModules,
    error,
  };
};

export default usePrivateCustomPrompts;
