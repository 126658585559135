export function removeUndefinedNull(map) {
  Object.keys(map).forEach(
    (key) => (map[key] === undefined || map[key] === null) && delete map[key]
  );
}

export function removeFields(map, fieldsToRemove) {
  Object.keys(map).forEach(
    (key) => fieldsToRemove.includes(key) && delete map[key]
  );
}

export function removeUndefinedNullRecursive(map) {
  Object.keys(map).forEach((key) => {
    if (map[key] === undefined || map[key] === null) {
      delete map[key];
    } else if (typeof map[key] === "object") {
      removeUndefinedNullRecursive(map[key]);
    }
  });
}
