import { Icon } from "@chakra-ui/react";
import React from "react";

const PinIcon = (props) => {
  return (
    <Icon viewBox="0 0 18 18" {...props}>
      <path
        d="M6.3335 1.66683V5.00016C6.3335 5.74683 6.58016 6.44016 7.00016 7.00016H3.00016C3.4335 6.42683 3.66683 5.7335 3.66683 5.00016V1.66683H6.3335ZM8.3335 0.333496H1.66683C1.30016 0.333496 1.00016 0.633496 1.00016 1.00016C1.00016 1.36683 1.30016 1.66683 1.66683 1.66683H2.3335V5.00016C2.3335 6.10683 1.44016 7.00016 0.333496 7.00016V8.3335H4.3135V13.0002L4.98016 13.6668L5.64683 13.0002V8.3335H9.66683V7.00016C8.56016 7.00016 7.66683 6.10683 7.66683 5.00016V1.66683H8.3335C8.70016 1.66683 9.00016 1.36683 9.00016 1.00016C9.00016 0.633496 8.70016 0.333496 8.3335 0.333496Z"
        fill={props.color}
      />
    </Icon>
  );
};

export default PinIcon;
