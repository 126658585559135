import { Flex } from "@chakra-ui/react";
import React from "react";
import { v4 } from "uuid";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { BoxLoading } from "../../Utils/Loading";
import { AddSectionBttn } from "../AddSectionBttn";
import ControlCenter from "./ControlCenter";
import SentimentCenter from "./SentimentCenter";
import SmartTemplateDetail from "./SmartTemplateDetails";
import TemplateInsights from "./TemplateInsights";
import { WelcomeToTemplatesSection } from "./WelcomeToTemplatesSection";

const customization = getCustomizationData();

export const FILL_TEMPLATE_STATUS = {
  DONE: "done",
  LOADING: "loading",
};

const AssistantSmartTemplate = () => {
  const { currentTemplate } = useTemplateContext();

  const fillTemplateStatus = currentTemplate?.fillTemplateStatus;

  if (fillTemplateStatus?.status === FILL_TEMPLATE_STATUS.LOADING) {
    return (
      <BoxLoading
        loadingText={fillTemplateStatus.message}
        progress={fillTemplateStatus.progress || null}
      />
    );
  }

  return (
    <Flex backgroundColor="#FBFBFB" direction={"column"} gap="8px">
      <WelcomeToTemplatesSection />
      <ControlCenter />
      <SentimentCenter />
      {customization.template.showTemplateInsights && <TemplateInsights />}
      <SmartTemplateDetail />
      <AddSectionBttn
        newSectionName="Click here to change “Title”"
        projectId={v4()}
      />
    </Flex>
  );
};

export default AssistantSmartTemplate;
