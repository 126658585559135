// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import { useTemplateContext } from "../../../Context/TemplateContext";
import {
  SMART_CARD_TYPE,
  SMART_TABLE_COLUMNS,
} from "../../../data/SmartTemplateData";
import { useFragmentGroups } from "../../../hooks/useFragmentGroups";
import ControlCenterIcon from "../../Icons/ControlCenterIcon";
import { InfoTooltip } from "../../InfoTooltip";
import { AccordionTitle } from "./AccordionTitle";

function HeaderRow() {
  return (
    <Thead>
      <Tr>
        {SMART_TABLE_COLUMNS.map((column, index) => (
          <Th
            key={index}
            textTransform="none"
            pb="8px"
            textAlign={column.textAlign ?? "left"}
          >
            <Text fontSize="8px" fontWeight="bold" letterSpacing="0">
              {column.name}
            </Text>
          </Th>
        ))}
      </Tr>
    </Thead>
  );
}

function TableRow({
  row,
  filterDataByDataValue,
  returnActiveFilterProperties,
}) {
  return (
    <Tr height="20px">
      <Source />
      <MNumber />
      <MRating />
      <Date />
    </Tr>
  );

  function Date() {
    return (
      <Td
        onClick={() => filterDataByDataValue(row.date, SMART_CARD_TYPE.date)}
        cursor="pointer"
        {...returnActiveFilterProperties(row.date, SMART_CARD_TYPE.date)}
        py="0"
        textAlign="right"
      >
        <Text fontSize="10px">{row.date}</Text>
      </Td>
    );
  }

  function MRating() {
    return (
      <Td
        onClick={() =>
          filterDataByDataValue(row.m_rating, SMART_CARD_TYPE.m_rating)
        }
        cursor="pointer"
        py="0"
        textAlign="right"
      >
        <Text
          {...returnActiveFilterProperties(
            row.m_rating,
            SMART_CARD_TYPE.m_rating
          )}
          fontSize="10px"
        >
          {row.m_rating}
        </Text>
      </Td>
    );
  }

  function MNumber() {
    return (
      <Td
        onClick={() =>
          filterDataByDataValue(row.m_number, SMART_CARD_TYPE.m_number)
        }
        cursor="pointer"
        py="0"
        textAlign="right"
      >
        <Text
          {...returnActiveFilterProperties(
            row.m_number,
            SMART_CARD_TYPE.m_number
          )}
          fontSize="10px"
        >
          {row.m_number}
        </Text>
      </Td>
    );
  }

  function Source() {
    return (
      <Td
        onClick={() =>
          filterDataByDataValue(row.source, SMART_CARD_TYPE.source)
        }
        cursor="pointer"
        py="0"
      >
        <Text
          {...returnActiveFilterProperties(row.source, SMART_CARD_TYPE.source)}
          fontSize="10px"
          textTransform="capitalize"
        >
          {row.source}
        </Text>
      </Td>
    );
  }
}

function ControlCenterTable({
  filterDataByDataValue,
  returnActiveFilterProperties,
  fragmentGroups,
}) {
  return (
    <>
      <TableContainer className="webkit-scrollbar-display-none">
        <Table variant="unstyled" size="sm">
          <HeaderRow />
          <Tbody p={0}>
            {Object.entries(fragmentGroups).map(([, fragments], groupIndex) => {
              return fragments.map((fragment, index) => {
                return (
                  <>
                    {/* if groupIndex > 0 and index===0, show a horizontal line */}
                    {groupIndex > 0 && index === 0 && (
                      <Tr>
                        <Td colSpan={4} p={0} px="16px">
                          <Box
                            borderBottom="1px solid #000000"
                            opacity="0.1"
                            height="1px"
                          />
                        </Td>
                      </Tr>
                    )}
                    <TableRow
                      key={index}
                      // for all indexes except the first one, send only the date parameter of the metadata
                      row={
                        index === 0
                          ? fragment.metadata
                          : { date: fragment.metadata.date }
                      }
                      filterDataByDataValue={filterDataByDataValue}
                      returnActiveFilterProperties={
                        returnActiveFilterProperties
                      }
                    />
                  </>
                );
              });
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

function VerticalDivider() {
  return (
    <Flex px="8px">
      <Flex width="1px" height="20px" backgroundColor="#000000" opacity="0.5" />
    </Flex>
  );
}

const ArticlesIndicator = () => {
  const { currentTemplate } = useTemplateContext();

  if (!currentTemplate.fillTemplateArticles) return null;

  // list article titles as a numbered list
  const articlesString = currentTemplate.fillTemplateArticles
    .map((article, index) => `${index + 1}. ${article.title}`)
    .join("<br />");

  return (
    <>
      <VerticalDivider />
      <Flex direction="row" align="center" gap="4px">
        <Text
          fontSize="10px"
          color="#000000"
          opacity="0.7"
          fontStyle="italic"
          noOfLines={1}
        >
          <span
            style={{
              fontWeight: 800,
              opacity: "1",
              marginRight: "4px",
            }}
          >
            {currentTemplate.fillTemplateArticles.length}
          </span>
          file(s) analysed &nbsp;
        </Text>
        <InfoTooltip string={articlesString} />
      </Flex>
    </>
  );
};

const ControlCenter = () => {
  const { toggleMetadataFilter, metadataFilters } = useOrganizerContext();

  const { sections } = useOrganizerContext();
  const { projectId } = useParams();
  const fragmentGroups = useFragmentGroups(sections, projectId);

  if (Object.keys(fragmentGroups).length === 0) return null;

  const filterDataByDataValue = (value, type) => {
    toggleMetadataFilter({ value, type });
  };

  const returnActiveFilterProperties = (value, key) => {
    const filter = metadataFilters.find(
      (filter) => filter.type == key && filter.value == value
    );

    return filter
      ? { color: "#59808D", fontWeight: "bolder" }
      : { color: "#000000", opacity: "0.7" };
  };

  return (
    <Box p={0} borderRadius="4px" backgroundColor="#ffffff">
      <Accordion
        position="relative"
        borderColor="white"
        allowToggle
        _before={{
          content: '""',
          display: "block",
          position: "absolute",
          top: "0",
          left: "0",
          width: "4px",
          height: "100%",
          backgroundColor: "#C3F73A",
          borderRadius: "4px 0 0 4px",
        }}
      >
        <AccordionItem border={0}>
          <h2>
            <AccordionButton>
              <Flex flex="1" textAlign="left" align={"center"}>
                <Flex gap="4px" align={"center"}>
                  <AccordionTitle
                    title="Control Center"
                    icon={ControlCenterIcon}
                  />
                  <InfoTooltip type="controlCenter" />
                </Flex>
                <ArticlesIndicator />
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0} pb="16px">
            <ControlCenterTable
              filterDataByDataValue={filterDataByDataValue}
              returnActiveFilterProperties={returnActiveFilterProperties}
              fragmentGroups={fragmentGroups}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ControlCenter;
