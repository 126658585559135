import { Box, HStack, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { LAYOUT_PROPS } from ".";
import { getCustomizationData } from "../Utils/customizationUtils";
import SearchInput from "./SearchInput";

const customization = getCustomizationData();

const Header = ({ OtherComponent }) => {
  // const { assistantIsOpen } = useAssistantContext();

  return (
    <Box
    // maxW={{
    //   base: assistantIsOpen ? "95%" : "96%",
    //   xl: assistantIsOpen ? "100%" : "78%",
    // }}
    >
      <HStack
        gap={4}
        w="full"
        maxW={LAYOUT_PROPS.maxWidth}
        justify="space-between"
      >
        <Link to="/">
          <Image w="100px" minW="72px" src={customization.logo} mt={2} />
        </Link>
        <HStack gap={10} flex={1}>
          <SearchInput />
        </HStack>
        {OtherComponent}
      </HStack>
    </Box>
  );
};

export const HeaderWithoutLinks = () => {
  return (
    <Box>
      <HStack
        gap={4}
        w="full"
        maxW={LAYOUT_PROPS.maxWidth}
        justify="space-between"
      >
        <Link to="/">
          <Image w="100px" minW="72px" src={customization.logo} mt={2} />
        </Link>
      </HStack>
    </Box>
  );
};

export default Header;
