import { Flex, Switch, Text } from "@chakra-ui/react";
import React from "react";

export const MessageIsRestrictedToChatIndicator = () => {
  return (
    <Flex w="100%" align="center" gap="6px" color="#191D30">
      <Switch
        isRequired
        isDisabled
        isChecked
        sx={{
          ".chakra-switch__track": {
            bg: "transparent",
            border: "2px solid #000",
            width: "12px",
            height: "6px",
            padding: "2px",
            paddingRight: "6px",
          },
          ".chakra-switch__thumb": {
            bg: "primary",
            width: "6px",
            height: "6px",
          },
        }}
        size="sm"
        background="transparent"
      />
      <Text fontSize="10px" fontWeight="500" opacity="0.5">
        Information restricted to current chat answers
      </Text>
    </Flex>
  );
};
