import { IconType } from "react-icons";
import { MdEditNote, MdLink, MdOutlineImage } from "react-icons/md";
import { SnippetType } from "shared/snippetTypes";
import AssistantInsightIcon from "../Components/Icons/AssistantInsightIcon";
import HighlightIconComponent from "../Components/Icons/HighlightIconComponent";
import KeypointIconComponent from "../Components/Icons/KeypointIconComponent";
import NumberIconComponent from "../Components/Icons/NumberIconComponent";
import QuoteIconComponent from "../Components/Icons/QuoteIconComponent";
import SummaryIconComponent from "../Components/Icons/SummaryIconComponent";

interface SnippetTypeData {
  name: string;
  defaultTitle?: string;
  title: string;
  type: SnippetType;
  color: string;
  icon: IconType;
  showInCurated: boolean;
  showInInsight?: boolean;
  showInPdfInsight?: boolean;
  toolTipMsg: string;
  insightToolTipMsg?: string;
  order: number;
}

export const SNIPPET_TYPE_DATA: Record<string, SnippetTypeData> = {
  summary: {
    name: "Summary",
    defaultTitle: "Summary",
    title: "Summaries",
    type: "summary",
    color: "#c3f73a",
    icon: SummaryIconComponent,
    showInCurated: true,
    showInInsight: true,
    showInPdfInsight: true,
    toolTipMsg: "Summaries generated by AI",
    insightToolTipMsg: "The summary generated by AI",
    order: 1,
  },
  keyPoint: {
    name: "Key Point",
    title: "Key Points",
    type: "keyPoint",
    color: "#a8dcd9",
    icon: KeypointIconComponent,
    showInCurated: true,
    showInInsight: true,
    toolTipMsg: "Main ideas generated by AI",
    order: 2,
  },
  nextSteps: {
    name: "Next Step",
    title: "Next Steps",
    type: "nextSteps",
    color: "#f9b9f2",
    icon: KeypointIconComponent,
    showInCurated: true,
    showInInsight: true,
    toolTipMsg: "Actions to take generated by AI",
    order: 7,
  },
  quote: {
    name: "Quote",
    title: "Quotes",
    type: "quote",
    color: "#ef8354",
    icon: QuoteIconComponent,
    showInCurated: false,
    showInInsight: true,
    toolTipMsg: "What people said",
    order: 4,
  },
  number: {
    name: "Number",
    title: "Numbers",
    type: "number",
    color: "#f9b9f2",
    icon: NumberIconComponent,
    showInCurated: false,
    showInInsight: true,
    toolTipMsg: "Sentences with numbers",
    order: 5,
  },
  manualHighlight: {
    name: "Highlight",
    title: "Manual Highlights",
    type: "manualHighlight",
    color: "#ffc49b",
    icon: HighlightIconComponent,
    showInCurated: true,
    toolTipMsg: "Highlighted in documents and web pages",
    order: 6,
  },
  manualInsight: {
    name: "Manual Insight",
    title: "Manual Insights",
    type: "manualInsight",
    color: "#ccc9dc",
    icon: MdEditNote,
    showInCurated: true,
    toolTipMsg: "Manually added from extension or Saved Insights",
    order: 0,
  },
  inTextLink: {
    name: "In-text link",
    title: "In-text links",
    type: "inTextLink",
    color: "#F4989C",
    icon: MdLink,
    showInCurated: false,
    showInInsight: true,
    toolTipMsg: "Links to other related articles",
    order: 3,
  },
  articleNote: {
    name: "Article Note",
    title: "Article Notes",
    type: "articleNote",
    color: "#F4989C",
    icon: MdEditNote,
    showInCurated: false,
    toolTipMsg: "",
    order: 8,
  },
  image: {
    name: "Images",
    title: "Images",
    type: "image",
    color: "#9EE8FF",
    icon: MdOutlineImage,
    showInCurated: true,
    showInInsight: false,
    toolTipMsg: "Manually added image from Saved Insights",
    order: 9,
  },
  assistantInsight: {
    name: "Assistant Insight",
    title: "Assistant Insights",
    type: "assistantInsight",
    color: "primary",
    icon: AssistantInsightIcon,
    showInCurated: true,
    toolTipMsg: "Added from Assistant",
    order: 10,
  },
  fillTemplateInsight: {
    name: "Fill Template Insight",
    title: "Fill Template Insights",
    type: "fillTemplateInsight",
    color: "#F4989C",
    icon: MdEditNote,
    showInCurated: false,
    toolTipMsg: "",
    order: 11,
  },
};

export const ACTIVE_SAVED_INSIGHT_BUTTONS: SnippetType[] = [
  "summary",
  "keyPoint",
  "manualHighlight",
  "quote",
  "number",
  "manualInsight",
  "image",
  "assistantInsight",
];

export const ACTIVE_INSIGHT_BUTTONS: SnippetType[] = [
  "summary",
  "keyPoint",
  "inTextLink",
  "quote",
  "number",
];

// which type of insights can be viewed in source
export const VIEW_IN_SOURCE_ENABLED: SnippetType[] = ["quote", "number"];
