import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { updatePassword } from "firebase/auth";
import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { auth } from "../../firebase";
import useNotification from "../../hooks/useNotification";

export const ChangePasswordInput = ({ closeShowEditPassword }) => {
  const [unMaskPassword, setUnMaskPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [newConfrirmPassword, setNewConfirmPassword] = React.useState("");
  const [resetPasswordInit, setResetPasswordInit] = React.useState(false);
  const {notify} = useNotification();

  const updateUserPassword = () => {
    const user = auth.currentUser;
    setResetPasswordInit(true);
    updatePassword(user, newPassword)
      .then(() => {
        notify({
          title: "Password updated.",
          description: "Your password has been updated.",
          status: "success",
        });
        setNewConfirmPassword("");
        setNewPassword("");
        closeShowEditPassword();
      })
      .catch(() => {
        notify({
          title: "Password update failed.",
          description: "Your password could not be updated.",
          status: "error",
        });
      });
  };
  return (
    <Flex gap={2} align="end" w="100%">
      <FormControl h="fit-content">
        <FormLabel my="0.1rem" fontSize="12px" color="#2D3748">
          New password
        </FormLabel>
        <InputGroup display="flex" flexDir="column" align="center">
          <InputRightElement>
            <AiOutlineEyeInvisible
              cursor="pointer"
              style={{ display: unMaskPassword ? "block" : "none" }}
              onClick={() => {
                setUnMaskPassword(!unMaskPassword);
              }}
            />
            <AiOutlineEye
              cursor="pointer"
              style={{ display: unMaskPassword ? "none" : "block" }}
              onClick={() => {
                setUnMaskPassword(!unMaskPassword);
              }}
            />
          </InputRightElement>
          <Input
            fontSize="13px"
            border="1px solid #E6E6E6"
            bg="white"
            type={unMaskPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            w="100%"
          />
          <Text
            textAlign="left"
            display={
              newPassword.length > 0 && newPassword.length < 8
                ? "block"
                : "none"
            }
            color="red"
            fontSize="11px"
          >
            Password must be at least 8 characters long.
          </Text>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel my="0.1rem" fontSize="12px" color="#2D3748">
          Confirm password
        </FormLabel>
        <InputGroup display="flex" flexDir="column">
          <InputRightElement>
            <AiOutlineEyeInvisible
              cursor="pointer"
              style={{ display: unMaskPassword ? "block" : "none" }}
              onClick={() => {
                setUnMaskPassword(!unMaskPassword);
              }}
            />
            <AiOutlineEye
              cursor="pointer"
              style={{ display: unMaskPassword ? "none" : "block" }}
              onClick={() => {
                setUnMaskPassword(!unMaskPassword);
              }}
            />
          </InputRightElement>
          <Input
            fontSize="13px"
            border="1px solid #E6E6E6"
            bg="white"
            type={unMaskPassword ? "text" : "password"}
            value={newConfrirmPassword}
            onChange={(e) => {
              setNewConfirmPassword(e.target.value);
            }}
          />
          <Text
            textAlign="left"
            display={
              newPassword.length > 0 && newPassword !== newConfrirmPassword
                ? "block"
                : "none"
            }
            color="red"
            fontSize="11px"
          >
            Password does not match
          </Text>
        </InputGroup>
      </FormControl>
      <Button
        leftIcon={
          <Spinner
            left="2"
            right="2"
            size="xs"
            color="white"
            display={resetPasswordInit ? "block" : "none"}
          />
        }
        rightIcon={
          <Spinner
            position="absolute"
            left="2"
            right="2"
            size="xs"
            color="white"
            display="none"
          />
        }
        size="md"
        border="1px solid transparent"
        bg="primary"
        isDisabled={
          newPassword.length >= 8 && newPassword === newConfrirmPassword
            ? false
            : true
        }
        onClick={() => {
          updateUserPassword(newPassword);
        }}
      >
        Save
      </Button>
      <Button
        border="1px solid #191D30"
        color="primary"
        size="md"
        bg="transparent"
        onClick={() => {
          closeShowEditPassword();
        }}
      >
        Close
      </Button>
    </Flex>
  );
};
