import { Icon } from "@chakra-ui/react";
import React from "react";

const MultiDocQueryChatIcon = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.03606 6H7.39321V5H5.03606V6ZM5.03606 4.5H9.75035V3.5H5.03606V4.5ZM5.03606 3H9.75035V2H5.03606V3ZM3.85749 8C3.53338 8 3.25593 7.90208 3.02513 7.70625C2.79432 7.51042 2.67892 7.275 2.67892 7V1C2.67892 0.725 2.79432 0.489583 3.02513 0.29375C3.25593 0.0979167 3.53338 0 3.85749 0H10.9289C11.253 0 11.5305 0.0979167 11.7613 0.29375C11.9921 0.489583 12.1075 0.725 12.1075 1V7C12.1075 7.275 11.9921 7.51042 11.7613 7.70625C11.5305 7.90208 11.253 8 10.9289 8H3.85749ZM3.85749 7H10.9289V1H3.85749V7ZM1.50035 10C1.17624 10 0.898786 9.90208 0.667983 9.70625C0.437179 9.51042 0.321777 9.275 0.321777 9V2H1.50035V9H9.75035V10H1.50035Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

export default MultiDocQueryChatIcon;
