import {
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { MdControlPointDuplicate, MdMoreHoriz } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { templateVersionOutdated } from "../../../Context/OrganizerContext";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { deleteTemplate } from "../../../db/organizer";
import { PrimaryDeleteAlert } from "../../Elements/DeleteAlert";
import EditableText from "../../General/EditableText";
import TemplateIconComponent from "../../Icons/TemplateIconComponent";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { formatDate } from "../../Utils/dateutils";

const customization = getCustomizationData();

export function TemplateCard({
  handleTemplateSelection,
  updateTemplateName,
  projectId,
  template,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { copyTemplateWithSections } = useTemplateContext();

  const startDuplicateTemplate = () => {
    console.log(template);
    const newTemplateId = uuidv4();
    copyTemplateWithSections(template.id, newTemplateId, true);
  };
  return (
    <Box pos="relative">
      <Box
        bg="white"
        boxShadow="sm"
        p={4}
        rounded="sm"
        mb={2}
        cursor={
          templateVersionOutdated(template.version) ? "not-allowed" : "pointer"
        }
        onClick={
          templateVersionOutdated(template.version)
            ? () => {}
            : (e) => {
                if (!e.target.classList.value.includes("chakra-editable")) {
                  handleTemplateSelection(template.id);
                }
              }
        }
      >
        <HStack mb="1">
          <Icon as={TemplateIconComponent} color="primary" boxSize={4} />
          <Box>
            <EditableText
              submitHandler={(value) => updateTemplateName(value, template.id)}
              text={template.name}
              fontSize="xs"
              color="primary"
              fontWeight="medium"
              className="editable-text"
            />
          </Box>
        </HStack>
        <Flex>
          <Text
            fontSize="x-small"
            color="#191D30"
            fontStyle="italic"
            opacity="0.5"
          >
            Created {formatDate(template.createdAt)}
          </Text>
          <Spacer />
          {templateVersionOutdated(template.version) && (
            <Text
              fontSize="x-small"
              color="red.500"
              fontStyle="italic"
              opacity="0.5"
            >
              This version of {customization.template.name.toLowerCase()} is no
              longer supported
            </Text>
          )}
        </Flex>
      </Box>
      <Menu>
        <MenuButton pos="absolute" top="5%" right="0" p="2" h="fit-content">
          <MdMoreHoriz color="#757575" />
        </MenuButton>
        <MenuList w="0%" p="0" border="none">
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => onOpen()}
          >
            <RiDeleteBin2Line />
            Delete
          </MenuItem>
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => {
              const newTemplateId = uuidv4();
              copyTemplateWithSections(template.id, newTemplateId, false);
            }}
          >
            <HiOutlineDocumentDuplicate />
            Duplicate
          </MenuItem>
          <MenuItem
            gap={4}
            fontSize="12px"
            bg="primary"
            borderRadius="sm"
            color="white"
            onClick={() => {
              startDuplicateTemplate();
            }}
          >
            <MdControlPointDuplicate />
            Duplicate with content
          </MenuItem>
        </MenuList>
      </Menu>
      <PrimaryDeleteAlert
        isOpen={isOpen}
        onClose={onClose}
        action={() => {
          deleteTemplate(template.id, projectId);
        }}
        body="Are you sure you want to delete this template?"
      />
    </Box>
  );
}
