import React from "react"
import { Flex, Spinner, Text } from "@chakra-ui/react";

export const PDFViewerLoading = () => {
    return (
      <Flex h="70vh" flexDir="column" alignItems="center" justify="center">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="grey"
          color="primary"
          size="xl"
          transform="scale(2.5)"
        />
        <Text pt="10px">Loading...</Text>
      </Flex>
    );
  };
