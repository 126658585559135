import { Box } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ViewMode } from "../../data/AssistantData";
import useAssistantResize from "../../hooks/useAssistantResize";
import AiModulesInHeader from "../AiModules/AiModulesInHeader";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantChatHeader from "./AssistantChatHeader";
import AssistantChatHeaderTemplateProminent from "./AssistantChatHeaderTemplateProminent";
import AssistantChatWindow from "./AssistantChatWindow";
import AssistantHistory from "./AssistantHistory";
import AssistantTemplate from "./AssistantTemplate";
import GeneralTabAssistantHeader from "./GeneralTabAssistantHeader";

const customization = getCustomizationData();

export function AssistantDrawer() {
  const { projectId } = useParams();
  const {
    setConversationId,
    subscribeToConversationDoc,
    viewMode,
    setViewMode,
    setAssistantIsOpen,
    assistantWidth,
    setAssistantWidth,
    assistantIsOpen,
    assistantIsFullScreen,
  } = useAssistantContext();

  const { startResize } = useAssistantResize(setAssistantWidth);

  const { allUnfilteredArticles: articles } = useArticle();

  if (!assistantIsOpen) {
    return null;
  }

  return (
    <Box
      bg="white"
      width={assistantIsFullScreen ? "100%" : `${assistantWidth}px`}
      height="100%"
      p={2}
      position={assistantIsFullScreen ? "static" : "fixed"}
      right={assistantIsFullScreen ? "30%" : "0"}
      roundedLeft="2xl"
    >
      <Box
        h={12}
        w="4px"
        position="absolute"
        top="42%"
        bgColor="gray"
        cursor="ew-resize"
        onMouseDown={startResize}
        rounded="md"
        ml="-1.5"
      />

      {viewMode === ViewMode.CHAT && (
        <>
          {customization.assistant.headerTemplateProminent ? (
            <AssistantChatHeaderTemplateProminent
              setAssistantIsOpen={setAssistantIsOpen}
            />
          ) : (
            <AssistantChatHeader setAssistantIsOpen={setAssistantIsOpen} />
          )}
          <AssistantChatWindow />
        </>
      )}
      {viewMode === ViewMode.HISTORY && (
        <>
          <GeneralTabAssistantHeader title="History" id="history" />
          <AssistantHistory
            setViewMode={setViewMode}
            setConversationId={setConversationId}
            projectId={projectId}
          />
        </>
      )}

      {viewMode === ViewMode.CUSTOMAI && (
        <Box height="100%">
          <GeneralTabAssistantHeader title="Custom AI" id="customAi" />
          <AiModulesInHeader
            articles={articles}
            assistantIsGenerating={subscribeToConversationDoc}
          />
        </Box>
      )}
      {viewMode === ViewMode.TEMPLATE && (
        <Box height="100%">
          <GeneralTabAssistantHeader
            title={customization.template.namePlural}
            id="templates"
          />
          <AssistantTemplate projectId={projectId} />
        </Box>
      )}
    </Box>
  );
}
