import { Button, Flex, Heading, Link, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Auth/AuthProvider";
import { resendVerificationEmail } from "../Components/Utils/auth";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { auth } from "../firebase";

const customization = getCustomizationData();

export const VerifyEmail = () => {
  const { currentUser } = useAuth();
  const resendTimer = 60;
  const [currentVerificationSeconds, setCurrentVerificationSeconds] =
    useState(resendTimer);
  const history = useHistory();
  // if email is verified, redirect to home page
  if (currentUser.emailVerified) {
    history.push("/");
  }

  const handleResendEmail = () => {
    if (currentVerificationSeconds < resendTimer) return;
    resendVerificationEmail(currentUser);
    let seconds = resendTimer;
    const timer = setInterval(() => {
      seconds -= 1;
      setCurrentVerificationSeconds(seconds);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      setCurrentVerificationSeconds(resendTimer);
    }, resendTimer * 1000);
  };
  return (
    <Flex
      w={["70%", "70%", "70%", "40%"]}
      m="0 auto"
      h="100vh"
      flexDir="column"
      align="left"
      justify="center"
    >
      <Heading mb={24}>Confirm your email</Heading>
      <Text fontWeight="black" color="#2D3748" fontSize="18px">
        To continue, click the confirmation link we emailed to{" "}
        <Text textDecor="underline" display="inline">
          {currentUser.email}
        </Text>
      </Text>
      <Text mt={8}>
        If you have confirmed your email,{" "}
        <Link href="/">
          <Text textDecor="underline" display="inline" color="link">
            click here to access {customization.name}
          </Text>
        </Link>
        .
      </Text>
      <Text
        mt={8}
        boxSizing="border-box"
        fontWeight="black"
        color={
          currentVerificationSeconds < resendTimer ? "gray.300" : "primary"
        }
      >
        Please check your spam folder. Can&apos;t find the email?{" "}
        <Text
          onClick={handleResendEmail}
          cursor={
            currentVerificationSeconds < resendTimer ? "not-allowed" : "pointer"
          }
          textDecor="underline"
          display="inline"
          color={currentVerificationSeconds < resendTimer ? "gray.300" : "link"}
        >
          Click here to resend the email{" "}
        </Text>
        <Text
          display="inline"
          maxWidth="20px"
          fontSize="12px"
          color="primary"
          visibility={
            currentVerificationSeconds < resendTimer ? "visible" : "hidden"
          }
        >
          Try again in ({currentVerificationSeconds}) Seconds
        </Text>
      </Text>
      <Text mt={8}>
        Not you?{" "}
        <Button
          onClick={() => {
            auth.signOut();
            history.push("/");
          }}
          variant="link"
        >
          <Text textDecor="underline" display="inline" color="link">
            Sign out
          </Text>
        </Button>
        .
      </Text>
    </Flex>
  );
};
