import { Box, Divider, Flex, HStack, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { HiSparkles } from "react-icons/hi";
import { MdOutlineTextSnippet } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIconComponent from "../Components/Icons/ArrowBackIconComponent";
import Layout from "../Components/Layout";
import { ToggleArticleDetailsBttn } from "../Components/Viewer/Buttons";
import { NoPDFViewerView } from "../Components/Viewer/NoPDFViewerView";
import { SmartImageView } from "../Components/Viewer/SmartImageView";
import InsightSide, { PAGES } from "../Components/insights/InsideSide";
import { InsightCardsSection } from "../Components/insights/InsightViewer";
import { useArticle } from "../Context/ArticleContext";
import { useAssistantContext } from "../Context/AssistantContext";
import { VIEW_MODE_MAP } from "../data/ViewModeData";
import { ARTICLE_TYPE_DATA } from "../data/articleTypeData";
import { getArticleManualHighlights } from "../db/article";
import { getStorageDownloadUrl } from "../services/firebaseStorageSrv";
import PdfExclusiveViewer from "./PdfExclusiveViewer";

function ViewerSection({ manualHighlights, activeArticle, viewFileFunc }) {
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const fetchPdfUrl = async () => {
      if (ARTICLE_TYPE_DATA[activeArticle.type].viewFilePdfField === "url") {
        setPdfUrl(activeArticle.url);
      } else if (
        ARTICLE_TYPE_DATA[activeArticle.type].viewFilePdfField === "pathToPdf"
      ) {
        if (!activeArticle.pathToPdf) {
          return;
        }
        const url = await getStorageDownloadUrl(activeArticle.pathToPdf);
        setPdfUrl(url);
      } else {
        console.error(
          "Invalid viewFilePdfField in ARTICLE_TYPE_DATA for this article type"
        );
      }
    };

    fetchPdfUrl();

    return () => {
      setPdfUrl("");
    };
  }, [activeArticle.pathToPdf, activeArticle.url]);

  if (activeArticle.type === "smartImage") {
    return <SmartImageView article={activeArticle} />;
  }
  if (viewFileFunc !== "VIEWER") {
    return <NoPDFViewerView article={activeArticle} />;
  }

  if (!pdfUrl) {
    return <NoPDFViewerView article={activeArticle} />;
  }
  return (
    <Flex w="100%">
      <PdfExclusiveViewer
        url={pdfUrl}
        manualHighlights={manualHighlights}
        reduceHeading={true}
      />
    </Flex>
  );
}

// function ViewerSection({ manualHighlights, activeArticle, viewFileFunc }) {
//   const [pdfUrl, setPdfUrl] = useState("");

//   useEffect(async () => {
//     if (ARTICLE_TYPE_DATA[activeArticle.type].viewFilePdfField === "url") {
//       setPdfUrl(activeArticle.url);
//     } else if (
//       ARTICLE_TYPE_DATA[activeArticle.type].viewFilePdfField === "pathToPdf"
//     ) {
//       const url = await getStorageDownloadUrl(activeArticle.pathToPdf);
//       setPdfUrl(url);
//     } else {
//       console.error(
//         "Invalid viewFilePdfField in ARTICLE_TYPE_DATA for this article type"
//       );
//     }
//     return () => {
//       setPdfUrl("");
//     };
//   }, [activeArticle.pathToPdf, activeArticle.url]);

//   if (viewFileFunc !== "VIEWER") {
//     return <NoPDFViewerView article={activeArticle} />;
//   }

//   if (!pdfUrl) {
//     return <NoPDFViewerView article={activeArticle} />;
//   }

//   return (
//     <Flex w="100%">
//       <PdfExclusiveViewer
//         url={pdfUrl}
//         manualHighlights={manualHighlights}
//         reduceHeading={true}
//       />
//     </Flex>
//   );
// }

const ArticleDetail = () => {
  const history = useHistory();
  const searchParams = history.location?.state?.params ?? "";
  let { projectId, articleId } = useParams();
  // const location = useLocation();
  const { assistantIsOpen, setAssistantIsOpen } = useAssistantContext();
  // temporary change set to true
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  // temporary change set to true
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const [manualHighlights, setManualHighlights] = useState([]);
  const { activeArticle } = useArticle();
  const articleType = activeArticle?.type;
  // const [snippetMode, setSnippetMode] = useState(
  //   // ARTICLE_TYPE_DATA[articleType]?.defaultInsightCardMode ||
  //   //   VIEW_MODE_MAP.default.id
  //   VIEW_MODE_MAP.comfort.id
  // );
  const currentSearchParams = new URLSearchParams(location.search);
  const detailParams = currentSearchParams.get("detail");

  useEffect(() => {
    const unsubscribe = getArticleManualHighlights({
      projectId,
      articleId,
      callback: setManualHighlights,
    });
    return () => {
      console.log("Unsubscribing from manual extractions");
      unsubscribe();
    };
  }, [projectId, articleId]);
  const viewFileFunc = ARTICLE_TYPE_DATA[articleType]?.viewFileFunction;

  // handle changing the URL to a different articles
  const routerHandler = (article) => {
    let currentSearchParams = new URLSearchParams(window.location.search);
    const detailParams = currentSearchParams.get("detail") || "aiInsight";
    currentSearchParams.set("detail", detailParams);

    const searchParamsString = currentSearchParams.toString();
    const newPathname = `/${projectId}/${article.uid}/articledetail`;

    // Use history to navigate
    history.push({
      pathname: newPathname,
      search: `?${searchParamsString}`,
      state: {
        articleData: article,
      },
    });
  };

  // useEffect(() => {
  //   if (!hasViewer) {
  //     let currentSearchParams = new URLSearchParams(window.location.search);
  //     currentSearchParams.set("detail", "aiInsight");
  //     history.push({
  //       pathname: `/${projectId}/${articleId}/articledetail`,
  //       search: `?${currentSearchParams.toString()}`,
  //     });
  //   }
  // }, [ARTICLE_TYPE_DATA, articleType]);

  useEffect(() => {
    if (assistantIsOpen) {
      setMenuIsOpen(false);
      setMenuIsExpanded(false);
    }
  }, [assistantIsOpen]);

  useEffect(() => {
    if (menuIsExpanded) {
      setAssistantIsOpen(false);
    }
  }, [menuIsExpanded]);

  if (!activeArticle) {
    return null;
  }

  // console.log("menuIsOpen :>> ", menuIsOpen);
  // console.log("menuIsExpanded :>> ", menuIsExpanded);
  return (
    <Layout>
      <Box bgColor="#F8F9FA" mt={3} overflowY="hidden">
        <VStack
          align="start"
          color="black"
          py="1"
          // pb="22px"
          pt="24px"
          borderBottom="1px solid #dadada"
          overflow="scroll"
          // hide the y-scroll bar
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Flex
            gap="32px"
            color="primary"
            fontWeight="medium"
            pb={1}
            w="full"
            backgroundColor="transparent"
          >
            <ArrowBackIconComponent
              onClick={() => {
                history.push(`/${projectId}/${searchParams}`);
              }}
              color="primary"
              fontSize="24px"
            />
            <HStack gap="28px">
              <ToggleArticleDetailsBttn
                bttnText="AI Insights"
                id="aiInsight"
                icon={HiSparkles}
              />
              <ToggleArticleDetailsBttn
                bttnText="View file"
                id="viewfile"
                icon={MdOutlineTextSnippet}
              />
            </HStack>
          </Flex>
          <Divider borderColor="#dadada" mt="10px" mb="16px" />
          <Flex pl={2} w="100%" gap="50px">
            <Flex w="3%" flex={menuIsOpen & menuIsExpanded ? 2 : 0}>
              <InsightSide
                routerHandler={(article) => routerHandler(article)}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
                menuIsOpen={menuIsOpen}
                setMenuIsExpanded={setMenuIsExpanded}
                defaultState="BUTTON"
                page={PAGES.ARTICLE_DETAIL}
                menuIsExpanded={menuIsExpanded}
              />
            </Flex>
            <Flex
              height="81vh"
              className="webkit-scrollbar-display-none"
              backgroundColor="transparent"
              w="95%"
              flex={10}
            >
              {detailParams === "viewfile" ? (
                <ViewerSection
                  manualHighlights={manualHighlights}
                  activeArticle={activeArticle}
                  viewFileFunc={viewFileFunc}
                />
              ) : null}
              <Flex
                w="80%"
                display={detailParams === "aiInsight" ? "block" : "none"}
              >
                <Droppable droppableId="CARDS" isDropDisabled={true}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      w="100%"
                    >
                      {/* <Stack
                        id="aianalystBttn"
                        spacing={2}
                        direction="row"
                        align="center"
                        mb="20px"
                        display="none"
                      > */}
                      {/* <ViewModeMenu
                          setViewMode={(id) => setSnippetMode(id)}
                          viewMode={snippetMode}
                        /> */}
                      {/* </Stack> */}
                      {/* {activeArticle?.summaryReady ? ( */}
                      <InsightCardsSection
                        snippetMode={VIEW_MODE_MAP.comfort.id}
                      />

                      {/* )} */}
                    </Box>
                  )}
                </Droppable>
              </Flex>
            </Flex>
          </Flex>
        </VStack>
      </Box>
    </Layout>
  );
};

export default ArticleDetail;
