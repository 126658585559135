import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { FiPlay } from "react-icons/fi";

export const OnboardingFlowWrapper = ({
  heading,
  intro,
  children,
  showDemoBtt,
  subParagraph,
  onOpen,
  width,
  height,
  marginTop,
  subParagraphWithText,
}: {
  /** The heading text to display */
  heading: string;
  /** The introductory text to display */
  intro: string;
  /** Optional child components to render */
  children?: React.ReactNode;
  /** Whether to show the demo button */
  showDemoBtt?: boolean;
  /** Optional subparagraph text to display */
  subParagraph?: string;
  /** Function to call when the demo button is clicked */
  onOpen?: () => void;
  /** Width of the wrapper */
  width?: string;
  /** Height of the wrapper */
  height?: string;
  /** Top margin of the wrapper */
  marginTop?: string;
  /** Function that returns a React node for the subparagraph */
  subParagraphWithText?: () => React.ReactNode;
}) => {
  return (
    <Flex
      mt={marginTop}
      flexDir="column"
      w="100%"
      align="center"
      boxSizing="border-box"
      maxH={height || "400px"}
      minH={height || "400px"}
    >
      <Flex flexDir="column" gap="16px" align="center" boxSizing="border-box">
        <Text
          textTransform="uppercase"
          letterSpacing="3%"
          fontSize="12px"
          fontWeight="medium"
        >
          {heading}
        </Text>
        <Heading fontSize="30px" fontWeight="bold">
          {intro}
        </Heading>
        {subParagraph && (
          <Heading fontWeight="bold" fontSize="13px">
            {subParagraph}
          </Heading>
        )}
        {subParagraphWithText && subParagraphWithText()}
        {showDemoBtt && (
          <Button
            color="#08B2E3"
            fontWeight="900"
            rightIcon={<FiPlay />}
            onClick={onOpen}
          >
            Watch demo
          </Button>
        )}
      </Flex>
      <Flex w={width || "600px"} m="0 auto">
        {children}
      </Flex>
    </Flex>
  );
};
