import { collection, FirestoreDataConverter } from "firebase/firestore";
import { COL_USERS } from "shared/constants";
import { UserDoc } from "shared/userTypes";
import { db } from "src/firebase";

export const userConverter: FirestoreDataConverter<UserDoc> = {
  toFirestore: (user: UserDoc) => user,
  fromFirestore: (snapshot) => snapshot.data() as UserDoc,
};

export const usersCollection = collection(db, COL_USERS).withConverter(
  userConverter
);
