import { Icon } from "@chakra-ui/react";
import React from "react";

const TemplateInsightsIcon = (props) => {
  return (
    <Icon viewBox="0 0 16 11" fill="black" opacity={0.54} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M8.02635 8.66797C8.85965 8.66797 9.56795 8.3763 10.1513 7.79297C10.7346 7.20964 11.0262 6.5013 11.0262 5.66797C11.0262 4.83464 10.7346 4.1263 10.1513 3.54297C9.56795 2.95964 8.85965 2.66797 8.02635 2.66797C7.19306 2.66797 6.48476 2.95964 5.90145 3.54297C5.31815 4.1263 5.02649 4.83464 5.02649 5.66797C5.02649 6.5013 5.31815 7.20964 5.90145 7.79297C6.48476 8.3763 7.19306 8.66797 8.02635 8.66797ZM8.02635 7.46797C7.52638 7.46797 7.1014 7.29297 6.75141 6.94297C6.40143 6.59297 6.22644 6.16797 6.22644 5.66797C6.22644 5.16797 6.40143 4.74297 6.75141 4.39297C7.1014 4.04297 7.52638 3.86797 8.02635 3.86797C8.52633 3.86797 8.95131 4.04297 9.30129 4.39297C9.65128 4.74297 9.82627 5.16797 9.82627 5.66797C9.82627 6.16797 9.65128 6.59297 9.30129 6.94297C8.95131 7.29297 8.52633 7.46797 8.02635 7.46797ZM8.02635 10.668C6.40421 10.668 4.9265 10.2152 3.59323 9.30964C2.25995 8.40408 1.29333 7.19019 0.693359 5.66797C1.29333 4.14575 2.25995 2.93186 3.59323 2.0263C4.9265 1.12075 6.40421 0.667969 8.02635 0.667969C9.6485 0.667969 11.1262 1.12075 12.4595 2.0263C13.7928 2.93186 14.7594 4.14575 15.3593 5.66797C14.7594 7.19019 13.7928 8.40408 12.4595 9.30964C11.1262 10.2152 9.6485 10.668 8.02635 10.668ZM8.02635 9.33464C9.28185 9.33464 10.4346 9.00408 11.4845 8.34297C12.5345 7.68186 13.3372 6.79019 13.8927 5.66797C13.3372 4.54575 12.5345 3.65408 11.4845 2.99297C10.4346 2.33186 9.28185 2.0013 8.02635 2.0013C6.77086 2.0013 5.61813 2.33186 4.56818 2.99297C3.51823 3.65408 2.71549 4.54575 2.15996 5.66797C2.71549 6.79019 3.51823 7.68186 4.56818 8.34297C5.61813 9.00408 6.77086 9.33464 8.02635 9.33464Z" />
      </svg>
    </Icon>
  );
};

export default TemplateInsightsIcon;
