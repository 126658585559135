import FileIconComponent from "../Components/Icons/FileIconComponent";
import LinkIconComponent from "../Components/Icons/LinkIconComponent";
import MediaIconComponent from "../Components/Icons/MediaComponent";
import SmartImageComponentIcon from "../Components/Icons/SmartImageIconsComponent";
import TextIconComponent from "../Components/Icons/TextIconComponent";
import { getCustomizationData } from "../Components/Utils/customizationUtils";

const customization = getCustomizationData();

export const CONTENT_TYPE = {
  DEFAULT: "DEFAULT",
  FILE: "FILE",
  MEDIA: "MEDIA",
  MANUAL_INSIGHT: "MANUAL_INSIGHT",
  TEXT: "TEXT",
  LINK: "LINK",
  AUDIO: "AUDIO",
  SMARTIMAGE: "SMARTIMAGE",
} as const;

export type ContentType = (typeof CONTENT_TYPE)[keyof typeof CONTENT_TYPE];

/**
 * Types of data that can be added through the Add Content modal
 */
export type ContentTypeId =
  | "file"
  | "text"
  | "smartImage"
  | "link"
  | "media"
  | "audio"
  | "intelqVerbatim"
  | "intelqQna";

export type ContentDetailData = {
  id: ContentTypeId;
  title: string;
  description: string;
  icon: React.ComponentType;
  type: ContentType;
  headerTitle: string;
  forceArticleType?: string;
  toolTip?: string;
  acceptedMimeTypes?: string[];
};

const CONTENT_DETAILS_DATA: ContentDetailData[] = [
  {
    id: "file",
    title: "File",
    description: "Import files to your project (pdf, csv, xlsx, docx, pptx)",
    icon: FileIconComponent,
    type: CONTENT_TYPE.FILE,
    headerTitle: "Upload Files",
  },
  // {
  //   id: "manualInsight",
  //   title: "Manual Insight",
  //   description: "Add and save important snippets of text",
  //   icon: TextIconComponent,
  //   type: CONTENT_TYPE.MANUAL_INSIGHT,
  //   headerTitle: "Create Snippet",
  // },
  {
    id: "text",
    title: "Create text file",
    description: "Save plain text as a file",
    icon: TextIconComponent,
    type: CONTENT_TYPE.TEXT,
    headerTitle: "Create Text File",
  },
  {
    id: "smartImage",
    title: "Smart Image",
    description: "Generate a file from an image with AI description",
    icon: SmartImageComponentIcon,
    type: CONTENT_TYPE.SMARTIMAGE,
    headerTitle: "Add Smart image",
  },
  {
    id: "link",
    title: "Link",
    description: "Copy and paste the website's URL",
    icon: LinkIconComponent,
    type: CONTENT_TYPE.LINK,
    headerTitle: "Title",
  },
  {
    id: "media",
    title: "Media",
    description: "Import screenshots and media (JPG, SVG, PNG)",
    icon: MediaIconComponent,
    type: CONTENT_TYPE.MEDIA,
    headerTitle: "Upload Images",
  },
  {
    id: "audio",
    title: "Audio",
    description: "Upload audio file",
    icon: FileIconComponent,
    type: CONTENT_TYPE.AUDIO,
    headerTitle: "Upload Audio",
    forceArticleType: "audio",
  },
  {
    id: "intelqVerbatim",
    title: "IntelQ statement",
    description: "Upload IntelQ statement PDF file",
    toolTip:
      "The IntelQ statement PDF is a special type of document allowing IntelQ's AI to run a specific analysis that extracts additional data when filling a brief. It allows for the extraction of sources, sub-sources, ratings, date, and sub-source sentiment analysis",
    icon: FileIconComponent,
    type: CONTENT_TYPE.FILE,
    headerTitle: "Upload IntelQ statement",
    forceArticleType: "intelqVerbatim",
    acceptedMimeTypes: ["application/pdf"],
  },
  {
    id: "intelqQna",
    title: "IntelQ Q&A",
    description: "Upload IntelQ Q&A PDF file",
    toolTip:
      "The IntelQ Q&A PDF is a special type of document allowing IntelQ's AI to run a specific analysis that extracts additional data when filling a brief. It allows for the extraction of sources, sub-sources, ratings, date, and sub-source sentiment analysis",
    icon: FileIconComponent,
    type: CONTENT_TYPE.FILE,
    headerTitle: "Upload IntelQ Q&A",
    forceArticleType: "intelqQna",
    acceptedMimeTypes: ["application/pdf"],
  },
];

export const getAddContentDetails = ({
  isPowerUser = false,
}: {
  isPowerUser?: boolean;
}): ContentDetailData[] => {
  // Power Users have access to all content types
  if (isPowerUser) return CONTENT_DETAILS_DATA;

  // for all other users, return only the content types that are specified in customization.contentTypeIds array
  // (in the order in which they are specified in the array)
  const contentTypeIds = customization.contentTypeIds;
  return contentTypeIds
    .map((id) => CONTENT_DETAILS_DATA.find((content) => content.id === id))
    .filter((content): content is ContentDetailData => content !== undefined);
};
