import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

export function IndexingError({ indexingError }) {
  return indexingError ? (
    <Tooltip
      label={
        indexingError ||
        "This file cannot be extracted due to its format or because protected. Please try again or contact us at support@mosaiqlabs.com"
      }
      placement="bottom-end"
      background="#979797"
      fontSize="10px"
      maxW="206px"
      borderRadius="0"
    >
      <Flex>
        <Icon as={AiOutlineInfoCircle} color="#191d30" fontSize="16px" />
      </Flex>
    </Tooltip>
  ) : null;
}
