import { Flex, Heading, Img, Link, Text, Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Logo from "../../assets/mosaiqweblogo.png";
import { auth } from "../../firebase";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const TourWelcomeMessage = () => {
  const user = auth.currentUser;
  const [videoHeight, setVideoHeight] = useState(window.innerHeight * 0.5); // initial height is 50% of viewport height

  // this effect updates the size of the video in order to keep the joyride modal in bounds of the screen height
  useEffect(() => {
    const updateVideoHeight = () => {
      setVideoHeight(window.innerHeight * 0.5); // update height to 50% of viewport height on window resize
    };

    window.addEventListener("resize", updateVideoHeight);

    return () => {
      window.removeEventListener("resize", updateVideoHeight); // cleanup listener on component unmount
    };
  }, []);
  return (
    <Flex flexDir="column" justify="left">
      {/* <Img w="100px" m="0 auto" src={Logo} /> */}
      <Heading fontSize="2xl" mb="4">
        Welcome, {user.displayName}!
      </Heading>
      <Text mb={4} fontWeight="black" fontSize="1rem">
        Get started in 1 minute
      </Text>
      <div
        style={{
          position: "relative",
          height: `${videoHeight}px`, // set iframe height to state variable
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: `${videoHeight}px`, // set iframe height to state variable
          }}
          src="https://www.youtube.com/embed/DIToNTcBmw8"
          title="Introducing Apple Vision Pro"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <Text mt={4}>
        {`Take the tour to learn more about the platform and experiment with ${customization.name}. Busy? You can always restart it from the user menu on
        top right.`}
      </Text>
    </Flex>
  );
};

export const ProjectTourWelcomeMessage = () => {
  return (
    <Flex flexDir="column" justify="left">
      <Img w="100px" m="0 auto" src={Logo} />
      <Heading fontSize="2xl" mb="4">
        Project Database
      </Heading>
      <Text>
        The place where all files are added, organized, and ready to be analyzed
        80% faster
      </Text>
    </Flex>
  );
};

export const ViewerTourWelcomeMessage = () => {
  return (
    <Flex flexDir="column" justify="left">
      <Img w="100px" m="0 auto" src={Logo} />
      <Heading fontSize="2xl" mb="4">
        Welcome To Viewer Page!
      </Heading>
      <Text>
        This tour will give you a first look to using the viewer page to save,
        edit and make highlights
      </Text>
    </Flex>
  );
};

export const AiInsightPageTourWelcomeMessage = () => {
  return (
    <Flex flexDir="column" justify="left">
      <Img w="100px" m="0 auto" src={Logo} />
      <Heading fontSize="2xl" mb="4">
        Welcome To AI Insights Page!
      </Heading>
      <Text>
        This tour will give you a first look to using the AI Insights page to
        analyse and save your AI generated contents.
      </Text>
    </Flex>
  );
};

export const InsightPageTourWelcomeMessage = () => {
  return (
    <Flex flexDir="column" justify="left">
      <Img w="100px" m="0 auto" src={Logo} />
      <Heading fontSize="2xl" mb="4">
        Saved insights
      </Heading>
      <Text>
        All the information you extracted and saved from files appear here
      </Text>
    </Flex>
  );
};

export const CuratedSectionInsightWelcomeMessage = () => {
  return (
    <Flex flexDir="column" justify="left">
      <Img w="100px" m="0 auto" src={Logo} />
      <Heading fontSize="2xl" mb="4">
        Organizer
      </Heading>
      <Text>
        Use the Organizer to structure your Saved Insights and get them ready
        for reports or content creation
      </Text>
    </Flex>
  );
};

export const SimpleTourMessage = ({ heading, text, link, linkText }) => {
  return (
    <Flex flexDir="column" justify="left">
      <Heading fontSize="24px" mb="2" textAlign="center" color="#191D30">
        {heading}
      </Heading>
      <Text fontSize="16px" color="#5F616F">
        {text}
      </Text>
      {link && (
        <Link
          display="block"
          color="linkedin.800"
          textDecor="underline"
          href={link}
          isExternal
        >
          {linkText}
        </Link>
      )}
    </Flex>
  );
};

export const ProjectSwtichBttn = ({ text, icon, size, color, mx, ml }) => {
  return (
    <Button
      p="0"
      gap={0}
      fontWeight="bold"
      fontSize={size}
      leftIcon={icon}
      color={color}
      h="fit-content"
      pb="5px"
      borderRadius="0"
      iconSpacing="1"
      mx={mx}
      ml={ml && ml}
    >
      {text}
    </Button>
  );
};
