import { BsGlobe } from "react-icons/bs";
import { MdEditNote } from "react-icons/md";
import { SNIPPET_TYPE_DATA } from "../../data/SnippetTypeData";

/**
 *  Generates a string with the text content of cards from all sections in the organizer.
 *
 * @param {Map} sections a map of sections with their cards, each card is an object with a text property
 * @returns String with the content of cards from all sections in the organizer
 */
export const generateTemplateContentText = (sections) => {
  return Object.entries(sections)
    .map(([, section]) => {
      const header = section.name;
      const bodies = section.cards?.filter((c) => c != 0).map((c) => c.text);
      return `${header}\n\n${bodies.join("\n\n")}`;
    })
    .join("\n\n");
};

export const groupSnippetsByDocument = (articles, projectContents) => {
  const categorizedResult = articles?.reduce((result, article) => {
    const { uid } = article;
    const matchingCards = projectContents.filter((d) => d.articleId === uid);

    if (matchingCards.length > 0) {
      result.push({
        uid: article.uid,
        title: article.title || "No Title",
        source: article.source || "No Source",
        icon: article.icon || "",
        cards: matchingCards,
        url: article.url,
        fallbackIcon: BsGlobe,
      });
    }
    return result;
  }, []);

  projectContents.forEach((card) => {
    // image, manualInsight and assistantInsight are their own categories
    if (["image", "manualInsight", "assistantInsight"].includes(card.type)) {
      const { title } = card;
      const existingArticle = categorizedResult.find(
        (item) => item.title === title
      );
      if (existingArticle) {
        existingArticle.cards.push(card);
      } else {
        const newArticle = {
          title,
          cards: [card],
          uid: card.uid,
          source: title,
          // fallbackIcon: MdEditNotem
          // if the card is assistantInsight, use the assistantInsightIcon
          fallbackIcon:
            card.type === "assistantInsight"
              ? SNIPPET_TYPE_DATA.assistantInsight.icon
              : MdEditNote,
          imageUrl: card.imageUrl,
        };
        categorizedResult.push(newArticle);
      }
    }
  });
  categorizedResult.sort((a, b) => b.cards.length - a.cards.length);
  return categorizedResult;
};
