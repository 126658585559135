import { Button } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
const SwitchBttnType = {
  AllContent: "allContent",
  KeyInsights: "keyInsights",
};

export const ProjectPageSwitchBttn = ({ type, text, BttnIcon }) => {
  const allContentType = type === SwitchBttnType.AllContent;
  const keyInsightType = type === SwitchBttnType.KeyInsights;
  const history = useHistory();
  const searchParams = history.location.search;
  // const [showInsight, setShowInsight] = useState(false);

  const currentSearchParams = new URLSearchParams(searchParams);
  const showInsightParam = currentSearchParams.get("showInsight");
  const showInsight = showInsightParam === "true";

  //This updated the showInsight params in the URL search params and replaces the current history state.
  const updateSearchParams = () => {
    const currentSearchParams = new URLSearchParams(history.location.search);
    if (keyInsightType) {
      currentSearchParams.set("showInsight", "true");
      history.push({
        search: currentSearchParams.toString(),
      });
    } else {
      currentSearchParams.delete("showInsight");
      history.push({
        search: currentSearchParams.toString(),
      });
    }
  };

  //This useEffect will fetch the state of the showKeyFindings switch from the URL search params and update the state of the switch accordingly.
  // useEffect(() => {
  //   const currentSearchParams = new URLSearchParams(searchParams);
  //   const showInsightParam = currentSearchParams.get("showInsight");
  //   setShowInsight(showInsightParam === "true");
  // }, [searchParams]);

  const getBttnBackground = () => {
    if (allContentType && !showInsight) {
      return "primary";
    } else if (keyInsightType && showInsight) {
      return "primary";
    } else {
      return "transparent";
    }
  };

  const getBttnTextColor = () => {
    if (allContentType && !showInsight) {
      return "white";
    } else if (keyInsightType && showInsight) {
      return "white";
    } else {
      return "primary";
    }
  };
  return (
    <Button
      bg={getBttnBackground()}
      id="projectPageSwitch"
      color={getBttnTextColor()}
      fontSize="12px"
      h="24px"
      py="1"
      display="flex"
      px="1"
      pr="2"
      leftIcon={<BttnIcon style={{ marginTop: "-1px" }} />}
      onClick={() => {
        updateSearchParams();
      }}
    >
      {text}
    </Button>
  );
};
