import { HStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useTemplateContext } from "../../Context/TemplateContext";
import OrganizerPreviewModal from "../Elements/OrganizerPreviewModal";
import EditableText from "../General/EditableText";
import AddIconComponent from "../Icons/AddIconComponent";
import PreviewIconComponent from "../Icons/PreviewIconComponent";
import HeaderTab from "../SavedContents/HeaderTab";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantHeaderWrapper from "./AssistantHeaderWrapper";
import FillTemplate from "./FillTemplate";
import { FILL_TEMPLATE_STATUS } from "./SmartTemplate/AssistantSmartTemplate";

const customization = getCustomizationData();

const GeneralTabAssistantHeader = ({ title, id }) => {
  const { isPowerUser } = useAuth();
  const {
    currentTemplate,
    handleBackNavigation,
    createNewTemplate,
    updateTemplateName,
  } = useTemplateContext();
  const fillTemplateIsLoading =
    currentTemplate?.fillTemplateStatus?.status ===
    FILL_TEMPLATE_STATUS.LOADING;
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  return (
    <AssistantHeaderWrapper
      backButtonHandler={handleBackNavigation}
      title={
        currentTemplate ? (
          <EditableText
            text={currentTemplate.name}
            submitHandler={(value) =>
              updateTemplateName(value, currentTemplate.id)
            }
            fontSize="xs"
            color="primary"
            fontWeight="medium"
          />
        ) : (
          title
        )
      }
    >
      {currentTemplate ? (
        <HStack gap={6}>
          {/* <HeaderTab
            icon={BiBoltCircle}
            title="Fill template"
            boxSize={5}
            fontSize="xs"
            handler={() => {
              console.log("currentTemplate :>> ", currentTemplate);
              const requestRef = doc(db, `fillTemplateRequests/${uuid4()}`);
              setDoc(requestRef, {
                projectId,
                templateId: currentTemplate.id,
                userId: currentUser.uid,
              });
            }}
          /> */}
          {isPowerUser || customization.template.enableFillTemplate ? (
            <FillTemplate />
          ) : null}
          <HeaderTab
            handler={() => openModal()}
            icon={PreviewIconComponent}
            title="Preview"
            boxSize={5}
            fontSize="xs"
            color="#707070"
            display={fillTemplateIsLoading ? "none" : "flex"}
          />
        </HStack>
      ) : (
        <HeaderTab
          icon={AddIconComponent}
          title={`New ${customization.template.name}`}
          boxSize={5}
          fontSize="xs"
          handler={() => {
            createNewTemplate();
          }}
          display={id === "templates" ? "flex" : "none"}
        />
      )}
      <OrganizerPreviewModal isOpen={isModalOpen} onClose={closeModal} />
    </AssistantHeaderWrapper>
  );
};

export default GeneralTabAssistantHeader;
