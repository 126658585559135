import { useToast } from "@chakra-ui/react";
import {
  createCheckoutSession,
  getCurrentUserSubscription,
  getProducts,
  getStripePayments,
} from "@invertase/firestore-stripe-payments";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { app, auth } from "../firebase";
// import { db, addDoc, collection, onSnapshot } from "../firebase";

//configures the stripe payments to use the products and customers collections
export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});

getCurrentUserSubscription;

// const getPremiumPrice = async (productId, priceId) => {
//   const products = await getProducts(payments, {
//     includePrices: true,
//     activeOnly: true,
//   });

//   const product = products.find((product) => product.id === productId);

//   if (!product) {
//     console.error(`Product ${productId} not found`);
//     return null;
//   }

//   const activePrices = product.prices.filter((price) => price.active);
//   const price = activePrices.find((price) => price.interval === plan);

//   if (!price) {
//     console.error(`Price for ${plan} not found`);
//     return null;
//   }

//   return price;
// };

// export const handleStripePayment = async (plan, successUrl, cancelUrl) => {
//   const origin = window.location.origin;
//   const price = await getPremiumPrice(plan);

//   if (!price) {
//     return null;
//   }

//   const session = await createCheckoutSession(payments, {
//     price: price.id,
//     success_url: `${origin}/${successUrl}`,
//     cancel_url: `${origin}/${cancelUrl}`,
//   });
//   return session;
// };

/**
 * Checkout session for AI Credits
 *
 * @returns
 */
export const handleStripeAiCreditsPayment = async (
  nCredits,
  successUrl,
  cancelUrl
) => {
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: true,
  });

  const productId = process.env.REACT_APP_AI_CREDITS_PRODUCT_ID;
  if (!productId) {
    console.error("AI Credits product ID not found");
    return null;
  }
  const product = products.find((product) => product.id === productId);
  if (!product) {
    console.error(`Product ${productId} not found`);
    return null;
  }

  // get only active prices
  const activePrices = product.prices.filter((price) => price.active);
  // check if there is a env var for this number of nCredits
  if (!process.env[`REACT_APP_AI_CREDITS_${nCredits}_PRICE_ID`]) {
    console.error(`Price ID for ${nCredits} AI credits not found`);
    return null;
  }
  // grab the price with the corresponding interval
  const price = activePrices.find(
    (price) =>
      price.id === process.env[`REACT_APP_AI_CREDITS_${nCredits}_PRICE_ID`]
  );
  if (!price) {
    console.error(`Price for ${nCredits} AI credits not found`);
    return null;
  }

  const session = await createCheckoutSession(payments, {
    mode: "payment",
    price: price.id,
    success_url: `${window.location.origin}/${successUrl}`,
    cancel_url: `${window.location.origin}/${cancelUrl}`,
  });
  return session;
};

export const getUserSubscriptionStatus = async () => {
  try {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    return decodedToken?.claims;
  } catch (err) {
    console.error("Error checking premium status:", err);
    return false;
  }
};

export const useDeleteSubscription = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: async ({ subscriptionId }: { subscriptionId: string }) => {
      const queryParams = {
        subscription_id: subscriptionId,
      };
      const decodedToken = await auth.currentUser?.getIdToken(true);
      const headers = {
        Authorization: decodedToken,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_STRIPE_API_URL}/cancelUserSubscription`,
        {
          params: queryParams,
          headers: headers,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to delete subscription");
      }

      return response.data;
    },
    onSuccess: () => {
      toast({
        title: "Subscription canceled",
        description: "Your subscription has been canceled",
        status: "success",
        position: "top-right",
        duration: 5000,
      });
    },
    onError: (error) => {
      toast({
        title:
          "Error canceling subscription. Please contact us at support@mosaiqlabs.com.",
        description: error.message,
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    },
  });
};
