import { Icon } from "@chakra-ui/react";
import React from "react";

const ExternalLinkIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"
        fill="black"
        fillOpacity="0.54"
      />
    </Icon>
  );
};

export const OpenInBrowserIcon = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9.5C0.725 9.5 0.489583 9.40208 0.29375 9.20625C0.0979167 9.01042 0 8.775 0 8.5V1.5C0 1.225 0.0979167 0.989583 0.29375 0.79375C0.489583 0.597917 0.725 0.5 1 0.5H4.5V1.5H1V8.5H8V5H9V8.5C9 8.775 8.90208 9.01042 8.70625 9.20625C8.51042 9.40208 8.275 9.5 8 9.5H1ZM3.35 6.85L2.65 6.15L7.3 1.5H5.5V0.5H9V4H8V2.2L3.35 6.85Z"
          fill="black"
          // fillOpacity="0.56"
        />
      </svg>
    </Icon>
  );
};

export default ExternalLinkIconComponent;
