import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { User } from "firebase/auth";
import React from "react";
import { ProjectDoc } from "shared/projectTypes";
import { useAuth } from "../../Auth/AuthProvider";
import { unArchiveProject } from "../../db/project";
import SharedWithAvatar from "../Elements/SharedWithAvatar";
import FolderIconComponent from "../Icons/FolderIconComponent";
import FolderUploadIconComponent from "../Icons/FolderUploadIconComponent";

export const ArchiveProjectListCard = ({
  projectName,
  projectId,
  createdBy,
  authorDisplayName,
  authorPhotoURL,
}: {
  projectName: ProjectDoc["name"];
  projectId: ProjectDoc["uid"];
  createdBy: ProjectDoc["createdBy"];
  authorDisplayName: ProjectDoc["authorDisplayName"];
  authorPhotoURL: ProjectDoc["authorPhotoURL"];
}) => {
  const { currentUser }: { currentUser: User } = useAuth();
  const { userDocRef } = useAuth();
  return (
    <>
      <Flex my={4}>
        <Flex flex="1" gap="8px" alignItems="center" flexWrap="wrap">
          <Icon as={FolderIconComponent} />
          <Box>
            <Text fontSize="12px" fontWeight={400}>
              {projectName}
            </Text>
          </Box>
          <Flex>
            {createdBy !== currentUser?.uid ? (
              <SharedWithAvatar
                displayName={authorDisplayName || "Anonymous User"}
                photoURL={authorPhotoURL || ""}
                size="2xs"
                tooltipPrefix="Created by "
              />
            ) : null}
          </Flex>
        </Flex>
        <Icon
          onClick={() => unArchiveProject({ projectId, userDocRef })}
          as={FolderUploadIconComponent}
          cursor="pointer"
        />
      </Flex>
      <Divider borderColor="#191D30" opacity={0.12} />
    </>
  );
};
