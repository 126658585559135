import { Query, useQuery } from "@tanstack/react-query";
import {
  collection,
  collectionGroup,
  FirestoreDataConverter,
  getDocs,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { COL_PROJECT_PERMISSIONS, COL_PROJECTS } from "shared/constants";
import { ProjectDoc, ProjectPermissionDoc } from "shared/projectTypes";
import {
  arrayRemove,
  arrayUnion,
  db,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "../firebase";
import { queryKeyUserProjects } from "./queryKeys";

const projectPermissionConverter: FirestoreDataConverter<ProjectPermissionDoc> =
  {
    toFirestore: (permission: ProjectPermissionDoc) => permission,
    fromFirestore: (snapshot) => snapshot.data() as ProjectPermissionDoc,
  };

const projectConverter: FirestoreDataConverter<ProjectDoc> = {
  toFirestore: (project: ProjectDoc) => project,
  fromFirestore: (snapshot) => snapshot.data() as ProjectDoc,
};

export const projectPermissionsCollectionGroup = collectionGroup(
  db,
  COL_PROJECT_PERMISSIONS
).withConverter(projectPermissionConverter);

export const projectsCollection = collection(db, COL_PROJECTS).withConverter(
  projectConverter
);

export const getProjectPermissionsCollection = (projectId: string) =>
  collection(
    doc(projectsCollection, projectId),
    COL_PROJECT_PERMISSIONS
  ).withConverter(projectPermissionConverter);

export const archiveProject = ({ projectId, userDocRef }) => {
  // var docRef = doc(db, "projects/" + projectId);
  // updateDoc(docRef, {
  //   archived: true,
  // });
  updateDoc(userDocRef, {
    archivedProjects: arrayUnion(projectId),
  });
};

export const unArchiveProject = ({ projectId, userDocRef }) => {
  // var docRef = doc(db, "projects/" + projectId);
  // updateDoc(docRef, {
  //   archived: false,
  //   modifiedAt: Date.now(),
  // });
  updateDoc(userDocRef, {
    archivedProjects: arrayRemove(projectId),
  });
};

/**
 * Updates the pinnedProjects array of a user in Firestore database.
 * @param {string} userId - The ID of the user whose pinnedProjects array is to be updated.
 * @param {string} projectId - The ID of the project to be added or removed from the pinnedProjects array.
 * @param {boolean} removeFromArray - A flag indicating whether to remove the project from the pinnedProjects array (true) or add it (false).
 */
export const updateUserPinnedProjectsArr = (
  userId,
  projectId,
  removeFromArray
) => {
  const docRef = doc(db, "users/" + userId);
  updateDoc(docRef, {
    pinnedProjects: removeFromArray
      ? arrayRemove(projectId)
      : arrayUnion(projectId),
  });
};

/**
 * Retrieves the pinned projects of a user from the database.
 *
 * @param {string} userId - The ID of the user.
 * @param {function} callback - The callback function to be called with the pinned projects data.
 * @returns {function} - The unsubscribe function to stop listening to changes in the database.
 */
export const getUsersPinnedProjects = (userId, callback) => {
  const docRef = doc(db, `users/${userId}`);
  const unsubscribe = onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      console.log("Updating pinned projects from db", doc.data());
      callback(doc.data().pinnedProjects);
    }
  });
  return unsubscribe;
};

// export const getProject = ({ projectId, setProjectCallback }) => {
//   var docRef = doc(db, "projects/" + projectId);
//   const unsubscribe = onSnapshot(docRef, (doc) => {
//     if (doc.exists) {
//       console.log("Updating project from db", doc.data());
//       setProjectCallback({ ...doc.data(), uid: doc.id });
//     } else {
//       // doc.data() will be undefined in this case
//       console.log("No such project!", projectId);
//     }
//   });
//   return unsubscribe;
// };

export const getProjectData = async (projectId) => {
  const docRef = doc(projectsCollection, projectId);
  console.log(`Fetching project data for ${projectId}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), uid: docSnap.id };
  } else {
    return null;
  }
};

export const createProject = async ({
  projectId,
  userId,
  userEmail,
  payload,
}: {
  projectId: ProjectDoc["uid"];
  userId: ProjectPermissionDoc["uid"];
  userEmail: ProjectPermissionDoc["email"];
  payload: ProjectDoc;
}) => {
  // create permission
  const permissionRef = getProjectPermissionsCollection(projectId);
  const permissionPayload: ProjectPermissionDoc = {
    uid: userId,
    email: userEmail,
    permission: "owner",
    dontSendInviteEmail: true,
  };
  await setDoc(doc(permissionRef, userId), permissionPayload);
  const projectDocRef = doc(projectsCollection, projectId);
  await setDoc(projectDocRef, payload);
  console.log("Added project", projectId);
};

export const useUserProjects = (
  userId,
  refetchInterval:
    | number
    | false
    | ((
        query: Query<{ data: ProjectDoc; permission: ProjectPermissionDoc }[]>
      ) => number | false) = false
) => {
  return useQuery({
    queryKey: [queryKeyUserProjects, userId],
    queryFn: async () => {
      console.log(`Re-fetching user workspace projects`);
      // retrieve permissions for projects
      const permissionsSnapshot = await getDocs(
        query(projectPermissionsCollectionGroup, where("uid", "==", userId))
      );
      const projectIds = permissionsSnapshot.docs
        .map((doc) => doc.ref.parent.parent?.id)
        .filter((id) => id !== undefined);
      // We retrieve projects individually here because it is not possible to do a query
      // when permissions are based on another document (projectPermissions).
      // it seems that this is a fundamental limitation of Firestore.
      const projectPromises = projectIds.map(async (projectId) => {
        // console.log("Retrieving project", projectId);
        const projectDocRef = doc(projectsCollection, projectId);
        const projectDoc = await getDoc(projectDocRef);
        const permissionDoc = permissionsSnapshot.docs.find(
          (doc) => doc.ref.parent.parent?.id === projectId
        );
        if (projectDoc.exists() && permissionDoc) {
          return {
            data: { ...projectDoc.data(), uid: projectDoc.id } as ProjectDoc,
            permission: {
              ...permissionDoc.data(),
              uid: permissionDoc.id,
            } as ProjectPermissionDoc,
          };
        }
        return null;
      });

      const projects = (await Promise.all(projectPromises)).filter(
        (
          project
        ): project is {
          data: ProjectDoc;
          permission: ProjectPermissionDoc;
        } => {
          if (project !== null) {
            // console.log(
            //   "Project:",
            //   project.data,
            //   "Permission:",
            //   project.permission
            // );
            return true;
          }
          return false;
        }
      );
      // console.log(
      //   "useUserWorkspaceProjects projects",
      //   JSON.stringify(projects)
      // );
      return projects;
    },
    throwOnError: true,
    refetchInterval,
  });
};

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 */
export const getAllUserProjects = (userId, callback) => {
  let authoredProjects = [] as ProjectDoc[];
  const sharedProjectsMap = new Map<ProjectDoc["uid"], ProjectDoc>();

  const updateCallback = () => {
    const allProjects = [...authoredProjects, ...sharedProjectsMap.values()];
    console.log("Updated projects from db", allProjects);
    callback(allProjects);
  };

  // Query for authored projects
  const authoredProjectsRef = query(
    projectsCollection,
    where("author", "==", userId)
  );

  // Subscribe to authored projects
  const unsubscribeAuthored = onSnapshot(
    authoredProjectsRef,
    (collectionObj) => {
      authoredProjects = [];
      collectionObj.forEach((d) => {
        authoredProjects.push({ ...d.data(), uid: d.id });
      });
      updateCallback();
    }
  );

  // Query for shared projects
  const sharedProjectsRef = query(
    projectPermissionsCollectionGroup,
    where("uid", "==", userId)
  );

  const unsubscribeSharedProjects = [] as Unsubscribe[];

  getDocs(sharedProjectsRef).then((snapshot) => {
    const sharedProjectsId = snapshot.docs.map(
      (doc) => doc.ref.parent.parent?.id
    );
    for (const projectId of sharedProjectsId) {
      const sharedProjectRef = doc(projectsCollection, projectId);
      unsubscribeSharedProjects.push(
        onSnapshot(sharedProjectRef, (doc) => {
          console.log(`Subscribed to shared project ${projectId}`);
          sharedProjectsMap.delete(projectId as string);
          if (doc.exists()) {
            sharedProjectsMap.set(projectId as string, {
              ...doc.data(),
              uid: doc.id,
              shared: true,
            });
          }
          updateCallback();
        })
      );
    }
  });

  return () => {
    console.log(`Unsubscribing from all projects`);
    unsubscribeAuthored();
    unsubscribeSharedProjects.forEach((f) => f());
  };
};
