import { Icon } from "@chakra-ui/react";
import React from "react";

const OfferIcon = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12 3.66927L6 0.335938L0 3.66927V10.3359L6 13.6693L12 10.3359V3.66927ZM6 1.8626L9.94 4.04927L7.93333 5.1626C7.44667 4.65594 6.76 4.33594 6 4.33594C5.24 4.33594 4.55333 4.65594 4.06667 5.1626L2.06 4.04927L6 1.8626ZM5.33333 11.7759L1.33333 9.55594V5.17594L3.42 6.33594C3.36 6.5426 3.33333 6.76927 3.33333 7.0026C3.33333 8.2426 4.18 9.28927 5.33333 9.5826V11.7759ZM4.66667 7.0026C4.66667 6.26927 5.26667 5.66927 6 5.66927C6.73333 5.66927 7.33333 6.26927 7.33333 7.0026C7.33333 7.73594 6.73333 8.33594 6 8.33594C5.26667 8.33594 4.66667 7.73594 4.66667 7.0026ZM6.66667 11.7759V9.58927C7.82 9.29594 8.66667 8.24927 8.66667 7.00927C8.66667 6.77594 8.64 6.54927 8.58 6.33594L10.6667 5.17594V9.55594L6.66667 11.7759Z"
        fill="black"
      />
    </Icon>
  );
};

export default OfferIcon;
