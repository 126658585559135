import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaChevronRight } from "react-icons/fa";

interface ForwardBttnProps {
  handleArrowClicks: (direction: "left" | "right") => void;
  showText: boolean;
  allowForwardNav: boolean;
  setAllowForwardNav: (allow: boolean) => void;
  endOfSteps: boolean;
  onClick?: () => Promise<void>;
}

export const ForwardBttn = ({
  handleArrowClicks,
  showText,
  allowForwardNav,
  setAllowForwardNav,
  endOfSteps,
  onClick = async () => {},
}: ForwardBttnProps) => {
  if (endOfSteps) {
    return null;
  }
  return (
    <Flex
      h="fit-content"
      justify="center"
      align="center"
      onClick={async () => {
        if (!allowForwardNav) return;
        await onClick();
        setAllowForwardNav(true);
        handleArrowClicks("right");
      }}
      gap="38px"
      pos="absolute"
      right="2%"
      cursor={allowForwardNav ? "pointer" : "not-allowed"}
      opacity={allowForwardNav ? 1 : 0.5}
    >
      <Text
        fontSize="12px"
        color="primary"
        visibility={showText ? "visible" : "hidden"}
      >
        GO FORWARD
      </Text>
      <FaChevronRight width="29px" height="33px" fontSize="40px" />
    </Flex>
  );
};
