export function applyBold(text, bold) {
  let boldedText = text;

  if (bold) {
    // sort bold array in descending order based on start index
    // this is to ensure we don't mess up the indexes when adding bold tags
    // (copy of the array is needed to avoid an error on changing the original array)
    const sortedBold = [...bold];
    sortedBold.sort((a, b) => b.start - a.start);

    sortedBold.forEach(({ start, end }) => {
      const before = boldedText.slice(0, start);
      const boldPart = boldedText.slice(start, end);
      const after = boldedText.slice(end);
      boldedText = `${before}**${boldPart}**${after}`;
    });
  }

  return boldedText;
}

export function replaceNewLines(text) {
  return text.replace(/\n/g, " ");
}
