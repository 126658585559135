import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Login } from "../Components/UserAuth/Login";
import { Flex, Text } from "@chakra-ui/react";
import { EmailSignUp } from "../Components/UserAuth/EmailSignUp";
import { BiLeftArrowAlt } from "react-icons/bi";

const Home = () => {
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    // if (loading) return;
    if (user) {
      //If user email is not verfiefied, send them to the email verification page
      if (!user.emailVerified) {
        history.replace("/verifyemail");
      } else {
        if (location.state && location.state.from) {
          history.replace(location.state.from);
        } else {
          history.replace(`/user${location.search}`);
        }
      }
    }
  }, [user, loading]);

  const [showSignUpElem, setShowSignUpElem] = useState(false);
  return (
    <Flex position="relative" height="100vh" align="center" justify="center">
      <Flex
        display={showSignUpElem ? "flex" : "none"}
        onClick={() => {
          setShowSignUpElem(false);
        }}
        cursor="pointer"
        position="absolute"
        top="2%"
        left="2%"
        align="center"
      >
        <BiLeftArrowAlt fontSize="25px" />
        <Text fontSize="16px">Login</Text>
      </Flex>
      <Flex display={showSignUpElem ? "none" : "flex"}>
        <Login
          showSignUpElem={() => {
            setShowSignUpElem(true);
          }}
        />
      </Flex>
      <Flex
        w={{ lg: "50%", base: "80%", "2xl": "25%" }}
        align="center"
        justify="center"
        display={showSignUpElem ? "flex" : "none"}
      >
        <EmailSignUp
          showLogin={() => {
            setShowSignUpElem(false);
          }}
        />
      </Flex>
    </Flex>
  );
};

export default Home;
