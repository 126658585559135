import { Box, Flex } from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import { paraisoLight } from "react-syntax-highlighter/dist/esm/styles/hljs";

SyntaxHighlighter.registerLanguage("yaml", yaml);

/**
 *
 * Based on https://www.haydenbleasel.com/blog/making-react-syntax-highlighter-editable
 *
 * @param yamlCode - The YAML code to display and edit.
 * @param setYamlCode - A function to set the YAML code.
 * @returns A component that allows editing YAML code.
 */
const YamlEditor: React.FC<{
  yamlCode: string;
  setYamlCode: (code: string) => void;
  textStyles?: React.CSSProperties;
}> = ({ yamlCode, setYamlCode, textStyles }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const highlighterRef = useRef<HTMLDivElement>(null);

  if (!textStyles) {
    textStyles = {
      fontSize: "14px",
      lineHeight: "20px",
      padding: "8px",
      margin: "0",
      fontFamily: "monospace",
    };
  }

  const commonStyles: React.CSSProperties = {
    ...textStyles,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    width: "100%",
  };

  const handleScroll = useCallback(() => {
    if (textareaRef.current && highlighterRef.current) {
      highlighterRef.current.scrollTop = textareaRef.current.scrollTop;
    }
  }, []);

  // Add this function to ensure newlines are preserved
  const preserveNewlines = (code: string) => {
    return code.replace(/\n$/, "\n\n");
  };

  return (
    <Flex
      role="button"
      tabIndex={0}
      onKeyDown={() => textareaRef.current?.focus()}
      onClick={() => textareaRef.current?.focus()}
      position="relative"
      height="100%"
      w="100%"
    >
      <textarea
        ref={textareaRef}
        value={yamlCode}
        onChange={(e) => setYamlCode(e.target.value)}
        onScroll={handleScroll}
        style={{
          position: "absolute",
          inset: 0,
          resize: "none",
          background: "transparent",
          color: "transparent",
          caretColor: "black",
          outline: "none",
          overflow: "auto",
          ...commonStyles,
        }}
      />
      <Box ref={highlighterRef} flex="1" overflow="hidden" p="0" m="0" w="100%">
        <SyntaxHighlighter
          language="yaml"
          style={paraisoLight}
          customStyle={{
            flex: "1",
            background: "transparent",
            ...commonStyles,
          }}
          wrapLines={true}
          wrapLongLines={true}
        >
          {preserveNewlines(yamlCode)}
        </SyntaxHighlighter>
      </Box>
    </Flex>
  );
};

export default YamlEditor;
