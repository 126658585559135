import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ProtectedRoute from "./Auth/AuthProtectedRoute";
import { AuthProvider } from "./Auth/AuthProvider";
import { AiModuleEditor } from "./Components/AiModuleEditor/AiModuleEditor";
import { MultistepAiModuleEditor } from "./Components/AiModuleEditor/MultistepAiModuleEditor";
import ErrorBoundary from "./Components/ErrorBoundary";
import { EmailSignUp } from "./Components/UserAuth/EmailSignUp";
import { ResetPassword } from "./Components/UserAuth/ResetPassword";
import { UserProfileActions } from "./Components/UserProfile/UserProfileActions";
import { Workspace } from "./Components/UserProfile/Workspace";
import { WorkspaceAcceptInvitePage } from "./Components/Workspace/AcceptInvitePage";
import { AssistantContextProvider } from "./Context/AssistantContext";
import { OnboardingContextProvider } from "./Context/OnboardingContext";
import { UserPermissionProvider } from "./Context/UserPermissionContext";
import ArticleDetail from "./Pages/ArticleDetailPage";
import { FullScreenAssistant } from "./Pages/Assistant/FullScreenAssistant";
import { SignUpPage } from "./Pages/Auth/Signup";
import { HelpAndSupport } from "./Pages/Help/HelpAndSupport";
import Home from "./Pages/Home";
import InsightPage from "./Pages/InsightPage";
import { NotFound } from "./Pages/NotFound/NotFound";
import { OnboardingFlow } from "./Pages/Onboarding/OnboardingFlow";
import { UpdatedOnboardingFlow } from "./Pages/Onboarding/UpdatedOnboardingFlow";
import PaymentStatusPage from "./Pages/PaymentStatusPage";
import ProjectPage from "./Pages/ProjectPage";
import SignOutExtension from "./Pages/SignOutExtension";
import { UserProfileWrapper } from "./Pages/User/UserProfileWrapper";
import LibraryPage from "./Pages/UserHomePage/LibraryPage";
import UserHomePage from "./Pages/UserHomePage/UserHomePage";
import { VerifyEmail } from "./Pages/VerifyEmail";
import ViewerPage from "./Pages/ViewerPage";

const showNewOnboarding = process.env.REACT_APP_USE_ONBOARDING_FLOW === "true";

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/signup">
            <SignUpPage />
          </Route>
          <Route exact path="/signup-with-email">
            <EmailSignUp />
          </Route>
          <Route exact path="/help">
            <HelpAndSupport />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPassword />
          </Route>
          <Route>
            <AuthProvider>
              <Switch>
                <Route exact path="/signoutExtension">
                  <SignOutExtension />
                </Route>
                <ProtectedRoute path="/verifyemail">
                  <VerifyEmail />
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/profile">
                  <UserProfileWrapper>
                    <UserProfileActions />
                  </UserProfileWrapper>
                </ProtectedRoute>
                <ProtectedRoute exact path="/welcome">
                  {showNewOnboarding ? (
                    <OnboardingContextProvider>
                      <UpdatedOnboardingFlow />
                    </OnboardingContextProvider>
                  ) : (
                    <OnboardingFlow />
                  )}
                </ProtectedRoute>
                <ProtectedRoute path="/user/profile/workspace">
                  <UserProfileWrapper>
                    <Workspace />
                  </UserProfileWrapper>
                </ProtectedRoute>
                <ProtectedRoute path="/accept-invite">
                  <WorkspaceAcceptInvitePage />
                </ProtectedRoute>
                <ProtectedRoute path="/ai-module-editor/:moduleId?/:versionId?">
                  <AssistantContextProvider>
                    <AiModuleEditor />
                  </AssistantContextProvider>
                </ProtectedRoute>
                <ProtectedRoute path="/multistep-ai-module-editor/:moduleId?/:versionId?">
                  <AssistantContextProvider>
                    <MultistepAiModuleEditor />
                  </AssistantContextProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/user">
                  <AssistantContextProvider>
                    <UserHomePage />
                  </AssistantContextProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/library">
                  <AssistantContextProvider>
                    <LibraryPage />
                  </AssistantContextProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/user/payment/:status">
                  <PaymentStatusPage />
                </ProtectedRoute>
                <ProtectedRoute exact path="/:projectId">
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <ProjectPage />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/:projectId/assistant">
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <FullScreenAssistant />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/:projectId/:articleId/insights">
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <InsightPage />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/:projectId/:articleId/viewer/#highlight-:uid"
                >
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <ViewerPage />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <ProtectedRoute exact path="/:projectId/:articleId/viewer">
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <ViewerPage />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/:projectId/:articleId/articledetail/"
                >
                  <UserPermissionProvider>
                    <AssistantContextProvider>
                      <ArticleDetail />
                    </AssistantContextProvider>
                  </UserPermissionProvider>
                </ProtectedRoute>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </AuthProvider>
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
