import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

export const InputPopup = ({
  isOpen,
  onClose,
  action,
  body: inputDescription,
  setInput,
  input,
  optional,
}) => {
  const cancelRef = useRef();
  const [inputIsNotEmpty, setInputIsNotEmpty] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setInputIsNotEmpty(true);
    } else {
      setInputIsNotEmpty(false);
    }
    setInput(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setInputIsNotEmpty(false);
  };

  const PopUpFooter = (
    <AlertDialogFooter px="0" pb="0" pt="14px">
      <Flex
        w="100%"
        justify="flex-end"
        color="#191D30"
        gap="16px"
        fontSize="12px"
      >
        <Button
          fontSize="12px"
          py="2"
          px="0"
          h="fit-content"
          bg="transparent"
          onClick={handleClose}
          border="1px solid transparent"
          color="primary"
          fontWeight="500"
        >
          Cancel
        </Button>
        <Button
          fontSize="12px"
          fontWeight="500"
          py="2"
          h="fit-content"
          bg="primary"
          onClick={action}
          border="1px solid #191D30"
          minW="149px"
          maxW="149px"
        >
          {optional && !inputIsNotEmpty
            ? "Skip & Run AI Module"
            : "Run AI Module"}
        </Button>
      </Flex>
    </AlertDialogFooter>
  );
  const PopUpBody = (
    <AlertDialogBody px="0" py="0">
      <Flex direction={"column"} gap="16px">
        <Text color="#191D30" fontSize="12px" w="90%">
          {inputDescription}
        </Text>
        <Flex align={"center"} alignContent={"center"} alignItems={"center"}>
          <ReactTextareaAutosize
            value={input}
            minRows={1}
            maxRows={40}
            onChange={(e) => handleInputChange(e)}
            style={{
              height: "40px !important",
              width: "100%",
              border: "0.5px solid #e0e0e0",
              fontSize: "12px",
              borderRadius: "4px",
              outline: "none", // Add this line to prevent border change when input is active
              padding: "8px 12px",
              lineHeight: "1.25",
            }}
          />
        </Flex>
      </Flex>
    </AlertDialogBody>
  );
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        size="sm"
      >
        <AlertDialogOverlay>
          <AlertDialogContent py="16px" px="24px">
            <button
              ref={cancelRef}
              onClick={onClose}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                right: "16px",
                top: "8px",
              }}
            >
              <span aria-hidden="true">×</span>
            </button>

            {PopUpBody}

            {PopUpFooter}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
