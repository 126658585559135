import axios from "axios";
import { updateProfile } from "firebase/auth";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { auth, storage } from "../firebase";

// export const readAsArrayBuffer = (file) => {
//   return new Promise<ArrayBuffer>((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       if (event.target && event.target.result) {
//         resolve(event.target.result as ArrayBuffer);
//       } else {
//         reject(new Error("Failed to read file"));
//       }
//     };
//     reader.onerror = (error) => reject(error);
//     reader.readAsArrayBuffer(file);
//   });
// };
export const uploadToGCS = async (url, body) => {
  try {
    const response = await axios.put(url, body);
    return response;
  } catch (error) {
    throw new Error(`GCS upload failed: ${error.message}`);
  }
};

export const generateDownloadUrl = async (filename) => {
  const url = await getDownloadURL(storageRef(storage, filename));
  return url;
};

// export const saveUserImage = async (file) => {
//   const fileName = `${uuidv4()}-${file.name}`;
//   const storageReference = storageRef(
//     storage,
//     `usersUpdatedImages/${fileName}`
//   );
//   await uploadBytes(storageReference, file);
//   const downloadUrl = await getDownloadURL(storageReference);
//   updateProfile(auth.currentUser, {
//     photoURL: downloadUrl,
//   });
//   return downloadUrl;
// };

// export const saveInsightImage = async (file) => {
//   const fileName = `${uuidv4()}-${file.name}`;
//   const storageReference = storageRef(
//     storage,
//     `manualInsightImages/${fileName}`
//   );
//   await uploadBytes(storageReference, file);
//   const downloadUrl = await getDownloadURL(storageReference);
//   return downloadUrl;
// };

export const getStorageDownloadUrl = (pathOnStorage) => {
  const storageReference = storageRef(storage, pathOnStorage);
  const downloadUrl = getDownloadURL(storageReference);
  return downloadUrl;
};

export const saveFile = async ({
  file,
  path,
  isProfilePicture = false,
  newFileName = undefined,
}: {
  /**
   * The file to be uploaded.
   */
  file: File;
  /**
   * The directory path on the storage where the file will be uploaded.
   */
  path: string;
  /**
   * Whether the file is a profile picture.
   */
  isProfilePicture?: boolean;
  /**
   * Assign a new filename to the file. Normally, the filename is generated automatically by combining the uuid and the original filename.
   */
  newFileName?: string | undefined;
}) => {
  if (!auth.currentUser) {
    throw new Error("User not signed in");
  }
  const fileName = newFileName ? newFileName : `${uuidv4()}-${file.name}`;
  if (path.endsWith("/")) {
    path = path.slice(0, -1);
  }
  const storageReference = storageRef(storage, `${path}/${fileName}`);
  await uploadBytes(storageReference, file);
  const downloadUrl = await getDownloadURL(storageReference);
  if (isProfilePicture) {
    updateProfile(auth.currentUser, {
      photoURL: downloadUrl,
    });
  }
  return downloadUrl;
};
