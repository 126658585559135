import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { IoCheckmarkOutline, IoLinkOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { projectsCollection } from "src/db/project";
import { queryKeyUserProjects } from "src/db/queryKeys";
import { useAuth } from "../../../Auth/AuthProvider";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import { doc, updateDoc } from "../../../firebase";
import useProjectPermissions from "../../../hooks/useProjectPermissions";
import { formatClipboardCopy } from "../../Utils/urlUtils";
import { InviteSection } from "./InviteSection";
import { UserList } from "./UserList";

export const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const SharingSettingModal = ({ isOpen, onClose, isCentered, size }) => {
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { isEditor } = useUserPermission();
  const queryClient = useQueryClient();
  const userPermissions = useProjectPermissions(projectId);

  const setAuthorDataInProjectDoc = async () => {
    if (!currentUser || !currentUser.email) {
      return;
    }
    await updateDoc(doc(projectsCollection, projectId), {
      authorDisplayName: currentUser.displayName || currentUser.email,
      authorPhotoURL: currentUser.photoURL || "",
    });

    queryClient.invalidateQueries({ queryKey: [queryKeyUserProjects] });
  };

  const CopyLinkButton = () => {
    const [clicked, setClicked] = useState(false);
    const [buttonText, setButtonText] = useState("Copy link");
    const [icon, setIcon] = useState(<IoLinkOutline fontSize="20px" />);

    const handleClick = () => {
      setClicked(true);
      setButtonText("Copied!");
      setIcon(<IoCheckmarkOutline fontSize="20px" />);
      // copy current URL to clipboard
      navigator.clipboard.writeText(
        formatClipboardCopy({
          url: window.location.href,
          source: "sharing",
        })
      );

      setTimeout(() => {
        setClicked(false);
        setButtonText("Copy link");
        setIcon(<IoLinkOutline fontSize="20px" />);
      }, 3000); // reset after 3 seconds
    };

    const buttonStyle = {
      transform: clicked ? "scale(0.95)" : "scale(1)",
      transition: "transform 0.3s ease",
    };

    return (
      <Button
        p="0"
        w="fit-content"
        fontSize="12px"
        color="#37BDDB"
        iconSpacing="2"
        my="4"
        leftIcon={icon}
        fontWeight="normal"
        style={buttonStyle}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
    >
      <ModalOverlay w="full" h="full" />
      <ModalContent
        bg="#191D30"
        pb="0"
        pt="4"
        position="fixed"
        top="30%"
        left="35%"
      >
        <ModalCloseButton
          boxSize="24px"
          bg="#282C3E"
          color="white"
          p="0"
          fontSize="9px"
        />
        <ModalHeader px="6" color="white">
          {isEditor() ? "Invite" : "Sharing"}
        </ModalHeader>
        {isEditor() ? (
          <InviteSection
            userPermissions={userPermissions}
            setAuthorDataInProjectDoc={setAuthorDataInProjectDoc}
          />
        ) : null}
        <UserList userList={userPermissions} projectId={projectId} />
        <Flex px="6" mt="10" flexDir="column">
          <Text color="#8C8E98" fontSize="11px">
            General access
          </Text>
          <Flex flexDir="row" justify="space-between" align="center">
            <Menu placement="bottom" gutter={0}>
              <MenuButton
                mt="2"
                px="2"
                fontWeight="normal"
                fontSize="12px"
                as={Button}
                rightIcon={<ChevronDownIcon fontSize="15px" />}
                border="1px solid #8C8E97"
                isDisabled={!isEditor()}
              >
                Only people invited to this project
              </MenuButton>
              <MenuList
                h="fit-content"
                bg="#282C3E"
                border="none"
                p="2"
                borderRadius="md"
              >
                <MenuItem
                  bg="#282C3E"
                  onClick={() => {
                    // setPermission("canView");
                  }}
                  color="white"
                  fontSize="12px"
                  _hover={{ bg: "#191D30" }}
                  borderRadius="md"
                >
                  Only people invited to this project
                </MenuItem>
              </MenuList>
            </Menu>
            <Text color="white" fontSize="12px" fontWeight="regular">
              Restricted
            </Text>
          </Flex>
        </Flex>
        <Divider mt="2" orientation="horizontal" borderColor="#282C3E" />
        <Flex px="6">
          <CopyLinkButton />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SharingSettingModal;
