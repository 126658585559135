import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BiInfoCircle } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";

export const ArticleTypeBttn = ({
  text,
  isSelected,
  updateSelectedTypedArr,
  tooltip,
  typeCount,
  color,
}) => {
  const textColor = isSelected ? "white" : color ? color : "primary";
  return (
    <Flex
      cursor="pointer"
      border="1px solid"
      borderColor={isSelected ? "transparent" : "#dddee0"}
      borderRadius="3xl"
      w="fit-content"
      px="4"
      py="2"
      h="24px"
      align="center"
      userSelect="none"
      bg={isSelected ? "#59808D" : "transparent"}
      gap={1}
      position="relative"
      justify="center"
      transition={"all 0.3s ease-in-out"}
      onClick={() => {
        updateSelectedTypedArr();
      }}
    >
      <AiOutlineCheck
        color="white"
        fontSize="10px"
        style={{
          display: isSelected ? "block" : "none",
        }}
      />
      <HStack gap="3px" align="center">
        <Text
          color={textColor}
          textAlign="center"
          fontSize="12px"
          noOfLines={1}
          textTransform="capitalize"
        >
          {text}
        </Text>
        <Text
          color={isSelected ? "white" : "black"}
          textAlign="center"
          fontSize="12px"
          noOfLines={1}
          fontWeight="900"
        >
          {typeCount?.toLocaleString()}
        </Text>
      </HStack>

      {/* show info icon if there is a tooltip */}
      {tooltip ? (
        <Tooltip label={tooltip} placement="top">
          <Box>
            <BiInfoCircle color="gray" fontSize="14px" />
          </Box>
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export const MoreOptionBttn = ({ hasSelectedItems }) => {
  const color = hasSelectedItems ? "white" : "#191D30";
  return (
    <Flex
      border="1px solid #dddee0"
      borderRadius="3xl"
      px="4"
      fontStyle="italic"
      py="2"
      h="24px"
      align="center"
      userSelect="none"
      gap={1}
      minW="60px"
      justify="center"
      fontWeight="normal"
      color={color}
    >
      <Text textAlign="center" fontSize="12px">
        More
      </Text>
      <FiPlus color={color} fontSize="10px" />
    </Flex>
  );
};

export const AllArticleTypeList = ({
  sortedArticleType,
  selectedArticleTypes,
  routerHistory,
  allUnfilteredArticles,
  updateSelectedTypedArr,
}) => {
  return (
    <Flex position="absolute" zIndex={1200} bg="transparent">
      <Flex
        p="4"
        gap={2}
        h="fit-content"
        flexDir="column"
        bg="white"
        w="100%"
        boxShadow="
        0px 4px 4px 0px #0000000F
      "
        borderRadius="4px"
      >
        {sortedArticleType.map((article) => {
          return (
            <ArticleTypeBttn
              color="#757783"
              text={article.type}
              isSelected={selectedArticleTypes.includes(article.id)}
              updateSelectedTypedArr={() => {
                updateSelectedTypedArr(
                  article.id,
                  selectedArticleTypes,
                  routerHistory
                );
              }}
              typeCount={
                allUnfilteredArticles.filter(
                  (articleType) => articleType.type === article.id
                ).length
              }
              key={article.id}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export const MoreMenuBttn = ({
  visibleItems,
  sortedArticleType,
  hiddenItems,
  selectedArticleTypes,
  routerHistory,
  allUnfilteredArticles,
  updateSelectedTypedArr,
}) => {
  const hasMatchichingId = selectedArticleTypes.some((types) => {
    return hiddenItems.some((item) => {
      return types === item.id;
    });
  });
  return (
    <Menu placement="end">
      <MenuButton
        display={
          visibleItems.length < sortedArticleType.length ? "block" : "none"
        }
        as={Button}
        h="fit-content"
        p="0"
        transform="none"
        borderRadius="3xl"
        bg={hasMatchichingId ? "#59808D" : "transparent"}
      >
        <MoreOptionBttn hasSelectedItems={hasMatchichingId} />
      </MenuButton>
      <MenuList bg="transparent" border="none">
        <AllArticleTypeList
          sortedArticleType={hiddenItems}
          selectedArticleTypes={selectedArticleTypes}
          routerHistory={routerHistory}
          allUnfilteredArticles={allUnfilteredArticles}
          updateSelectedTypedArr={updateSelectedTypedArr}
        />
      </MenuList>
    </Menu>
  );
};
