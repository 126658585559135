import { Icon } from "@chakra-ui/react";
import React from "react";

const CalendarIcon = (props) => {
  return (
    <Icon viewBox="0 0 10 12" fill="black" fillOpacity="0.54" {...props}>
      <path d="M3.5 5.5H4.5V6.5H3.5V5.5ZM10.5 3V10C10.5 10.55 10.05 11 9.5 11H2.5C1.945 11 1.5 10.55 1.5 10L1.505 3C1.505 2.45 1.945 2 2.5 2H3V1H4V2H8V1H9V2H9.5C10.05 2 10.5 2.45 10.5 3ZM2.5 4H9.5V3H2.5V4ZM9.5 10V5H2.5V10H9.5ZM7.5 6.5H8.5V5.5H7.5V6.5ZM5.5 6.5H6.5V5.5H5.5V6.5Z" />
    </Icon>
  );
};

export default CalendarIcon;
