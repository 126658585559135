import React from "react";
import { Icon } from "@chakra-ui/react";

const QuoteIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.66669 7.66667H12.3334V5H9.66669V7.66667ZM3.66669 7.66667H6.33335V5H3.66669V7.66667ZM10.4334 11.3333L11.7667 8.66667H8.66669V4H13.3334V8.8L12.0667 11.3333H10.4334ZM4.43335 11.3333L5.76669 8.66667H2.66669V4H7.33335V8.8L6.06669 11.3333H4.43335Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default QuoteIconComponent;
