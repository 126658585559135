import { Icon } from "@chakra-ui/react";
import React from "react";

const SmartImageComponentIcon = (props) => {
  return (
    <Icon width="20" height="21" viewBox="0 0 19 21" fill="none" {...props}>
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16ZM3 14H15L11.25 9L8.25 13L6 10L3 14Z"
        fill="#E8EAED"
        fillOpacity="1"
      />
    </Icon>
  );
};

export default SmartImageComponentIcon;
