import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import InsightCard from "../Elements/SnippetCards/ComfortCard";
import { Draggable } from "react-beautiful-dnd";

const SavedContentCard = ({
  data,
  projectId,
  viewMode,
  index,
  // isOrganizerOpen,
}) => {
  return (
    <Draggable isDragDisabled={false} draggableId={data?.uid} index={index}>
      {(provided, snapshot) => (
        <>
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            className="draggable-card"
          >
            <InsightCard
              insight={data}
              projectId={projectId}
              articleText={data?.articleExtractedText}
              articleTitle={data.articleTitle}
              title={data?.title}
              viewMode={viewMode}
              isInsightPage={false}
            />
          </Box>
        </>
      )}
    </Draggable>
  );
};

export default memo(SavedContentCard);
