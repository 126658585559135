import { ContentTypeId } from "./AddContentModalData";

interface Template {
  name: string;
  namePlural: string;
  enableFillTemplate: boolean;
  smartTemplates?: boolean;
  showTemplateInsights: boolean;
  showTemplate: boolean;
}

interface Assistant {
  showCustomPrompts: boolean;
  isOpen: boolean;
  name: string;
  nameCasual: string;
  /** Remove URLs to the original articles from citations in the assistant
   * response, and when copying responses */
  removeReferenceLinks: boolean;
  headerTemplateProminent: boolean;
  openingMessage: (assistantName: string) => string;
}

interface AiModules {
  hideLibrary: boolean;
}

interface HomePage {
  showExtensionMissingNotice: boolean;
  noProjectText: string;
}

interface Brief {
  heading: string;
  body: string;
}

interface InfoTooltips {
  sentimentCenter: string;
  controlCenter: string;
  fillTemplate: string;
  templates: string;
  history: string;
  customAI: string;
  briefs: string;
  aiModules: string;
  fillBrief: string;
  sentiments: string;
}

interface SavedInsights {
  linkToVideo?: string;
}

interface Meta {
  tabTitle: string;
  description: string;
  icon: string;
}

interface AccountMenu {
  hideProductTour: boolean;
  hideChromeExtensionLink: boolean;
}

interface DocumentMenu {
  hideOpenInBrowser: boolean;
  hideMarkAsLink: boolean;
}

export interface ClientCustomization {
  name: string;
  logo: string;
  appleSignIn: boolean;
  template: Template;
  assistant: Assistant;
  aiModules: AiModules;
  disableProductTour: boolean;
  disableOnboarding: boolean;
  homePage: HomePage;
  brief: Brief;
  infoTooltips: InfoTooltips;
  savedInsights: SavedInsights;
  hasSourceColumn: boolean;
  contentTypeIds: ContentTypeId[];
  skipSampleProject: boolean;
  swapTypeForLength: boolean;
  meta: Meta;
  accountMenu: AccountMenu;
  documentMenu: DocumentMenu;
  articleDetailDefaultTab?: string;
}

type ClientCustomizationData = {
  default: ClientCustomization;
  [key: string]:
    | Partial<ClientCustomization>
    | {
        template?: Partial<Template>;
        assistant?: Partial<Assistant>;
        aiModules?: Partial<AiModules>;
        homePage?: Partial<HomePage>;
        brief?: Partial<Brief>;
        infoTooltips?: Partial<InfoTooltips>;
        savedInsights?: Partial<SavedInsights>;
        meta?: Partial<Meta>;
        accountMenu?: Partial<AccountMenu>;
        documentMenu?: Partial<DocumentMenu>;
      };
};

export const CLIENT_CUSTOMIZATION_DATA: ClientCustomizationData = {
  default: {
    name: "MosaiQ",
    logo: `${process.env.PUBLIC_URL}/mosaiq-logo-new.png`,
    appleSignIn: process.env.REACT_APP_APPLE_SIGN_IN === "enable",
    template: {
      name: "Template",
      namePlural: "Templates",
      enableFillTemplate: false,
      smartTemplates: true,
      showTemplateInsights: false,
      showTemplate: true,
    },
    assistant: {
      showCustomPrompts: false,
      isOpen: false,
      name: "Symba",
      nameCasual: "Symba",
      removeReferenceLinks: false,
      headerTemplateProminent: false,
      openingMessage: (assistantName) => {
        return `**Welcome to ${assistantName}, Your AI Assistant!**

${assistantName} uses the information you add to your projects to answer questions and find solutions.

**Ask**: Select relevant documents and ask questions, to get the answers you need right away. For example, "What are the main ideas in these documents?"

**Save**: Highlight and save important insights.

**Organize**: Create templates to structure your insights.

Start exploring what ${assistantName} can do for you today!`;
      },
    },
    aiModules: {
      hideLibrary: false,
    },
    disableProductTour: false,
    disableOnboarding: false,
    homePage: {
      showExtensionMissingNotice: true,
      noProjectText: "Create your first project!",
    },
    brief: {
      heading: "Welcome to your brief.",
      body: "Add your project questions so that IntelQ's AI can automatically answer them with insights from your documents. You can also manually add insights!",
    },
    infoTooltips: {
      sentimentCenter: "",
      controlCenter: "",
      fillTemplate: "",
      templates: "",
      history: "",
      customAI: "",
      briefs: "",
      aiModules: "",
      fillBrief: "",
      sentiments: "",
    },
    savedInsights: {},
    hasSourceColumn: true,
    contentTypeIds: ["file", "text", "smartImage", "audio", "link"],
    skipSampleProject: false,
    swapTypeForLength: false,
    meta: {
      tabTitle: "MosaiQ - Knowledge Assistant",
      description:
        "Next Generation, AI powered Document Curation and Research Labs",
      icon: `${process.env.PUBLIC_URL}/favicon.ico`,
    },
    accountMenu: {
      hideProductTour: false,
      hideChromeExtensionLink: false,
    },
    documentMenu: {
      hideOpenInBrowser: false,
      hideMarkAsLink: false,
    },
    articleDetailDefaultTab: "aiInsight",
  },
  "p001-intelq": {
    name: "IntelQ",
    logo: `${process.env.PUBLIC_URL}/intelq-logo.png`,
    template: {
      name: "Brief",
      namePlural: "Briefs",
      enableFillTemplate: true,
      showTemplateInsights: true,
    },
    assistant: {
      isOpen: true,
      name: "IntelQ AI Assistant",
      nameCasual: "IntelQ assistant",
      headerTemplateProminent: true,
      openingMessage: (assistantName) => {
        return `**Welcome to your ${assistantName}!**

It uses the information you add to your projects to answer questions and help you find solutions.

**Ask**: Select relevant documents and ask questions, to get the answers you need right away. For example, "What are the main ideas in these documents?"

**Save**: Highlight and save important insights.

**Organize**: Create Briefs to structure your insights.

Start asking questions to explore what your assistant can do for you!`;
      },
    },
    aiModules: {
      hideLibrary: true,
    },
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
      noProjectText: "Create your first project!",
    },
    brief: {
      heading: "Welcome to your brief.",
      body: "Add your project questions so that IntelQ's AI can automatically answer them with insights from your documents. You can also manually add insights!",
    },
    infoTooltips: {
      aiModules: "Run IntelQ's custom analysis on selected documents",
      sentimentCenter:
        "Assess the openness and  level of confidence of your sources to better contextualize their insights.",
      controlCenter:
        "Assess the sources and insights used to answer your project questions",
      fillTemplate:
        "Automatically classify information from selected documents to populate the sections you created",
      fillBrief:
        "Use AI to answer your project questions with insights from your selected documents",
      briefs:
        "Answer your project questions with AI and manually-generated insights",
      templates:
        "Open, add sections / questions, and automatically classify information from documents in those sections",
      history: "View past conversations and pick up where you left off",
      customAI:
        "Open, select and run custom analysis modules on your documents",
    },
    contentTypeIds: [
      "intelqVerbatim",
      "intelqQna",
      "file",
      "text",
      "smartImage",
      "link",
    ],
    meta: {
      tabTitle: "IntelQ",
      description: "IntelQ",
      icon: `${process.env.PUBLIC_URL}/intelq.ico`,
    },
    accountMenu: {
      hideProductTour: true,
      hideChromeExtensionLink: true,
    },
  },
  "p002-listn": {
    name: "Rekap",
    logo: `${process.env.PUBLIC_URL}/rekap-logo.png`,
    template: {
      showTemplate: false,
    },
    assistant: {
      name: "Zazu",
      nameCasual: "Zazu",
      isOpen: true,
      removeReferenceLinks: true,
      openingMessage: (assistantName) => {
        return `**Welcome to ${assistantName}, Your AI Assistant!**

${assistantName} utilizes the audio content (transcripts) you upload to enhance your understanding and manage your audio projects effectively.

**Ask**: Upload your audio files and ask specific questions to receive transcriptions and key information on demand. For instance, "What are the main topics discussed in this meeting?"

**Save**: Highlight and save crucial segments from your conversations.

Start exploring what ${assistantName} can do for you today!`;
      },
    },
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
    },
    contentTypeIds: ["audio"],
    hasSourceColumn: false,
    skipSampleProject: true,
    swapTypeForLength: true,
    meta: {
      tabTitle: "REKAP - Meeting intelligence",
      description: "REKAP - Meeting intelligence",
      icon: `${process.env.PUBLIC_URL}/rekap.ico`,
    },
    accountMenu: {
      hideProductTour: true,
      hideChromeExtensionLink: true,
    },
    documentMenu: {
      hideOpenInBrowser: true,
      hideMarkAsLink: true,
    },
  },
  "p003-sdv": {
    name: "SwissDataVision",
    logo: `${process.env.PUBLIC_URL}/sdv-logo.png`,
    disableProductTour: true,
    disableOnboarding: true,
    homePage: {
      showExtensionMissingNotice: false,
    },
    meta: {
      tabTitle: "SwissDataVision",
      description: "SwissDataVision",
      icon: `${process.env.PUBLIC_URL}/sdv.ico`,
    },
  },
};

export const DOMAIN_PROJECT_MAP: { [key: string]: string } = {
  "swissdatavision.web.app": "p003-sdv",
};
