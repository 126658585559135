import { Flex, Text } from "@chakra-ui/react";
import { AutoCompleteItem } from "@choc-ui/chakra-autocomplete";
import React from "react";
import { useHistory } from "react-router-dom";
import ImageWithIconFallback from "../Elements/ImageWithIconFallback";
import { SEARCH_LIMIT } from "./SearchInput";

export const ArticleSearchList = ({ results, articleType, setQuery }) => {
  const router = useHistory();

  const currentArticles = results?.articles?.filter(
    (article) => article.type === articleType
  );

  if (!currentArticles.length) {
    return null;
  }

  return (
    <>
      {currentArticles.slice(0, SEARCH_LIMIT).map((article) => {
        return (
          <AutoCompleteItem
            key={article.uid}
            value={article.title}
            label={article.title}
            onClick={() => {
              setQuery("");
              router.push(
                `/${article.projectId}?sortBy=type&poppedArticle=${article.uid}`
              );
            }}
            _focus={{
              bg: "white",
            }}
            mt={1}
          >
            <Flex direction="row" align="center" w="100%" gap={4} ml={1}>
              <ImageWithIconFallback imgSrc={article.icon} />
              <Flex direction="column" gap={1}>
                <Text
                  noOfLines={{ base: 2, lg: 1 }}
                  fontWeight="semibold"
                  fontSize="sm"
                >
                  {article.title}
                </Text>
                <Text maxW="full" fontSize="xs" fontStyle="italic">
                  {article.projectName}
                </Text>
              </Flex>
            </Flex>
          </AutoCompleteItem>
        );
      })}
    </>
  );
};
