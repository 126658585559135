import React, { createContext, useContext, useState } from "react";

const OnboardingFlowContext = createContext();

export const OnboardingContextProvider = ({ children }) => {
  const [allowForwardNav, setAllowForwardNav] = useState(true);

  return (
    <OnboardingFlowContext.Provider
      value={{
        allowForwardNav,
        setAllowForwardNav,
      }}
    >
      {children}
    </OnboardingFlowContext.Provider>
  );
};

export const useOnboardingFlowContext = () => {
  const context = useContext(OnboardingFlowContext);
  if (context === undefined) {
    throw new Error("context must be used within a AssistantProvider");
  }
  return context;
};

export default OnboardingFlowContext;
