import { Box, Center, Icon, Tooltip, VStack } from "@chakra-ui/react";
import React from "react";
import { useAssistantContext } from "../../Context/AssistantContext";
import AssistantIcon from "../Icons/AssistantIcon";

export function OpenAssistantFloatingButton() {
  const { assistantIsOpen, setAssistantIsOpen } = useAssistantContext();
  if (assistantIsOpen) {
    return null;
  }
  return (
    <Box
      style={{
        position: "fixed",
        bottom: "45%",
        right: "0",
        transition: "right 0.3s",
      }}
    >
      <VStack
        pl={1}
        py={6}
        roundedLeft="lg"
        bg="primary"
        gap={3}
        height={20}
        justify="center"
        id="assistantBttn"
      >
        <Tooltip label="Assistant" placement="left" openDelay={200}>
          <Center>
            <Icon
              pt={2}
              pl={2}
              as={AssistantIcon}
              onClick={() => {
                setAssistantIsOpen(true);
              }}
              cursor="pointer"
              color="white"
              boxSize={10}
            />
          </Center>
        </Tooltip>
      </VStack>
    </Box>
  );
}
