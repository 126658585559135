import { Flex, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineBell,
  AiOutlineUser,
} from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { BsCashStack } from "react-icons/bs";
import { PiCirclesThreeBold } from "react-icons/pi";
import { TbGridDots } from "react-icons/tb";
import { Link as ReactRouterLink } from "react-router-dom";
import { UserPageMenuBttn } from "../UserPageMenuBttn";

export const MainProfileNav = () => {
  const PROFILE_NAV_ITEMS = [
    {
      name: "Personal Info",
      icon: AiOutlineUser,
      id: "profile",
      isComingSoon: false,
    },
    {
      name: "Workspace",
      icon: PiCirclesThreeBold,
      id: "workspace",
      isComingSoon: false,
    },
    // {
    //   name: "Plans",
    //   icon: AiOutlineCreditCard,
    //   id: "plans",
    //   isComingSoon: false,
    // },
    {
      name: "Notification & Privacy",
      icon: AiOutlineBell,
      id: "notification",
      isComingSoon: true,
    },
    {
      name: "Integrations",
      icon: TbGridDots,
      id: "integrations",
      isComingSoon: true,
    },
    {
      name: "Activity log",
      icon: BiHistory,
      id: "activityLog",
      isComingSoon: true,
    },
    {
      name: "Billing",
      icon: BsCashStack,
      id: "billing",
      isComingSoon: true,
    },
  ];
  return (
    <Flex
      h="100vh"
      top="0%"
      gap={12}
      pl="30px"
      pr="12px"
      py={8}
      bg="white"
      flexDir="column"
      position="relative"
    >
      <Flex align="center" gap={4}>
        <Link as={ReactRouterLink} to="/user">
          <AiOutlineArrowLeft fontSize="18px" />
        </Link>
        <Heading fontSize="16px" fontWeight="600">
          Account
        </Heading>
      </Flex>
      <Flex flexDir="column" gap="2">
        {PROFILE_NAV_ITEMS.map((item) => {
          return (
            <UserPageMenuBttn
              key={item.id}
              buttonName={item.name}
              buttonId={item.id}
              BttnIcon={item.icon}
              isComingSoon={item.isComingSoon}
            />
          );
        })}
      </Flex>

      <Flex
        position="fixed"
        bottom="0"
        left="0"
        w="100%"
        px="30px"
        pb={5}
        flexDir="column"
      >
        <Text fontSize="2xs" color="gray.500">
          v{process.env.REACT_APP_VERSION}
        </Text>
      </Flex>
    </Flex>
  );
};
