import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAssistantContext } from "../../Context/AssistantContext";
import { getAssistantConversations } from "../../db/assistant";
import { getConversationTitle } from "../Utils/assistant";
import { shortenText } from "../Utils/shortenText";

const AssistantHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const { setConversationId, setSubscribeToConversationDoc, setViewMode } =
    useAssistantContext();
  const { projectId } = useParams();

  useEffect(() => {
    let unsubscribe = () => {};
    if (conversations.length < 1) {
      unsubscribe = getAssistantConversations(projectId, (conversationData) => {
        if (conversationData) {
          setConversations(conversationData);
          setIsLoading(false);
        }
      });
    }
    return () => {
      console.log("Unsubscribing from project generations");
      unsubscribe();
    };
  }, [projectId]);

  return (
    <Box mt={2} maxH="85vh" overflow="auto" p={2}>
      {!isLoading &&
        conversations.map((conversation) => (
          <Box
            border="1px solid"
            borderColor="rgba(0, 0, 0, 0.3)"
            p={4}
            rounded="md"
            mb="3"
            cursor="pointer"
            onClick={() => {
              setConversationId(conversation.uid);
              setSubscribeToConversationDoc(true);
              setViewMode("CHAT");
            }}
            key={conversation.uid}
          >
            <Text fontSize="sm">{getConversationTitle(conversation)}</Text>
            <Text fontSize="xs" mt="2" color="rgba(0, 0, 0, 0.54)">
              {shortenText(
                conversation?.messages[0]?.content || "Empty Conversation",
                150
              )}
            </Text>
          </Box>
        ))}
      {isLoading && (
        <Box my={16}>
          <Center>
            <Spinner size="xl" />
          </Center>
        </Box>
      )}
    </Box>
  );
};

export default AssistantHistory;
