import React from "react";
import { Icon } from "@chakra-ui/react";

const MenuIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_3239_11613)">
        <path
          d="M17.9309 12.1383C19.0815 12.1383 20.0142 11.2056 20.0142 10.055C20.0142 8.90442 19.0815 7.97168 17.9309 7.97168C16.7803 7.97168 15.8475 8.90442 15.8475 10.055C15.8475 11.2056 16.7803 12.1383 17.9309 12.1383Z"
          fill="#191D30"
        />
        <path
          d="M9.99996 12.0833C11.1506 12.0833 12.0833 11.1506 12.0833 9.99996C12.0833 8.84937 11.1506 7.91663 9.99996 7.91663C8.84937 7.91663 7.91663 8.84937 7.91663 9.99996C7.91663 11.1506 8.84937 12.0833 9.99996 12.0833Z"
          fill="#191D30"
        />
        <path
          d="M2.08333 12.0833C3.23393 12.0833 4.16667 11.1506 4.16667 9.99996C4.16667 8.84937 3.23393 7.91663 2.08333 7.91663C0.93274 7.91663 0 8.84937 0 9.99996C0 11.1506 0.93274 12.0833 2.08333 12.0833Z"
          fill="#191D30"
        />
      </g>
      <defs>
        <clipPath id="clip0_3239_11613">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default MenuIconComponent;
