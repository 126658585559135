import { onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ProjectPermissionDoc } from "shared/projectTypes";
import { getProjectPermissionsCollection } from "src/db/project";

const useProjectPermissions = (projectId) => {
  const [projectPermissions, setProjectPermissions] = useState<
    ProjectPermissionDoc[]
  >([]);

  useEffect(() => {
    if (!projectId) return;

    const q = query(getProjectPermissionsCollection(projectId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log(`Updating projectPermissions for ${projectId}`);
      const projectPermissions: ProjectPermissionDoc[] = [];
      querySnapshot.forEach((doc) => {
        projectPermissions.push({ ...doc.data(), uid: doc.id });
      });
      console.log(projectPermissions);
      setProjectPermissions(projectPermissions);
    });

    return () => {
      unsubscribe();
    };
  }, [projectId]);

  return projectPermissions;
};

export default useProjectPermissions;
