export const extensionPresence = async () => {
  const urlParts = process.env.REACT_APP_CHROME_WEB_STORE_URL.split("/");
  const extensionId = urlParts[urlParts.length - 1];
  const url = `chrome-extension://${extensionId}/extensionLogo.png`;
  try {
    const res = await fetch(url);
    if (!res.ok) {
      // Request failed, treat as if the extension is not present
      return false;
    }
    return true;
  } catch (error) {
    // Network error or request couldn't be made at all, treat as if the extension is not present
    return false;
  }
};
