import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Icon,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import { useContentContext } from "../../Context/ContentContext";
import {
  ContentDetailData,
  getAddContentDetails,
} from "../../data/AddContentModalData";

const ContentComponent = () => {
  const [activeContent, setActiveContent] = useState<ContentDetailData | null>(
    null
  );
  const { setContent } = useContentContext();
  const { isPowerUser } = useAuth();
  const CONTENT_DETAILS = getAddContentDetails({ isPowerUser });
  return (
    <Stack
      border="1px solid"
      rounded="md"
      borderColor="rgba(217, 217, 217, 0.2)"
      divider={<Divider style={{ marginTop: "0px", marginBottom: "0px" }} />}
    >
      {CONTENT_DETAILS.map((content) => (
        <Box
          onMouseEnter={() => setActiveContent(content)}
          onMouseLeave={() => setActiveContent(null)}
          background={
            activeContent?.id === content.id ? "rgba(217, 217, 217, 0.2)" : ""
          }
          cursor="pointer"
          key={content.id}
          onClick={() => setContent(content)}
        >
          <Flex
            py={3}
            px={4}
            flex="1"
            gap="4"
            alignItems="center"
            flexWrap="wrap"
          >
            <Icon boxSize={6} as={content.icon} />
            <Box>
              <Text fontSize="sm">{content.title}</Text>
              <Flex align="center" gap={1}>
                <Text fontSize="xs" color="rgba(217, 217, 217, 0.7)">
                  {content.description}
                </Text>
                {content.toolTip && (
                  <Tooltip
                    fontSize="12px"
                    label={content.toolTip}
                    placement="top"
                    borderRadius="sm"
                    lineHeight="1.5"
                    p="2"
                  >
                    <Icon as={InfoIcon} fontSize="10px" />
                  </Tooltip>
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      ))}
    </Stack>
  );
};

export default ContentComponent;
