import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaGears } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi";
import { useHistory, useLocation } from "react-router-dom";

const ToggleBttn = ({ title, id, icon }) => {
  const Icon = icon;
  const history = useHistory();
  const location = useLocation(); // Use useLocation to get the current location
  const searchParams = location.search;
  const currentSearchParams = new URLSearchParams(searchParams);
  const workspaceTab = currentSearchParams.get("workspacetab");

  const updateSearchParams = () => {
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set("workspacetab", id);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  return (
    <Button
      fontSize="12px"
      bg={workspaceTab === id ? "primary" : "transparent"}
      w="fit-content"
      height="fit-content"
      p="2"
      onClick={updateSearchParams}
      leftIcon={<Icon fontSize="16px" />}
      color={workspaceTab === id ? "white" : "primary"}
    >
      {title}
    </Button>
  );
};

export const WorkSpaceMenuToggle = () => {
  const history = useHistory();
  const location = useLocation(); // Use useLocation to get the current location
  const searchParams = location.search;

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(searchParams);
    if (!currentSearchParams.get("workspacetab")) {
      currentSearchParams.set("workspacetab", "overview");
      history.push({
        search: currentSearchParams.toString(),
      });
    }
  }, [searchParams]);
  return (
    <Flex gap="16px" pb="40px">
      <ToggleBttn title="Overview" id="overview" icon={FaGears} />
      <ToggleBttn title="Members" id="members" icon={HiUsers} />
    </Flex>
  );
};
