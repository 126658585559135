import { Icon } from "@chakra-ui/react";
import React from "react";

export const LibraryIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="black" {...props}>
      <path
        d="M2.66667 9.33333H5.33333V6.66667H2.66667V9.33333ZM6.66667 9.33333H9.33333V6.66667H6.66667V9.33333ZM2.66667 5.33333H5.33333V2.66667H2.66667V5.33333ZM6.66667 5.33333H9.33333V2.66667H6.66667V5.33333ZM1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM1.33333 10.6667H10.6667V1.33333H1.33333V10.6667Z"
        fill="black"
        fillOpacity="0.87"
      />
    </Icon>
  );
};
