import React from "react";
import { Icon } from "@chakra-ui/react";

const ExtensionIconComponent = (props) => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M8.8 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15.2C3.8 15.2 4.5 14.9458 5.1 14.4375C5.7 13.9292 6 13.2833 6 12.5C6 11.7167 5.7 11.0708 5.1 10.5625C4.5 10.0542 3.8 9.8 3 9.8V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H9C9 3.3 9.24167 2.70833 9.725 2.225C10.2083 1.74167 10.8 1.5 11.5 1.5C12.2 1.5 12.7917 1.74167 13.275 2.225C13.7583 2.70833 14 3.3 14 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V10C20.7 10 21.2917 10.2417 21.775 10.725C22.2583 11.2083 22.5 11.8 22.5 12.5C22.5 13.2 22.2583 13.7917 21.775 14.275C21.2917 14.7583 20.7 15 20 15V19C20 19.55 19.8042 20.0208 19.4125 20.4125C19.0208 20.8042 18.55 21 18 21H14.2C14.2 20.1667 13.9375 19.4583 13.4125 18.875C12.8875 18.2917 12.25 18 11.5 18C10.75 18 10.1125 18.2917 9.5875 18.875C9.0625 19.4583 8.8 20.1667 8.8 21ZM5 19H7.125C7.525 17.9 8.16667 17.125 9.05 16.675C9.93333 16.225 10.75 16 11.5 16C12.25 16 13.0667 16.225 13.95 16.675C14.8333 17.125 15.475 17.9 15.875 19H18V13H20C20.1333 13 20.25 12.95 20.35 12.85C20.45 12.75 20.5 12.6333 20.5 12.5C20.5 12.3667 20.45 12.25 20.35 12.15C20.25 12.05 20.1333 12 20 12H18V6H12V4C12 3.86667 11.95 3.75 11.85 3.65C11.75 3.55 11.6333 3.5 11.5 3.5C11.3667 3.5 11.25 3.55 11.15 3.65C11.05 3.75 11 3.86667 11 4V6H5V8.2C5.9 8.53333 6.625 9.09167 7.175 9.875C7.725 10.6583 8 11.5333 8 12.5C8 13.45 7.725 14.3167 7.175 15.1C6.625 15.8833 5.9 16.45 5 16.8V19Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default ExtensionIconComponent;

