import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useZoomContext } from "../../Context/ZoomContext";
import { MENU_DATA, MENU_TYPE } from "../../data/MenuData";
import ZoomInIconComponent from "../Icons/ZoomInIconComponent";
import ZoomOutIconComponent from "../Icons/ZoomOutIconComponent";
import { WorkSpaceMenuItems } from "../Workspace/Menu";

const MAX_ZOOM = 1.2; // 150%
const MIN_ZOOM = 0.8; // 80%

const CustomMenuItem = ({ item, handleMenuOnClick }) => {
  const { isPowerUser } = useAuth();

  const handleClick = () => {
    handleMenuOnClick(item.handler, item.type);
  };

  if (item.onlyForPowerUser && !isPowerUser) {
    return null;
  }

  const Item = (
    <MenuItem my={1} bg="white" onClick={handleClick}>
      <Flex color="primary" align="center" gap={2}>
        <Icon as={item.icon} fontSize="larger" />
        <Text fontWeight="medium" fontSize="xs">
          {item.title}
        </Text>
      </Flex>
    </MenuItem>
  );

  if (item.hasLink) {
    return (
      <Link as={item.router} _hover={{ textDecoration: "none" }} {...item.rest}>
        {Item}
      </Link>
    );
  }

  return Item;
};

const AccountMenu = () => {
  const { logout, currentUser, isPowerUser } = useAuth();
  const history = useHistory();
  const { updateZoom, zoomLevel } = useZoomContext();

  const handleMenuOnclick = (handler, type) => {
    switch (type) {
      case MENU_TYPE.PRODUCT_TOUR:
        handler(currentUser);
        break;
      case MENU_TYPE.LOGOUT:
        handler(logout, history);
        break;
      case MENU_TYPE.DARK_MODE:
        handler();
        break;
      default:
        break;
    }
  };

  const CurrentUserProfile = () => {
    return (
      <Flex
        align="center"
        gap="9px"
        px="12px"
        py="16px"
        borderRadius="4px"
        mb="8px"
      >
        <Avatar
          src={currentUser.photoURL ?? ""}
          boxSize="36px"
          name={currentUser.displayName ?? ""}
          borderRadius="200px"
        />
        <Flex flexDir="column" w="100%" justify="space-between">
          <Heading color="primary" fontSize="12px">
            {currentUser.displayName}
          </Heading>
          <Heading color="primary" opacity="0.56" fontSize="12px">
            {currentUser.email}
          </Heading>
        </Flex>
        <Link href="/user/profile">
          <IoSettingsOutline color="black" fontSize="12px" />
        </Link>
      </Flex>
    );
  };

  return (
    <Menu direction="ltr" placement="auto-start">
      <MenuButton cursor="pointer" my={4}>
        <Flex align="center" gap={1}>
          <Avatar
            size="sm"
            name={currentUser.displayName ?? ""}
            src={currentUser.photoURL ?? ""}
          />
          <BsArrowDownSquareFill
            fontSize="11px"
            style={{ borderRadius: "4px" }}
            cursor="pointer"
          />
        </Flex>
      </MenuButton>
      <MenuList shadow="md" w="375px" px={2} py={4}>
        <CurrentUserProfile />
        <Flex flexDir="column" pb="19px">
          <Heading
            pb="13px"
            opacity="0.56"
            fontWeight="bold"
            fontSize="12px !important"
            pl="4"
            color="#191D30"
          >
            Workspaces
          </Heading>
          <WorkSpaceMenuItems />
        </Flex>
        <Heading
          pl="4"
          opacity="0.56"
          fontWeight="bold"
          fontSize="12px !important"
        >
          Product
        </Heading>
        {MENU_DATA.map((item) => {
          if (item.onlyForPowerUser && !isPowerUser) {
            return null;
          }
          if (item.hidden) {
            return null;
          }
          return (
            <CustomMenuItem
              key={item.id}
              item={item}
              handleMenuOnClick={() =>
                handleMenuOnclick(item.handler, item.type)
              }
            />
          );
        })}
        <Box px={4} gap={4} mt={4}>
          <Divider />
          <Flex gap={2} mt={4}>
            <Button
              variant="outline"
              leftIcon={<Icon as={ZoomOutIconComponent} />}
              size="xs"
              onClick={() => {
                updateZoom(zoomLevel - 0.1);
              }}
              isDisabled={zoomLevel <= MIN_ZOOM}
            >
              <Text fontSize="x-small">Zoom out</Text>
            </Button>
            <Button
              variant="outline"
              leftIcon={<Icon as={ZoomInIconComponent} />}
              size="xs"
              onClick={() => {
                updateZoom(zoomLevel + 0.1);
              }}
              isDisabled={zoomLevel >= MAX_ZOOM}
            >
              <Text fontSize="x-small">Zoom in</Text>
            </Button>
          </Flex>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default AccountMenu;
