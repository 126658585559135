import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

export function AccordionTitle({ title, icon }) {
  return (
    <Flex gap="8px" alignItems={"center"}>
      <Icon as={icon} fontSize={"12px"} />
      <Text fontSize="12px" fontWeight="medium" opacity={0.54} noOfLines={1}>
        {title}
      </Text>
    </Flex>
  );
}
