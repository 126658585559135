export const formatClipboardCopy = ({ url, source }) => {
  return `${url}/?source=${source}`;
};

export function addUrlParam(path, key, value) {
  // Extract the existing parameters and the base path
  const [basePath, queryString] = path.split("?");
  const params = new URLSearchParams(queryString);

  // Set the parameter (this will add or replace the parameter)
  params.set(key, value);

  // Return the updated path
  return `${basePath}?${params.toString()}`;
}

export const isFirebaseStoragePDF = (url) => {
  return (
    url &&
    (url.includes("firebasestorage.googleapis.com") ||
      url.includes("storage.googleapis.com")) &&
    url.includes(".pdf")
  );
};

export const isEmulatorUrl = (url) => {
  return url.includes("localhost") || url.startsWith("http://127.0.0.1");
};
