import { Flex, HStack, Icon, Text } from "@chakra-ui/react";
import React from "react";
import BoxMenuIcon from "../../Icons/BoxMenuIcon";

function CustomPromptMessageIndicator(props) {
  return (
    <HStack userSelect="none" align="center" gap={1}>
      <Icon fontSize="16px" as={BoxMenuIcon} mt={1} />
      <Text noOfLines={2}>{props.title}</Text>
    </HStack>
  );
}

export const CustomAIDocQueryMessage = ({ title }) => {
  return (
    <Flex
      borderRadius="lg"
      gap="2"
      bg="#F1F1F1"
      align="top"
      color="#191D30"
      w="100%"
    >
      <Flex flexDir="column" w="90%" fontSize="12px" gap={1}>
        <CustomPromptMessageIndicator title={title} />
      </Flex>
    </Flex>
  );
};
