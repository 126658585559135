import { copyFormattedText } from "./FormatCopiedText";

export const filterDataByType = (data, type) => {
  return data?.filter((d) => d.type === type);
};

export const getInsightTitle = (insights) => {
  const title = insights.filter((insight) => insight?.name == "title");
  return title;
};

export const copyInsightCardTextToClipboard = (text) => {
  copyFormattedText(text);
};
