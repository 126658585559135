import { Icon } from "@chakra-ui/react";
import React from "react";

const KeypointIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.85714 11.7174V10.1522H12V11.7174H3.85714ZM3.85714 7.28261V5.71739H12V7.28261H3.85714ZM3.85714 2.84783V1.28261H12V2.84783H3.85714ZM1.28193 12.5C0.92731 12.5 0.625 12.3463 0.375 12.0389C0.125 11.7314 0 11.3619 0 10.9302C0 10.4985 0.126262 10.1304 0.378786 9.82609C0.631322 9.52174 0.934893 9.36957 1.2895 9.36957C1.64412 9.36957 1.94643 9.52328 2.19643 9.8307C2.44643 10.1381 2.57143 10.5077 2.57143 10.9394C2.57143 11.3711 2.44517 11.7391 2.19264 12.0435C1.94011 12.3478 1.63654 12.5 1.28193 12.5ZM1.28193 8.06522C0.92731 8.06522 0.625 7.91151 0.375 7.60409C0.125 7.29665 0 6.92709 0 6.49539C0 6.06368 0.126262 5.69565 0.378786 5.3913C0.631322 5.08696 0.934893 4.93478 1.2895 4.93478C1.64412 4.93478 1.94643 5.08849 2.19643 5.39591C2.44643 5.70335 2.57143 6.07291 2.57143 6.50461C2.57143 6.93632 2.44517 7.30435 2.19264 7.6087C1.94011 7.91304 1.63654 8.06522 1.28193 8.06522ZM1.28193 3.63043C0.92731 3.63043 0.625 3.47672 0.375 3.1693C0.125 2.86187 0 2.4923 0 2.06061C0 1.6289 0.126262 1.26087 0.378786 0.956522C0.631322 0.652174 0.934893 0.5 1.2895 0.5C1.64412 0.5 1.94643 0.65371 2.19643 0.961131C2.44643 1.26857 2.57143 1.63813 2.57143 2.06983C2.57143 2.50154 2.44517 2.86957 2.19264 3.17391C1.94011 3.47826 1.63654 3.63043 1.28193 3.63043Z"
        fill="currentcolor"
        transform="translate(1.5, 1.5)"
      />
    </Icon>
  );
};

export default KeypointIconComponent;
