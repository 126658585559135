import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectDoc, ProjectPermissionDoc } from "shared/projectTypes";
import { LoadingScreen } from "src/Auth/LoadingAuth";
import { useUserProjects } from "src/db/project";
import {
  useActiveWorkspaceId,
  useUserWorkspacesIds,
} from "src/stores/userWorkspaces";
import { useAuth } from "../Auth/AuthProvider";

type ProjectWithPermission = {
  data: ProjectDoc;
  permission: ProjectPermissionDoc;
};

type ProjectContextType = {
  allProjects: ProjectWithPermission[] | undefined;
  currentProjectData: ProjectWithPermission | null;
  currentProjectId: string | null;
  sortedByTimeProjects: ProjectWithPermission[];
  activeProjectsWithoutArchived: ProjectWithPermission[];
  sortedAndArchivedProjects: ProjectWithPermission[];
};

//Get the project context
const ProjectContext = React.createContext<ProjectContextType | undefined>(
  undefined
);

export const ProjectContextProvider = ({ children }) => {
  const { currentUser, userDoc } = useAuth();
  const { projectId: currentProjectId } = useParams();
  const userWorkspaceIds = useUserWorkspacesIds();

  const [currentProjectData, setCurrentProjectData] =
    useState<ProjectWithPermission | null>(null);
  const [sortedByTimeProjects, setSortedByTimeProjects] = useState<
    ProjectWithPermission[]
  >([]);
  const [activeProjectsWithoutArchived, setActiveProjectsWithoutArchived] =
    useState<ProjectWithPermission[]>([]);
  const [sortedAndArchivedProjects, setSortedAndArchivedProjects] = useState<
    ProjectWithPermission[]
  >([]);

  const activeWorkspaceId = useActiveWorkspaceId();

  const { data: allProjects, isLoading } = useUserProjects(
    currentUser?.uid,
    // if there are no projects, refetch every second;
    // this is to handle a case when new user signs up. it takes
    // some time for the sample project to be created on the
    // backend (see setUpNewUser function), and the frontend is
    // not aware of that.
    // this is not the most efficient way to do it, it would be better
    // to have some kind of notificaiton system to invalidate the
    // projects query.
    (currentQuery) => {
      if (currentQuery.state.data?.length === 0) {
        return 1000;
      }
      return false;
    }
  );
  console.log("ProjectContextProvider allProjects", allProjects);

  useEffect(() => {
    if (currentProjectId && allProjects) {
      const project = allProjects.find((p) => p.data.uid === currentProjectId);
      console.log("getting current project", project);
      if (project) {
        setCurrentProjectData(project);
      } else {
        throw new Error("Project not found");
      }
    }
  }, [currentProjectId, allProjects]);

  const archivedProjects = userDoc?.archivedProjects;

  useEffect(() => {
    if (allProjects) {
      // filter out all projects which belong to other workspaces
      // this user has access to (except the active workspace)
      const filteredProjects = allProjects.filter((project) => {
        return (
          !userWorkspaceIds.includes(project.data.workspace?.uid) ||
          project.data.workspace?.uid === activeWorkspaceId
        );
      });

      const sortedProjectsByTime = filteredProjects.sort((a, b) => {
        return b.data.createdAt - a.data.createdAt;
      });

      const activeProjects = sortedProjectsByTime.filter(
        (project) => !archivedProjects?.includes(project.data.uid)
      );

      const sortedAndArchivedProjects = sortedProjectsByTime.filter((project) =>
        archivedProjects?.includes(project.data.uid)
      );

      setActiveProjectsWithoutArchived(activeProjects);
      setSortedByTimeProjects(sortedProjectsByTime);
      setSortedAndArchivedProjects(sortedAndArchivedProjects);
    }
  }, [
    allProjects,
    archivedProjects,
    setActiveProjectsWithoutArchived,
    setSortedByTimeProjects,
    setSortedAndArchivedProjects,
    currentProjectId,
    activeWorkspaceId,
  ]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ProjectContext.Provider
      value={{
        allProjects,
        currentProjectData,
        currentProjectId,
        sortedByTimeProjects,
        activeProjectsWithoutArchived,
        sortedAndArchivedProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("context must be used within a ProjectProvider");
  }
  return context;
};
