import { Avatar, Tooltip } from "@chakra-ui/react";
import React from "react";
import { handleProductNameAndIcon } from "../UserPage/ProjectListCard";

const SharedWithAvatar = ({
  displayName,
  photoURL,
  tooltipPrefix = "",
  size = "sm",
}: {
  displayName: string;
  photoURL: string;
  tooltipPrefix?: string;
  size?: string;
}) => {
  const { authorDisplayName, authorPhotoURL } = handleProductNameAndIcon(
    displayName,
    photoURL
  );
  return (
    <Tooltip label={tooltipPrefix + authorDisplayName}>
      <Avatar size={size} name={authorDisplayName} src={authorPhotoURL} />
    </Tooltip>
  );
};

export default SharedWithAvatar;
