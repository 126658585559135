import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sortTableArticles } from "../Components/Utils/ProjectPageUtil/tableUtils";
import { filterContentsBySearch } from "../Components/Utils/searchUtil";

const useFilteredAndSortedArticles = (articles, searchKeyword) => {
  const routerHistory = useHistory();

  const searchParamsStr = routerHistory.location.search;
  const searchParams = new URLSearchParams(searchParamsStr);

  const sortByParams = searchParams.get("sortBy");
  const filterByArticleTypeParams = searchParams.get("articleTypes");

  const [filteredAndSortedArticles, setFilteredAndSortedArticles] = useState(
    []
  );

  useEffect(() => {
    if (!articles) return;

    let updatedArticles = [...articles];

    if (filterByArticleTypeParams) {
      updatedArticles = updatedArticles.filter((article) =>
        filterByArticleTypeParams.includes(article.type)
      );
    }

    if (sortByParams) {
      updatedArticles = sortTableArticles(updatedArticles, sortByParams);
    }

    if (searchKeyword) {
      updatedArticles = filterContentsBySearch(updatedArticles, searchKeyword);
    }

    setFilteredAndSortedArticles(updatedArticles);
  }, [articles, searchParamsStr, searchKeyword]);

  return filteredAndSortedArticles;
};

export default useFilteredAndSortedArticles;
