import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import React from "react";
import { MdFormatLineSpacing } from "react-icons/md";
import { VIEW_MODE } from "../../data/ViewModeData";

const ViewModeMenu = ({ setViewMode, viewMode }) => {
  return (
    <Menu w={150} boxShadow="2xl">
      <MenuButton
        as={IconButton}
        variant="outline"
        colorScheme="primary"
        aria-label="Switch mode"
        fontSize="20px"
        icon={<MdFormatLineSpacing />}
        size="sm"
      ></MenuButton>
      <MenuList>
        {VIEW_MODE.map((item, index) => (
          <React.Fragment key={item.id}>
            <MenuItem
              key={item.id}
              onClick={() => setViewMode(item.id)}
              fontWeight={item.id === viewMode && "bold"}
            >
              {item.name}
            </MenuItem>
            {index !== 2 && <MenuDivider />}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ViewModeMenu;
