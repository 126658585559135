import { Flex, HStack, Text } from "@chakra-ui/react";
import { MdOutlineAssignment } from "react-icons/md";

const TemplateMessageType = ({ templateTitle }) => {
  return (
    <Flex borderRadius="lg" gap="2" bg="#f1f1f1" w="100%" align="top">
      <Flex w="90%" fontSize="10px" flexDir="column" gap={1}>
        <HStack>
          <MdOutlineAssignment w="10%" fontSize="16px" />
          <Text fontSize="12px" display="inline">
            {templateTitle}
          </Text>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default TemplateMessageType;
