import { Flex } from "@chakra-ui/react";
import React from "react";

interface StepProgressCounterProps {
  currentStep: number;
  totalSteps: number;
}

export const StepProgressCounter = ({
  currentStep,
  totalSteps,
}: StepProgressCounterProps) => {
  return (
    <Flex
      position="absolute"
      bottom="5%"
      left="-5%"
      w="100%"
      justify="center"
      align="center"
      gap="24px"
      color="black"
      fontSize="12px"
      fontWeight="bold"
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <Flex
          key={index}
          boxSize={index === 0 || index === totalSteps - 1 ? "16px" : "8px"}
          bg={
            currentStep === index + 1 || index + 1 < currentStep
              ? "primary"
              : "#D9D9D9"
          }
          borderRadius="100px"
        />
      ))}
    </Flex>
  );
};
