import {
  Avatar,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { workspacesCollection } from "src/db/workspace";
import { saveFile } from "src/services/firebaseStorageSrv";
import {
  useActiveWorkspaceData,
  useActiveWorkspacePermission,
} from "src/stores/userWorkspaces";
import useNotification from "../../hooks/useNotification";
import { WorkspaceAdditionalOffers } from "./AdditionalOffers";

export const EditableWorkspaceName = ({
  workspaceName,
  setWorkspaceName,
  updateWorkspaceName,
  isPending,
  isDisabled,
}) => {
  return (
    <Flex flexDir="column" pb="34px">
      <Text color="#666666" fontWeight="400" fontSize="12px" pb="16px">
        Workspace Name
      </Text>
      <FormControl
        bg="white"
        display="flex"
        w="540px"
        maxW="540px"
        borderRadius="8px"
        alignItems="center"
        p="6px"
      >
        <Input
          border="none"
          _hover={{
            background: "transparent",
          }}
          _focusVisible={{
            background: "transparent",
          }}
          value={workspaceName}
          fontSize="12px"
          fontWeight="400"
          _placeholder={{
            color: "primary",
          }}
          onChange={(e) => setWorkspaceName(e.target.value)}
          p="0"
          h="fit-content"
        />
        <Button
          isDisabled={isDisabled}
          p="6px"
          w="71px"
          height="fit-content"
          bg="primary"
          color={"white"}
          fontSize="12px"
          isLoading={isPending}
          onClick={() => updateWorkspaceName()}
        >
          Update
        </Button>
      </FormControl>
    </Flex>
  );
};

export const WorkspaceOverview = ({ display }) => {
  const activeWorkspaceData = useActiveWorkspaceData();
  const activeWorkspacePermission = useActiveWorkspacePermission();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { notify } = useNotification();

  const handleFileUpload = () => {
    fileInputRef.current?.click();
  };

  const handleImgSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];

    if (file.size / 1024 / 1024 > 2) {
      notify({
        title: "File size too large.",
        description: "Please select a file less than 2MB.",
        status: "error",
      });
      return;
    }

    setUploadInProgress(true);
    try {
      const fileExtension = file.name.split(".").pop();
      const downloadURL = await saveFile({
        file,
        path: `workspace_data/${activeWorkspaceData?.uid}`,
        newFileName: `logo.${fileExtension}`,
        isProfilePicture: false,
      });

      // Update workspace document with new logo URL
      if (activeWorkspaceData?.uid) {
        await updateDoc(doc(workspacesCollection, activeWorkspaceData.uid), {
          imageUrl: downloadURL,
        });
      }

      notify({
        title: "Success",
        description: "Workspace logo updated successfully",
        status: "success",
      });
    } catch (error) {
      console.error("Error uploading workspace logo:", error);
      notify({
        title: "Error",
        description: "Failed to update workspace logo",
        status: "error",
      });
    } finally {
      setUploadInProgress(false);
    }
  };

  // keeping track of the original workspace name is needed here, because in case
  // of error in the name change, if onError handler tries to revert the name
  // to activeWorkspaceData?.name, it won't revert: Firestore subscription
  // optimistically updates the name, and onError handler will take the name
  // from that optimistically updated state, which is not what we want. The
  // name on Firestore subscription eventually reverts, but it doesn't rerender
  // the component, so the name doesn't revert in the UI. Long story short, we need
  // to keep track of the original workspace name and use it to revert the name
  // in case of error.
  const [oldWorkspaceName] = useState(activeWorkspaceData?.name || "");
  const [workspaceName, setWorkspaceName] = useState(
    activeWorkspaceData?.name || ""
  );
  const toast = useToast();
  const { mutate: updateWorkspaceName, isPending } = useMutation({
    mutationFn: async () => {
      if (!activeWorkspaceData) return;
      await updateDoc(doc(workspacesCollection, activeWorkspaceData.uid), {
        name: workspaceName,
      });
      toast({
        title: "Workspace name updated",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: async (error) => {
      toast({
        title: "Error",
        description: `Failed to update workspace name: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setWorkspaceName(oldWorkspaceName);
    },
  });

  if (!activeWorkspaceData) return null;

  return (
    <Flex flexDir="column" pb="24px" display={display ? "flex" : "none"}>
      <Heading fontSize="24px" color="primary" pb="8px">
        Workspace Overview
      </Heading>
      <Flex flexDir="column" gap="16px" pb="24px">
        <Text color="#666666" fontWeight="400" fontSize="12px">
          Workspace Logo
        </Text>
        <Flex pos="relative" w="fit-content">
          <Avatar
            src={activeWorkspaceData.imageUrl}
            name={activeWorkspaceData.name}
            boxSize="74px"
          />
          <Flex
            bg="primary"
            p="1"
            pos="absolute"
            bottom="-1"
            right="-1"
            borderRadius="100px"
            boxSize="24px"
            align="center"
            justify="center"
            cursor="pointer"
            onClick={handleFileUpload}
          >
            {uploadInProgress ? (
              <Spinner size="xs" color="white" />
            ) : (
              <MdOutlineModeEdit fontSize="12px" color="white" />
            )}
          </Flex>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".png, .jpeg, .jpg"
            onChange={handleImgSelected}
          />
        </Flex>
      </Flex>
      {activeWorkspacePermission?.role === "owner" ? (
        <EditableWorkspaceName
          workspaceName={workspaceName}
          setWorkspaceName={setWorkspaceName}
          updateWorkspaceName={updateWorkspaceName}
          isPending={isPending}
          isDisabled={workspaceName === activeWorkspaceData?.name}
        />
      ) : (
        <Flex flexDir="column" gap="16px" pb="24px">
          <Text color="#666666" fontWeight="400" fontSize="12px">
            Workspace Name
          </Text>
          <Text color="primary" fontWeight="600" fontSize="16px">
            {activeWorkspaceData?.name}
          </Text>
        </Flex>
      )}
      <WorkspaceAdditionalOffers />
    </Flex>
  );
};
