import { Grid, VStack } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import usePrivateCustomPrompts from "../../hooks/useCustomPrompt";
import useNotification from "../../hooks/useNotification";
import {
  addMessageToDb,
  manageSelectedArticles,
} from "../assistant/AssistantChatWindow";
import { AiModuleBox } from "./AiModuleBox";
import { clearVariables } from "./AiModulesInHeader";

function formatString(str, replacements) {
  return str.replace(/{{(\w+)}}/g, function (_, key) {
    return replacements[key];
  });
}

const CustomPrompts = ({ articles, assistantIsGenerating }) => {
  const { customPrompts, createPromptRequest, loading, error } =
    usePrivateCustomPrompts();
  const { notify } = useNotification();
  const {
    setConversationId,
    setSubscribeToConversationDoc,
    inputValue,
    setInputValue,
  } = useAssistantContext();
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const { selectedArticleIds } = useArticle();

  if (error) {
    notify({
      title: "Error",
      description: "Failed to start. Please try again later.",
      status: "error",
    });
    console.error(error);
  }

  function handleStartCustomPrompt(prompt) {
    return async () => {
      let message;
      if (prompt.variables) {
        // check if there are more than one variable in `variables` object -- not supported for now
        const variableKeys = Object.keys(prompt.variables);
        if (variableKeys.length > 1) {
          notify({
            title: "Error",
            description: "Multiple variables are not supported.",
            status: "error",
          });
          return;
        }

        const variableKey = variableKeys[0];
        const variable = prompt.variables[variableKey];

        // check if input value is empty
        if (!variable.optional && !inputValue) {
          notify({
            title: "Error",
            description: `Please enter ${
              variable.description.charAt(0).toLowerCase() +
              variable.description.slice(1)
            }`,
            status: "error",
          });
          return;
        }

        message = {
          role: "user",
          content: formatString(prompt.userMessage, {
            [variableKey]: inputValue,
          }),
          createdAt: Date.now(),
          title: prompt.title,
          type: "custom",
          inputValue: formatString(prompt.userMessage, {
            [variableKey]: inputValue,
          }),
        };
        setInputValue("");
      } else {
        message = {
          role: "user",
          content: clearVariables(prompt.userMessage),
          createdAt: Date.now(),
          title: prompt.title,
          type: "custom",
          inputValue: clearVariables(prompt.userMessage),
        };
      }
      // TODO: logic for selecting articles is scattered across the app;
      // refactor to a handle in a single place.

      if (selectedArticleIds.length === 0) {
        manageSelectedArticles(message, articles);
      } else {
        const selectedArticles = articles.filter((article) =>
          selectedArticleIds.includes(article.uid)
        );
        message.selectedArticles = selectedArticles;
        message.selectedArticleQuery = true;
      }

      const conversationId = uuidv4();
      await addMessageToDb({
        projectId,
        conversationId,
        userId: currentUser.uid,
        message,
        createdAt: Date.now(),
      });
      setConversationId(conversationId);
      setSubscribeToConversationDoc(true);
      createPromptRequest({ promptId: prompt.uid, conversationId, projectId });
    };
  }

  return (
    <VStack
      spacing={4}
      align="stretch"
      w="100%"
      display={assistantIsGenerating ? "none" : "flex"}
      maxH={"500px"}
      overflowY={"auto"}
    >
      <Grid
        bg="white"
        py="2"
        templateColumns="repeat(auto-fit, 170px)"
        gap="2"
        w="100%"
      >
        {customPrompts.length === 0 && loading
          ? // If loading and no prompts are available, display loading buttons
            Array.from({ length: 3 }).map((_, index) => (
              <AiModuleBox
                key={`loading-${index}`}
                moduleData={{ title: "Loading...", description: "" }}
                isLoading={true}
              />
            ))
          : // Otherwise, display the fetched prompts
            customPrompts.map((prompt) => (
              <AiModuleBox
                key={prompt.uid}
                moduleData={prompt}
                onClick={handleStartCustomPrompt(prompt)}
                isLoading={loading} // Pass the loading state here
                action="SEND"
              />
            ))}
      </Grid>
    </VStack>
  );
};

export default CustomPrompts;
