import React from "react";
import { Icon } from "@chakra-ui/react";

const MenuDotIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>

        <g clipPath="url(#clip0_4434_9098)">
          <path
            d="M14.3667 9.22366C15.234 9.22366 15.937 8.5206 15.937 7.65334C15.937 6.78607 15.234 6.08301 14.3667 6.08301C13.4994 6.08301 12.7964 6.78607 12.7964 7.65334C12.7964 8.5206 13.4994 9.22366 14.3667 9.22366Z"
            fill="black"
          />
          <path
            d="M8.386 9.18228C9.25327 9.18228 9.95633 8.47922 9.95633 7.61195C9.95633 6.74469 9.25327 6.04163 8.386 6.04163C7.51873 6.04163 6.81567 6.74469 6.81567 7.61195C6.81567 8.47922 7.51873 9.18228 8.386 9.18228Z"
            fill="black"
          />
          <path
            d="M2.41994 9.18228C3.2872 9.18228 3.99026 8.47922 3.99026 7.61195C3.99026 6.74469 3.2872 6.04163 2.41994 6.04163C1.55267 6.04163 0.849609 6.74469 0.849609 7.61195C0.849609 8.47922 1.55267 9.18228 2.41994 9.18228Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_4434_9098">
            <rect
              width="15.0751"
              height="15.0751"
              fill="white"
              transform="translate(0.851562 0.0742188)"
            />
          </clipPath>
        </defs>
    </Icon>
  );
};

export default MenuDotIconComponent;
