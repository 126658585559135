export const PERMISSION_TYPES = {
  READ: "canView",
  WRITE: "canEdit",
  OWNER: "owner",
};

export const PERMISSIONS = [PERMISSION_TYPES.READ, PERMISSION_TYPES.WRITE];

export const PERMISSIONS_MAP = {
  [PERMISSION_TYPES.READ]: "Can View",
  [PERMISSION_TYPES.WRITE]: "Can Edit",
  [PERMISSION_TYPES.OWNER]: "Owner",
};

export const PERMISSION_ERROR_MSG =
  "You do not have permission to perform this action.";

type UserPermission = "owner" | "canEdit" | "canView" | "none" | "loading";

export const UserPermissions: Record<string, UserPermission> = {
  OWNER: "owner",
  CAN_EDIT: "canEdit",
  CAN_VIEW: "canView",
  NONE: "none",
  LOADING: "loading",
};
