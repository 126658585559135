import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import { handleDownload } from "../Utils/viewerUtils";

export const NoPDFViewerView = ({ article }) => {
  const articleType = article?.type;
  const viewFileFunc = ARTICLE_TYPE_DATA[articleType].viewFileFunction;

  const actionTextMap = {
    DOWNLOAD: `${ARTICLE_TYPE_DATA[articleType].uiNamePlural} cannot yet be seen in app. Click to download`,
    OPEN_LINK: `${ARTICLE_TYPE_DATA[articleType].uiNamePlural} cannot yet be seen in app. Click to open in browser`,
    VIEWER:
      // this is to show a different message for articles that were created before the PDF generation feature was added;
      // at some point in the future(let's say >2025-06-17), this can be removed, because number of such articles will
      // be very small. santop 2024-06 - 17 Mon 17: 16: 56
      article.createdAt > 1718636230900
        ? "PDF is currently generating, it should take no more than a few minutes. Please check back later."
        : `PDFs are only generated for new ${ARTICLE_TYPE_DATA[articleType].uiNamePlural}. Reupload the file to view it in app.`,
  };

  const actionText = actionTextMap[viewFileFunc];

  return (
    <Flex
      w="100%"
      bg="transparent"
      align="center"
      justify="center"
      flexDir="column"
      cursor={viewFileFunc === "VIEWER" ? "default" : "pointer"}
      onClick={() => {
        if (viewFileFunc === "DOWNLOAD") {
          handleDownload(article.url, article.title);
        } else if (viewFileFunc === "OPEN_LINK") {
          window.open(article.url, "_blank");
        } else {
          return;
        }
      }}
    >
      {/* <HStack gap={1}>
        <Text textTransform="capitalize" fontSize="14px" color="#727272">
          {ARTICLE_TYPE_DATA[articleType].uiNamePlural}
        </Text>
        <Text fontSize="14px" color="#727272"></Text>
      </HStack> */}

      <Text
        fontSize="14px"
        color="#727272"
        w="50%"
        style={{ textWrap: "balance" }}
        align="center"
      >
        {actionText}{" "}
        {/* {viewFileFunc === "OPEN_LINK" ? (
          <ExternalLinkIcon color="black" fontSize="10px" />
        ) : (
          <Flex justify="center">
            <MdOutlineFileDownload color="black" fontSize="18px" />
          </Flex>
        )} */}
        {viewFileFunc === "VIEWER" ? null : viewFileFunc === "OPEN_LINK" ? (
          <ExternalLinkIcon color="black" fontSize="10px" />
        ) : (
          <Flex justify="center">
            <MdOutlineFileDownload color="black" fontSize="18px" />
          </Flex>
        )}
      </Text>
    </Flex>
  );
};
