import React from "react";
import { Icon } from "@chakra-ui/react";

const MediaIconComponent = (props) => {
  return (
    <Icon width="20" height="21" viewBox="0 0 19 21" fill="none" {...props}>

        <path
          d="M15.8333 4.66667V16.3333H4.16667V4.66667H15.8333ZM15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3ZM11.7833 10.3833L9.28333 13.6083L7.5 11.45L5 14.6667H15L11.7833 10.3833Z"
          fill="white"
        />
    </Icon>
  );
};

export default MediaIconComponent;
