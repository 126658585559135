import axios, { AxiosResponse } from "axios";
import { auth } from "src/firebase";

type ApiResponse<T = unknown> = {
  data: T;
  error: string;
};

const fetchWithAuthToken = async <T = unknown>(
  url: string,
  method: "GET" | "POST",
  token: string,
  payload?: object
): Promise<AxiosResponse<ApiResponse<T>>> => {
  const requestHeaders = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", token);

  const headerObject = Object.fromEntries(requestHeaders.entries());

  if (method === "GET") {
    const res = await axios.get(url, { headers: headerObject });
    return res;
  }

  if (method === "POST") {
    const res = await axios.post(url, payload, { headers: headerObject });
    return res;
  }

  throw new Error("Invalid method");
};

export async function callApi<T = unknown>(
  url: string,
  method: "GET" | "POST",
  payload?: object
): Promise<ApiResponse<T>> {
  let token: string | undefined;
  try {
    token = await auth.currentUser?.getIdToken(true);
  } catch (e) {
    throw new Error("Cannot get token");
  }
  if (!token) {
    throw new Error("No token found");
  }
  const res = await fetchWithAuthToken<T>(url, method, token, payload);
  return res.data;
}
