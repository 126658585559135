import { Flex } from "@chakra-ui/react";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";

interface BackwardBttnProps {
  handleArrowClicks: (direction: "left" | "right") => void;
  setAllowForwardNav: (allow: boolean) => void;
  step: number;
}

export const BackwardBttn = ({
  handleArrowClicks,
  setAllowForwardNav,
  step,
}: BackwardBttnProps) => {
  return (
    <Flex
      opacity={step === 1 ? 0.5 : 1}
      h="fit-content"
      justify="center"
      align="center"
      onClick={() => {
        if (step === 1) return;
        handleArrowClicks("left");
        setAllowForwardNav(true);
      }}
      gap="38px"
      pos="absolute"
      left="2%"
      cursor="pointer"
    >
      <FaChevronLeft width="29px" height="33px" fontSize="40px" />
    </Flex>
  );
};
