import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { PERMISSIONS, PERMISSIONS_MAP } from "src/data/PermissionData";
import { useUserPermission } from "../../../Context/UserPermissionContext";

interface PermissionMenuProps {
  currentPermission: string;
  setPermission: (permission: string) => void;
}

export const PermissionMenu: React.FC<PermissionMenuProps> = ({
  currentPermission,
  setPermission,
}) => {
  const { isEditor } = useUserPermission();

  return (
    <Menu placement="bottom" gutter={0}>
      <MenuButton
        p="0"
        fontWeight="500"
        fontSize="12px"
        as={Button}
        rightIcon={<ChevronDownIcon fontSize="15px" />}
        h="fit-content"
        isDisabled={!isEditor()}
      >
        {PERMISSIONS_MAP[currentPermission]}
      </MenuButton>
      <MenuList
        bg="#282C3E"
        border="none"
        p="2"
        borderRadius="md"
        as={Flex}
        w="12%"
        flexDir="column"
        css={{
          minWidth: "fit-content !important",
        }}
        gap={2}
      >
        {PERMISSIONS.map((perm) => (
          <MenuItem
            bg="#282C3E"
            onClick={() => setPermission(perm)}
            color="white"
            fontSize="12px"
            _hover={{ bg: "#191D30" }}
            borderRadius="md"
            key={perm}
          >
            {PERMISSIONS_MAP[perm]}
          </MenuItem>
        ))}
        <MenuItem
          bg="#282C3E"
          onClick={() => {
            setPermission("none");
          }}
          color="#D11A2A"
          fontSize="12px"
          _hover={{ bg: "#191D30" }}
          borderRadius="md"
        >
          Remove
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default PermissionMenu;
