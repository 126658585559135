import { Flex, HStack, IconButton, InputGroup } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiSend } from "react-icons/fi";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ShowHideCustomPromptsButton } from "./ShowHideCustomPromptsButton";
import { StopGeneration } from "./StopGeneration";

function TextInput({
  inputRef,
  assistantDisabled,
  handleSendClick,
  inputFieldPlaceholderText = "Ask the assistant anything...",
}) {
  const { setInputValue: contextInputValue } = useAssistantContext();
  const [inputValue, setInputValue] = React.useState("");

  const handleKeyPress = (event) => {
    if (!handleSendClick) {
      return;
    }
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendClick();
    }
  };

  // Debounce the input value to avoid sending too many requests,
  // because updates cause excessive re-renders
  useEffect(() => {
    const timeout = setTimeout(() => {
      contextInputValue(inputValue);
    }, 100);

    return () => clearTimeout(timeout);
  }, [inputValue, contextInputValue]);

  return (
    <InputGroup id="chatBoxTour" w="full">
      <ReactTextareaAutosize
        className="webkit-scrollbar-display-none"
        ref={inputRef}
        minRows={1} // minimum rows to show
        maxRows={3} // maximum rows to show
        style={{
          overflow: "auto",
          scrollbarWidth: "none",
          backgroundColor: "#F6F6F6",
          fontSize: "0.875rem",
          paddingRight: "",
          width: "100%",
          border: "none",
          padding: "0.75rem 0px 1.5rem 1rem",
          borderRadius: "12px",
          fontFamily: "inherit", // use the same font family as the rest of the app
          lineHeight: "1.25",
          outline: "none",
          resize: "none",
        }}
        placeholder={assistantDisabled ? "" : inputFieldPlaceholderText}
        cursor={assistantDisabled ? "not-allowed" : "text"}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={assistantDisabled}
      />
    </InputGroup>
  );
}

function SendMessageButton({ assistantDisabled, handleSendClick }) {
  return (
    <IconButton
      color="#fff"
      bg={assistantDisabled ? "#9C9C9C" : "primary"}
      aria-label="Send message"
      icon={<FiSend />}
      mr={3}
      onClick={assistantDisabled ? () => {} : handleSendClick}
      cursor={assistantDisabled ? "not-allowed" : "pointer"}
    />
  );
}

function InputFieldButtons({
  setShowCustomPrompts,
  assistantIsGenerating,
  assistantDisabled,
  handleSendClick,
  handleStopGeneration,
}) {
  if (!handleSendClick) {
    return null;
  }

  if (assistantIsGenerating) {
    return <StopGeneration handleStopGeneration={handleStopGeneration} />;
  }

  return (
    <HStack w="fit-content" color="black" align="center" justify="center">
      <ShowHideCustomPromptsButton
        setShowCustomPrompts={setShowCustomPrompts}
      />
      <SendMessageButton
        assistantDisabled={assistantDisabled}
        handleSendClick={handleSendClick}
      />
    </HStack>
  );
}

const InputField = ({
  assistantDisabled,
  assistantIsGenerating,
  handleSendClick,
  inputRef,
  inputFieldPlaceholderText,
}) => {
  if (assistantIsGenerating) {
    return null;
  }

  return (
    <TextInput
      inputRef={inputRef}
      assistantDisabled={assistantDisabled}
      handleSendClick={handleSendClick}
      inputFieldPlaceholderText={inputFieldPlaceholderText}
    />
  );
};

const AssistantChatInput = ({
  assistantDisabled,
  assistantIsGenerating,
  handleSendClick,
  handleStopGeneration,
  inputRef,
  setShowCustomPrompts,
  inputFieldPlaceholderText,
}) => {
  return (
    <Flex gap={2} justify="end" bg="#F6F6F6" borderRadius="12px">
      <InputField
        assistantDisabled={assistantDisabled}
        assistantIsGenerating={assistantIsGenerating}
        handleSendClick={handleSendClick}
        inputRef={inputRef}
        inputFieldPlaceholderText={inputFieldPlaceholderText}
      />
      <InputFieldButtons
        setShowCustomPrompts={setShowCustomPrompts}
        assistantIsGenerating={assistantIsGenerating}
        assistantDisabled={assistantDisabled}
        handleSendClick={handleSendClick}
        handleStopGeneration={handleStopGeneration}
      />
    </Flex>
  );
};

export default AssistantChatInput;
