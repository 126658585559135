import React from "react";
import { Icon } from "@chakra-ui/react";

const LinkIconComponent = (props) => {
  return (
    <Icon width="20" height="21" viewBox="0 0 19 21" fill="none" {...props}>

        <path
          d="M14.1665 6.33301H10.8332V7.99967H14.1665C15.5415 7.99967 16.6665 9.12467 16.6665 10.4997C16.6665 11.8747 15.5415 12.9997 14.1665 12.9997H10.8332V14.6663H14.1665C16.4665 14.6663 18.3332 12.7997 18.3332 10.4997C18.3332 8.19967 16.4665 6.33301 14.1665 6.33301ZM9.1665 12.9997H5.83317C4.45817 12.9997 3.33317 11.8747 3.33317 10.4997C3.33317 9.12467 4.45817 7.99967 5.83317 7.99967H9.1665V6.33301H5.83317C3.53317 6.33301 1.6665 8.19967 1.6665 10.4997C1.6665 12.7997 3.53317 14.6663 5.83317 14.6663H9.1665V12.9997ZM6.6665 9.66634H13.3332V11.333H6.6665V9.66634Z"
          fill="white"
        />
    </Icon>
  );
};

export default LinkIconComponent;
