export const ViewMode = {
  CHAT: "CHAT",
  HISTORY: "HISTORY",
  TEMPLATE: "TEMPLATE",
  CUSTOMAI: "CUSTOMAI",
};

export const TEMPLATE_MODE = {
  LIST: "LIST",
  VIEW: "VIEW",
  CREATE: "CREATE",
};
