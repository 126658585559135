import { Box, Flex, Text } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import { useEffect, useState } from "react";

const LoadingCircle = ({ animationDelay }) => {
  return (
    <Box
      w="4px"
      h="4px"
      borderRadius="100"
      sx={{
        animation: `colorChange 1.5s infinite`,
        animationDelay: `${animationDelay}s`,
        backgroundColor: "#c1c1c1",
      }}
      bg="#c1c1c1"
    />
  );
};

const getRandomNum = () => {
  return Math.floor(Math.random() * 5000) + 3000;
};

export const AssistantChatLoadingWithText = () => {
  const [loadingMsg, setLoadingMsg] = useState("Analyzing data...");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingMsg("Preparing answer...");
    }, getRandomNum());

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Global
        styles={css`
          @keyframes colorChange {
            0%,
            50% {
              background-color: #ececec;
            }
            51%,
            100% {
              background-color: #c1c1c1;
            }
          }
        `}
      />
      <Flex pt="8px" w="70%">
        <Flex
          gap="8px"
          alignItems="center"
          w="175px"
          px="12px"
          py="8px"
          bg="white"
          borderRadius="lg"
        >
          <Flex gap="2px" w="fit-content">
            <LoadingCircle animationDelay={0} />
            <LoadingCircle animationDelay={0.3} />
            <LoadingCircle animationDelay={0.6} />
          </Flex>
          <Text color="#737373" fontSize="12px">
            {loadingMsg}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export const AssistantChatLoadingWithOutText = () => {
  return (
    <>
      <Global
        styles={css`
          @keyframes colorChange {
            0%,
            50% {
              background-color: #ececec;
            }
            51%,
            100% {
              background-color: #c1c1c1;
            }
          }
        `}
      />
      <Flex
        gap="8px"
        alignItems="center"
        w="175px"
        px="12px"
        py="8px"
        bg="white"
        borderRadius="4px"
      >
        <Flex gap="2px" w="fit-content">
          <LoadingCircle animationDelay={0} />
          <LoadingCircle animationDelay={0.3} />
          <LoadingCircle animationDelay={0.6} />
        </Flex>
      </Flex>
    </>
  );
};
