import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ProjectDoc, ProjectPermissionDoc } from "shared/projectTypes";
import { PERMISSIONS_MAP } from "src/data/PermissionData";
import { useAuth } from "../../../Auth/AuthProvider";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import SharedWithAvatar from "../../Elements/SharedWithAvatar";
import PermissionMenu from "./PermissionMenu";
import { updateUserPermission } from "./sharingUtils";

interface IndividualUserProps {
  userProjectPermission: ProjectPermissionDoc;
  projectId: ProjectDoc["uid"];
}

const IndividualUser: React.FC<IndividualUserProps> = ({
  userProjectPermission,
  projectId,
}) => {
  const { currentUser } = useAuth();
  const { isOwner } = useUserPermission();

  const isCurrentUser = userProjectPermission.uid === currentUser.uid;

  const permissionComponent =
    isCurrentUser || !isOwner() ? (
      <Text color="white" fontSize="12px">
        {PERMISSIONS_MAP[userProjectPermission.permission]}
      </Text>
    ) : (
      <PermissionMenu
        currentPermission={userProjectPermission.permission}
        setPermission={(permission) => {
          updateUserPermission({
            userId: userProjectPermission.uid,
            projectId,
            permission,
          });
        }}
      />
    );

  return (
    <Flex mt={2} justify="space-between" align="center" gap={1}>
      <Flex align="center" gap="2">
        <SharedWithAvatar
          displayName={
            userProjectPermission.displayName || userProjectPermission.email
          }
          photoURL={""}
        />
        <Text fontSize="11px" color="white">
          {userProjectPermission.displayName || userProjectPermission.email}{" "}
          {userProjectPermission.uid === currentUser.uid ? " (you)" : ""}
        </Text>
      </Flex>
      {permissionComponent}
    </Flex>
  );
};

interface UserListProps {
  userList: ProjectPermissionDoc[];
  projectId: ProjectDoc["uid"];
}

export const UserList: React.FC<UserListProps> = ({ userList, projectId }) => {
  const { currentUser } = useAuth();

  return (
    <Flex px="6" mt="2rem" flexDir="column">
      <Text color="#8C8E98" fontSize="11px">
        People with access
      </Text>
      <Flex flexDir="column">
        {userList
          .sort((a, b) => {
            if (a.uid === currentUser.uid) return -1;
            if (b.uid === currentUser.uid) return 1;
            return 0;
          })
          .map((user, index) => (
            <IndividualUser
              key={index}
              userProjectPermission={user}
              projectId={projectId}
            />
          ))}
      </Flex>
    </Flex>
  );
};

export default UserList;
