import { Editable, EditableInput, EditablePreview } from "@chakra-ui/react";
import React, { useState } from "react";

const EditableText = ({ text, isEditable = false, submitHandler, ...rest }) => {
  // rest is the rest of the props
  const [value, setValue] = useState(text);
  const [initialValue] = useState(text);
  const handleSubmission = (value) => {
    if (!value) {
      setValue(initialValue);
    }
    submitHandler(value);
  };
  const updateValue = (value) => {
    if (!value) {
      setValue(initialValue);
    }
    setValue(value);
  };
  return (
    <Editable
      onSubmit={handleSubmission}
      isDisabled={isEditable}
      value={value}
      onChange={(value) => updateValue(value)}
      submitOnBlur
      border="1px solid"
      borderColor="transparent"
      _hover={{ borderColor: "#4299e1", borderRadius: "md" }}
    >
      <EditablePreview pr={2} pl={1} noOfLines={1} {...rest} />
      <EditableInput pl={1} {...rest} />
    </Editable>
  );
};

export default EditableText;
