import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { MdDeleteOutline } from "react-icons/md";

export const DeleteAlert = ({ isOpen, onClose, action, title, body }) => {
  const cancelRef = useRef();
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button color="primary" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button bg="red" onClick={action} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const PrimaryDeleteAlert = ({ isOpen, onClose, action, body }) => {
  const cancelRef = useRef();
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        size="sm"
      >
        <AlertDialogOverlay>
          <AlertDialogContent py="24px" px="45px">
            <AlertDialogHeader
              justifyContent="center"
              fontSize="lg"
              fontWeight="bold"
              pb="0"
              px="0"
              py="0"
            >
              <Flex w="100%" justify="center" fontSize="28px">
                <MdDeleteOutline />
              </Flex>
            </AlertDialogHeader>

            <AlertDialogBody px="0" py="0" mb="16px" mt="16px">
              <Flex w="100%" justify="center" color="#191D30" fontSize="12px">
                {body}
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter pt="0" px="0" py="0">
              <Flex
                w="100%"
                justify="center"
                gap="24px"
                color="#191D30"
                fontSize="12px"
              >
                <Button
                  fontSize="12px"
                  py="2"
                  h="fit-content"
                  color="primary"
                  ref={cancelRef}
                  onClick={onClose}
                  border="1px solid #e0e0e0"
                >
                  No, Cancel
                </Button>
                <Button
                  fontSize="12px"
                  py="2"
                  h="fit-content"
                  bg="primary"
                  onClick={action}
                  border="1px solid #191D30"
                >
                  Yes, Delete
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
