import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { ARTICLE_TYPE_NAMES } from "../../data/constants";
import { updateArticleType } from "../../db/article";

const SwitchAlert = ({ isOpen, onClose, articleId, projectId, newType }) => {
  const cancelRef = useRef();

  const handleSwap = () => {
    updateArticleType({ projectId, articleId, type: newType });
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {/* Swap between article types */}
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">{`Mark as ${ARTICLE_TYPE_NAMES[newType]}?`}</AlertDialogBody>

            <AlertDialogFooter>
              <Button bg="primary" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button bg="red" onClick={handleSwap} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default SwitchAlert;
