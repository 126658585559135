import React from "react";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useArticle } from "../../../Context/ArticleContext";

export function ArticleSelector({ uid }) {
  const { selectedArticleIds, setSelectedArticleIds, selectArticle } =
    useArticle();
  return selectedArticleIds.includes(uid) ? (
    <IoMdCheckboxOutline
      onClick={() => {
        const updatedArr = selectedArticleIds.filter((id) => id !== uid);
        setSelectedArticleIds(updatedArr);
      }}
      color="primary"
      cursor="pointer"
      fontSize="14px"
    />
  ) : (
    <MdCheckBoxOutlineBlank
      onClick={() => {
        selectArticle(uid);
      }}
      color="#727272"
      cursor="pointer"
      fontSize="14px"
    />
  );
}
