import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { defaultSchema } from "hast-util-sanitize";
import { merge } from "lodash";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { RiDeleteBin2Line } from "react-icons/ri";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import style from "../../../Components/template-card-markdown.module.css";
import { useArticle } from "../../../Context/ArticleContext";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { SMART_CARD_METADATA } from "../../../data/SmartTemplateData";
import { DeleteAlert } from "../../Elements/DeleteAlert";
import ImageViewer from "../../Elements/ImageViewer";
import ImageWithIconFallback from "../../Elements/ImageWithIconFallback";
import MenuDotIconComponent from "../../Icons/MenuDotIconComponent";
import { ManualInsightSourceList } from "../ManualInsightSource/ManualInsightSourceList";
import { ReaddToTemplateBttn, ViewInSourceBttn } from "../ViewInSourceBttn";
import ColorBar from "./ColorBar";
import { FeedbackComponent } from "./Feedback";
import { TEMPLATE_CARD_TYPES } from "./TemplateInsights";

const IconComponent = ({ card, articleIcon, onOpen: onOpenImage }) => {
  if (card.type === "image") {
    return (
      <Box onClick={onOpenImage} cursor="pointer">
        <Avatar
          borderRadius="10"
          src={card.imageUrl || "https://via.placeholder.com/200"}
          boxSize={5}
        />
      </Box>
    );
  }
  return (
    <Box w="20px">
      {card.type === "assistantInsight" ? (
        <ManualInsightSourceList
          iconsLists={card.sourceArticles}
          iconsGaps={card.sourceArticles?.length > 1 && "-30%"}
          iconsToRender={3}
          isTemplate={true}
          counterGap={"-35%"}
        />
      ) : (
        <Tooltip
          fontSize="x-small"
          color="black"
          placement="right-end"
          bg="white"
          label={
            <Flex
              flexDir="column"
              w="fit-content"
              pr="4"
              bg="white"
              color="primary"
            >
              <Text fontSize="10px">{card?.articleTitle}</Text>
            </Flex>
          }
        >
          <Flex>
            <ImageWithIconFallback imgSrc={articleIcon} />
          </Flex>
        </Tooltip>
      )}
    </Box>
  );
};

const TopMetadataStack = ({ card }) => {
  return card?.metadata ? (
    <HStack p="0px" pb="6px">
      {SMART_CARD_METADATA.filter((meta) => meta.position === "top").map(
        (meta) =>
          card?.metadata[meta.key] && (
            <Box key={meta.key}>
              {meta.showName && <Text fontSize="8px">{meta.displayName}</Text>}
              <Text
                textTransform="capitalize"
                fontSize="8px"
                color="black"
                opacity={0.54}
              >
                {card?.metadata[meta.key]}
              </Text>
            </Box>
          )
      )}
    </HStack>
  ) : (
    <div />
  );
};

function MetadataTextField({ card, meta }) {
  return (
    <Text fontSize="8px" fontWeight="bold" opacity={0.54}>
      {card?.metadata[meta.key]}
    </Text>
  );
}

const MetadataBar = ({ card, meta }) => {
  return ColorBar({ value: card?.metadata[meta.key] });
};

const MetaComponent = ({ card, meta }) => {
  if (meta.format === "bar") {
    return <MetadataBar card={card} meta={meta} />;
  }
  return <MetadataTextField card={card} meta={meta} />;
};

const BottomMetadataStack = ({ card }) => {
  if (!card?.metadata) {
    return <div />;
  }

  return (
    <HStack w="fit-content" gap="22px">
      {SMART_CARD_METADATA.filter((meta) => meta.position === "bottom").map(
        (meta) =>
          card.metadata[meta.key] && (
            <VStack alignItems="flex-start" key={meta.key} gap="4px">
              <Text fontSize="8px" opacity={0.54}>
                {meta.displayName}
              </Text>
              <MetaComponent card={card} meta={meta} />
            </VStack>
          )
      )}
    </HStack>
  );
};

const OverflowMenu = ({ openAlert }) => {
  return (
    <Menu>
      <MenuButton
        variant="ghost"
        // bg="white"
        aria-label="See menu"
        icon={<MenuDotIconComponent />}
        color="black"
        // opacity={0.54}
        as={IconButton}
        h={8}
      />
      <MenuList minW={0} w="auto">
        <MenuItem
          icon={<RiDeleteBin2Line />}
          onClick={() => openAlert()}
          fontSize="sm"
        >
          Delete Card
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const SmartSectionCard = ({ card, id, index, sectionName }) => {
  const { projectId } = useParams();
  const { allUnfilteredArticles: articles } = useArticle();
  const { currentTemplate } = useTemplateContext();
  const { deleteCard } = useOrganizerContext();

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: openAlert,
    onClose: closeAlert,
    isOpen: isAlertOpen,
  } = useDisclosure();

  const getArticleIcon = (articleId) => {
    const article = articles?.find((article) => article.uid === articleId);
    return article?.icon;
  };

  const customSchema = merge(defaultSchema, {
    attributes: {
      "*": ["style", "figure", "img", "figcaption", "className"],
    },
  });
  return (
    <Draggable draggableId={`${card.uid}+${index}`} index={index}>
      {(provided, snapshot) => (
        <Card
          id={card.id}
          // my={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}
          {...provided.dragHandleProps}
          boxShadow="none"
          bg="white"
        >
          <CardBody
            pt="0"
            pb={"14px"}
            px="0"
            borderRadius="4px"
            bg={TEMPLATE_CARD_TYPES[card.cardType]?.color}
          >
            <Stack>
              <Flex>
                <Box pl="16px" pt="16px">
                  <IconComponent
                    card={card}
                    articleIcon={getArticleIcon(card.articleId)}
                    onOpen={onOpen}
                  />
                </Box>
                <Flex
                  flex="1"
                  // gap="4"
                  alignItems="start"
                  flexWrap="wrap"
                  py="0"
                  pl="16px"
                  pr="0px"
                  pt={"16px"}
                >
                  <VStack
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap={0}
                    w="100%"
                  >
                    <TopMetadataStack card={card} />
                    <div className={style.reactMarkDown}>
                      <ReactMarkdown
                        rehypePlugins={[
                          rehypeRaw,
                          [rehypeSanitize, customSchema],
                        ]}
                      >
                        {card?.text}
                      </ReactMarkdown>
                    </div>
                    <Flex
                      w="100%"
                      gap={"20px"}
                      justifyContent={"space-between"}
                    >
                      <BottomMetadataStack card={card} />
                      <Spacer />
                      {TEMPLATE_CARD_TYPES[card.cardType]?.showReAdd && (
                        <ReaddToTemplateBttn card={card} />
                      )}
                      <ViewInSourceBttn projectId={projectId} card={card} />
                      {card.type === "fillTemplateInsight" ? (
                        <FeedbackComponent
                          card={card}
                          templateName={currentTemplate.name}
                          sectionName={sectionName}
                        />
                      ) : null}
                    </Flex>
                  </VStack>
                </Flex>
                <VStack
                  justifyContent="space-between"
                  align="flex-end"
                  pt="6px"
                >
                  <OverflowMenu openAlert={openAlert} />
                  {/* <HStack>
                    <Icon as={ViewIconComponent} color="primary" boxSize={4} />
                    <Text fontSize="8px" fontWeight="bold">
                      View in source
                    </Text>
                  </HStack> */}
                </VStack>
              </Flex>
              {/* <ViewInSourceBttn projectId={projectId} card={card} /> */}
            </Stack>
          </CardBody>
          <ImageViewer
            imageUrl={card.imageUrl}
            isOpen={isOpen}
            onClose={onClose}
          />
          <DeleteAlert
            action={() => {
              deleteCard(id, card.uid);
              closeAlert();
            }}
            isOpen={isAlertOpen}
            onClose={closeAlert}
            title="Delete Card"
            body="Are you sure you want to delete this card?"
          />
        </Card>
      )}
    </Draggable>
  );
};

export default SmartSectionCard;
