import {
  CLIENT_CUSTOMIZATION_DATA,
  ClientCustomization,
  DOMAIN_PROJECT_MAP,
} from "../../data/ClientCustomizationData";

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidationError";
    this.stack = ""; // Remove the stack trace
  }
}

/**
 * Recursively validates an object against a default object, ensuring that all properties from the default object are present in the client object.
 * If a property is missing in the client object, it will be added with the default value.
 * If a property is an object, the function will recursively validate the nested object.
 * If a property is missing in the default object, an error will be thrown.
 *
 * @param {object} defaultObj - The default object to validate against.
 * @param {object} clientObj - The client object to validate.
 * @param {string} [path=""] - The current path of the object being validated (used for error messages).
 * @throws {Error} If a property is missing in the default object.
 * @returns {void}
 */
const validateObject = (defaultObj, clientObj, path = "") => {
  for (const key in defaultObj) {
    if (
      typeof defaultObj[key] === "object" &&
      !Array.isArray(defaultObj[key])
    ) {
      if (!clientObj[key]) {
        clientObj[key] = {};
      }
      validateObject(defaultObj[key], clientObj[key], `${path}.${key}`);
    } else {
      if (clientObj[key] === undefined) {
        clientObj[key] = defaultObj[key];
      }
    }
  }

  for (const key in clientObj) {
    if (!(key in defaultObj)) {
      throw new ValidationError(
        `Field '${key}' at '${path}' doesn't have a default value. Add it to the "default" object in CLIENT_CUSTOMIZATION_DATA.`
      );
    }
  }
};

/**
 * Merges the client data with the default data, recursively merging nested objects.
 *
 * @param {Object} clientData - The client data to merge.
 * @param {Object} defaultData - The default data to merge with.
 * @returns {Object} - The merged data.
 */
const mergeWithDefault = (clientData, defaultData) => {
  const result = { ...defaultData };
  validateObject(defaultData, clientData);
  Object.keys(clientData).forEach((key) => {
    if (
      typeof clientData[key] === "object" &&
      !Array.isArray(clientData[key])
    ) {
      result[key] = mergeWithDefault(clientData[key], defaultData[key]);
    } else {
      result[key] = clientData[key];
    }
  });
  return result;
};

export const getCustomizationData = () => {
  const project = process.env.REACT_APP_PROJECT;
  let clientData;

  if (project) {
    clientData = CLIENT_CUSTOMIZATION_DATA[project];
  } else {
    const domain = window.location.hostname;
    const client = DOMAIN_PROJECT_MAP[domain];
    clientData = CLIENT_CUSTOMIZATION_DATA[client];
  }

  if (!clientData) {
    clientData = CLIENT_CUSTOMIZATION_DATA.default;
  }

  const mergedData = mergeWithDefault(
    clientData,
    CLIENT_CUSTOMIZATION_DATA.default
  );

  mergedData.assistant.openingMessage = mergedData.assistant.openingMessage(
    mergedData.assistant.name
  );

  return mergedData as ClientCustomization;
};

const validateAllClients = (defaultData, clientDataMap) => {
  Object.keys(clientDataMap).forEach((clientKey) => {
    const clientData = clientDataMap[clientKey];
    validateObject(
      defaultData,
      clientData,
      `CLIENT_CUSTOMIZATION_DATA.${clientKey}`
    );
  });
};

// Validate all clients during initialization
validateAllClients(
  CLIENT_CUSTOMIZATION_DATA.default,
  CLIENT_CUSTOMIZATION_DATA
);
