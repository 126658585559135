import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ControlCenterData,
  EnrichedSectionsMap,
  Metadata,
  SectionCardData,
  Snippet,
  _Section,
  createControlCenterData,
  createSectionsData,
} from "../Components/Utils/template";
import {
  createSection,
  deleteSection,
  subToTemplateSections,
  updateDocuments,
  updateTemplateSection,
} from "../db/organizer";
import { updateSnippet } from "../db/snippet";
import useGetSnippets from "../hooks/useGetSnippets";
import { useArticle } from "./ArticleContext";
import { useTemplateContext } from "./TemplateContext";

const CURRENT_TEMPLATE_VERSION = 2;

export const templateVersionOutdated = (templateVersion) => {
  return !templateVersion || templateVersion < CURRENT_TEMPLATE_VERSION;
};

interface MetadataFilter {
  type: keyof Metadata;
  value: string;
}

export interface CardTypeFilter {
  cardType: string;
}

interface IOrganizerContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  snippets: Snippet[];
  sections: EnrichedSectionsMap;
  activeSections: string[];
  setActiveSections: (sections) => void; // replace 'any' with the actual type
  createNewSection: (newSectionData) => void;
  deleteTemplateSection: (sectionId) => void;
  updateSectionTitle: (sectionId, updatedTitle) => void;
  onMouseUp: () => void;
  onMouseDown: (id) => void;
  deleteCard: (sectionId, cardId) => void;
  unRemoveCard: (snippetId) => void;
  onDragEnd: (result) => void;
  toggleMetadataFilter: (filter: MetadataFilter) => void;
  metadataFilters: MetadataFilter[];
  toggleCardTypeFilter: (filter: CardTypeFilter) => void;
  cardTypeFilters: CardTypeFilter[];
  controlSectionData: ControlCenterData;
  filteredSectionsData: EnrichedSectionsMap;
}

const OrganizerContext = createContext<IOrganizerContext | undefined>(
  undefined
);

/**
 * Augments the cards data with article titles based on the provided articleIdTitleMap.
 */
function augmentCardsData(
  sectionsData: EnrichedSectionsMap,
  articleIdTitleMap
) {
  Object.values(sectionsData).forEach((sectionData) => {
    sectionData.cards.forEach((card) => {
      if (!card.articleId) return;
      if (!articleIdTitleMap[card.articleId]) return;
      card.articleTitle = articleIdTitleMap[card.articleId];
    });
  });
}

export const OrganizerContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { projectId, articleId } = useParams();
  const { snippets } = useGetSnippets({
    projectId: projectId,
    curated: true,
  }) as { snippets: Snippet[] | undefined };
  const { articleIdTitleMap } = useArticle();

  const { snippets: articleSnippets } = useGetSnippets({
    snippetTypes: ["summary", "keyPoint"],
    projectId,
    articleId: articleId,
  }) as { isLoading: boolean; snippets: Snippet[] | undefined };

  // Define the shape of your Redux state
  interface RootState {
    curatedContents: {
      curatedContents: Snippet[];
    };
    // Add other top-level state properties as needed
  }

  // Use RootState to type the state argument in your useSelector hook
  const { curatedContents: curatedSnippets } = useSelector(
    (state: RootState) => state.curatedContents
  );
  const { currentTemplate } = useTemplateContext();
  const [rawTemplateSectionsData, setRawTemplateSectionsData] = useState<
    _Section[]
  >([]);
  const [sections, setSections] = useState<EnrichedSectionsMap>({});
  const [openedSections, setOpenedSections] = useState([] as string[]);
  const [temp, setTemp] = useState<string | null>();
  const [metadataFilters, setMetadataFilters] = useState<MetadataFilter[]>([]);
  const [cardTypeFilters, setCardTypeFilters] = useState<CardTypeFilter[]>([
    {
      cardType: "addedByUser",
    },
    {
      cardType: "aiGenerated",
    },
  ]);
  const [controlSectionData, setControlSectionData] =
    useState<ControlCenterData>({});
  const [filteredSectionsData, setFilteredSectionsData] =
    useState<EnrichedSectionsMap>({});

  /**
   * Load the sections data for the current template.
   */
  useEffect(() => {
    if (currentTemplate) {
      if (templateVersionOutdated(currentTemplate.version)) {
        console.error("Previous template version is no longer supported.");
        return;
      }
      let unsubscribe = () => {};
      console.log("currentTemplate", currentTemplate);
      unsubscribe = subToTemplateSections(
        projectId,
        currentTemplate?.id,
        (templateData) => {
          setRawTemplateSectionsData(templateData);
          const sectionsData = createSectionsData(
            templateData || [],
            curatedSnippets || []
          );
          if (sectionsData) {
            const controlData = createControlCenterData(sectionsData);
            setControlSectionData(controlData);
            // Sections are no longer open (active) by default santop 2024-05-08 Wed 21:21:32
            // setActiveSections(Object.keys(sectionsData));
          }

          augmentCardsData(sectionsData, articleIdTitleMap);

          setSections(sectionsData);
        }
      );
      return () => {
        console.log("Unsubscribed from current template sections");
        unsubscribe();
      };
    } else {
      setSections({} as EnrichedSectionsMap);
    }
  }, [projectId, curatedSnippets, currentTemplate]);

  /**
   * Apply metadata and card type filters to the sections data.
   */
  useEffect(() => {
    let filteredSectionsData = { ...sections };

    // As per product request, when no metadata filters are selected,
    // it is assumed that all metadata values are selected;
    // Hence, filtering is only applied when there is at least one metadata filter.
    if (metadataFilters.length > 0) {
      filteredSectionsData = filterByMetadata(
        filteredSectionsData,
        metadataFilters
      );
    }
    // As opposed to metadata filters, card type filters are always applied, and if
    // no card type filters are selected, it is assumed that no card types are selected.
    filteredSectionsData = filterByCardType(
      filteredSectionsData,
      cardTypeFilters
    );
    setFilteredSectionsData(filteredSectionsData);
  }, [sections, metadataFilters, cardTypeFilters]);

  const createNewSection = (newSectionData) => {
    const keyCount = Object.keys(sections).length;
    const payload = {
      order: keyCount + 1,
      name: newSectionData.name,
      id: newSectionData.id,
      snippets: [],
    };
    // setOpenedSections([...openedSections, newSectionData.id]);
    createSection({
      sectionId: newSectionData.id,
      payload,
      projectId,
      templateId: currentTemplate.id,
    });
  };

  const deleteTemplateSection = (sectionId) => {
    deleteSection({ sectionId, projectId, templateId: currentTemplate.id });
  };

  const updateSectionTitle = (sectionId, updatedTitle) => {
    updateTemplateSection({
      projectId: projectId,
      name: updatedTitle,
      templateId: currentTemplate.id,
      id: sectionId,
    });
  };

  const onMouseUp = () => {
    if (temp) {
      // if temp is not null, it means the section is actively opened
      setOpenedSections([...openedSections, temp]);
      setTemp(null);
    }
  };

  const onMouseDown = (id) => {
    const isActive = openedSections.includes(id); //check if the section is actively opened
    if (isActive) {
      setTemp(id);
      const newSections = openedSections.filter((item) => item !== id); // remove the section from active sections
      setOpenedSections(newSections);
    }
  };

  const deleteCard = async (sectionId, cardId) => {
    const cards = sections[sectionId].cards.map((card) => {
      if (card.uid == cardId) {
        return {
          snippetId: card.uid,
          cardType: "removedByUser",
        } as SectionCardData;
      }
      return {
        snippetId: card.uid,
        cardType: card.cardType,
      } as SectionCardData;
    });
    await updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: sectionId,
      snippets: cards,
    });
  };

  type SectionReoderInfo = {
    index: number;
    droppableId: string;
  };

  const updateTemplateOrder = async (
    source: SectionReoderInfo,
    destination: SectionReoderInfo
  ) => {
    // clone the data
    const clonedOrganizerData = [...rawTemplateSectionsData];
    // raw data is not sorted and comes in random order -- fixed
    clonedOrganizerData.sort((a, b) => a.order - b.order);
    // get the dragged section
    const [removedSection] = clonedOrganizerData.splice(source.index, 1);
    // insert the dragged section to the destination index
    // reorder the order property and update the firebase
    clonedOrganizerData.splice(destination.index, 0, removedSection);
    const updatedClonedData = clonedOrganizerData.map((d, i) => {
      return {
        ...d,
        order: i + 1,
      };
    });
    await updateDocuments(updatedClonedData, projectId, currentTemplate.id);

    if (temp) {
      setOpenedSections([...openedSections, temp]); // revert active sections
      setTemp(null); //reset temp
    }
  };

  const reorderSnippets = (sourceId, startIndex, endIndex) => {
    const cards = sections[sourceId].cards;
    const [removedCard] = cards.splice(startIndex, 1);
    cards.splice(endIndex, 0, removedCard);
    const snippets = cards.map(
      (card) =>
        ({
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData)
    );
    updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: sourceId,
      snippets,
    });
  };

  const copySnippet = (draggedSnippetId, targetSectionId, destination) => {
    // find snippet data of the dragged card
    let draggedSnippetData = curatedSnippets.find(
      (snippet) => snippet.uid == draggedSnippetId
    );
    let isCurated = true;
    // if the snippet is not found in the curated snippets, find it in the article snippets
    if (!draggedSnippetData && articleSnippets) {
      draggedSnippetData = articleSnippets.find(
        (content) => content.uid == draggedSnippetId
      );
      isCurated = false;
    }
    if (!draggedSnippetData) return;
    // get current section cards
    const sectionCards = [...sections[targetSectionId].cards];
    // insert the dragged snippet to the destination index
    sectionCards.splice(destination.index, 0, draggedSnippetData);
    const cardData = sectionCards.map((card) => {
      if (card.uid == draggedSnippetId)
        return {
          snippetId: card.uid,
          cardType: "addedByUser",
        } as SectionCardData;
      else
        return {
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData;
    });
    updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: targetSectionId,
      snippets: cardData,
    });

    // if the snippet was not from the curated snippets, update the snippet to be curated
    if (!isCurated) {
      updateSnippet({
        projectId,
        snippetId: draggedSnippetData.uid,
        curated: true,
        title: undefined,
        text: undefined,
        authorId: undefined,
        inTextLinkAddedToProject: undefined,
      });
    }
  };

  /**
   * Re-add a snippet that was previously removed by the user. Mark it as added by the user.
   */
  const unRemoveCard = (snippetId) => {
    const sectionId = Object.keys(sections).find((sectionId) =>
      sections[sectionId].cards.some((card) => card.uid === snippetId)
    );
    if (!sectionId) return;
    const sectionCards = sections[sectionId].cards.map((card) => {
      if (card.uid === snippetId) {
        return {
          snippetId: card.uid,
          cardType: "addedByUser",
        } as SectionCardData;
      } else {
        return {
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData;
      }
    });
    updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: sectionId,
      snippets: sectionCards,
    });
  };

  const moveSnippet = (
    fromSectionId,
    poluttedSnippetId,
    toSectionId,
    destinationIndex
  ) => {
    const snippetId = poluttedSnippetId.split("+")[0];
    const draggedSnippetData = sections[fromSectionId].cards
      ?.filter((card) => card.uid == snippetId)
      ?.map((card) => {
        return {
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData;
      })[0];
    const updatedSourceCards = sections[fromSectionId].cards.filter(
      (d) => d.uid != snippetId
    );
    const updatedSourceSnippets = updatedSourceCards.map(
      (card) =>
        ({
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData)
    );
    const targetSnippets = sections[toSectionId].cards.map(
      (card) =>
        ({
          snippetId: card.uid,
          cardType: card.cardType,
        } as SectionCardData)
    );
    const cloneTargetSnippets = [...targetSnippets];
    cloneTargetSnippets.splice(destinationIndex, 0, draggedSnippetData);

    updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: toSectionId,
      snippets: cloneTargetSnippets,
    });

    updateTemplateSection({
      projectId: projectId,
      templateId: currentTemplate.id,
      id: fromSectionId,
      snippets: updatedSourceSnippets,
    });
  };

  type DragEndResult = {
    source: SectionReoderInfo;
    destination: SectionReoderInfo;
    draggableId: string;
  };

  const onDragEnd = (result: DragEndResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    switch (source.droppableId) {
      case "SECTIONS":
        updateTemplateOrder(source, destination);
        break;
      case destination.droppableId:
        reorderSnippets(source.droppableId, source.index, destination.index);
        break;
      case "CARDS":
        copySnippet(draggableId, destination.droppableId, destination);
        break;
      default:
        moveSnippet(
          source.droppableId,
          draggableId,
          destination.droppableId,
          destination.index
        );
        break;
    }
  };

  const toggleMetadataFilter = (filter) => {
    // Convert controlFilters to a Set of JSON strings
    const filterSet = new Set(metadataFilters.map((f) => JSON.stringify(f)));

    const filterStr = JSON.stringify(filter);

    // Add or remove the filter based on its presence in the Set
    if (filterSet.has(filterStr)) {
      filterSet.delete(filterStr);
    } else {
      filterSet.add(filterStr);
    }

    // Convert the Set back to an array of objects
    const newControlFilters = Array.from(filterSet).map((f) => JSON.parse(f));

    setMetadataFilters(newControlFilters);
  };

  const toggleCardTypeFilter = (filter) => {
    // Convert controlFilters to a Set of JSON strings
    const filterSet = new Set(cardTypeFilters.map((f) => JSON.stringify(f)));

    const filterStr = JSON.stringify(filter);

    // Add or remove the filter based on its presence in the Set
    if (filterSet.has(filterStr)) {
      filterSet.delete(filterStr);
    } else {
      filterSet.add(filterStr);
    }

    // Convert the Set back to an array of objects
    const newControlFilters = Array.from(filterSet).map((f) => JSON.parse(f));

    setCardTypeFilters(newControlFilters);
  };

  const filterByMetadata = (
    sections: EnrichedSectionsMap,
    metadataFilters: MetadataFilter[]
  ): EnrichedSectionsMap => {
    return Object.keys(sections).reduce((filteredData, sectionKey) => {
      const sectionData = sections[sectionKey];

      const sectionFilteredCards = sectionData.cards.filter((card) =>
        metadataFilters.some(
          (filter) =>
            card.metadata && card.metadata[filter.type] === filter.value
        )
      );

      filteredData[sectionKey] = {
        ...sectionData,
        cards: sectionFilteredCards,
      };

      return filteredData;
    }, {});
  };

  const filterByCardType = (
    sections: EnrichedSectionsMap,
    cardTypeFilters: CardTypeFilter[]
  ): EnrichedSectionsMap => {
    return Object.keys(sections).reduce((filteredData, sectionKey) => {
      const sectionData = sections[sectionKey];

      const sectionFilteredCards = sectionData.cards.filter((card) =>
        cardTypeFilters.some((filter) => card.cardType === filter.cardType)
      );

      filteredData[sectionKey] = {
        ...sectionData,
        cards: sectionFilteredCards,
      };

      return filteredData;
    }, {});
  };

  return (
    <OrganizerContext.Provider
      value={{
        loading,
        setLoading,
        snippets: snippets || [],
        sections,
        activeSections: openedSections,
        setActiveSections: setOpenedSections,
        createNewSection,
        deleteTemplateSection,
        updateSectionTitle,
        onMouseUp,
        onMouseDown,
        deleteCard,
        unRemoveCard,
        onDragEnd,
        toggleMetadataFilter,
        metadataFilters,
        toggleCardTypeFilter,
        cardTypeFilters,
        controlSectionData,
        filteredSectionsData,
      }}
    >
      {children}
    </OrganizerContext.Provider>
  );
};

export const useOrganizerContext = () => {
  const context = useContext(OrganizerContext);
  if (context === undefined) {
    throw new Error("context must be used within an Organizer Provider");
  }
  return context;
};

export default OrganizerContext;
