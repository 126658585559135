import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { TableMoreActionBttn } from "./TableComponents";

const MenuListElement = ({ items }) => {
  return (
    <MenuList>
      {items.map((item, index) => (
        <MenuItem key={index} {...item}>
          {item.name}
        </MenuItem>
      ))}
    </MenuList>
  );
};

const SelectControlButton = ({ icon, color, onClick, items }) => {
  return (
    <Flex w="100%" pl="4" justify={"space-between"}>
      <HStack
        align="center"
        gap="0"
        minW="fit-content"
        alignItems="top"
        h="fit-content"
        pr="0"
        py="1"
        borderRadius="5px"
      >
        {icon && (
          <icon.type
            fontSize="14px"
            color={color}
            cursor="pointer"
            onClick={onClick}
          />
        )}
        <Menu w="20%">
          <MenuButton
            p="0"
            h="fit-content"
            as={Button}
            rightIcon={<ChevronDownIcon color="#727272" fontSize={"12px"} />}
            w="10% !important"
            minW="30%"
          />
          <MenuListElement items={items} />
        </Menu>
      </HStack>
      <TableMoreActionBttn />
    </Flex>
  );
};

export default SelectControlButton;
