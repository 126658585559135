import { Icon } from "@chakra-ui/react";
import React from "react";

const SlidesIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="black" {...props}>
      <path d="M8.5 4C8.35833 4 8.23958 3.95208 8.14375 3.85625C8.04792 3.76042 8 3.64167 8 3.5C8 3.35833 8.04792 3.23958 8.14375 3.14375C8.23958 3.04792 8.35833 3 8.5 3C8.64167 3 8.76042 3.04792 8.85625 3.14375C8.95208 3.23958 9 3.35833 9 3.5C9 3.64167 8.95208 3.76042 8.85625 3.85625C8.76042 3.95208 8.64167 4 8.5 4ZM4.5 7L5.85 5.25L6.625 6.25L7.775 4.75L9.5 7H4.5ZM2 11C1.725 11 1.48958 10.9021 1.29375 10.7063C1.09792 10.5104 1 10.275 1 10V3H2V10H9V11H2ZM3 4.6875V2C3 1.725 3.09792 1.48958 3.29375 1.29375C3.48958 1.09792 3.725 1 4 1H6.5V2H4V4.6875H3ZM4 9C3.725 9 3.48958 8.90208 3.29375 8.70625C3.09792 8.51042 3 8.275 3 8V5.6875H4V8H6.5V9H4ZM7.5 9V8H10V5.6875H11V8C11 8.275 10.9021 8.51042 10.7063 8.70625C10.5104 8.90208 10.275 9 10 9H7.5ZM10 4.6875V2H7.5V1H10C10.275 1 10.5104 1.09792 10.7063 1.29375C10.9021 1.48958 11 1.725 11 2V4.6875H10Z" />
    </Icon>
  );
};

export default SlidesIcon;
