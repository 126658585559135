import { Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { subToTemplateSections } from "../../../db/organizer";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { FloatingIndividualTemplateSection } from "./FloatingIndividualTemplateSection";

const customization = getCustomizationData();

export const ContextIndividualFloatingTemplate = ({
  id: templateId,
  text,
  highlightedText,
  onClickAway,
  sourceArticles,
}) => {
  const [showTemplateSection, setShowTemplateSection] = useState(false);
  const [showingTemplateSectionList, setShowingTemplateSectionList] =
    useState();
  const { setCurrentTemplate } = useTemplateContext();
  const [sectionData, setSectionData] = useState([]);
  const { projectId } = useParams();
  useEffect(() => {
    const unsubscribe = subToTemplateSections(
      projectId,
      templateId,
      (organizerData) => {
        setSectionData(organizerData);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [templateId]);

  //Order sectionData by order
  sectionData.sort((a, b) => {
    return a.order - b.order;
  });
  return (
    <Flex borderBottom="1px solid white">
      <Flex
        onMouseOver={() => {
          setShowTemplateSection(true);
        }}
        onMouseLeave={() => {
          if (!showingTemplateSectionList) {
            setCurrentTemplate(null);
            setShowTemplateSection(false);
          }
        }}
      >
        <Flex
          _hover={{
            bg: "#474959",
          }}
          maxH="20px"
          cursor={sectionData.length > 0 ? "pointer" : "not-allowed"}
          py="1"
          alignItems="center"
          justify="center"
          gap={2}
          minW="97px"
          maxW="97px"
          bg="primary"
          minH="20px"
          pl="1.5"
        >
          <Tooltip
            placement="right"
            label={
              sectionData.length <= 0 &&
              `${customization.template.name} has no sections`
            }
          >
            <Flex w="100%" justify="space-between" align="center">
              <Text fontWeight="bold" noOfLines={1}>
                {text}
              </Text>
              <AiOutlineCaretRight
                style={{
                  display: sectionData.length > 0 ? "block" : "none",
                }}
              />
            </Flex>
          </Tooltip>
        </Flex>
        <Flex flexDir="column" gap="1px" position="absolute" left="54.3%">
          {sectionData?.map((section, index) => (
            <FloatingIndividualTemplateSection
              key={index}
              sectionName={section.name}
              sectionId={section.id}
              templateId={templateId}
              highlightedText={highlightedText}
              displayTemplateSections={
                showTemplateSection || showingTemplateSectionList
                  ? "flex"
                  : "none"
              }
              MouseEnteredTemplateSection={() => {
                setShowingTemplateSectionList(true);
              }}
              MouseLeftTemplateSection={() => {
                setShowingTemplateSectionList(false);
              }}
              onClickAway={onClickAway}
              sourceArticles={sourceArticles}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
