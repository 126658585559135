import {
  Checkbox,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

const formSets = {
  motivationToUseProduct: {
    question: "What do you want to use the product for?",
    options: [
      { value: "professional", label: "Professional use" },
      { value: "personal", label: "Personal use" },
      { value: "playing_around", label: "Not sure, just playing around" },
    ],
    showOther: false,
  },

  inWhatIndustry: {
    question: "What industry are you in?",
    options: [
      { value: "finance", label: "Finance" },
      { value: "consulting", label: "Consulting" },
      { value: "pr_and_comms", label: "PR & Comms" },
      { value: "marketing", label: "Marketing" },
      { value: "legal", label: "Legal" },
    ],
    showOther: true,
  },

  howCanAiHelp: {
    question:
      "If you had a magic wand, what would you like AI to help you with in your work?",
    options: [
      {
        value: "organize_information_prepare_deliverables",
        label: "Organize information and prepare deliverables faster",
      },
      {
        value: "use_information_to_improve_work_quality",
        label:
          "Use all the information out there effectively to improve work quality",
      },
      {
        value: "focus_on_stimulating_aspects_ai_takes_care_of_redundant_tasks",
        label:
          "Focus on the stimulating aspects of my work such as critical thinking, driving revenue, while AI helps me take care of the redundant, time consuming tasks",
      },
      {
        value: "use_ai_to_find_information_faster",
        label: "Use AI to find information faster",
      },
      {
        value: "use_ai_to_serve_clients_faster",
        label: "Use AI to serve clients faster",
      },
    ],
    showOther: true,
  },

  aiAssistantRelevance: {
    question: "Let's get into the magic. You can select one or more options",
    options: [
      {
        value: "discoverig_and_playing_around",
        label: "I am discovering and playing around for now",
      },
      {
        value: "not_urgent_but_important",
        label: "Not urgent but important, I need to understand better",
      },
      {
        value: "strategic_priority",
        label: "It's a strategic priority",
      },
    ],
    showOther: true,
  },
};

interface TitleItem {
  content: string;
  bold?: boolean;
  italic?: boolean;
}

interface Option {
  value: string;
  label: string;
}

const checkboxStyles = {
  ".chakra-checkbox__control": {
    borderColor: "#999999",
    _checked: {
      bg: "transparent",
      borderColor: "#999999",
    },
  },
  ".chakra-checkbox__control svg": {
    color: "black",
  },
};

const inputStyles = {
  _focusVisible: {
    outline: "none",
  },
  bg: "white",
  border: "1px solid #999999",
  fontSize: "16px",
  _placeholder: {
    color: "#999999",
    fontStyle: "italic",
  },
};

interface OnboardingCustomFormProps {
  title: TitleItem[];
  optionsKey: keyof typeof formSets;
  allowNextStep: (value: boolean) => void;
  onFormChange: ({
    formKey,
    checkedOptions,
  }: {
    formKey: string;
    checkedOptions: Record<string, string | boolean>;
  }) => void;
}

export const OnboardingCustomForm = ({
  title,
  optionsKey: formKey,
  allowNextStep,
  onFormChange,
}: OnboardingCustomFormProps) => {
  const formOptions = formSets[formKey];
  const { register, control } = useForm();

  const watchedData = useWatch({ control });
  useEffect(() => {
    const checkedOptions = Object.entries(watchedData || {})
      .filter(([key]) => key !== "otherText")
      .reduce((acc, [key, value]) => {
        if (key === "other") {
          if (watchedData.otherText) {
            return { ...acc, [key]: watchedData.otherText };
          }
          return { ...acc, [key]: false };
        }
        return { ...acc, [key]: value };
      }, {});

    if (
      Object.values(checkedOptions).some(
        (value) => value === true || (typeof value === "string" && value)
      )
    ) {
      allowNextStep(true);
      onFormChange({ formKey, checkedOptions });
    } else {
      allowNextStep(false);
    }
  }, [watchedData]);

  const renderTitle = (item: TitleItem, index: number) => (
    <Heading
      display="inline"
      w="fit-content"
      key={index}
      fontWeight={item.bold ? "black" : "regular"}
      fontSize="16px"
      fontStyle={item.italic ? "italic" : "normal"}
      whiteSpace="nowrap"
    >
      {item.content}
    </Heading>
  );

  const renderOption = (option: Option, index: number) => (
    <Checkbox
      display="flex"
      key={index}
      {...register(option.value)}
      colorScheme="primary"
      sx={checkboxStyles}
    >
      <Text
        fontSize="16px"
        color={watchedData[option.value] ? "primary" : "#727272"}
      >
        {option.label}
      </Text>
    </Checkbox>
  );

  return (
    <Flex flexDir="column" gap="12px" w="100%">
      <HStack>{title.map((item, index) => renderTitle(item, index))}</HStack>
      <VStack align="start" spacing="16px" color="#727272">
        {formOptions.options.map((option, index) =>
          renderOption(option, index)
        )}
        <Checkbox
          {...register("other")}
          display={formOptions.showOther ? "flex" : "none"}
          colorScheme="primary"
          sx={checkboxStyles}
        >
          <Text
            fontSize="16px"
            color={watchedData["other"] ? "primary" : "#727272"}
          >
            Other
          </Text>
        </Checkbox>
        {formOptions.showOther && (
          <Input
            {...register("otherText")}
            isDisabled={!watchedData["other"]}
            placeholder="Type here"
            {...inputStyles}
          />
        )}
      </VStack>
    </Flex>
  );
};
