import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  IoMdAddCircleOutline,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
} from "react-icons/io";
import NumberInput from "./NumberInput";

type AdditionalProps = {
  placeholder?: string;
  height?: string | number;
  min?: number;
  max?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type InputCombinationWithTooltipProps = {
  title: string;
  tooltipText: string;
  width?: string;
  onChange: (value: string | number) => void;
  inputType: "textarea" | "input" | "numberInput";
  defaultValue?: string | number;
} & AdditionalProps;

const TextareaInput = ({ onChange, value, ...props }) => (
  <Textarea
    onChange={(e) => onChange(e.target.value)}
    value={value as string}
    {...props}
  />
);

const TextInput = ({ onChange, value, ...props }) => (
  <Input
    onChange={(e) => onChange(e.target.value)}
    value={value as string}
    {...props}
  />
);

export const InputCombinationWithTooltip = ({
  title,
  tooltipText,
  width = "md",
  onChange,
  inputType,
  defaultValue = "",
  ...inputProps
}: InputCombinationWithTooltipProps) => {
  const [value, setValue] = useState<string | number>(defaultValue);

  useEffect(() => {
    setValue(defaultValue); // Set the initial value on load
  }, [defaultValue]);

  const handleChange = (newValue: string | number) => {
    setValue(newValue); // Update the state
    onChange(newValue); // Call the onChange callback
  };

  let InputComponent;
  switch (inputType) {
    case "textarea":
      InputComponent = TextareaInput;
      break;
    case "input":
      InputComponent = TextInput;
      break;
    case "numberInput":
      InputComponent = NumberInput;
      break;
    default:
      throw new Error("Invalid input type");
  }

  return (
    <FormControl width={width}>
      <FormLabel
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        {title}
        {tooltipText && (
          <Tooltip label={tooltipText} ml={2} hasArrow>
            <InfoOutlineIcon ml="1" cursor="pointer" color="secondaryGrey" />
          </Tooltip>
        )}
      </FormLabel>
      <InputComponent
        fontWeight="normal"
        fontSize="12px"
        onChange={handleChange}
        value={value}
        _placeholder={{
          fontWeight: "normal",
          fontSize: "12px",
        }}
        background="white"
        p="8px"
        {...inputProps}
      />
    </FormControl>
  );
};

export const InputCombinationWithMenu = ({
  title,
  tooltipText,
  width = "md",
  onChange,
  options = ["True", "False"],
  defaultValue = "True",
}: {
  title: string;
  tooltipText: string;
  width?: string;
  onChange: (value: string) => void;
  options?: string[];
  defaultValue?: string;
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  // // if
  // useEffect(() => {

  return (
    <FormControl width={width}>
      <FormLabel
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        {title}
        {tooltipText && (
          <Tooltip label={tooltipText} ml={2} hasArrow>
            <InfoOutlineIcon ml="1" cursor="pointer" color="secondaryGrey" />
          </Tooltip>
        )}
      </FormLabel>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color="black"
          fontWeight="normal"
          fontSize="12px"
          variant="outline"
          borderColor="black"
          _hover={{ borderColor: "black" }}
          _active={{ borderColor: "black" }}
          _focus={{ borderColor: "black" }}
        >
          {selectedOption || "Select an option"}
        </MenuButton>
        <MenuList>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setSelectedOption(option);
                onChange(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </FormControl>
  );
};

export const InputCombinationWithAddIcon = ({
  title,
  tooltipText,
  width = "md",
  onChange,
  placeholder,
  inputType,
}) => {
  const InputOrTextarea = inputType === "textarea" ? Textarea : Input;
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const updateEmailValidity = (email) => {
    setEmail(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };
  return (
    <FormControl width={width}>
      <FormLabel
        color="black"
        fontSize="10px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        {title}
        {tooltipText && (
          <Tooltip label={tooltipText} ml={2} hasArrow>
            <InfoOutlineIcon ml="1" cursor="pointer" color="secondaryGrey" />
          </Tooltip>
        )}
      </FormLabel>
      <HStack bg="white" border="1px solid #E0E0E0" borderRadius="md" pr="4">
        <InputOrTextarea
          type="email"
          placeholder={placeholder}
          _placeholder={{
            fontWeight: "normal",
            fontSize: "12px",
          }}
          fontWeight="normal"
          fontSize="12px"
          rows={inputType === "textarea" ? 12 : 1}
          border="none"
          _focusVisible={{
            outline: "none",
            border: "none",
          }}
          onChange={(e) => updateEmailValidity(e.target.value)}
          value={email}
        />
        <Tooltip
          placement="right"
          label={
            !validEmail &&
            email.length >= 1 &&
            "Email is invalid. Please check and update"
          }
        >
          <span>
            <IoMdAddCircleOutline
              onClick={() => {
                if (!validEmail) return;
                onChange(email);
                setEmail("");
              }}
              color="#191D30"
              cursor="pointer"
              fontSize="13px"
            />
          </span>
        </Tooltip>
      </HStack>
      <FormHelperText
        fontSize="12px"
        color={validEmail ? "green" : "red"}
        display={email.length >= 1 ? "block" : "none"}
        mt="0"
      >
        {validEmail ? "valid email" : "invalid email"}
      </FormHelperText>
    </FormControl>
  );
};

export const ReadOrRagMode = ({ value, updateReadAndRagMode }) => {
  const options = [
    {
      title: "READ mode",
      text: "Quick Answers: Choose READ for fast, straight-to-the-point responses. Ideal for direct questions when you need immediate facts or solutions.",
      id: "read",
    },
    {
      title: "RAG mode",
      text: "Detailed Insights: Opt for RAG for comprehensive and in-depth answers, drawing from a broad range of sources. Perfect for when you want to explore a topic thoroughly.",
      id: "rag",
    },
  ];
  const OptionCard = ({ selected, title, text, id, updateReadAndRagMode }) => {
    return (
      <Flex
        p="16px"
        border={selected ? "1px solid #E0E0E0" : "1px solid transparent"}
        onClick={() => updateReadAndRagMode(id)}
        boxSizing="border-box"
        flexDir="column"
        borderRadius="4"
        cursor="pointer"
      >
        <HStack>
          {selected ? (
            <IoMdRadioButtonOn fontSize="12px" />
          ) : (
            <IoMdRadioButtonOff fontSize="12px" />
          )}
          <Text fontSize="10px" fontWeight="black">
            {title}
          </Text>
        </HStack>

        <Flex fontSize="10px" color="#757575">
          <Text>{text}</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex>
      {options.map((option, index) => (
        <OptionCard
          key={index}
          {...option}
          updateReadAndRagMode={() => {
            updateReadAndRagMode(option.id);
          }}
          selected={value === option.id}
        />
      ))}
    </Flex>
  );
};

// export const EditiableInputWithActionEditBttn = ({ onChange }) => {
//   // Create a reference to the input element
//   const inputRef = useRef<HTMLInputElement>(null);
//   const [showEditIcon, setShowEditIcon] = useState(true);
//   // Event handler to focus the input when the addon is clicked
//   const handleAddonClick = () => {
//     inputRef.current?.focus();
//     setShowEditIcon(false);
//   };

//   return (
//     <InputGroup
//       size="sm"
//       ml="4"
//       // onSubmit={() => {
//       //   console.log("Submit clicked");
//       // }}
//     >
//       <Input
//         onChange={(e) => onChange(e.target.value)}
//         // onSubmit={() => {
//         //   console.log("Submit clicked");
//         // }}
//         ref={inputRef}
//         placeholder="Add name"
//         _placeholder={{
//           color: "black",
//         }}
//         fontWeight="medium"
//         fontSize="14px"
//         border="none"
//         _focusVisible={{
//           outline: "none",
//         }}
//         p="0"
//         // w="50%"
//         w="fit-content"
//         // onClick={() => setShowEditIcon(false)}
//         onMouseLeave={() => {
//           setShowEditIcon(true);
//           inputRef.current?.blur();
//         }}
//       />
//       <InputRightAddon
//         bg="none"
//         border="none"
//         cursor="pointer"
//         onClick={handleAddonClick}
//         display={showEditIcon ? "flex" : "none"}
//         p="0"
//       >
//         <MdOutlineModeEdit />
//       </InputRightAddon>
//     </InputGroup>
//   );
// };
