import React from "react";
// import SearchIconComponent from "../Components/Icons/SearchIconComponent";
import { Flex, HStack, Text } from "@chakra-ui/react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../Components/Layout";
import { LibraryPageCustomAIModulesLists } from "../../Components/Library/LibraryCustomAIModulesList";
import { LibraryPageCategoryFilter } from "../../Components/Library/LibraryPageCategoryFilter";
import { LibraryPageTopRatedCustomAIModulesLists } from "../../Components/Library/LibraryTopRatedCustomAIModulesList";
import { RequestModuleBttn } from "../../Components/RequestModule/RequestModuleBttn";
import { getCustomizationData } from "../../Components/Utils/customizationUtils";
import "./userpage.module.css";

const customization = getCustomizationData();

const LibraryPage = () => {
  return (
    <Layout>
      <RequestModuleBttn />
      <Flex flexDir="column" mt="44px" maxW="784px">
        <HStack gap={4}>
          <Link to="/user?tab=custom-ai">
            <MdOutlineArrowBack
              color="primary"
              fontSize="14px"
              cursor="pointer"
            />
          </Link>

          <Text fontWeight="600" color="primary" fontSize="12px" noOfLines={1}>
            {customization.name} AI Modules Library
          </Text>
        </HStack>

        <LibraryPageTopRatedCustomAIModulesLists />
        <LibraryPageCategoryFilter />
        <LibraryPageCustomAIModulesLists />
      </Flex>
    </Layout>
  );
};

export default LibraryPage;
