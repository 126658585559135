import { Box, Button, Flex, Input } from "@chakra-ui/react";
import React, { useState } from "react";

const RenameComp = ({ cardTitle, handleEditSubmit, setShowRename }) => {
  const [renameFieldText, setRenameFieldText] = useState(cardTitle);

  return (
    <Flex w="80%" alignItems="center" gap={2}>
      <Input
        placeholder={cardTitle}
        onChange={(e) => {
          let text = e.target.value;
          setRenameFieldText(text);
        }}
        value={renameFieldText}
        variant="flushed"
        fontSize="12px"
        h="fit-content"
      />
      <Box mt={2}>
        <Button
          onClick={() => {
            handleEditSubmit(renameFieldText);
            setShowRename(false);
          }}
          colorScheme="blue"
          bg="primary"
          color="white"
          mr={2}
          fontSize="10px"
          p="2"
          py="2"
          h="0"
        >
          Save
        </Button>
        <Button
          id="close"
          onClick={() => {
            setShowRename(false);
          }}
          variant="outline"
          p="2"
          py="2"
          h="0"
          fontSize="10px"
        >
          Cancel
        </Button>
      </Box>
    </Flex>
  );
};

export default RenameComp;
