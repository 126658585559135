import { ParsedToken, User } from "firebase/auth";
import { DocumentReference } from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { UserDoc } from "shared/userTypes";
import { useCreateModulePermission } from "src/Components/AiModuleEditor/multistepAiModuleUtils";
import { usersCollection } from "src/db/user";
import { PROJECT_TOUR_ALL_STEPS_DONE } from "../Components/Tour/TourComponent";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import {
  auth,
  db,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "../firebase";
import { LoadingScreen } from "./LoadingAuth";
import TokenLimitationModal from "./TokenLimitationModal";

export type UserWithEmail = User & { email: string };

interface IAuthContext {
  currentUser: UserWithEmail;
  authClaims: ParsedToken | null;
  isAiModulesAdmin: boolean;
  userDoc: UserDoc;
  userDocRef: DocumentReference<UserDoc>;
  addCustomAiModule: (moduleId: string) => Promise<void>;
  removeCustomAiModule: (moduleId: string) => Promise<void>;
  isPremium: boolean;
  isPowerUser: boolean;
  logout: () => Promise<void>;
}

interface UserInitialData {
  status: "online" | "offline";
  createdAt: number;
  onboardingProcess?: {
    completed: boolean;
  };
  projectTour?: typeof PROJECT_TOUR_ALL_STEPS_DONE;
  skipSampleProject?: boolean;
}

const customization = getCustomizationData();

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  // const history = useHistory();
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }) {
  const [userDocLoading, setUserDocLoading] = useState(true);
  const [userDocRef, setUserDocRef] =
    useState<DocumentReference<UserDoc> | null>(null);
  const [userDoc, setUserDoc] = useState<UserDoc | null>(null);
  const [authClaims, setAuthClaims] = useState<ParsedToken | null>(null);
  const [isAiModulesAdmin, setIsAiModulesAdmin] = useState(false);
  const [currentUser, loading]: [
    User | null | undefined,
    boolean,
    Error | undefined
  ] = useAuthState(auth);
  const [isPowerUser, setIsPowerUser] = useState(false);
  const history = useHistory();

  const updateLoginStatus = async (userDocRef, status) => {
    updateDoc(userDocRef, {
      status,
    })
      .then(() => console.log("Updated user status to ", status))
      .catch((err) => {
        // When user is just created, before the userDoc is created, we try to update the status to online,
        // and it will fail. This is expected to happen and we can ignore the error.
        console.log(
          `Error updating user status to ${status}: ${err}. This is expected to happen 1 time for a new user.
        If you see this error more than once, or if you see this error for an existing user, it is wrong.
        `
        );
      });
  };

  const logout = async () => {
    if (!currentUser) {
      console.log("No current user, skipping logout");
      return;
    }
    //Update user status to offline
    const userDocRef = doc(db, `users/${currentUser.uid}`);
    updateLoginStatus(userDocRef, "offline")
      .then(async () => {
        console.log("Signing out user");
        await auth.signOut();
      })
      .catch((err) => {
        console.log(`Error updating user status to offline: ${err}`);
      });
  };

  // load userDoc
  useEffect(() => {
    if (loading) {
      console.log("AuthState is loading, waiting");
      setUserDocLoading(true);
      return;
    }
    if (!currentUser) {
      console.log("No current user, setting userDoc to null");
      setUserDoc(null);
      setUserDocLoading(false);
      return;
    }

    // retrieve user custom claims
    currentUser.getIdTokenResult().then((idTokenResult) => {
      setAuthClaims(idTokenResult.claims);
      setIsAiModulesAdmin(!!idTokenResult.claims.aiModulesAdmin);
    });

    // if email ends with @mosaiqlabs.com
    setIsPowerUser(currentUser.email?.endsWith("@mosaiqlabs.com") ?? false);

    const userDocRef = doc(usersCollection, currentUser.uid);
    setUserDocRef(userDocRef);
    // whenever we access userDoc, it means the user is online
    try {
      updateLoginStatus(userDocRef, "online");
    } catch (err) {
      console.log(`Error updating user status to online: ${err}`);
    }
    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        console.log(`Updating userDoc for ${currentUser.uid}`);
        if (!doc.exists()) {
          const userInitialData: UserInitialData = {
            status: "online",
            createdAt: Date.now(),
          };

          if (customization.disableOnboarding) {
            userInitialData.onboardingProcess = {
              completed: true,
            };
          }

          if (customization.disableProductTour) {
            userInitialData.projectTour = PROJECT_TOUR_ALL_STEPS_DONE;
          }

          if (customization.skipSampleProject) {
            userInitialData.skipSampleProject = true;
          }

          console.log("Creating userDoc for ", currentUser.uid);
          setDoc(userDocRef, userInitialData, { merge: true });
        } else {
          console.log(`UserDoc for ${currentUser.uid} exists`);
          setUserDoc(doc.data());
          setUserDocLoading(false);
        }
      },
      (err) => {
        console.log(`Error updating userDoc for ${currentUser.uid}: ${err}`);
      }
    );
    return () => {
      console.log(`Unsubscribing from userDoc for ${currentUser.uid}`);
      setUserDocLoading(true);
      unsubscribe();
    };
  }, [currentUser, loading]);

  const { mutateAsync: createModulePermission } = useCreateModulePermission();

  const addCustomAiModule = useCallback(
    async (moduleId) => {
      if (!currentUser?.email) {
        console.error("No current user email, skipping addCustomAiModule");
        return;
      }
      await createModulePermission({
        moduleId,
        userId: currentUser.uid,
        userEmail: currentUser.email,
        role: "user",
      });
    },
    [currentUser?.uid, currentUser?.email]
  );

  const removeCustomAiModule = useCallback(
    async (moduleId) => {
      if (!currentUser?.uid) {
        console.error("No current user uid, skipping removeCustomAiModule");
        return;
      }
      const permissionDocRef = doc(
        db,
        `aiModules/${moduleId}/aiModulePermissions/${currentUser.uid}`
      );
      await deleteDoc(permissionDocRef);
    },
    [currentUser?.uid]
  );

  if (loading || userDocLoading) {
    console.log("Loading auth, userDoc or userClaims");
    return <LoadingScreen />;
  }

  if (currentUser && !currentUser.email) {
    console.log("No current user email, logging out");
    logout();
    history.push("/");
    return <LoadingScreen />;
  }

  if (currentUser && !userDoc) {
    console.log("User is logged in but userDoc is null");
    return <LoadingScreen />;
  }

  if (currentUser && !userDocRef) {
    console.log("User is logged in but userDocRef is null");
    return <LoadingScreen />;
  }

  if (!userDoc || !userDocRef) {
    history.push("/");
    // throw new Error("UserDoc or userDocRef is null, redirecting to home");
    return <LoadingScreen />;
  }

  const contextValue = {
    currentUser: currentUser as UserWithEmail,
    authClaims,
    isAiModulesAdmin,
    userDoc,
    userDocRef,
    isPremium: userDoc?.subscriptionStatus === "premium",
    isPowerUser,
    logout,
    addCustomAiModule,
    removeCustomAiModule,
  };

  // console.log("AuthContext.Provider value:", contextValue);
  return (
    <AuthContext.Provider value={contextValue}>
      <>
        {children}
        <TokenLimitationModal />
      </>
    </AuthContext.Provider>
  );
}
