import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export interface ValidationResponse {
  valid: boolean;
  errors: string[];
}

export default function useValidateMultistepConfig() {
  return useMutation<ValidationResponse, Error, object>({
    mutationFn: async (yaml: object | undefined) => {
      const url = process.env.REACT_APP_VALIDATE_MODULE_URL;
      if (!url) {
        throw new Error("REACT_APP_VALIDATE_MODULE_URL is not set");
      }

      if (!yaml) {
        return { valid: false, errors: ["Module config is empty"] };
      }

      try {
        const response = await axios.post<ValidationResponse>(url, yaml);
        console.log("Validation response:", response.data);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // Handle 400 status code
          if (error.response.status === 400) {
            return error.response.data as ValidationResponse;
          }
        }
        throw new Error(`Error validating config: ${error}`);
      }
    },
  });
}
