import { Divider, Flex, HStack, Input, Text } from "@chakra-ui/react";
import React from "react"
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { BiHighlight } from "react-icons/bi";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";


export const PDFViewerPageControl = ({
    currentPdfPage,
    updateCurrentPdfPage,
    totalPage,
  }) => {
    const handlePageInputChange = (value) => {
      if (parseInt(value)) {
        updateCurrentPdfPage(value);
      }
    };
    return (
      <HStack>
        <Input
          value={currentPdfPage}
          _placeholder={{
            color: "#5f616e",
          }}
          color="#5f616e"
          p="0"
          m="0"
          border="none"
          w="30px"
          height="20px"
          outline="none"
          fontSize="14px"
          _hover={{
            outline: "none",
          }}
          _focusVisible={{
            outline: "none",
          }}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            handlePageInputChange(value);
          }}
          bg="white"
          display="flex"
          pl={currentPdfPage > 9 ? "2" : "3"}
        />
        <Text color="#5f616e" fontSize="14px">
          /
        </Text>
        <Text fontSize="14px" color="#5f616e">
          {" "}
          {totalPage}
        </Text>
      </HStack>
    );
  };

  
export const PdfViewerZoom = ({ zoomOutFunc, zoomInFunc, scale }) => {
    return (
      <HStack userSelect="none">
        <AiOutlineMinus color="#5f616e" onClick={zoomOutFunc} cursor="pointer" />
        <Text bg="white" px="2" py="1" borderRadius="2" fontSize="14px">
          {parseInt(scale * 100)}%
        </Text>
        <AiOutlinePlus color="#5f616e" onClick={zoomInFunc} cursor="pointer" />
      </HStack>
    );
  };

export const ToggleHighlight = ({ toggleHighlight, highlightAllowed }) => {
    return (
      <Flex
        px="2"
        py="1"
        bg={highlightAllowed ? "primary" : "white"}
        align="center"
        borderRadius="2"
        mr={6}
        cursor="pointer"
        onClick={() => {
          toggleHighlight();
        }}
        display="none"
      >
        <BiHighlight color={highlightAllowed ? "white" : "#757575"} />
      </Flex>
    );
  };

  
export const PDFViewerHeader = ({
    currentPdfPage,
    updateCurrentPdfPage,
    totalPage,
    zoomIn,
    zoomOut,
    highlightAllowed,
    onOpen,
    scale,
    toggleHighlight,
    style,
    reduceHeading,
  }) => {
    return (
      <Flex
        py={8}
        pt={reduceHeading && "0"}
        justify="center"
        userSelect="none"
        {...style}
      >
        <PDFViewerPageControl
          currentPdfPage={currentPdfPage}
          updateCurrentPdfPage={updateCurrentPdfPage}
          totalPage={totalPage}
        />
        <Divider orientation="vertical" mx={6} />
        <PdfViewerZoom zoomInFunc={zoomIn} zoomOutFunc={zoomOut} scale={scale} />
        <Divider orientation="vertical" mx={6} />
        <ToggleHighlight
          toggleHighlight={toggleHighlight}
          highlightAllowed={highlightAllowed}
        />
        <HStack
          onClick={() => {
            onOpen();
          }}
          cursor="pointer"
        >
          <MdOutlineBookmarkBorder fontSize="14px" color="#757575" />
          <Text fontSize="14px" color="#757575">
            All highlights
          </Text>
        </HStack>
      </Flex>
    );
  };
  