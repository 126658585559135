import { Icon } from "@chakra-ui/react";
import React from "react";

const ControlCenterIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="black" opacity={0.54} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33333 9.33333H6.66667L6.86667 8.33333C7 8.27778 7.125 8.21945 7.24167 8.15833C7.35833 8.09722 7.47778 8.02222 7.6 7.93333L8.56667 8.23333L9.23333 7.1L8.46667 6.43333C8.48889 6.27778 8.5 6.13333 8.5 6C8.5 5.86667 8.48889 5.72222 8.46667 5.56667L9.23333 4.9L8.56667 3.76667L7.6 4.06667C7.47778 3.97778 7.35833 3.90278 7.24167 3.84167C7.125 3.78056 7 3.72222 6.86667 3.66667L6.66667 2.66667H5.33333L5.13333 3.66667C5 3.72222 4.875 3.78056 4.75833 3.84167C4.64167 3.90278 4.52222 3.97778 4.4 4.06667L3.43333 3.76667L2.76667 4.9L3.53333 5.56667C3.51111 5.72222 3.5 5.86667 3.5 6C3.5 6.13333 3.51111 6.27778 3.53333 6.43333L2.76667 7.1L3.43333 8.23333L4.4 7.93333C4.52222 8.02222 4.64167 8.09722 4.75833 8.15833C4.875 8.21945 5 8.27778 5.13333 8.33333L5.33333 9.33333ZM6 7.33333C5.63333 7.33333 5.31944 7.20278 5.05833 6.94167C4.79722 6.68056 4.66667 6.36667 4.66667 6C4.66667 5.63333 4.79722 5.31944 5.05833 5.05833C5.31944 4.79722 5.63333 4.66667 6 4.66667C6.36667 4.66667 6.68056 4.79722 6.94167 5.05833C7.20278 5.31944 7.33333 5.63333 7.33333 6C7.33333 6.36667 7.20278 6.68056 6.94167 6.94167C6.68056 7.20278 6.36667 7.33333 6 7.33333ZM1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM1.33333 10.6667H10.6667V1.33333H1.33333V10.6667Z" />
      </svg>
    </Icon>
  );
};

export default ControlCenterIcon;
