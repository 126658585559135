import { getProjectPermissionsCollection } from "src/db/project";
import { deleteDoc, doc, setDoc } from "../../../firebase";
import { isValidEmail } from "./SharingSettingModal";

export const createdUserSharedWithDoc = async ({
  uid,
  email,
  projectId,
  permission,
}) => {
  const userSharedWithDocRef = doc(
    getProjectPermissionsCollection(projectId),
    uid
  );
  await setDoc(userSharedWithDocRef, {
    email,
    permission,
    uid,
  });
};

export const updateUserPermission = async ({
  userId,
  projectId,
  permission,
}) => {
  const userSharedWithDocRef = doc(
    getProjectPermissionsCollection(projectId),
    userId
  );
  if (permission === "none") {
    await deleteDoc(userSharedWithDocRef);
  } else {
    await setDoc(
      userSharedWithDocRef,
      {
        permission: permission,
      },
      { merge: true }
    );
  }
};

export const checkCannotAdd = (email, currentUser, userPermissions, notify) => {
  if (!email) {
    notify({
      title: "Error",
      description: "Please enter an email",
      status: "error",
      position: "top-right",
    });
    return true;
  } else if (!isValidEmail(email)) {
    notify({
      title: "Error",
      description: "Please enter a valid email",
      status: "error",
      position: "top-right",
    });
    return true;
  } else if (currentUser.email === email) {
    notify({
      title: "Error",
      description: "You cannot invite yourself",
      status: "error",
      position: "top-right",
    });
    return true;
  } else if (userPermissions.find((user) => user.email === email)) {
    notify({
      title: "Error",
      description: "User already has access",
      status: "error",
      position: "top-right",
    });
    return true;
  } else {
    return false;
  }
};
