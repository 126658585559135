import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getCustomizationData } from "../Components/Utils/customizationUtils";
import { TEMPLATE_MODE, ViewMode } from "../data/AssistantData";
import {
  createSection,
  createTemplate,
  getTemplateList,
  updateTemplate,
} from "../db/organizer";

import { db } from "../firebase";
import { useAssistantContext } from "./AssistantContext";

const TemplateContext = createContext();
const customization = getCustomizationData();

export const TemplateContextProvider = ({ children }) => {
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templateState, setTemplateState] = useState(TEMPLATE_MODE.LIST);
  const [templateList, setTemplateList] = useState([]);
  const { setViewMode } = useAssistantContext();
  const { projectId } = useParams();

  /**
   * Handles the back navigation action from a single template view.
   * If the current template is null, it sets the view mode to CHAT.
   * Otherwise, it sets the current template to null and the template state to LIST.
   *
   * This is overloaded to handle multiple scenarios, ideally it should be split into two functions.
   */
  const handleBackNavigation = () => {
    if (currentTemplate === null) {
      setViewMode(ViewMode.CHAT);
      return;
    }
    setCurrentTemplate(null);
    setTemplateState(TEMPLATE_MODE.LIST);
  };

  /**
   * Retrieve templates (without sections) when a project is selected.
   */
  useEffect(() => {
    // let unsubscribe = () => {};
    if (projectId) {
      const unsubscribe = getTemplateList(projectId, (templates) => {
        setTemplateList(templates.sort((a, b) => b.createdAt - a.createdAt));
      });
      return () => {
        console.log("Unsubscribed from templates collection");
        unsubscribe();
      };
    }
  }, [projectId]);

  /**
   * Update the current template when the template list is updated.
   */
  useEffect(() => {
    if (currentTemplate) {
      const updatedTemplate = templateList.find(
        (t) => t.id === currentTemplate.id
      );
      if (updatedTemplate) {
        setCurrentTemplate(updatedTemplate);
      }
    }
  }, [templateList]);

  /**
   * Handles the selection of a template from the template list.
   *
   * @param {string} templateId
   */
  const handleTemplateSelection = (templateId) => {
    const template = templateList.find((t) => t.id === templateId);
    if (template) {
      setCurrentTemplate(template);
      setTemplateState(TEMPLATE_MODE.VIEW);
    }
  };

  /**
   * Create a new template with a default section.
   */
  const createNewTemplate = () => {
    const templateId = uuidv4();
    const keyCount = templateList.length;
    const payload = {
      name: `New ${customization.template.name} ${keyCount + 1}`,
      id: templateId,
      createdAt: Date.now(),
      description: `New ${customization.template.name} description`,
      version: 2,
    };
    createTemplate(templateId, payload, projectId);

    //Add section to the newly created template
    const sectionId = uuidv4();
    const sectionPayload = {
      order: 0,
      name: "Click here to change “Title”",
      id: sectionId,
      snippets: [],
    };
    createSection({
      sectionId: sectionId,
      payload: sectionPayload,
      projectId,
      templateId: templateId,
    });
  };

  const updateTemplateName = (value, templateId) => {
    if (currentTemplate) {
      setCurrentTemplate({ ...currentTemplate, name: value });
    }
    updateTemplate({
      projectId: projectId,
      name: value,
      templateId,
    });
  };

  const copyTemplateWithSections = async (
    oldTemplateId,
    newTemplateId,
    withSectionsData
  ) => {
    const oldTemplateRef = doc(
      db,
      `projects/${projectId}/templates/${oldTemplateId}`
    );
    const oldTemplateSnap = await getDoc(oldTemplateRef);

    if (!oldTemplateSnap.exists()) {
      throw new Error("Template not found");
    }

    const oldTemplateData = oldTemplateSnap.data();

    const newTemplateData = {
      ...oldTemplateData,
      id: newTemplateId,
      createdAt: Date.now(),
      name: `${oldTemplateData.name} Copy`,
    };

    // delete fields related to done fill templates
    // so that new template is not confused with old fill status
    delete newTemplateData.fillTemplateStatus;
    delete newTemplateData.fillTemplateArticles;

    await setDoc(
      doc(db, `projects/${projectId}/templates/${newTemplateId}`),
      newTemplateData
    );

    const sectionsRef = collection(
      db,
      `projects/${projectId}/templates/${oldTemplateId}/organizerSections`
    );
    const sectionsSnap = await getDocs(sectionsRef);

    const copyPromises = sectionsSnap.docs.map(async (sectionDoc) => {
      const sectionData = sectionDoc.data();
      const newSectionId = uuidv4();

      const newSectionData = {
        ...sectionData,
        id: newSectionId,
        snippets: withSectionsData ? sectionData.snippets : [],
      };

      await setDoc(
        doc(
          db,
          `projects/${projectId}/templates/${newTemplateId}/organizerSections/${newSectionId}`
        ),
        newSectionData
      );
    });

    await Promise.all(copyPromises);
  };

  return (
    <TemplateContext.Provider
      value={{
        currentTemplate,
        templateState,
        handleBackNavigation,
        setCurrentTemplate,
        templateList,
        setTemplateList,
        handleTemplateSelection,
        createNewTemplate,
        copyTemplateWithSections,
        updateTemplateName,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (context === undefined) {
    throw new Error("context must be used within a TemplateProvider");
  }
  return context;
};

export default TemplateContext;
