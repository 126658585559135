import { Box, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TABS } from "../../Pages/UserHomePage/UserHomePage";
import { LibraryIcon } from "../Icons/LibraryIcon";

/**
 *
 * @param {Object} props
 * @param {string} props.text
 * @param {Object} props.type
 * @param {string} props.type.id
 * @param {React.ReactElement} props.type.icon
 * @param {number} [props.totalNum] - Optional prop for total number
 * @returns {React.ReactElement}
 */
export const HomeTabSwitchBttn = ({ text, type, totalNum }) => {
  const history = useHistory();
  const searchParams = history.location.search;
  const currentSearchParams = new URLSearchParams(searchParams);
  const selectedType = currentSearchParams.get("tab");

  const getBttnBackground = () => {
    if (type.id === selectedType) {
      return "primary";
    } else {
      return "transparent";
    }
  };

  const getColor = () => {
    if (type.id === selectedType) {
      return "white";
    } else {
      return "primary";
    }
  };

  const updateSearchParams = () => {
    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.set("tab", type.id);
    history.push({
      search: currentSearchParams.toString(),
    });
  };

  useEffect(() => {
    if (!selectedType) {
      currentSearchParams.set("tab", TABS.PROJECTS.id);
      history.push({
        search: currentSearchParams.toString(),
      });
    }
  }, [searchParams]);

  return (
    <Button
      leftIcon={type.icon}
      w="fit-content"
      h="fit-content"
      p="4px 8px 4px"
      fontSize="12px"
      bg={getBttnBackground()}
      onClick={() => {
        updateSearchParams();
      }}
      color={getColor()}
    >
      {text} {totalNum ? `(${totalNum})` : ""}
    </Button>
  );
};

export const HomeLibraryBttn = () => {
  return (
    <Link to="/library?category=all">
      <Button
        rightIcon={
          <Box
            justifyContent="center"
            alignContent="center"
            opacity="0.87"
            borderRadius="2px"
          >
            <LibraryIcon />
          </Box>
        }
        p={0}
        w="fit-content"
        h="fit-content"
        fontSize="12px"
        fontWeight="500"
        bg="transparent"
        color="primary"
      >
        Library
      </Button>
    </Link>
  );
};
