import {
  collection,
  collectionGroup,
  doc,
  FirestoreDataConverter,
} from "firebase/firestore";
import {
  COL_RETIRED_WORKSPACE_PERMISSIONS,
  COL_WORKSPACE_PERMISSIONS,
  COL_WORKSPACES,
} from "shared/constants";
import {
  RetiredWorkspacePermissionDoc,
  WorkspaceDoc,
  WorkspacePermissionDoc,
} from "shared/workspaceTypes";
import { db } from "src/firebase";

const workspaceConverter: FirestoreDataConverter<WorkspaceDoc> = {
  toFirestore: (workspace: WorkspaceDoc) => workspace,
  fromFirestore: (snapshot) => snapshot.data() as WorkspaceDoc,
};

const workspacePermissionConverter: FirestoreDataConverter<WorkspacePermissionDoc> =
  {
    toFirestore: (permission: WorkspacePermissionDoc) => permission,
    fromFirestore: (snapshot) => snapshot.data() as WorkspacePermissionDoc,
  };

const retiredWorkspacePermissionConverter: FirestoreDataConverter<RetiredWorkspacePermissionDoc> =
  {
    toFirestore: (permission: RetiredWorkspacePermissionDoc) => permission,
    fromFirestore: (snapshot) =>
      snapshot.data() as RetiredWorkspacePermissionDoc,
  };

export const workspacePermissionsCollectionGroup = collectionGroup(
  db,
  COL_WORKSPACE_PERMISSIONS
).withConverter(workspacePermissionConverter);

export const workspacesCollection = collection(
  db,
  COL_WORKSPACES
).withConverter(workspaceConverter);

export const getWorkspacePermissionsCollection = (workspaceId: string) =>
  collection(
    doc(workspacesCollection, workspaceId),
    COL_WORKSPACE_PERMISSIONS
  ).withConverter(workspacePermissionConverter);

export const getRetiredWorkspacePermissionsCollection = (workspaceId: string) =>
  collection(
    doc(workspacesCollection, workspaceId),
    COL_RETIRED_WORKSPACE_PERMISSIONS
  ).withConverter(retiredWorkspacePermissionConverter);
