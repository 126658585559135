import React from "react";
import { Icon } from "@chakra-ui/react";

const ZoomInIconComponent = (props) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M10.5033 9.50333H9.97665L9.78998 9.32333C10.4433 8.56333 10.8366 7.57667 10.8366 6.50333C10.8366 4.11 8.89665 2.17 6.50332 2.17C4.10998 2.17 2.16998 4.11 2.16998 6.50333C2.16998 8.89666 4.10998 10.8367 6.50332 10.8367C7.57665 10.8367 8.56332 10.4433 9.32332 9.79L9.50332 9.97667V10.5033L12.8366 13.83L13.83 12.8367L10.5033 9.50333ZM6.50332 9.50333C4.84332 9.50333 3.50332 8.16333 3.50332 6.50333C3.50332 4.84333 4.84332 3.50333 6.50332 3.50333C8.16332 3.50333 9.50332 4.84333 9.50332 6.50333C9.50332 8.16333 8.16332 9.50333 6.50332 9.50333ZM6.83665 4.83666H6.16998V6.17H4.83665V6.83666H6.16998V8.17H6.83665V6.83666H8.16998V6.17H6.83665V4.83666Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default ZoomInIconComponent;
