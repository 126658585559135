import { Icon } from "@chakra-ui/react";
import React from "react";

const ThumbUpIcon = (props) => {
  return (
    <Icon viewBox="0 0 18 18" fill="none" stroke="#191D30" {...props}>
      <path
        d="M4.83329 8.1665L8.16663 0.666504C8.82967 0.666504 9.46555 0.929896 9.93439 1.39874C10.4032 1.86758 10.6666 2.50346 10.6666 3.1665V6.49984H15.3833C15.6249 6.4971 15.8642 6.54692 16.0846 6.64585C16.305 6.74478 16.5013 6.89045 16.6598 7.07277C16.8184 7.25508 16.9354 7.46969 17.0027 7.70171C17.0701 7.93374 17.0862 8.17763 17.05 8.4165L15.9 15.9165C15.8397 16.3139 15.6378 16.6762 15.3315 16.9365C15.0253 17.1968 14.6352 17.3377 14.2333 17.3332H4.83329M4.83329 8.1665V17.3332M4.83329 8.1665H2.33329C1.89127 8.1665 1.46734 8.3421 1.15478 8.65466C0.842221 8.96722 0.666626 9.39114 0.666626 9.83317V15.6665C0.666626 16.1085 0.842221 16.5325 1.15478 16.845C1.46734 17.1576 1.89127 17.3332 2.33329 17.3332H4.83329"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ThumbUpIcon;
