import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

export const NewModuleButton = () => {
  return (
    <Link to="/ai-module-editor/">
      <Button
        rightIcon={
          <Flex opacity="0.87" borderRadius="2px">
            <IoMdAddCircleOutline fontSize="12px" color="black" />
          </Flex>
        }
        p={0}
        w="fit-content"
        h="fit-content"
        fontSize="12px"
        fontWeight="500"
        bg="transparent"
        color="primary"
      >
        New
      </Button>
    </Link>
  );
};
