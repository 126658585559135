import { useState } from "react";
import { createArticle } from "../db/article";
import { updateSnippet } from "../db/snippet";
import { auth } from "../firebase";
import md5 from "md5";

const useInsight = (projectId) => {
  const [activeInsight, setActiveInsight] = useState(null);
  const updateSnippetCurated = (snippetId, isCurated) => {
    updateSnippet({ projectId, snippetId, curated: isCurated });
  };

  const addArticleToProject = (snippetId, url) => {
    updateSnippet({
      projectId,
      snippetId,
      inTextLinkAddedToProject: true,
    });
    const createdAt = Date.now(); //new Date().toISOString();
    const type = url.endsWith(".pdf") ? "pdf" : "html";
    const createdBy = auth.currentUser.uid;
    const articleId = md5(url);
    const payload = {
      createdAt,
      type,
      createdBy,
      url,
    };
    createArticle({ projectId, articleId, payload });
  };

  return {
    activeInsight,
    setActiveInsight,
    updateSnippetCurated,
    addArticleToProject,
  };
};

export default useInsight;
