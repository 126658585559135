import { FirestoreDataConverter } from "firebase/firestore";
import { removeUndefinedNullRecursive } from "../Components/Utils/dbUtils";
import {
  collection,
  db,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  q,
  setDoc,
  updateDoc,
  where,
} from "../firebase";

import { ArticleDoc } from "shared/articleTypes";
import { COL_ARTICLES } from "shared/constants";
import { ProjectDoc } from "shared/projectTypes";
import { SnippetDoc } from "shared/snippetTypes";
import { v4 as uuidv4 } from "uuid";
import { projectsCollection } from "./project";
import { getSnippetsCollection } from "./snippet";

const articleConverter: FirestoreDataConverter<ArticleDoc> = {
  toFirestore: (article: ArticleDoc) => article,
  fromFirestore: (snapshot) => snapshot.data() as ArticleDoc,
};

export const getArticlesCollection = (projectId) =>
  collection(doc(projectsCollection, projectId), COL_ARTICLES).withConverter(
    articleConverter
  );

const getArticleDocRef = ({ projectId, articleId }) =>
  doc(getArticlesCollection(projectId), articleId);

// const createArticlesCollectionPath = ({ projectId }) =>
//   "projects/" + projectId + "/articles";

// star Web Article
export const starArticle = (projectId, articleId) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  // dbref.update({
  updateDoc(articleDocRef, {
    starred: true,
  });
};

// Unstar Web Article
export const unStarArticle = (projectId, articleId) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  updateDoc(articleDocRef, {
    starred: false,
  });
};

//Update the requestInsight attr in an article
// export const updateArticleRequestInsights = ({ projectId, articleId }) => {
//   const articleDocRef = doc(db, createArticleDocRef({ projectId, articleId }));
//   updateDoc(articleDocRef, {
//     requestInsights: true,
//   }).then(() => {
//     return "done";
//   });
// };

export const updateArticleType = ({ projectId, articleId, type }) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  updateDoc(articleDocRef, {
    type,
  }).then(() => {
    return "done";
  });
};

//Get an article
export const getArticle = ({ projectId, articleId }) => {
  return new Promise((resolve) => {
    const articleDocRef = getArticleDocRef({ projectId, articleId });
    getDoc(articleDocRef).then((result) => {
      if (result.data()) {
        resolve({
          ...result.data(),
          uid: result.id,
        });
      }
    });
  });
};

export const editArticleTitle = (projectId, articleId, newtitle) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  updateDoc(articleDocRef, {
    title: newtitle,
  });
};

// add article to project
export const createArticle = ({
  projectId,
  articleId,
  payload,
}: {
  projectId: ProjectDoc["uid"];
  articleId: ArticleDoc["uid"];
  payload: ArticleDoc;
}) => {
  console.log("Adding article", articleId, "to project", projectId);
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  setDoc(articleDocRef, { ...payload }).then(() => {
    console.log("Added article", articleId, "to project", projectId);
  });
  // .catch((e) => {
  //   console.log(e);
  // });
};

export const deleteArticle = (projectId, articleId) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  deleteDoc(articleDocRef);
};

export const getAllArticles = ({ projectId, callback }) => {
  const articleCollectionQuery = q(
    // collection(db, `projects/${projectId}/articles`)
    getArticlesCollection(projectId)
  );
  const unsubscribe = onSnapshot(articleCollectionQuery, (snapshot) => {
    console.log(`Updating articles for project ${projectId}`);
    const articles: ArticleDoc[] = [];
    snapshot.forEach((doc) => {
      const articleData = doc.data();
      // if article has to title, set title to url
      if (!articleData.title) {
        articleData.title = articleData.url || "Untitled";
      }
      if (!articleData.source) {
        articleData.source = "";
      }
      articles.push({ ...articleData, uid: doc.id });
    });
    callback(articles);
  });
  return () => {
    console.log("Unsubscribed from articles");
    unsubscribe();
  };
};

export const getArticleById = ({ projectId, articleId, callback }) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  const unsubscribe = onSnapshot(articleDocRef, (doc) => {
    if (doc.exists()) {
      const article = { ...doc.data(), uid: doc.id };
      console.log("Updated article from db", article);
      callback(article);
    }
  });
  return () => {
    console.log("Unsubscribing from article", articleId);
    unsubscribe();
  };
};

//Create oneAiInsightRequest collection.
export const createAiInsightsRequests = ({
  projectId,
  articleId,
  articleTitle,
  articleUrl,
  createdBy,
}) => {
  const requestId = uuidv4();
  const incomingReqRef = doc(
    db,
    `getOneAiInsightsIncomingRequests/${requestId}`
  );
  const incomingRequestData = {
    projectId,
    articleId,
    articleUrl,
    createdBy,
  };

  setDoc(incomingReqRef, incomingRequestData);

  const gptKeyPointsRef = doc(db, `gptKeyPointRequests/${requestId}`);
  setDoc(gptKeyPointsRef, {
    ...incomingRequestData,
    articleTitle,
    createdAt: Date.now(),
  });
  console.log(`GPT key points request ${requestId} sent successfully.`);
};

export const updateXfdString = ({ projectId, articleId, xfdString }) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  updateDoc(articleDocRef, {
    xfdString,
  }).then(() => {
    // console.log("Updated xfdString for article", articleId, "to", xfdString);
  });
};

export const getXfdString = (projectId, articleId) => {
  const articleDocRef = getArticleDocRef({ projectId, articleId });
  return new Promise((resolve) => {
    // console.log("articleDocRef", articleDocRef);
    getDoc(articleDocRef).then((doc) => {
      // console.log("Document data:", doc.data());
      resolve(doc.data()?.xfdString || "");
    });
  });
};

export const getArticleManualHighlights = ({
  projectId,
  articleId,
  callback,
}) => {
  const manualHighlightsQuery = q(
    getSnippetsCollection(projectId),
    where("articleId", "==", articleId),
    where("type", "in", ["manualHighlight", "fillTemplateInsight"])
  );

  const unsubscribe = onSnapshot(manualHighlightsQuery, (querySnapshot) => {
    const manualHighlights: SnippetDoc[] = [];
    querySnapshot.forEach((doc) => {
      manualHighlights.push({ ...doc.data(), uid: doc.id });
    });
    console.log("Updated manual extractions from db: ", manualHighlights);
    callback(manualHighlights);
  });
  return unsubscribe;
};

export const updateArticle = ({ projectId, articleId, ...articleData }) => {
  const articleDocRef = doc(db, `projects/${projectId}/articles/${articleId}`);
  removeUndefinedNullRecursive(articleData);
  updateDoc(articleDocRef, articleData);
  console.log("Updated article", articleId, "with data", articleData);
};

// export const getAllUserProjects = (userId, callback) => {
//   // grab all projects authored by the user; in the future,
//   // this can be expanded to include projects shared with the user
//   const collectionRef = q(
//     collection(db, `projects`),
//     where("author", "==", userId)
//   );
//   const unsubscribe = onSnapshot(collectionRef, (collectionObj) => {
//     const projects = [];
//     collectionObj.forEach((d) => {
//       // include uid in the project object
//       projects.push({ uid: d.id, ...d.data() });
//     });
//     console.log("Updated projects from db", projects);
//     callback(projects);
//   });
//   return unsubscribe;
// };
