import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { getCustomizationData } from "../Utils/customizationUtils";
import { SimpleTourMessage } from "./Index";
import { TourComponent } from "./TourComponent";

const customization = getCustomizationData();

export const UserHomePageTour = () => {
  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: (
          <Flex justify="center">
            <SimpleTourMessage
              heading="Create projects here or in the Chrome extension"
              text="This is your Homepage. All project appear here."
            />
          </Flex>
        ),
        placement: "center",
        target: "#newProjectBttnTour",
        spotlight: false,
        heading: 1,
      },
      {
        content: (
          <Flex justify="center">
            <SimpleTourMessage
              heading="Click here to access the project"
              text={`Follow the tour and play around with ${customization.name} capabilities`}
            />
            <Flex
              bg="white"
              w="100%"
              h="60px"
              pos="absolute"
              left="0"
              bottom="0"
              zIndex="10"
              borderRadius="20px"
            ></Flex>
          </Flex>
        ),
        placement: "center",
        target: ".isFirstItem",
        spotlight: true,
        heading: 2,
      },
    ],
  });
  return <TourComponent tourType="projectList" steps={steps} run={run} />;
};
