import { Icon } from "@chakra-ui/react";
import React from "react";

// const AssistantIcon = (props) => {
//   return (
//     <Icon ml="1.5" viewBox="0 0 24 24" fill="none" {...props}>
//       <path
//         d="M7 14.2222H3.55556L0 17.7778V1.77778C0 0.8 0.8 0 1.77778 0H16C16.9778 0 17.7778 0.8 17.7778 1.77778V6H16.5C16.2239 6 16 5.77614 16 5.5V1.77778H1.77778V12.4444H6.5C6.77614 12.4444 7 12.6683 7 12.9444V14.2222Z"
//         fill="white"
//       />
//       <path
//         d="M11.4436 6.44488C12.6658 6.44488 13.6658 7.44488 13.6658 8.6671C13.6658 9.88932 12.6658 10.8893 11.4436 10.8893C10.2214 10.8893 9.22135 9.88932 9.22135 8.6671C9.22135 7.44488 10.2214 6.44488 11.4436 6.44488ZM11.4436 17.556C14.4436 17.556 17.888 18.9893 18.1102 19.7782H4.77691C5.03247 18.9782 8.45469 17.556 11.4436 17.556ZM11.4436 4.22266C8.98802 4.22266 6.99913 6.21155 6.99913 8.6671C6.99913 11.1227 8.98802 13.1115 11.4436 13.1115C13.8991 13.1115 15.888 11.1227 15.888 8.6671C15.888 6.21155 13.8991 4.22266 11.4436 4.22266ZM11.4436 15.3338C8.47691 15.3338 2.55469 16.8227 2.55469 19.7782V22.0004H20.3325V19.7782C20.3325 16.8227 14.4102 15.3338 11.4436 15.3338Z"
//         fill="white"
//       />
//     </Icon>
//   );
// };

const AssistantIcon = (props) => {
  return (
    <Icon fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0061 3.2C14.0061 1.43269 12.5734 4.09724e-07 10.8061 3.32472e-07L3.2 0C1.43269 -7.72516e-08 4.09726e-07 1.43269 3.32473e-07 3.2L0 10.8061C-7.72507e-08 12.5734 1.43269 14.0061 3.2 14.0061H10.5045V14.3514C10.5045 15.235 11.2209 15.9514 12.1045 15.9514H15.1295C16.0131 15.9514 16.7295 15.235 16.7295 14.3514V13.2717C16.7295 12.3881 16.0131 11.6717 15.1295 11.6717H13.8876C13.9648 11.3964 14.0061 11.1061 14.0061 10.8061V3.2Z"
        fill="url(#paint0_linear_10644_5793)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10644_5793"
          x1="2.13982"
          y1="13.228"
          x2="13.228"
          y2="0.972646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59808D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default AssistantIcon;
