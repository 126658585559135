import { Icon } from "@chakra-ui/react";
import React from "react";

const RequestModuleIcon = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="black" {...props}>
      <rect x="6.25" width="3.75" height="3.75" fill="#08B2E3" />
      <rect width="3.75" height="3.75" fill="#08B2E3" />
      <rect x="6.25" y="6.25" width="3.75" height="3.75" fill="#08B2E3" />
      <rect y="6.25" width="3.75" height="3.75" fill="#08B2E3" />
    </Icon>
  );
};

export default RequestModuleIcon;
