import { Icon } from "@chakra-ui/react";

export const PdfIcon = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.414 1.707L8.293 0.586C7.91727 0.212011 7.40913 0.0014221 6.879 0L2.5 0C2.10218 0 1.72064 0.158035 1.43934 0.43934C1.15804 0.720644 1 1.10218 1 1.5V12H10V3.121C9.99858 2.59087 9.78799 2.08273 9.414 1.707ZM8.707 2.414C8.73199 2.44141 8.75553 2.47012 8.7775 2.5H7.5V1.2225C7.52988 1.24447 7.55859 1.26801 7.586 1.293L8.707 2.414ZM2 11V1.5C2 1.36739 2.05268 1.24021 2.14645 1.14645C2.24021 1.05268 2.36739 1 2.5 1H6.5V3.5H9V11H2ZM6.5 8.5H8V9.5H6.5V8.5ZM7 7.5V6.6465C7.0006 6.14934 6.89472 5.65782 6.6895 5.205L6.4285 4.6285C6.35516 4.44425 6.2287 4.28597 6.06519 4.17377C5.90168 4.06157 5.7085 4.00052 5.5102 3.99837C5.31191 3.99623 5.11746 4.05309 4.95156 4.16173C4.78566 4.27037 4.6558 4.42588 4.5785 4.6085L4.309 5.2055C4.10425 5.65825 3.99888 6.14961 4 6.6465V7.5H5V7H6V7.5H7ZM5.509 5.0205L5.7785 5.6165C5.83087 5.74069 5.87301 5.86895 5.9045 6H5.0955C5.12691 5.86909 5.16905 5.74099 5.2215 5.617L5.509 5.0205ZM5.27 8.0205L5.98 8.7245L5.047 9.6665C4.83564 9.87733 4.54929 9.99573 4.25075 9.99573C3.95221 9.99573 3.66586 9.87733 3.4545 9.6665L3.018 9.221L3.732 8.521L4.165 8.963C4.18869 8.98511 4.22006 8.99711 4.25246 8.99646C4.28486 8.99581 4.31572 8.98255 4.3385 8.9595L5.27 8.0205Z"
        fill="#191D30"
      />
    </Icon>
  );
};
