import { Box, Flex, Heading, Image, useDisclosure } from "@chakra-ui/react";
import { defaultSchema } from "hast-util-sanitize";
import { merge } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import style from "../../Components/smart-image-markdown.module.css";
import { SmartImageViewerModal } from "../Elements/ImageViewer";
import { HighlightSelectTools } from "./HighlightSelectTools";

export const SmartImageView = ({ article }) => {
  const { projectId, articleId } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selection, setSelection] = useState({
    text: "",
    isActive: false,
  });
  const [menuPos, setMenuPos] = useState({ x: 0, y: 0 });

  const customSchema = merge(defaultSchema, {
    attributes: {
      "*": ["style", "figure", "img", "figcaption", "className"],
    },
  });

  const handleMouseUp = () => {
    const selectedText = window.getSelection().toString();
    if (selectedText) {
      const range = window.getSelection().getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setSelection({
        text: selectedText,
        isActive: true,
      });
      setMenuPos({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY,
      });
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (selection.isActive && !event.target.closest(".context-menu")) {
        setSelection({ text: "", isActive: false });
      }
    },
    [selection.isActive]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseUp, handleClickOutside]);

  return (
    <Flex w="100%" pos="relative">
      <Flex
        pb="24px"
        flexDir="column"
        w="fit-content"
        minW="60%"
        maxW="70%"
        m="0 auto"
        gap={6}
        border="1px solid #e2e8f0"
        h="fit-content"
        p="24"
        bg="white"
      >
        <Heading fontSize="24px" color="primary" w="100%">
          {article.title}
        </Heading>
        <Image
          src={article.imageUrl}
          maxH="700px"
          onClick={onOpen}
          cursor="pointer"
        />
        <Flex flexDir="column" p="4">
          <div className={style.reactMarkDown}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
            >
              {article.extractedText}
            </ReactMarkdown>
          </div>
        </Flex>
      </Flex>
      <SmartImageViewerModal
        isOpen={isOpen}
        onClose={onClose}
        imageUrl={article.imageUrl}
      />

      {selection.isActive && (
        <Box className="context-menu">
          <HighlightSelectTools
            isSmartImage={true}
            position={menuPos}
            content={selection}
            projectId={projectId}
            articleId={articleId}
            key={selection.text}
          />
        </Box>
      )}
    </Flex>
  );
};
