import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface TestimonialCardProps {
  nameAndTitle: string;
  headline: string;
  subHeadline: string;
  testimony: () => React.ReactNode;
}

export const TestimonialCard = ({
  nameAndTitle,
  headline,
  subHeadline,
  testimony,
}: TestimonialCardProps) => {
  return (
    <Flex w="100%" flexDir="column" align="center" gap="10px" h="100%">
      <Flex flexDir="column" align="center" gap="10px" h="100%">
        <Flex color="#323232" flexDir="column">
          <Heading
            fontFamily="Inter"
            textAlign="center"
            fontSize="40px"
            fontWeight="900"
            color="#000"
            fontStyle="normal"
            letterSpacing="-1.6px"
            textShadow="0 0 1px #000, 0 0 1px #000"
            opacity="0.8"
          >
            {headline}
          </Heading>
          <Heading
            mt="10px"
            fontSize="18px"
            fontWeight="400"
            opacity="0.8"
            color="#191D30"
            fontFamily="Inter"
            fontStyle="normal"
            letterSpacing="-0.768px"
          >
            {subHeadline}
          </Heading>
        </Flex>
        <Text
          fontSize="12px"
          color="#464959"
          fontWeight="md"
          textAlign="center"
        >
          {testimony()}
        </Text>
        <Flex
          flexGrow={1}
          alignItems="flex-end"
          justifyContent="center"
          width="100%"
        >
          <Text
            textAlign="center"
            fontSize="12px"
            fontStyle="italic"
            color="#191D30"
            fontWeight="bold"
          >
            {nameAndTitle}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
