import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { queryKeyUserProjects } from "src/db/queryKeys";
import useNotification from "../../hooks/useNotification";

const ProjectModal = ({
  isOpen,
  onClose,
  onSubmit,
  projectName,
  isSaving,
  title,
  type,
}) => {
  const queryClient = useQueryClient();
  const [newProjectName, setNewProjectName] = useState(projectName);
  const { notify } = useNotification();
  const handleSubmit = () => {
    if (isSaving) return;
    if (newProjectName) {
      onSubmit(newProjectName);
      queryClient.invalidateQueries({ queryKey: [queryKeyUserProjects] });
      setNewProjectName("");
      return;
    }
    notify({
      title: "Project name cannot be empty",
      status: "error",
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
    >
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay w="full" h="full" />
        <ModalContent
          h="181px"
          bg="primary"
          color="white"
          position="fixed"
          top="30%"
          left="35%"
        >
          <ModalHeader fontSize="14px">{title}</ModalHeader>
          <ModalBody pb={2}>
            <FormControl>
              <InputGroup size="lg">
                <Input
                  fontSize="12px"
                  value={newProjectName}
                  placeholder="New project name"
                  onChange={(e) => setNewProjectName(e.target.value)}
                  bg="white"
                  size="lg"
                  color="black"
                  onKeyDown={handleKeyPress}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={8} pt="0">
            <Button
              p="0"
              fontSize="12px"
              bg="transparent"
              color="white"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              p="0"
              fontSize="12px"
              bg="transparent"
              color={newProjectName ? "white" : "#8c8d97"}
              onClick={handleSubmit}
            >
              {type == "rename"
                ? "Rename Project"
                : type == "create"
                ? "Create Project"
                : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ProjectModal;
