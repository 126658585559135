import { Text, Icon, Stack, Flex } from "@chakra-ui/react";
import React from "react";

const TabButton = ({
  handleButtonSelection,
  activeButtons,
  currentEnabledButtons,
  buttonDetails,
  buttonName,
}) => {
  const checkButtonDisabled = (type) => {
    if (!currentEnabledButtons) return false;
    if (currentEnabledButtons.includes(type)) return false;
    return true;
  };
  return (
    <Stack
      direction={"row"}
      align={"center"}
      onClick={() =>
        checkButtonDisabled(buttonDetails.type)
          ? null
          : handleButtonSelection(buttonDetails.type)
      }
      cursor="pointer"
      color={activeButtons.includes(buttonDetails.type) ? "black" : "gray.400"}
    >
      <Flex w={3} h={8} align={"center"} justify={"center"} rounded={"full"}>
        <Icon fontSize="12px" as={buttonDetails.icon} w="14px" h="15px" />
      </Flex>
      <Text
        fontSize="12px"
        fontWeight={activeButtons.includes(buttonDetails.type) && "bold"}
      >
        {buttonName}
      </Text>
    </Stack>
  );
};

export default TabButton;
