import { AbsoluteCenter, Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { MdCheck } from "react-icons/md";
import { BookSupportCallButton } from "./RequestModuleVideo";

export const RequestModuleSuccess = ({ onClose }) => {
  return (
    <Flex flexDir="column" maxW="35%" minW="35%">
      <Flex flexDir="column" justify="center">
        <Flex justify="center" pb="28px">
          <MdCheck fontSize="29px" color="#08B2E3" />
        </Flex>
        <Flex flexDir="column" gap="24px">
          <Flex flexDir="column" textAlign="center" gap="8px">
            <Text fontSize="16px" fontWeight="600">
              Thanks for your Request
            </Text>
            <Text fontSize="12px" fontWeight="400">
              We will get back to you as soon as possible
            </Text>
          </Flex>
          <Flex flexDir="column" gap="24px">
            <Box position="relative" w="50%" m="0 auto">
              <Divider />
              <AbsoluteCenter
                px="4"
                bg="white"
                fontSize="12px"
                fontWeight="400"
              >
                Or
              </AbsoluteCenter>
            </Box>
            <Flex justify="center" flexDir="column" align="center" gap="24px">
              <BookSupportCallButton />
              <Text
                fontSize="12px"
                opacity="0.54"
                fontWeight="400"
                userSelect="none"
                onClick={onClose}
                cursor="pointer"
              >
                Close
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
