import { Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

type NumberInputProps = {
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const NumberInput = ({
  onChange,
  value,
  min = -Infinity,
  max = +Infinity,
  ...props
}: NumberInputProps) => {
  const [internalValue, setInternalValue] = useState<string>(value.toString());

  useEffect(() => {
    setInternalValue(value.toString());
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    // Allow only valid number input
    if (/^-?\d*\.?\d*$/.test(newValue)) {
      setInternalValue(newValue);
      if (newValue !== "" && !isNaN(parseFloat(newValue))) {
        onChange(parseFloat(newValue));
      }
    }
  };

  const handleBlur = () => {
    let numericValue = parseFloat(internalValue);
    if (isNaN(numericValue)) {
      numericValue = min;
    }
    if (numericValue < min) {
      numericValue = min;
    }
    if (numericValue > max) {
      numericValue = max;
    }
    setInternalValue(numericValue.toString());
    onChange(numericValue);
  };

  return (
    <Input
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      value={internalValue}
      {...props}
    />
  );
};

export default NumberInput;
