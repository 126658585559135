import { Box, Flex, Spinner } from "@chakra-ui/react";
import React from "react";

export const LoadingScreen = ({
  h = "90vh",
  size = "xl",
  additionalComponents = null,
}: {
  h?: string;
  size?: string;
  additionalComponents?: React.ReactNode;
}) => {
  return (
    <Box position="relative" h={h}>
      {additionalComponents}

      <Flex h="100%" align="center" justify="center">
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="#191D30"
          color="grey"
          size={size}
        />
      </Flex>
    </Box>
  );
};
