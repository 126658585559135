import DOMPurify from "dompurify";
import { marked } from "marked";

export const generateMarkdownHtml = (formattedText: string): string => {
  const styles = `
    <style>
      div { font-family: Arial, sans-serif; line-height: 1.5; }
      p { margin-top: 1em; margin-bottom: 1em; }
      .citation-section { margin-top: 2em; }
      ol { margin-top: 1em; margin-bottom: 1em; padding-left: 2em; }
      li { margin-bottom: 0.5em; }
    </style>
  `;

  const processedBody = formattedText
    .split("\n\n")
    .map((para) => {
      if (para.trim().match(/^\d+\./)) {
        const items = para
          .split("\n")
          .map((item) => `<li>${item.replace(/^\d+\.\s*/, "")}</li>`)
          .join("");
        return `<ol>${items}</ol>`;
      }
      return para.trim() ? `<p>${para}</p>` : "";
    })
    .join("");

  return `<!DOCTYPE html><html><head>${styles}</head><body>${processedBody}</body></html>`;
};

// Copy to clipboard
export const copyToClipboard = (
  formattedText: string,
  googleDocsHtml: string
): void => {
  try {
    const clipboardContent = new ClipboardItem({
      "text/plain": new Blob([formattedText], { type: "text/plain" }),
      "text/html": new Blob([googleDocsHtml], { type: "text/html" }),
    });

    navigator.clipboard.write([clipboardContent]).catch((err) => {
      console.warn("err", err);
      navigator.clipboard.writeText(formattedText);
    });
  } catch (err) {
    navigator.clipboard.writeText(formattedText);
  }
};

export const copyFormattedText = async (markdown: string) => {
  console.log("copyFormattedText markdown", markdown);
  try {
    const html = await marked(markdown);

    const sanitizedHtml = DOMPurify.sanitize(html);

    const data = new ClipboardItem({
      "text/plain": new Blob([markdown], { type: "text/plain" }),
      "text/html": new Blob([sanitizedHtml], { type: "text/html" }),
    });

    console.log("data done: ", data);
    await navigator.clipboard.write([data]);
  } catch (err) {
    await navigator.clipboard.writeText(markdown);
  }
};
