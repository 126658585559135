import { Icon } from "@chakra-ui/react";
import React from "react";

const HeaderBoxMenuIcon = (props) => {
  return (
    <Icon fill="none" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="10" width="6" height="6" fill="black" />
        <rect width="6" height="6" fill="black" />
        <rect x="10" y="10" width="6" height="6" fill="black" />
        <rect y="10" width="6" height="6" fill="black" />
      </svg>
    </Icon>
  );
};

export default HeaderBoxMenuIcon;
