import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { IoIosMore } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useArticle } from "../../../Context/ArticleContext";
import { DeleteAlert } from "../../Elements/DeleteAlert";
import {
  MoreActionMenuItem,
  handleDeleteMultipleArticle,
} from "../../Utils/ProjectPageUtil/tableUtils";
import { TableRowCopyToProjectBttn } from "./TableRowCopyToProjectBttn";

export const MenuItemBttn = (children) => {
  const { setShowArticleCopyMenu } = useArticle();
  return (
    <MenuItem
      data-testid={children.label}
      onClick={children.onClick}
      bg="white"
      color="#7b7b7b"
      icon={children.icon}
      _hover={{
        bg: "#f7f7f7",
      }}
      isDisabled={false}
      onMouseOut={() => {
        if (children.label !== "Copy to Project") {
          setShowArticleCopyMenu(false);
        }
      }}
      fontWeight="600"
      px="4"
      py="1"
      pr={4}
      {...children}
    >
      {children.children}
    </MenuItem>
  );
};

export const TableMoreActionBttn = () => {
  const { selectedArticlesData, selectedArticleIds, clearSelectedArticles } =
    useArticle();
  const { projectId } = useParams();
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);

  const tableMoreActionList = [
    {
      id: "copy to project",
      action: () => {},
      text: "Copy to project",
      render: (
        <TableRowCopyToProjectBttn
          articleData={null}
          multiDocSelected={true}
          color="#707070"
        />
      ),
    },
    // {
    //   id: "download",
    //   action: () => {},
    //   text: "Download",
    //   render: (
    //     <MoreActionMenuItem
    //       text="Download"
    //       icon={MdOutlineDownload}
    //       id="download"
    //     />
    //   ),
    // },
    {
      id: "delete",
      action: () => {},
      text: "Delete",
      render: (
        <MoreActionMenuItem
          text="Delete"
          icon={MdDeleteOutline}
          id="delete"
          action={() => setShowDeleteAlert(true)}
        />
      ),
    },
  ];

  return (
    <Flex
      minW="20px"
      h="20px"
      bg="white"
      align="center"
      justify="center"
      borderRadius="100px"
      visibility={selectedArticlesData.length >= 1 ? "visible" : "hidden"}
    >
      <Menu>
        <MenuButton
          p="0"
          as={Button}
          bg="white"
          h="100%"
          w="fit-content"
          minW="20%"
          alignContent="center"
          justifyContent="center"
        >
          <IoIosMore
            fontSize="16px"
            color="black"
            cursor="pointer"
            fontWeight={"bold"}
          />
        </MenuButton>
        <MenuList>
          <Flex flexDir="column" gap={2}>
            {tableMoreActionList.map((item) => {
              return (
                <React.Fragment key={item.id}>{item.render}</React.Fragment>
              );
            })}
          </Flex>
        </MenuList>
      </Menu>
      <DeleteAlert
        action={() => {
          handleDeleteMultipleArticle(projectId, selectedArticleIds);
          clearSelectedArticles();
          setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
        title={`Delete ${selectedArticlesData.length} Articles`}
        body="Are you sure you want to delete this articles?"
      />
    </Flex>
  );
};
