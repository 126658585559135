/**
 * Interface for metadata.
 */
export interface Metadata {
  source: string;
  m_number: string;
  m_rating: string;
  date: string;
}
/**
 * Interface for a snippet.
 */
export interface Snippet {
  uid: string;
  articleId: string;
  articleTitle: string;
  articleUrl?: string;
  createdAt: number;
  createdBy: string;
  curated: boolean;
  text: string;
  type:
    | "fillTemplateInsight"
    | "summary"
    | "keyPoint"
    | "quote"
    | "number"
    | "manualHighlight"
    | "manualInsight"
    | "inTextLink"
    | "articleNote"
    | "image"
    | "assistantInsight";
  feedback?: number;
  fragmentId?: string;
  metadata?: Metadata;
  cardType?: CardType;
}

export interface SectionCardData {
  snippetId: string;
  cardType: CardType;
}

export type CardType = "addedByUser" | "removedByUser" | "aiGenerated";

/**
 * Interface for a database template section type.
 */
export interface _Section {
  id: string;
  name: string;
  order: number;
  snippets: SectionCardData[];
  // Add other properties here
}

export interface EnrichedSectionData {
  name: string;
  order: number;
  cards: Snippet[];
}

/**
 * Interface for the enriched sections data.
 */

export interface EnrichedSectionsMap {
  [id: string]: EnrichedSectionData;
}

/**
 * Interface for the control center data.
 */
export interface ControlCenterData {
  // Add properties here
}

/**
 * Creates enriched sections data based on template sections data and snippets.

 * Essentially, that has actual snippet data instead of just the snippet ids.
 *
 * @param {_Section[]} templateSectionsData - The template sections data.
 * @param {Snippet[]} snippets - The snippets data.
 * @returns {EnrichedSectionsMap} - The enriched sections data.
 */

export const createSectionsData = (
  templateSectionsData: _Section[],
  snippets: Snippet[]
): EnrichedSectionsMap => {
  const snippetsMap = new Map(
    snippets.map((snippet) => [snippet.uid, snippet])
  );
  console.log("snippetsMap :>> ", snippetsMap);
  const enrichedSectionsData = templateSectionsData.reduce((acc, section) => {
    const { id, name, order, snippets: sectionSnippets } = section;
    const cards = sectionSnippets
      .map(({ snippetId, cardType }) => ({
        ...snippetsMap.get(snippetId),
        cardType,
      }))
      .filter(Boolean);
    acc[id] = { name, order, cards };
    return acc;
  }, {});
  console.log("enrichedSectionsData", enrichedSectionsData);
  return enrichedSectionsData;
};

/**
 * Creates control center data based on the enriched sections data.
 *
 * @param {EnrichedSectionsMap} data - The enriched sections data.
 * @returns {ControlCenterData[]} - The control center data.
 */
export const createControlCenterData = (
  data: EnrichedSectionsMap
): ControlCenterData[] => {
  const sections = Object.keys(data);
  let allCards: Snippet[] = [];
  sections.forEach((sectionKey) => {
    const section = data[sectionKey];
    allCards = allCards.concat(section.cards);
  });
  const cardsMetadata = allCards.map(
    (snippet) => (snippet.metadata as Metadata) || {}
  );
  const uniqueMetadata = cardsMetadata.reduce((acc, current) => {
    const x = acc.find((item) => {
      return item.source === current.source;
    });
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [] as Metadata[]);

  const cardsWithoutUndefine = uniqueMetadata.filter(
    (card) => card !== undefined
  );
  const filteredCards = cardsWithoutUndefine.filter(
    (card) => Object.keys(card).length !== 0
  );
  return filteredCards;
};
