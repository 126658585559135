import { useEffect, useState } from "react";
import { extensionPresence } from "../Components/Utils/extensionPresence";

export const useExtensionPresence = () => {
  const [extensionPresent, setExtensionPresent] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    extensionPresence().then((res) => {
      setExtensionPresent(res);
      setLoading(false);
    });
  }, []);

  return { extensionPresent, loading };
};
