import React from "react";
import { Icon } from "@chakra-ui/react";

const HighlightIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 14 15" fill="none" {...props}>
        <path
          d="M0.95 11.0498L2.95 9.04984L2.88333 8.98317C2.63889 8.73873 2.51944 8.43039 2.525 8.05817C2.53056 7.68595 2.65556 7.37762 2.9 7.13317L9.58333 0.449837C9.77222 0.260948 10.0111 0.166504 10.3 0.166504C10.5889 0.166504 10.8278 0.260948 11.0167 0.449837L12.8833 2.3165C13.0722 2.50539 13.1639 2.75817 13.1583 3.07484C13.1528 3.3915 13.0556 3.64428 12.8667 3.83317L6.18333 10.5165C5.97222 10.7276 5.68056 10.8332 5.30833 10.8332C4.93611 10.8332 4.64444 10.7276 4.43333 10.5165L4.25 10.3332L3.53333 11.0498H0.95ZM9.18333 6.09984L7.26667 4.18317L3.38333 8.0665L5.3 9.98317L9.18333 6.09984ZM0 14.8332V12.8165H13.3333V14.8332H0Z"
          fill="currentColor"
        />
    </Icon>
  );
};

export default HighlightIconComponent;
