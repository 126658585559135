import { fetchSignInMethodsForEmail, updateProfile } from "firebase/auth";
import { auth, sendEmailVerification } from "../../firebase";

export const checkIfUserEmailIsInDb = async (email) => {
  try {
    const res = await fetchSignInMethodsForEmail(auth, email);
    return res.length > 0;
  } catch (err) {
    console.error(err); // Log the error
    throw err; // Propagate the error upwards
  }
};

export const logUserInFromExtension = () => {
  const fetcher = async (url, method, token, payload) => {
    const requestHeaders = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", token);

    const res = await fetch(url, {
      body: payload ? JSON.stringify(payload) : undefined,
      headers: requestHeaders,
      method,
    });

    let resobj = res;
    try {
      resobj = await res.json();
    } catch (e) {
      throw new Error("Unexpected issue. Please try again.");
    }
    return resobj;
  };

  const callApi = async (url, method, payload) => {
    let res = { data: "", error: "" };
    try {
      const token = await auth.currentUser?.getIdToken(true);
      res = await fetcher(url, method, token, payload);
    } catch (e) {
      res.error = e.message;
      console.error("Error: ", e.message);
    }
    return res;
  };

  const getCustomTokenFromServer = async () => {
    const customToken = (
      await callApi(process.env.REACT_APP_GET_JWT_FUNCTION_URL, "GET")
    ).data.customToken;
    return customToken;
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has("extension") === true) {
    getCustomTokenFromServer().then((customToken) => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("custom_token", customToken);
      window.location.search = urlParams.toString();
    });
  }
};

export const updateUserDisplayName = async (displayName) => {
  try {
    await updateProfile(auth.currentUser, {
      displayName: displayName,
    });
    return true;
  } catch (err) {
    console.error(err); // Log the error
    throw err; // Propagate the error upwards
  }
};

export const resendVerificationEmail = async (currentUser) => {
  try {
    await sendEmailVerification(currentUser);
    return true;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
