import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { MdPreview } from "react-icons/md";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { useOrganizerContext } from "../../Context/OrganizerContext";
import { getCustomizationData } from "../Utils/customizationUtils";

export const ViewInSourceBttn = ({ projectId, card }) => {
  // const routerHistory = useHistory();

  // const handleOpenPDFArticle = (card) => {
  //   routerHistory.push({
  //     pathname: `/${projectId}/${card.articleId}/articledetail/?detail=viewfile&#highlight-${card.uid}`,
  //   });
  // };

  return (
    <Flex
      // w="100%"
      w="20%"
      // justify="end"
      display={card.position?.pageNumber ? "flex" : "none"}
      // mt="-5"
      align="end"
    >
      <Button
        as={Link}
        to={`/${projectId}/${card.articleId}/articledetail/?detail=viewfile&#highlight-${card.uid}`}
        leftIcon={<MdPreview fontSize="12px" />}
        p="0"
        fontSize="8px"
        h="16px"
        color="#757783"
      >
        View in source
      </Button>
    </Flex>
  );
};

const customization = getCustomizationData();

export const ReaddToTemplateBttn = ({ card }) => {
  const { unRemoveCard } = useOrganizerContext();
  return (
    <Flex display={"flex"} align="end">
      <Button
        borderRadius="8px"
        bg="white"
        w="fit-content"
        h="16px"
        px="8px"
        py="4px"
        color="black"
        fontSize="9px"
        fontWeight={500}
        onClick={() => {
          unRemoveCard(card.uid);
        }}
      >
        + Re-add to {`${customization.template.name.toLowerCase()}`}?
      </Button>
    </Flex>
  );
};
