import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { UserWorkspacesLoader } from "src/Context/WorkspaceBoundary";
import { DesktopOnlyCompatibilityNotice } from "../Components/Home/DesktopOnlyCompatibilityNotice";
import { useIsMobileDevice } from "../Components/Utils/userDevice";
import { ArticleContextProvider } from "../Context/ArticleContext";
import { CustomAiModulesProvider } from "../Context/CustomAiModuleContext";
import { ProjectContextProvider } from "../Context/ProjectContext";
import { useAuth } from "./AuthProvider";
import { LoadingScreen } from "./LoadingAuth";

const ProtectedRoute = ({ children, ...rest }) => {
  const { currentUser, userDoc, userDocRef } = useAuth();
  const location = useLocation();
  const isMobile = useIsMobileDevice();

  if (!currentUser) {
    return (
      <Redirect to={{ pathname: "/", state: { from: location.pathname } }} />
    );
  }

  if (!userDoc || !userDocRef) {
    return <LoadingScreen />;
  }

  if (isMobile) {
    return <DesktopOnlyCompatibilityNotice userEmail={currentUser.email} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        React.cloneElement(
          <UserWorkspacesLoader>
            <CustomAiModulesProvider>
              <ProjectContextProvider>
                <ArticleContextProvider>{children}</ArticleContextProvider>
              </ProjectContextProvider>
            </CustomAiModulesProvider>
          </UserWorkspacesLoader>,
          props
        )
      }
    />
  );
};

export default ProtectedRoute;
