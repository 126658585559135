import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingScreen } from "../../Auth/LoadingAuth";
import { useArticle } from "../../Context/ArticleContext";
import {
  ACTIVE_INSIGHT_BUTTONS,
  SNIPPET_TYPE_DATA,
} from "../../data/SnippetTypeData";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import useGetSnippets from "../../hooks/useGetSnippets";
import InsightCard from "../Elements/SnippetCards/ComfortCard";
import TabButton from "../Elements/TabButton";
import { BoxLoading } from "../Utils/Loading";

export const InsightTypeButtonsSection = ({
  handleButtonSelection,
  activeButtons,
}) => {
  const { activeArticle } = useArticle();

  // console.log("activeArticle :>> ", activeArticle);
  if (!activeArticle) return null;

  return (
    <Flex id="aianalystBttn" flexWrap="wrap" columnGap={8} rowGap={4}>
      {Object.entries(SNIPPET_TYPE_DATA).map(([key, button]) => {
        const showButton = ARTICLE_TYPE_DATA[
          activeArticle.type
        ].insightTypes.includes(button.type);
        return (
          <>
            {showButton && (
              <Tooltip
                hasArrow
                label={button.insightToolTipMsg || button.toolTipMsg}
                bg="primary"
                key={key}
                openDelay={1000}
              >
                <Box>
                  <TabButton
                    handleButtonSelection={() =>
                      handleButtonSelection(button.type)
                    }
                    buttonDetails={button}
                    activeButtons={activeButtons}
                    key={button.name}
                    currentEnabledButtons={ACTIVE_INSIGHT_BUTTONS}
                    buttonName={button.defaultTitle || button.title}
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      })}
    </Flex>
  );
};

export const InsightCardsSection = ({ snippetMode }) => {
  const { articleId, projectId } = useParams();
  const { activeArticle } = useArticle();

  // console.log(activeArticle);

  const { snippets } = useGetSnippets({
    snippetTypes: ["summary", "keyPoint", "nextSteps"],
    projectId,
    articleId,
  });

  if (!snippets)
    return (
      <Flex
        align={"center"}
        width="900px"
        direction={"column"}
        gap={"20px"}
        py={"40px"}
      >
        <Text fontSize="14px" fontWeight={"bold"}>
          {"Getting insights..."}
        </Text>
        <LoadingScreen h="40px" size="lg" />
      </Flex>
    );

  if (!activeArticle.summaryReady) {
    return (
      <Flex
        gap="24px"
        w="100%"
        flexDir="column"
        h="60vh"
        justify="center"
        align="center"
      >
        <BoxLoading loadingText="Generating AI Insights..." />
      </Flex>
    );
  }

  return (
    <Box
      // mt="4"
      h="auto"
      overflowY="scroll"
      className="webkit-scrollbar-display-none"
      pb="10"
    >
      {snippets &&
        snippets.map((insight, index) => (
          <Draggable
            key={insight.uid}
            isDragDisabled={false}
            draggableId={insight?.uid}
            index={index}
          >
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                className="draggable-card"
              >
                <InsightCard
                  insight={insight}
                  articleText={activeArticle?.extractedText}
                  projectId={projectId}
                  articleTitle={activeArticle?.title}
                  viewMode={snippetMode}
                  isInsightPage={true}
                  isLastItem={index === snippets.length - 1}
                />
              </Box>
            )}
          </Draggable>
        ))}
    </Box>
  );
};
