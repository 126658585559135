import { Box, HStack, Icon } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Auth/AuthProvider";
import { updateSnippet } from "../../../db/snippet";
import { db, deleteDoc, doc, setDoc } from "../../../firebase";
import ThumbDownIcon from "../../Icons/ThumbDownIcon";
import ThumbUpIcon from "../../Icons/ThumbUpIcon";

export const FeedbackComponent = ({ card, templateName, sectionName }) => {
  const { projectId } = useParams();
  const { currentUser } = useAuth();

  const data = {
    articleTitle: card.articleTitle,
    templateName,
    sectionName,
    snippetText: card.text,
    snippetId: card.uid,
    createdBy: currentUser.email,
    projectId,
  };

  const addFeedback = (feedback) => {
    updateSnippet({
      projectId,
      snippetId: card.uid,
      feedback: feedback,
    });

    data.feedback = feedback;
    data.createdAt = Date.now();

    setDoc(doc(db, `snippetFeedback/${card.uid}`), data);
  };

  const removeFeedback = () => {
    updateSnippet({
      projectId,
      snippetId: card.uid,
      feedback: 0,
    });

    deleteDoc(doc(db, `snippetFeedback/${card.uid}`));
  };

  const FeedbackIcon = ({ icon, feedback, currentFeedback }) => {
    return (
      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        w="22px" // Width including the padding
        h="22px" // Height including the padding
        // p="5px"
        cursor="pointer"
        _hover={{
          bg: "gray.200",
        }}
        onClick={() => {
          currentFeedback === feedback
            ? removeFeedback()
            : addFeedback(feedback);
        }}
      >
        <Icon
          as={icon}
          fill={currentFeedback === feedback ? "#3d3d3d" : "none"}
          stroke="#3d3d3d"
          w="22px"
          h="22px"
          p="5px"
        />
      </Box>
    );
  };

  return (
    <HStack gap="1px" p={0} align="end">
      <FeedbackIcon
        icon={ThumbUpIcon}
        feedback={1}
        currentFeedback={card.feedback}
      />
      <FeedbackIcon
        icon={ThumbDownIcon}
        feedback={-1}
        currentFeedback={card.feedback}
      />
    </HStack>
  );
};
