import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getCustomizationData } from "./Utils/customizationUtils";

const customization = getCustomizationData();

export const getTooltipText = (type) => {
  if (!type) {
    return null;
  }
  const string = customization.infoTooltips[type];
  if (string === undefined) {
    throw new Error(
      `${type} doesn't exist in CLIENT_CUSTOMIZATION_DATA.[client].infoTooltips`
    );
  }
  return string;
};

export const InfoTooltip = ({ string, type }) => {
  // type or string needs to be passed to the component, but not both
  if (type && string) {
    throw new Error(
      "Only one of type or string should be passed to InfoTooltip"
    );
  }

  if (type) {
    string = getTooltipText(type);
  }

  if (!string) {
    return null;
  }

  return (
    <Tooltip label={<div dangerouslySetInnerHTML={{ __html: string }} />}>
      <Flex>
        <Icon
          as={AiOutlineInfoCircle}
          boxSize={"16px"}
          color="#000000"
          opacity="0.7"
        />
      </Flex>
    </Tooltip>
  );
};
