import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useAuth } from "src/Auth/AuthProvider";
import { LoadingScreen } from "src/Auth/LoadingAuth";
import { WorkSpaceMenuItems } from "src/Components/Workspace/Menu";
import {
  useActiveWorkspaceActions,
  useActiveWorkspaceId,
  useUserWorkspacePermissions,
  useUserWorkspacesIds,
  useUserWorkspacesStore,
} from "src/stores/userWorkspaces";

export const UserWorkspacesLoader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentUser, userDoc, logout } = useAuth();

  const subscribeToUserWorkspacePermissions = useUserWorkspacesStore(
    (state) => state.subscribeToUserWorkspacePermissions
  );
  const subscribeToUserWorkspaces = useUserWorkspacesStore(
    (state) => state.subscribeToUserWorkspaces
  );
  const userWorkspacePermissions = useUserWorkspacePermissions();
  const userWorkspacesIds = useUserWorkspacesIds();
  const activeWorkspaceId = useActiveWorkspaceId();
  const { setActiveWorkspaceId, cleanup } = useActiveWorkspaceActions();

  useEffect(() => {
    console.log("subscribeToUserWorkspacePermissions useEffect");
    if (!currentUser) {
      return;
    }

    const unsubscribePermissions = subscribeToUserWorkspacePermissions(
      currentUser.uid
    );

    return unsubscribePermissions;
  }, [currentUser, subscribeToUserWorkspacePermissions]);

  useEffect(() => {
    console.log("subscribeToUserWorkspaces useEffect");
    if (userWorkspacePermissions.size === 0) return;

    const unsubscribeWorkspaces = subscribeToUserWorkspaces(
      userWorkspacePermissions
    );

    return unsubscribeWorkspaces;
  }, [userWorkspacePermissions, subscribeToUserWorkspaces]);

  useEffect(() => {
    // reset the store when the user logs out
    return cleanup;
  }, [cleanup]);

  if (userWorkspacesIds.length === 0) {
    return (
      <LoadingScreen
        additionalComponents={
          <Button
            position="absolute"
            top="1rem"
            right="1rem"
            onClick={() => {
              logout();
            }}
            color="primary"
          >
            Logout
          </Button>
        }
      />
    );
  }

  if (!activeWorkspaceId) {
    if (userDoc?.activeWorkspaceId) {
      setActiveWorkspaceId(userDoc.activeWorkspaceId);
      return <LoadingScreen />;
    }
    if (userWorkspacesIds.length === 1) {
      setActiveWorkspaceId(userWorkspacesIds[0]);
    }
    return (
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Heading
          pb="13px"
          opacity="0.56"
          fontWeight="bold"
          fontSize="12px !important"
          pl="4"
          color="#191D30"
        >
          Choose a workspace
        </Heading>
        <Box maxWidth="400px" width="100%">
          <WorkSpaceMenuItems showSettings={false} />
        </Box>
      </Flex>
    );
  }

  if (activeWorkspaceId && !userWorkspacesIds.includes(activeWorkspaceId)) {
    setActiveWorkspaceId(userWorkspacesIds[0]);
  }

  return <>{children}</>;
};
