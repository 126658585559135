import React from "react";
import { useUserPermission } from "src/Context/UserPermissionContext";

interface PermissionWrapperProps {
  children: (hasEditPermission: boolean) => React.ReactNode;
}

export const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  children,
}) => {
  const { isEditor } = useUserPermission();

  const hasPermissionToEdit = isEditor();

  return <> {children(hasPermissionToEdit)}</>;
};

export default PermissionWrapper;
