import React, { useEffect } from "react";
import { Flex, Heading } from "@chakra-ui/react";
import { useAuth } from "../Auth/AuthProvider";

const SignOutExtension = () => {
  const { currentUser, logout } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search);
    // if status is already success, do nothing
    if (urlParams.get("status") === "success") {
      return;
    }
    if (currentUser) {
      logout().then(() => {
        urlParams.set("status", "success");
        window.location.search = urlParams.toString();
      });
    }
  }, [currentUser, urlParams]);

  return (
    <Flex flexDir="column" align="center" mt={5}>
      <Heading fontSize="24px" color="#1D1D1D" fontWeight="500">
        {urlParams.get("status") === "success"
          ? "Signed out"
          : "Signing out..."}
      </Heading>
    </Flex>
  );
};

export default SignOutExtension;
