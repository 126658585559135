import { Flex, Heading, Img, Text } from "@chakra-ui/react";
import React from "react";
import DesktopOnly from "../../assets/desktopOnly.svg";
import Logo from "../../assets/desktopOnlyLogo.png";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const DesktopOnlyCompatibilityNotice = ({ userEmail }) => {
  return (
    <Flex
      w="90%"
      m="0 auto"
      h="100vh"
      align="center"
      justifyContent="flex-end"
      flexDir="column"
    >
      <Flex
        flexDir="column"
        h="70%"
        align="center"
        justifyContent="space-between"
      >
        <Flex flexDir="column" align="center">
          <Img src={DesktopOnly} w="159px" />
          <Heading fontSize="24px" mt={6}>
            Welcome to {customization.name}!
          </Heading>
          <Text
            whiteSpace="normal"
            color="#202020"
            mt={4}
            fontSize="16px"
            textAlign="center"
          >
            {customization.name} is optimized for desktop use, we&apos;ve just
            sent you a login link to <strong>{userEmail}</strong>. Click it to
            access your optimized desktop experience right away!
          </Text>
        </Flex>

        <Img pb="4" w="88px" src={Logo} />
      </Flex>
    </Flex>
  );
};
