import {
  collection,
  collectionGroup,
  doc,
  FirestoreDataConverter,
} from "firebase/firestore";
import { AiModulePermission } from "shared/aiModule";
import { COL_AI_MODULE_PERMISSIONS, COL_AI_MODULES } from "shared/constants";
import { db } from "src/firebase";

const aiModulePermissionConverter: FirestoreDataConverter<AiModulePermission> =
  {
    toFirestore: (permission: AiModulePermission) => permission,
    fromFirestore: (snapshot) => snapshot.data() as AiModulePermission,
  };

export const aiModulesCollection = collection(db, COL_AI_MODULES);

export const getAiModulePermissionsCollection = (moduleId: string) =>
  collection(
    doc(aiModulesCollection, moduleId),
    COL_AI_MODULE_PERMISSIONS
  ).withConverter(aiModulePermissionConverter);

export const aiModulePermissionsCollectionGroup = collectionGroup(
  db,
  COL_AI_MODULE_PERMISSIONS
).withConverter(aiModulePermissionConverter);
