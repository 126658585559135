import React, { useEffect } from "react";
import { onCurrentUserSubscriptionUpdate } from "@invertase/firestore-stripe-payments";
import { getUserSubscriptionStatus, payments } from "../services/StripeSvc";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const PaymentStatusPage = () => {
  const [user] = useAuthState(auth);
  useEffect(() => {
    if (user) {
      const fetchSub = async () => {
        const status = await getUserSubscriptionStatus();
        console.log(status, "status");
      };
      fetchSub();
      onCurrentUserSubscriptionUpdate(payments, async (snapshot) => {
        for (const change of snapshot.changes) {
          if (change.type === "added") {
            console.log(snapshot.changes, "snapshot");
          }
        }
      });
    }
  }, [user]);

  return <div>First time</div>;
};

export default PaymentStatusPage;
