import md5 from "md5";
import { ArticleDoc } from "shared/articleTypes";
import { WHITELIST, isUrlInWhitelist } from "../../data/articleTypeData";
import { db, doc, getDoc } from "../../firebase";

export const sortArticles = (articles: ArticleDoc[]) => {
  // order articles, starred first, then most recent first
  // (.slice() is used to create a copy of the array, otherwise gives
  // an error on Insights page)
  articles = articles?.slice()?.sort((a, b) => {
    if (a.starred && !b.starred) return -1;
    if (!a.starred && b.starred) return 1;
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });
  return articles;
};

export const getArticleType = (url) => {
  if (/\.pdf($|\?|#)/i.test(url)) {
    return "pdf";
  } else if (isUrlInWhitelist(url, WHITELIST)) {
    return "supportingLink";
  } else {
    return "html";
  }
};

export const getArticleSource = (url) => {
  const urlSourcePattern = new RegExp(
    "^(https?:\\/\\/)?(www\\.)?([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\."
  );
  const source = url.match(urlSourcePattern)[3];

  return source;
};

// export const checkIfArticleAlreadyAdded = ({ projectId, url }) => {

//   if (!url) return Promise.resolve(false);

//   return new Promise((resolve) => {
//     const articleQuery = q(
//       doc(db, `projects/${projectId}/articles/${md5(url)}`)
//     );
//     getDoc(articleQuery).then((doc) => {
//       if (doc.exists()) {
//         resolve(true);
//       } else {
//         resolve(false);
//       }
//     });
//   });
// };

export const checkIfArticleAlreadyAdded = async ({ projectId, url }) => {
  if (!url) return false;

  try {
    const articleQuery = doc(db, `projects/${projectId}/articles/${md5(url)}`);
    const docSnapshot = await getDoc(articleQuery);
    return docSnapshot.exists();
  } catch (error) {
    console.error("Error checking if article is added:", error);
    return false;
  }
};
