import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useRef } from "react";

const ImageViewer = ({ isOpen, onClose, imageUrl }) => {
  const cancelRef = useRef();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={{ base: "md", lg: "xl", "2xl": "2xl" }}
    >
      <AlertDialogOverlay />
      <AlertDialogContent color="white">
        <Image
          src={imageUrl}
          alt="manual insight image"
          objectFit="cover"
          fallbackSrc="https://via.placeholder.com/200"
        />
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const SmartImageViewerModal = ({ isOpen, onClose, imageUrl }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent h="100%" bg="transparent" onClick={onClose}>
        <ModalBody h="100%">
          <Flex w="100%" h="100%" justify="center" align="center">
            <Image src={imageUrl} maxW="100%" maxH="100%" />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageViewer;
