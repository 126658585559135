import { Box, Divider, Flex } from "@chakra-ui/react";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useActiveWorkspaceResources } from "src/stores/userWorkspaces";
import { v4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useAssistantContext } from "../../Context/AssistantContext";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import { getProjectData } from "../../db/project";
import { db } from "../../firebase";
import useNotification from "../../hooks/useNotification";
import CustomPrompts from "../AiModules/RegularCustomHeader";
import { AIChatTour } from "../Tour/AIChatTour";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantChatInput from "./AssistantChatInput";
import { ChatToArticleOnAssistantMenu } from "./ChatToArticleOnAssistantMenu";
import { AssistantChatLoadingWithText } from "./Elements/AssistantChatLoadingWithText";
import MessageComponent from "./Message/MessageCitation";
import { ToggleOnlyCurrentChatBttn } from "./ToggleOnlyCurrentChatBttn";

const customization = getCustomizationData();

const Message = React.memo(MessageComponent);

const sortMessages = (messages) => {
  return messages.sort((a, b) => a.createdAt - b.createdAt);
};

/**
 * Adds a message to the database.
 * @param {Object} options - The options for adding the message.
 * @param {string} options.projectId - The ID of the project.
 * @param {string} options.conversationId - The ID of the conversation.
 * @param {string} options.userId - The ID of the user.
 * @param {string} options.message - The message to be added.
 * @returns {Promise<void>} - A promise that resolves when the message is added to the database.
 */
export async function addMessageToDb({
  projectId,
  conversationId,
  userId,
  message,
}) {
  await setDoc(
    doc(db, "projects", projectId, "conversations", conversationId),
    {
      messages: arrayUnion(message),
      userId,
      continue: true,
    },
    { merge: true }
  );
}

const updateMsgFeedback = async ({
  projectId,
  conversationId,
  msgIndex,
  feedbackValue,
}) => {
  await updateDoc(
    doc(db, "projects", projectId, "conversations", conversationId),
    {
      [`messageFeedback.${msgIndex}`]: feedbackValue,
    }
  );
};

const handleTableChatRequest = async (projectId, conversationId, userId) => {
  const messageId = v4();
  await setDoc(doc(db, "tableChatRequest", messageId), {
    projectId,
    conversationId,
    userId,
  });
};

const handleSendMessage = async ({
  messages,
  message,
  setMessages,
  articles,
  projectId,
  conversationId,
  setSubscribeToConversationDoc,
  currentUser,
}) => {
  console.log("handleSendMessage message :>> ", message);
  setMessages([...messages, message]);

  // if no articles are selected, add all articles to the message
  manageSelectedArticles(message, articles);

  await addMessageToDb({
    projectId,
    conversationId,
    userId: currentUser.uid,
    message,
  });
  // set the converation as started (this will trigger subscription to the doc)
  setSubscribeToConversationDoc(true);

  // if there is 1 selected article and it is of type table
  // then we create a request doc in tableChatRequest collection
  if (
    message.selectedArticles.length === 1 &&
    message.selectedArticles[0].type === "table"
  ) {
    await handleTableChatRequest(projectId, conversationId, currentUser.uid);
  } else {
    // create a request-doc to continue the conversation with the assistant
    const messageId = v4();
    const projectData = await getProjectData(projectId);
    await setDoc(doc(db, "assistantChatRequest", messageId), {
      projectId,
      conversationId,
      userId: currentUser.uid,
      isSampleProject: projectData?.isSampleProject || false,
    });
  }
};

const AssistantChatWindow = () => {
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const {
    conversationId,
    messages,
    setMessages,
    conversationData,
    subscribeToConversationDoc,
    setSubscribeToConversationDoc,
    assistantIsFullScreen,
    useOnlyChat,
  } = useAssistantContext();
  const { allUnfilteredArticles: articles } = useArticle();
  const { notify } = useNotification();

  const sortedMessages = sortMessages(messages);

  const onSendMessage = (message) => {
    handleSendMessage({
      messages,
      message,
      setMessages,
      articles: useOnlyChat ? [] : articles,
      projectId,
      conversationId,
      subscribeToConversationDoc,
      setSubscribeToConversationDoc,
      currentUser,
      useOnlyChat,
    });
  };

  const handleStopGeneration = async () => {
    await updateDoc(
      doc(db, "projects", projectId, "conversations", conversationId),
      {
        continue: false,
      }
    );
  };

  const assistantIsGenerating =
    subscribeToConversationDoc && conversationData?.continue;

  // Ref for the text input field in the assistant chat window, used to focus the input
  // after an article is clicked (see RelatedArticles component)
  const inputRef = useRef(null);

  // const { assistantDisabled: assistantDisabledFromLimitations } =
  //   useLimitations();
  // const assistantDisabled = false;
  const { aiCreditsTotal } = useActiveWorkspaceResources();
  const assistantDisabledFromLimitations = aiCreditsTotal <= 0;

  const assistantDisabled =
    assistantDisabledFromLimitations || (assistantIsGenerating ?? false);

  const [showCustomPrompts, setShowCustomPrompts] = useState(
    customization.assistant.showCustomPrompts
  );

  // Selected articles are used to limit the assistant search to the selected articles.
  const { inputValue, setInputValue } = useAssistantContext();
  const { selectedArticlesData } = useArticle();

  /**
   * Following code block is used to scroll to the bottom of the chat window when a
   * messages is added or a message is updated.
   *
   * START
   */
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [sortedMessages]);
  /**
   * END
   */

  const handleSendClick = () => {
    if (inputValue.trim() === "") {
      notify({
        title: "Error",
        description: "Please enter a message.",
        status: "error",
      });
      return;
    }

    onSendMessage({
      role: "user",
      content: inputValue,
      inputValue: inputValue,
      createdAt: Date.now(),
      selectedArticles: useOnlyChat ? [] : selectedArticlesData, // used to single doc queries
      useOnlyChat,
    });
    setInputValue("");
    // setRelatedArticles([]);
  };

  return (
    <Box
      bg="transparent"
      w="full"
      height={assistantIsFullScreen ? "96%" : "full"}
      position="relative"
    >
      {/* pb needs to be so high here to compensate for the header */}
      <Flex
        direction="column"
        h="full"
        pb={assistantIsFullScreen ? 0 : 12}
        gap={0}
        bg="white"
      >
        <AIChatTour />
        <Box
          flex="1"
          pt={2}
          pb={5}
          pl={2}
          pr={2}
          bg="#FAFAFA"
          // rounded="md"
          borderRadius="12px"
          overflowY="auto"
          className="webkit-scrollbar-display-none"
        >
          <>
            {sortedMessages.map((msg, index) => (
              <Message
                useOnlyChat={useOnlyChat}
                type={msg.type}
                msg={msg}
                index={index}
                key={msg.content + index}
                setMsgFeedback={(feedbackValue) => {
                  updateMsgFeedback({
                    projectId,
                    conversationId,
                    msgIndex: index,
                    feedbackValue,
                  });
                }}
              />
            ))}
            {/* if last message is from the user, we will show a loading animation */}
            {messages.length > 0 &&
              messages[messages.length - 1].role === "user" && (
                <AssistantChatLoadingWithText />
              )}
            <div ref={messagesEndRef} />
          </>
        </Box>
        <Flex
          bg="#FAFAFA"
          borderBottomLeftRadius="12px"
          borderBottomRightRadius="12px"
          display={showCustomPrompts ? "flex" : "none"}
        >
          <CustomPrompts
            articles={articles}
            assistantIsGenerating={assistantIsGenerating}
          />
        </Flex>
        <Flex h="12px" />
        <Box position="relative">
          {
            <Flex pb="12px" align="center">
              <Flex
                bg="#FBFBFB"
                align="center"
                w="100%"
                borderRadius="md"
                gap="4"
              >
                <ChatToArticleOnAssistantMenu />
                <Divider
                  orientation="vertical"
                  h="20px"
                  borderColor="black"
                  display={messages.length >= 2 ? "flex" : "none"}
                />
                <ToggleOnlyCurrentChatBttn />
              </Flex>
            </Flex>
          }
          <AssistantChatInput
            assistantDisabled={assistantDisabled}
            handleSendClick={handleSendClick}
            assistantIsGenerating={assistantIsGenerating}
            handleStopGeneration={handleStopGeneration}
            inputRef={inputRef}
            setShowCustomPrompts={setShowCustomPrompts}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default AssistantChatWindow; // /**
//  * Adds a message to the database.
//  * @param {Object} options - The options for adding the message.
//  * @param {string} options.projectId - The ID of the project.
//  * @param {string} options.conversationId - The ID of the conversation.
//  * @param {string} options.userId - The ID of the user.
//  * @param {string} options.message - The message to be added.
//  * @returns {Promise<void>} - A promise that resolves when the message is added to the database.
//  */
// export async function addMessageToDb({
//   projectId,
//   conversationId,
//   userId,
//   message,
// }) {
//   await setDoc(
//     doc(db, "projects", projectId, "conversations", conversationId),
//     {
//       messages: arrayUnion(message),
//       userId,
//       continue: true,
//     },
//     { merge: true }
//   );
// }

export const manageSelectedArticles = (message, articles) => {
  if (message.selectedArticles ? message.selectedArticles.length < 1 : true) {
    message.selectedArticles = articles
      .filter((article) => ARTICLE_TYPE_DATA[article.type]?.requestFromVectorDb)
      .map((article) => {
        return {
          uid: article.uid,
          title: article.title,
          type: article.type,
        };
      });
  } else {
    message.selectedArticleQuery = true;
  }
};
