import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import { useFragmentGroups } from "../../../hooks/useFragmentGroups";
import SentimentIcon from "../../Icons/SentimentIcon";
import { InfoTooltip } from "../../InfoTooltip";
import { AccordionTitle } from "./AccordionTitle";
import { SentimentGauge } from "./SentimentGauge";

function SentimentDataRow({ fragment }) {
  if (!fragment.metadata) return null;
  if (!fragment.sentiment) return null;

  return (
    <Flex direction={"column"} gap="9px">
      <Flex gap="24px">
        {Object.entries(fragment.sentiment)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([name, score]) => (
            <SentimentGauge key={name} name={name} score={score} />
          ))}
        <Spacer />
        <Text
          color="#000000"
          opacity="0.7"
          fontSize="10px"
          // fontWeight={"medium"}
        >
          {fragment.metadata.date}
        </Text>
      </Flex>
      <Divider />
    </Flex>
  );
}

function SourceSentimentSection({ source, fragments }) {
  // if none of the fragmens have sentiment data, return null
  // usecase: if the document was uploaded before sentiment was enabled
  if (!fragments.some((fragment) => fragment.sentiment)) return null;

  return (
    <React.Fragment>
      <Flex direction={"row"}>
        <Flex direction="column" gap="1px">
          <Text fontSize="8px" fontWeight="bold" letterSpacing="0">
            {"Sub-source"}
          </Text>
          <Text fontSize="11px" textTransform="capitalize" noOfLines={1}>
            {source}
          </Text>
        </Flex>
        <Spacer />
        <Flex direction={"row"} gap="20px">
          <Flex direction={"column"} alignItems={"center"} gap="1px">
            <Text
              fontSize="8px"
              fontWeight="bold"
              letterSpacing="0"
              noOfLines={1}
            >
              {"S Number"}
            </Text>
            <Text color="#000000" opacity="0.7" fontSize="10px" noOfLines={1}>
              {fragments[0].metadata.m_number}
            </Text>
          </Flex>
          <Flex direction={"column"} alignItems={"center"} gap="1px">
            <Text
              fontSize="8px"
              fontWeight="bold"
              letterSpacing="0"
              noOfLines={1}
            >
              {"S Rating"}
            </Text>
            <Text color="#000000" opacity="0.7" fontSize="10px">
              {fragments[0].metadata.m_rating}
            </Text>
          </Flex>
          <Flex direction={"column"} gap="1px" w="55px">
            <Text
              fontSize="8px"
              fontWeight="bold"
              letterSpacing="0"
              pl="23px"
              noOfLines={1}
            >
              {/* {"Date"} */}
            </Text>
            <Text color="#000000" opacity="0.7" fontSize="10px">
              {" "}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={"column"} gap="9px">
        {fragments.map((fragment) => {
          return <SentimentDataRow key={fragment.uid} fragment={fragment} />;
        })}
      </Flex>
    </React.Fragment>
  );
}

function FragmentGroupsSentimentTable({ fragmentGroups }) {
  // if none of the fragment groups have sentiment data, return null
  if (
    !Object.values(fragmentGroups).some((fragments) =>
      fragments.some((fragment) => fragment.sentiment)
    )
  )
    return (
      <Flex>
        <Text fontSize="11px" opacity={0.7}>
          It appears that sub-source sentiment is not available for the used
          document, as it was uploaded before sentiment was enabled. Please
          reupload the document and rerun the classification. If the issue
          persists, please contact support.
        </Text>
      </Flex>
    );

  return Object.entries(fragmentGroups).map(([source, fragments]) => (
    <SourceSentimentSection
      key={source}
      source={source}
      fragments={fragments}
    />
  ));
}

const SentimentCenter = () => {
  const { sections } = useOrganizerContext();
  const { projectId } = useParams();
  const fragmentGroups = useFragmentGroups(sections, projectId);

  if (Object.keys(fragmentGroups).length === 0) return null;

  return (
    <Box p={0} borderRadius="4px" backgroundColor="#ffffff">
      <Accordion
        pos="relative"
        _before={{
          //Add a stripe
          content: '""',
          display: "block",
          position: "absolute",
          top: "0",
          left: "0",
          width: "4px",
          height: "100%",
          backgroundColor: "accentHighlights",
          borderRadius: "4px 0 0 4px",
        }}
        borderColor="white"
        allowToggle
      >
        <AccordionItem border={0}>
          <h2>
            <AccordionButton>
              <Flex flex="1" textAlign="left" gap="4px" align="center">
                <AccordionTitle title="Sentiment" icon={SentimentIcon} />
                <InfoTooltip type="sentimentCenter" />
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0} pb="16px">
            <Flex direction="column" px="16px" gap="16px" pt="4px">
              <FragmentGroupsSentimentTable fragmentGroups={fragmentGroups} />
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default SentimentCenter;
