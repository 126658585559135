import { Flex, Icon, Text } from "@chakra-ui/react";
import { AutoCompleteItem } from "@choc-ui/chakra-autocomplete";
import React from "react";
import FolderIconComponent from "../Icons/FolderIconComponent";
import { SEARCH_LIMIT } from "./SearchInput";

export const ProjectSearchList = ({ results, router, setQuery }) => (
  <>
    {results?.projects?.slice(0, SEARCH_LIMIT).map((project) => {
      return (
        <AutoCompleteItem
          key={project.uid}
          value={project.name}
          label={project.name}
          onClick={() => {
            router.push(`/${project.uid}?sortBy=type`);
            setQuery("");
          }}
          _focus={{
            bg: "white",
          }}
        >
          <Flex
            direction="row"
            align="center"
            w={{ lg: "100%" }}
            gap={4}
            ml={1}
          >
            <Icon as={FolderIconComponent} />
            <Text
              noOfLines={{ base: 2, lg: 1 }}
              fontWeight="semibold"
              fontSize="sm"
            >
              {project.name}
            </Text>
          </Flex>
        </AutoCompleteItem>
      );
    })}
  </>
);
