export const getConversationTitle = (conversation) => {
  if (conversation?.title) {
    return conversation.title;
  } else {
    const createdAt = conversation?.messages[0]?.createdAt;
    return new Date(createdAt || 0).toISOString().split("T")[0];
  }
};

export const isOnViewerOrInsightPage = (history) => {
  return (
    history.location.pathname.includes("/viewer") ||
    history.location.pathname.includes("/insights")
  );
};

export const parseAndSortCustomAIPrompts = (customPrompts) => {
  const groupedCustomPrompts = customPrompts.reduce((acc, item) => {
    const groupName = item.group || "null";
    // Initialize the group array if it doesn't exist
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    // Add the item to the appropriate group
    acc[groupName].push(item);
    return acc;
  }, {});

  const unGroupedData = groupedCustomPrompts["null"] || [];

  delete groupedCustomPrompts["null"];

  return {
    null: unGroupedData,
    ...groupedCustomPrompts,
  };
};
