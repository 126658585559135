import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { auth } from "../../firebase";
import { getCustomizationData } from "../Utils/customizationUtils";
import { getGreeting } from "../Utils/date.js";

const customization = getCustomizationData();

export const FirstOnboardingStep = () => {
  const [userData, setUserData] = React.useState(null);
  useEffect(() => {
    setUserData(auth.currentUser);
  }, [auth.currentUser]);
  return (
    <Text fontSize="19px">
      {getGreeting()}, {userData && userData.displayName}. Welcome to{" "}
      {customization.name}!
    </Text>
  );
};
