import { Flex } from "@chakra-ui/react";
import React from "react";
import { FiUser } from "react-icons/fi";
import { IoGrid } from "react-icons/io5";
import { MdOutlineLock } from "react-icons/md";
import { useAuth } from "../../../Auth/AuthProvider";
import { useCustomAiModules } from "../../../Context/CustomAiModuleContext";
import { CustomPromptsModuleSection } from "../../AiModules/AiModuleSection";
import { getCustomizationData } from "../../Utils/customizationUtils";

const customization = getCustomizationData();

const getAction = ({ prompt }) => {
  if (prompt.isPublic) {
    return "DELETE";
  } else {
    if (["owner", "editor"].includes(prompt.permission.role)) {
      return "EDIT";
    } else {
      return "PRIVATE_INDICATOR";
    }
  }
};

export const AiModulesList = ({ action, onClick, ...rest }) => {
  const { userCustomAiModules } = useCustomAiModules();
  const { isAiModulesAdmin } = useAuth();

  const publicModules = userCustomAiModules.filter((module) => module.isPublic);

  const ownedModules = userCustomAiModules.filter(
    (module) => module.permission?.role === "owner" && !module.isPublic
  );
  const customModules = userCustomAiModules.filter(
    (module) => module?.permission?.role === "user" && !module.isPublic
  );
  const SectionIcon = ({ icon: Icon }) => <Icon fontSize="12px" />;

  const renderModuleSection = (title, modules, icon, id) => (
    <CustomPromptsModuleSection
      isAiModulesAdmin={isAiModulesAdmin}
      getAction={getAction}
      userCustomAiModules={modules}
      title={title}
      Icon={() => <SectionIcon icon={icon} />}
      id={id}
      action={action}
      onClick={(prompt) => {
        onClick(prompt);
      }}
    />
  );

  return (
    <Flex mt="1px" flexDir="column" gap="24px" {...rest}>
      {renderModuleSection(
        "My Custom Modules",
        ownedModules,
        FiUser,
        "ownedModules"
      )}
      {renderModuleSection(
        `Private modules - custom built by ${customization.name}`,
        customModules,
        MdOutlineLock,
        "privateModulesByMosaiq"
      )}
      {renderModuleSection(
        "Public modules - added from library",
        publicModules,
        IoGrid,
        "privateModulesByMosaiq"
      )}
    </Flex>
  );
};
