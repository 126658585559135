import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

export const useFragmentGroups = (sections, projectId) => {
  const [fragmentGroups, setFragmentGroups] = useState([]);
  const [fragmentQueries, setFragmentQueries] = useState([]);

  // Extract unique pairs of articleId and fragmentId
  useEffect(() => {
    const allTemplateSnippets = Object.values(sections).reduce(
      (acc, section) => acc.concat(section.cards),
      []
    );
    const uniquePairs = allTemplateSnippets.reduce((acc, snippet) => {
      if (!snippet.articleId) return acc;
      const pair = {
        articleId: snippet.articleId,
        fragmentId: snippet.fragmentId,
      };
      if (
        !acc.find(
          (p) =>
            p.articleId === pair.articleId && p.fragmentId === pair.fragmentId
        )
      ) {
        acc.push(pair);
      }
      return acc;
    }, []);

    uniquePairs.sort((a, b) => a.articleId.localeCompare(b.articleId));
    setFragmentQueries(uniquePairs);
  }, [sections]);

  // Fetch fragment groups
  useEffect(() => {
    if (fragmentQueries.length === 0) {
      setFragmentGroups([]);
      return;
    }

    const fetchFragments = async () => {
      const promises = fragmentQueries.map(({ articleId, fragmentId }) => {
        const fragmentRef = doc(
          db,
          `projects/${projectId}/articles/${articleId}/fragments/${fragmentId}`
        );
        return getDoc(fragmentRef).then((doc) => {
          if (doc.exists()) {
            return { ...doc.data(), uid: fragmentId };
          }
          return null;
        });
      });

      const fetchedFragments = await Promise.all(promises);
      const nonNullFragments = fetchedFragments.filter(Boolean);

      const groupedFragments = nonNullFragments.reduce((acc, fragment) => {
        if (!fragment.metadata) return acc;
        if (!acc[fragment.metadata.source]) {
          acc[fragment.metadata.source] = [];
        }
        acc[fragment.metadata.source].push(fragment);
        return acc;
      }, {});

      setFragmentGroups(groupedFragments);
    };

    fetchFragments();
  }, [fragmentQueries, projectId]);

  return fragmentGroups;
};
