import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { useAuthState } from "react-firebase-hooks/auth";
import { getSnippets } from "../db/snippet";
import {
  saveCuratedContents,
  saveProjectsContents,
} from "../store/slices/curated-slice";
// import { auth } from "../firebase";

const useGetSnippets = (params = {}) => {
  const [snippets, setSnippets] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  // const [user] = useAuthState(auth);

  useEffect(() => {
    let unsubscribe = () => {};
    if (params.projectId) {
      unsubscribe = getSnippets({
        ...params,
        setSnippetsCallback: (snippets) => {
          setSnippets(snippets);
          setIsLoading(false);
        },
      });
    }
    return () => {
      console.log("Unsubscribed from snippets");
      unsubscribe();
    };
  }, [params.projectId, params.articleId]);

  useEffect(() => {
    if (snippets) {
      if (params.curated) {
        dispatch(saveCuratedContents(snippets));
        return;
      }
      dispatch(saveProjectsContents(snippets));
    }
  }, [snippets]);

  return { isLoading, snippets };
};

export default useGetSnippets;
