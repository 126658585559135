import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Td,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useArticle } from "../../../Context/ArticleContext";
// import { useUserPermission } from "../../../Context/UserPermissionContext";
import PermissionWrapper from "src/Components/HigherOrderComponents/PermissionWrapper";
import { ARTICLE_TYPE_DATA } from "../../../data/articleTypeData";
import { editArticleTitle } from "../../../db/article";
import ImageWithIconFallback from "../../Elements/ImageWithIconFallback";
import RenameComp from "../../Elements/RenameComp";
import CalendarIcon from "../../Icons/CalendarIcon";
import GlobeIcon from "../../Icons/Globe";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { formatDate } from "../../Utils/dateutils";
import { ArticleSelector } from "./ArticleSelector";
import { IndexingError } from "./IndexingError";
import { IndexingStatus } from "./IndexingStatus";
import { StarredStatusButton } from "./StarredStatusButton";
import { TableRowMoreActions } from "./TableRowMoreActions";
import { TitleWithOrWithoutLink } from "./TitleWithOrWithoutLink";
import { getIndexingError, getIsIndexing } from "./indexingUtils";

const customization = getCustomizationData();

export const TABLE_PROPS = {
  gridSize: 42,
  checkBoxAndStarred: 2,
  checkBoxAndStarredWidth: "88px",
  checkBoxAndStarredPR: 21, //px  
  title: (() => {
    if(!customization.hasSourceColumn) {
      return 22 + 5 // base column span + source column span
    }
    return 22
  })(),
  type: 5,
  typeMinWidth: "55px",
  source: 5,
  sourceMinWidth: "65px",
  dateAdded: 6,
  dateAddedMinWidth: "90px",
  actions: 2,
  actionsMinWidth: "55px",
  gridPL: "1px",
  titlePR: "20px",
};

function formatDuration(seconds) {
  if (!seconds) {
    return "";
  }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Format each component to always have two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  // Combine into HH:MM:SS format
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const IndividualTableRow = ({
  articleData,
  showCommentModalAndUpdateProjectTitle,
  isLastItem,
}) => {
  // const { isReader } = useUserPermission();
  // const userCanFavourite = !isReader();
  const [showRename, setShowRename] = useState(false);
  const { projectId } = useParams();
  // const routerHistory = useHistory();
  const { type, source, uid, starred, createdAt, url } = articleData;

  const isIndexing = getIsIndexing(articleData);

  const indexingError = getIndexingError(articleData);

  const handleEditSubmit = (renameText) => {
    if (renameText) {
      editArticleTitle(projectId, uid, renameText);
    }
  };

  // const handleIconClick = (type, articleData) => {
  //   if (articleData.type === "supportingLink") return;
  //   if (type === "AI_INSIGHT" || type === "OPEN_VIEWER") {
  //     const urlType = type === "AI_INSIGHT" ? "insights" : "viewer";
  //     routerHistory.push({
  //       pathname: `/${projectId}/${articleData.uid}/${urlType}`,
  //       state: {
  //         articleData: articleData,
  //         params: routerHistory.location.search,
  //       },
  //     });
  //   } else if (type === "COMMENT") {
  //     showCommentModalAndUpdateProjectTitle();
  //   } else if (type === "GO_TO_LINK") {
  //     window.open(articleData.url, "_blank");
  //   }
  // };
  const { selectedArticleIds } = useArticle();
  const hasToolTip = ARTICLE_TYPE_DATA[type].toolTip;

  const ToolTipHandler = () => {
    return (
      <Tooltip
        label={ARTICLE_TYPE_DATA[type].toolTip}
        openDelay={300}
        placement="top"
      >
        <Flex>
          <IoMdInformationCircleOutline
            fontSize="14px"
            color="black"
            opacity="0.24"
          />
        </Flex>
      </Tooltip>
    );
  };
  return (
    <Grid
      as="tr"
      _notLast={{
        borderBottom: isLastItem ? "8px solid #f8f8f8" : "4px solid #f8f8f8",
      }}
      borderBottom="0px solid #f8f8f8"
      bg={selectedArticleIds.includes(uid) ? "#F0FFFF" : "white"}
      gridAutoFlow="column"
      templateColumns={`repeat(${TABLE_PROPS.gridSize}, 1fr)`}
      _disabled={{
        // bg: "gray.100",
        color: "#212121",
        // cursor: "not-allowed",
      }}
    >
      <GridItem
        as={Td}
        w={TABLE_PROPS.checkBoxAndStarredWidth}
        pr={`${TABLE_PROPS.checkBoxAndStarredPR}px`}
        colSpan={TABLE_PROPS.checkBoxAndStarred}
      >
        <Flex align="center" w="100%" gap={5} overflow="hidden">
          <HStack minW="100%" justify="space-between">
            <ArticleSelector uid={uid} />
            <PermissionWrapper>
              {(hasEditPermission) => (
                <StarredStatusButton
                  userCanFavourite={hasEditPermission}
                  projectId={projectId}
                  uid={uid}
                  starred={starred}
                />
              )}
            </PermissionWrapper>
          </HStack>
        </Flex>
      </GridItem>

      <GridItem
        as={Td}
        colSpan={TABLE_PROPS.title}
        w="100%"
        pl={TABLE_PROPS.gridPL}
        pr={TABLE_PROPS.titlePR}
      >
        <Flex gap={1}>
          <Flex
            align="center"
            gap={2}
            w="fit-content"
            overflow="hidden"
            className="articleTitle"
          >
            <IndexingStatus
              indexingError={indexingError}
              isIndexing={isIndexing}
              articleData={articleData}
            />

            {showRename ? (
              <RenameComp
                cardTitle={articleData?.title}
                handleEditSubmit={handleEditSubmit}
                setShowRename={setShowRename}
              />
            ) : (
              <TitleWithOrWithoutLink
                type={type}
                projectId={projectId}
                uid={uid}
                articleData={articleData}
                url={url}
              />
            )}
          </Flex>
          <IndexingError
            indexingError={indexingError}
            articleData={articleData}
          />
        </Flex>
      </GridItem>
      {customization.swapTypeForLength ? (
        <GridItem
          as={Td}
          colSpan={TABLE_PROPS.type}
          overflow="hidden"
          pl={TABLE_PROPS.gridPL}
          minW={TABLE_PROPS.typeMinWidth}
        >
          <Box overflow="hidden">
            <Text fontSize="12px" color="#757575">
              {formatDuration(articleData?.length)}
            </Text>
          </Box>
        </GridItem>
      ) : (
        <GridItem
          as={Td}
          colSpan={TABLE_PROPS.type}
          overflow="hidden"
          pl={TABLE_PROPS.gridPL}
          minW={TABLE_PROPS.typeMinWidth}
        >
          <Box overflow="hidden">
            <Flex align="center" gap="4px">
              <Text fontSize="12px" color="#757575">
                {ARTICLE_TYPE_DATA[type]?.uiName || "Other"}
              </Text>
              {hasToolTip && <ToolTipHandler />}
            </Flex>
          </Box>
        </GridItem>
      )}
      {customization.hasSourceColumn && (
        <GridItem
          as={Td}
          colSpan={TABLE_PROPS.source}
          overflow="hidden"
          pl={TABLE_PROPS.gridPL}
          minW={TABLE_PROPS.sourceMinWidth}
        >
          <Box overflow="hidden">
            <HStack gap="8px">
              <ImageWithIconFallback
                imgSrc={articleData?.icon}
                fallbackIcon={GlobeIcon}
                boxSize={3.5}
              />
              <Text textTransform="capitalize" fontSize="12px" color="#757575">
                {source}
              </Text>
            </HStack>
          </Box>
        </GridItem>
      )}

      <GridItem
        as={Td}
        colSpan={TABLE_PROPS.dateAdded}
        overflow="hidden"
        pl={TABLE_PROPS.gridPL}
        minW={TABLE_PROPS.dateAddedMinWidth}
      >
        <HStack gap={"8px"} overflow="hidden">
          <Icon as={CalendarIcon} />
          <Text fontSize="12px" color="#757575">
            {formatDate(createdAt) || "No Date"}
          </Text>
        </HStack>
      </GridItem>
      <GridItem
        as={Td}
        colSpan={TABLE_PROPS.actions}
        overflow="hidden"
        pl={TABLE_PROPS.gridPL}
        minW={TABLE_PROPS.actionsMinWidth}
      >
        <Flex gap="0">
          {/* {customization.uniteAiInsightsAndViewer ? ( */}
          <Icon visibility={"hidden"} fontSize={{ base: "14px" }} />
          {/* ) : (
            <AiInsightsButton
              type={type}
              articleData={articleData}
              handleIconClick={handleIconClick}
              projectId={projectId}
            />
          )} */}
          <TableRowMoreActions
            articleData={articleData}
            projectId={projectId}
            setShowRename={() => {
              setShowRename(true);
            }}
            showCommentModalAndUpdateProjectTitle={
              showCommentModalAndUpdateProjectTitle
            }
          />
        </Flex>
      </GridItem>
    </Grid>
  );
};
