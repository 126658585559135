import { Component } from "react";
import "./AiGeneratedHighlight.css";
// import type { LTWHP } from "../types.js";
// interface Props {
//   position: {
//     boundingRect: LTWHP;
//     rects: Array<LTWHP>;
//   };
//   onClick?: () => void;
//   onMouseOver?: () => void;
//   onMouseOut?: () => void;
//   comment: {
//     emoji: string;
//     text: string;
//   };
//   isScrolledTo: boolean;
// }
export class AiGeneratedHighlight extends Component {
  render() {
    const {
      position,
      // onClick,
      // onMouseOver,
      // onMouseOut,
      // comment,
      isScrolledTo,
    } = this.props;
    // const { boundingRect, rects } = position;
    const { rects } = position;
    // const { emoji, text } = comment;
    return (
      <div>
        {/* <div className="highlight__emoji">{emoji}</div>
        <div className="highlight__content">{text}</div> */}
        {rects.map((rect, index) => (
          <div
            key={index}
            className="AiGeneratedHighlight__part"
            style={{
              top: `${rect.top}px`,
              left: `${rect.left}px`,
              width: `${rect.width}px`,
              height: `${rect.height}px`,
              visibility: isScrolledTo ? "visible" : "hidden",
            }}
          ></div>
        ))}
      </div>
    );
  }
}
export default AiGeneratedHighlight;
