export const checkValidUrl = (url) => {
  url = url?.split("#")[0];
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+()]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=\\-*:/()]*)*" + // query string
      "(\\#[-a-z\\d_\\/+]*)?$",
    "i"
  ); // fragment locator

  let startWithForwardSlash = url?.startsWith("/");
  return pattern.test(url) || startWithForwardSlash;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
