/**
 *
 * Make request to process.env.REACT_APP_GET_USER_ID_FUNCTION_URL to get the uid of the user with the email.
 *
 * @param {*} email
 */

export const getUserId = async (email: string, raiseIfNotFound = false) => {
  const response = await fetch(
    process.env.REACT_APP_GET_USER_ID_FUNCTION_URL as string,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, raiseIfNotFound }),
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.uid;
};
