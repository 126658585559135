export const VIEW_MODE = [
  { name: "Default Card", id: "DEFAULT" },
  { name: "Comfort Card", id: "COMFORT" },
  { name: "Compact Card", id: "COMPACT" },
];

export const VIEW_MODE_MAP = {
  default: {
    id: "DEFAULT",
    uiName: "Default Card",
  },
  comfort: {
    id: "COMFORT",
    uiName: "Comfort Card",
  },
  compact: {
    id: "COMPACT",
    uiName: "Compact Card",
  },
};
