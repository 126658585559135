import { Flex } from "@chakra-ui/react";
import React from "react";

export const LinearBgFooter = () => {
  return (
    <Flex
      position="fixed"
      bottom="0"
      right="0"
      w="100%"
      h="12px"
      bg="linear-gradient(90deg, #191D30 0%, #49558D 100%, #4E5B96 100%);"
    />
  );
};
