
import React from "react";
import { Icon } from "@chakra-ui/react";

const ShareIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M9 8.06002C8.62 8.06002 8.28 8.21002 8.02 8.44502L4.455 6.37002C4.48 6.25502 4.5 6.14002 4.5 6.02002C4.5 5.90002 4.48 5.78502 4.455 5.67002L7.98 3.61502C8.25 3.86502 8.605 4.02002 9 4.02002C9.83 4.02002 10.5 3.35002 10.5 2.52002C10.5 1.69002 9.83 1.02002 9 1.02002C8.17 1.02002 7.5 1.69002 7.5 2.52002C7.5 2.64002 7.52 2.75502 7.545 2.87002L4.02 4.92502C3.75 4.67502 3.395 4.52002 3 4.52002C2.17 4.52002 1.5 5.19002 1.5 6.02002C1.5 6.85002 2.17 7.52002 3 7.52002C3.395 7.52002 3.75 7.36502 4.02 7.11502L7.58 9.19502C7.555 9.30002 7.54 9.41002 7.54 9.52002C7.54 10.325 8.195 10.98 9 10.98C9.805 10.98 10.46 10.325 10.46 9.52002C10.46 8.71502 9.805 8.06002 9 8.06002ZM9 2.02002C9.275 2.02002 9.5 2.24502 9.5 2.52002C9.5 2.79502 9.275 3.02002 9 3.02002C8.725 3.02002 8.5 2.79502 8.5 2.52002C8.5 2.24502 8.725 2.02002 9 2.02002ZM3 6.52002C2.725 6.52002 2.5 6.29502 2.5 6.02002C2.5 5.74502 2.725 5.52002 3 5.52002C3.275 5.52002 3.5 5.74502 3.5 6.02002C3.5 6.29502 3.275 6.52002 3 6.52002ZM9 10.03C8.725 10.03 8.5 9.80502 8.5 9.53002C8.5 9.25502 8.725 9.03002 9 9.03002C9.275 9.03002 9.5 9.25502 9.5 9.53002C9.5 9.80502 9.275 10.03 9 10.03Z"
        fill="black"
      />
    </Icon>
  );
};

export default ShareIconComponent;
