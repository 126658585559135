import React from "react";
import { updateSnippet } from "../db/snippet";
import useNotification from "./useNotification";

const useSnippetEdit = (text) => {
    const [showEdit, setShowEdit] = React.useState(false);
    const [updatedText, setUpdatedText] = React.useState(text);
    const [isEditing, setIsEditing] = React.useState(false);
    const { notify } = useNotification();

    const updateSnippetText = (projectId, insightId) => {
      updateSnippet({ projectId, snippetId: insightId, text: updatedText });
      setIsEditing(false);
      setShowEdit(false);
      notify({
        title: "Snippet updated.",
        description: "Your snippet has been updated.",
        status: "success",
        position: "top-right",
      });
    };

  return { showEdit, updatedText, isEditing, setShowEdit, setUpdatedText, setIsEditing, updateSnippetText };
};

export default useSnippetEdit;
