import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { HiOutlineLockClosed } from "react-icons/hi";
import { BiPencil } from "react-icons/bi";
import { ChangePasswordInput } from "./ChangePasswordInput";

export const ChangePassword = () => {
  const [showEditPassword, setShowEditPassword] = useState(false);
  //Only users that logged in with email and password can change their password
  const [isEmailAndPasswordUser, setIsEmailAndPasswordUser] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const providerArr = user.providerData;
        providerArr.some((provider) => {
          if (provider.providerId === "password") {
            setIsEmailAndPasswordUser(true);
            return true;
          }
        });
      }
    });
  }, []);
  if (!isEmailAndPasswordUser) {
    return null;
  }

  return (
    <Flex
      w={{ base: "100%", lg: "80%", md: "80%", "2xl": "40%" }}
      color="rgba(0, 0, 0, 0.54)"
      mt={4}
      flexDir="row"
      gap={4}
      justify="space-between"
      flexDirection="row"
    >
      <Flex flexDir="column" display={showEditPassword ? "none" : "flex"}>
        <Flex gap={2} align="center">
          <HiOutlineLockClosed fontSize="14px" />
          <Text fontSize="13px">Password</Text>
        </Flex>
        <Text cursor="not-allowed" fontSize="13px">
          xxxxxxxxxxxx
        </Text>
      </Flex>{" "}
      <Flex>
        <Box display={showEditPassword ? "block" : "none"}>
          <ChangePasswordInput
            closeShowEditPassword={() => {
              setShowEditPassword(false);
            }}
          />
        </Box>
        <BiPencil
          fontSize="24px"
          cursor="pointer"
          onClick={() => {
            setShowEditPassword(true);
          }}
          color="primary"
          visibility={showEditPassword ? "hidden" : "visible"}
        />
      </Flex>
    </Flex>
  );
};
