import { Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { SimpleTourMessage } from "./Index";
import { TourComponent } from "./TourComponent";

export const InsightPageTour = () => {
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: (
          <SimpleTourMessage
            heading="Navigate all the insights you saved"
            text="See all the information you have saved or extracted from files here. Filters let you see them all, by source, and by category of insights"
          />
        ),
        placement: "center",
        target: ".showMenuOpt",
      },
      {
        content: (
          <Flex justify="center" w="100%">
            <SimpleTourMessage
              heading="All your saved insights"
              text="View all the insights you have saved or extracted from files here."
            />
            <Flex
              bg="white"
              w="30%"
              h="60px"
              pos="absolute"
              right="0"
              bottom="0"
              zIndex="10"
              align="center"
              justify="center"
            >
              <Button
                h="fit-content"
                borderRadius="full"
                fontSize="16px"
                p="5px"
                px="6px"
                m="0"
                bg="#59808D"
                w="fit-content"
                mr="0.5rem"
                onClick={() => {
                  setState({ run: false, steps: [] });
                }}
              >
                Back to work
              </Button>
            </Flex>
          </Flex>
        ),
        placement: "center",
        target: "#insightPageSnippetList",
      },
    ],
  });
  return <TourComponent steps={steps} run={run} tourType="insightPage" />;
};

export const CuratedSectionInsight = () => {
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: (
          <SimpleTourMessage
            heading="Structure and use your insights"
            text="Create sections to structure & get your saved insights ready for use"
          />
        ),
        placement: "center",
        target: "#createSection",
      },
      {
        content: (
          <SimpleTourMessage
            heading="Structure and use your insights"
            text="Fill the section by drag & droping saved insights into the relevant sections"
          />
        ),
        placement: "center",
        target: "#organizerList",
      },
      {
        content: (
          <Flex>
            <SimpleTourMessage
              heading="Ask your analyst to finish the work"
              text={`
              It can write anything you need, based on the information
                    provided. Just end the tour, and ask him what you want, for
                    example "Write a report"`}
            />
            <Flex
              bg="white"
              w="30%"
              h="60px"
              pos="absolute"
              right="0"
              bottom="0"
              zIndex="10"
              align="center"
              justify="center"
            >
              <Button
                h="fit-content"
                borderRadius="full"
                fontSize="16px"
                p="5px"
                px="6px"
                m="0"
                bg="#59808D"
                w="fit-content"
                mr="0.5rem"
                onClick={() => {
                  //Close the tour
                  setState({ run: false, steps: [] });
                }}
              >
                Back to work
              </Button>
            </Flex>
          </Flex>
        ),
        placement: "center",
        target: "#aiTextInput",
      },
    ],
  });
  return (
    <TourComponent
      steps={steps}
      run={run}
      tourType="curatedSection"
      moveToLeft={true}
    />
  );
};
