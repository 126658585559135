import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getCustomizationData } from "./customizationUtils";

const customization = getCustomizationData();

export const InstallExtensionNotice = () => {
  if (customization.homePage.showExtensionMissingNotice === false) {
    return null;
  }

  return (
    <Flex
      h="48px"
      borderRadius="md"
      align="center"
      w="100%"
      gap={2}
      bg="white"
      p="2"
    >
      <AiOutlineInfoCircle fontSize="20px" color="accentHighlights" />
      <Text fontSize="12px">
        We can see you don’t have the Chrome Extension installed, to get best
        experience install it{" "}
        <Link
          isExternal
          _hover={{ textDecoration: "none" }}
          fontWeight="bold"
          color="#08B2E3"
          href={process.env.REACT_APP_CHROME_WEB_STORE_URL}
        >
          here
        </Link>
        .
      </Text>
    </Flex>
  );
};
