import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BsBookmark } from "react-icons/bs";
import { MdClose, MdInfoOutline } from "react-icons/md";
import { getCustomizationData } from "src/Components/Utils/customizationUtils";
import { useAuth } from "../../../Auth/AuthProvider";
import { colors } from "../../../theme/foundations/colors";

export const WelcomeToTemplatesSection = () => {
  const { userDoc, userDocRef } = useAuth();
  const [hasSeenWelcomeToTemplates, setHasSeenWelcomeToTemplates] =
    useState(false);

  const handleClose = () => {
    updateDoc(userDocRef, {
      hasSeenWelcomeToTemplates: true,
    });
  };

  useEffect(() => {
    setHasSeenWelcomeToTemplates(userDoc.hasSeenWelcomeToTemplates);
  }, [userDoc]);

  if (hasSeenWelcomeToTemplates) {
    return null;
  }
  const customization = getCustomizationData();
  const briefHeading = customization?.brief?.heading;
  const briefBody = customization?.brief?.body;
  return (
    <Box p="0" bg="white" borderRadius="md" boxShadow="sm">
      <Flex gap="12px" p="12px" pb="24px">
        <MdInfoOutline color={colors.accentHighlights} fontSize="16px" />
        <VStack w="100%" gap="8px">
          <HStack alignContent="start" w="100%" justify="space-between">
            <Heading fontSize="10px" color="black" fontWeight={600}>
              {briefHeading || "Welcome to your templates"}
            </Heading>
            <MdClose
              color="#757575"
              fontSize="16px"
              cursor="pointer"
              onClick={handleClose}
            />
          </HStack>
          <Text
            color="black"
            opacity={0.54}
            fontSize="10px"
            lineHeight={1.1}
            w="95%"
            alignSelf={"flex-start"}
          >
            {briefBody || (
              <>
                Create sections and populate them: highlight and add insights
                from assistant answers, PDF Viewer, or drag and drop from
                <Icon
                  as={BsBookmark}
                  viewBox="8px"
                  alignSelf={"center"}
                  justifySelf={"center"}
                />{" "}
                Saved Insights.
              </>
            )}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};
