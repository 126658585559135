import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Layout from "../../Components/Layout";
import { AssistantDrawer } from "../../Components/assistant/AssistantDrawer";
import { useAssistantContext } from "../../Context/AssistantContext";

export const FullScreenAssistant = () => {
  const { setAssistantIsFullScreen, assistantIsFulScreen, setAssistantIsOpen } =
    useAssistantContext();
  //   setAssistantIsFullScreen(true);

  useEffect(() => {
    setAssistantIsFullScreen(true);
    setAssistantIsOpen(true);
    return () => {
      setAssistantIsFullScreen(false);
    };
  }, [assistantIsFulScreen]);
  return (
    <Layout hideAssistant={true}>
      <Flex w="60%" mx="auto" h="90vh" pt="20px">
        <AssistantDrawer />
      </Flex>
    </Layout>
  );
};
