import React from "react";
import { Icon } from "@chakra-ui/react";

const FolderUploadIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
          d="M13.3334 3.99996H8.00004L6.66671 2.66663H2.66671C1.93337 2.66663 1.34004 3.26663 1.34004 3.99996L1.33337 12C1.33337 12.7333 1.93337 13.3333 2.66671 13.3333H13.3334C14.0667 13.3333 14.6667 12.7333 14.6667 12V5.33329C14.6667 4.59996 14.0667 3.99996 13.3334 3.99996ZM13.3334 12H2.66671V3.99996H6.11337L7.44671 5.33329H13.3334V12ZM6.27337 9.61329L7.33337 8.55996V11.3333H8.66671V8.55996L9.72671 9.61996L10.6667 8.67329L8.00671 5.99996L5.33337 8.67329L6.27337 9.61329Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default FolderUploadIconComponent;
