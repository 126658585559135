import {
  Flex,
  HStack,
  Icon,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { useArticle } from "../../Context/ArticleContext";
import { ARTICLE_TYPE_DATA } from "../../data/articleTypeData";
import MultiDocQueryChatIcon from "../Icons/MultiDocQueryChatIcon";

export const AllArticleTitleLists = ({ docs, showSelectedArticleList }) => {
  return (
    <Flex flexDir="column" gap={1} p="0" fontSize="12px">
      <OrderedList
        display={showSelectedArticleList ? "block" : "none"}
        spacing={1}
        p="0"
        fontSize="12px"
        mt="2px"
      >
        {docs.map((doc) => (
          <ListItem key={doc.uid}>
            <Text noOfLines={1}>{doc.title}</Text>
          </ListItem>
        ))}
      </OrderedList>
    </Flex>
  );
};

/**
 * Checks if two arrays contain the same elements.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {boolean} - Returns true if the arrays contain the same elements, false otherwise.
 */
const arraysContainSameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort((a, b) => a.uid - b.uid);
  const sortedArr2 = [...arr2].sort((a, b) => a.uid - b.uid);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i].uid !== sortedArr2[i].uid) {
      return false;
    }
  }

  return true;
};

export const MultiDocSelectMenuOnChat = ({
  toggleSelectedArticleList,
  selectedArticles,
  showSelectedArticleList,
}) => {
  const { allUnfilteredArticles } = useArticle();

  const menuText = arraysContainSameElements(
    allUnfilteredArticles,
    selectedArticles
  )
    ? "All project files"
    : `${selectedArticles.length} files`;

  const MenuSelectIcon = showSelectedArticleList
    ? IoChevronUpSharp
    : IoChevronDownSharp;

  return (
    <Flex direction="column">
      <HStack
        onClick={() => toggleSelectedArticleList()}
        p="0"
        alignItems="center"
        cursor="pointer"
        userSelect="none"
      >
        <Icon fontSize="16px" as={MultiDocQueryChatIcon} />
        <Text fontSize="12px" display="inline" noOfLines={2}>
          {menuText}
        </Text>
        <MenuSelectIcon />
      </HStack>

      <AllArticleTitleLists
        docs={selectedArticles}
        showSelectedArticleList={showSelectedArticleList}
      />
    </Flex>
  );
};

export const DisplaySingleArticle = ({ selectedArticle }) => {
  return (
    <Flex gap={1} fontSize="12px">
      <Icon fontSize="15px" as={ARTICLE_TYPE_DATA[selectedArticle.type].icon} />
      <Text display="inline" noOfLines={2}>
        {selectedArticle.title}
      </Text>
    </Flex>
  );
};
