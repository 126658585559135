import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { auth, registerWithEmailAndPassword } from "../../firebase";
import useNotification from "../../hooks/useNotification";
import { logUserInFromExtension } from "../Utils/auth";

export const EmailSignUp = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });
  const [validEmail, setValidEmail] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [userAuth, loading] = useAuthState(auth);
  const history = useHistory();
  const location = useLocation();
  const { notify } = useNotification();
  const createUser = () => {
    registerWithEmailAndPassword({
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
    })
      .then((res) => {
        if (res === "Firebase: Error (auth/email-already-in-use).") {
          notify({
            title: "Email already in use.",
            description:
              "This email is already in use, please try another one.",
            status: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        if (err.message === "Firebase: Error (auth/email-already-in-use).") {
          notify({
            title: "Email already in use.",
            description:
              "This email is already in use, please try another one.",
            status: "error",
          });
        } else {
          notify({
            title: "Something went wrong.",
            description: "Please refresh the page and try again.",
            status: "error",
          });
        }
      });
  };

  const checkIfFormIsComplete = () => {
    if (
      userData.firstName &&
      userData.lastName &&
      userData.email &&
      userData.password &&
      userData.confirmPassword &&
      validEmail &&
      userData.password === userData.confirmPassword &&
      acceptTerms === true
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateEmailText = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };
  useEffect(() => {
    //This will trigger the loggin in from extension if the user is logged in. The logUserInFromExtension checks if the URL matches the extension URL and if so, it will log the user in.
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        logUserInFromExtension();
      }
    });
  }, [user]);

  useEffect(() => {
    if (userAuth) {
      if (!userAuth.emailVerified) {
        history.replace("/verifyemail");
      } else {
        if (location.state && location.state.from) {
          history.replace(location.state.from);
        } else {
          history.replace("/user");
        }
      }
    }
  }, [user, loading]);

  return (
    <Flex
      h="98vh"
      align="center"
      justify="center"
      m="0 auto"
      w={{ lg: "50%", base: "80%", "2xl": "25%" }}
    >
      <Flex flexDir="column" align="left">
        <Heading fontSize="24px" color="#1D1D1D" mb={6}>
          Create account
        </Heading>
        <Flex
          justifyContent="space-between"
          gap={6}
          flexDir="row"
          flexWrap="wrap"
          w="100%"
        >
          <FormControl flexDir="column" w="45%" isRequired>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              First name
            </FormLabel>
            <Input
              px="0.3rem"
              w="100%"
              fontSize="14px"
              type="text"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.firstName}
              onChange={(e) =>
                setUserData({ ...userData, firstName: e.target.value })
              }
            />
          </FormControl>
          <FormControl flexDir="column" w="45%" isRequired>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              Last name
            </FormLabel>
            <Input
              w="100%"
              fontSize="14px"
              type="text"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.lastName}
              onChange={(e) =>
                setUserData({ ...userData, lastName: e.target.value })
              }
            />
          </FormControl>
          <FormControl flexDir="column" w="45%" isRequired>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              Email
            </FormLabel>
            <Input
              w="100%"
              fontSize="14px"
              type="email"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.email}
              onChange={(e) => {
                setUserData({ ...userData, email: e.target.value });
                updateEmailText(e.target.value);
              }}
            />
            <Text
              fontSize="14px"
              visibility={
                userData.email.length > 0
                  ? validEmail
                    ? "hidden"
                    : "visible"
                  : "hidden"
              }
              color="red"
            >
              Invalid email
            </Text>
          </FormControl>
          <FormControl flexDir="column" w="45%" isRequired={false}>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              Date of birth
            </FormLabel>
            <Input
              w="100%"
              fontSize="14px"
              type="date"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.dob}
              onChange={(e) =>
                setUserData({ ...userData, dob: e.target.value })
              }
            />
          </FormControl>
          <FormControl flexDir="column" w="45%" isRequired>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              Password{" "}
            </FormLabel>
            <Input
              w="100%"
              fontSize="14px"
              type="password"
              autoComplete="new-password"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
            {userData.password && userData.password.length < 6 && (
              <Text display="inline" color="red" fontSize="12px">
                must be at least 6 characters
              </Text>
            )}
          </FormControl>
          <FormControl flexDir="column" w="45%" isRequired>
            <FormLabel fontWeight="400" color="tertiary" fontSize="14px">
              Confirm Password
            </FormLabel>
            <Input
              w="100%"
              fontSize="14px"
              type="password"
              autoComplete="new-password"
              border="1px solid rgba(0, 0, 0, 0.12)"
              value={userData.confirmPassword}
              onChange={(e) => {
                setUserData({ ...userData, confirmPassword: e.target.value });
              }}
            />
            {userData.confirmPassword.length > 0 &&
            userData.confirmPassword !== userData.password ? (
              <Text display="inline" color="red" fontSize="12px">
                Not a match
              </Text>
            ) : userData.password &&
              userData.password === userData.confirmPassword ? (
              <Text display="inline" color="green" fontSize="12px">
                It&apos;s a match!
              </Text>
            ) : (
              <Text
                userSelect="none"
                display="inline"
                color="transparent"
                fontSize="12px"
              >
                {"alert"}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex gap={2} mt={0} flexDir="column">
          <Flex align="center" gap={2} mb={6}>
            <Text fontSize="14px" color="red">
              *
            </Text>
            <Text fontSize="14px" color="tertiary">
              Required
            </Text>
          </Flex>
          <Checkbox>
            <Text fontSize="14px">Remember me</Text>
          </Checkbox>
          <Checkbox
            value={acceptTerms}
            onChange={() => {
              setAcceptTerms(!acceptTerms);
            }}
          >
            <Text fontSize="14px">
              I agree to all the{" "}
              <Link
                color="link"
                href="https://www.mosaiqlabs.com/terms-and-conditions"
                isExternal
              >
                Terms
              </Link>{" "}
              and{" "}
              <Link
                color="link"
                href="https://www.mosaiqlabs.com/privacy-policy"
                isExternal
              >
                Privacy policy
              </Link>
            </Text>
          </Checkbox>
        </Flex>

        <Flex mt={10}>
          <Button
            isDisabled={checkIfFormIsComplete()}
            onClick={() => {
              createUser();
            }}
            fontSize="14px"
            w="100%"
            bg="secondary"
          >
            Create account
          </Button>
        </Flex>

        <Flex align="center" justify="center" mt={12}>
          <Text fontSize="14px">
            Already have an account?{" "}
            <Link
              textDecor="underline"
              color="#007aff"
              as={ReactRouterLink}
              to="/"
            >
              Sign in
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
