import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const gptSlice = createSlice({
  name: "gpt",
  initialState,
  reducers: {
    saveHistories: (state, action) => {
      state[action.payload.id] = { histories: action.payload.histories, unsubscribe: action.payload.unsubscribe };
    },
    resetHistory: (state, action) => {
      state[action.payload.id] = { histories: null, unsubscribe: null };
    }
  },
});

export const { saveHistories, resetHistory } = gptSlice.actions;

export default gptSlice.reducer;
