import React from "react";
import { Icon } from "@chakra-ui/react";

const FolderIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 40 40" fill="none" {...props}>
      <g clipPath="url(#clip0_3239_11611)">
        <path
          d="M31.6667 4.99996H20.7867C20.5289 5.00166 20.2741 4.94467 20.0417 4.83329L14.7817 2.19329C14.0873 1.84753 13.3223 1.66727 12.5467 1.66663H8.33333C6.12401 1.66927 4.00593 2.5481 2.4437 4.11033C0.88147 5.67256 0.00264643 7.79063 0 9.99996L0 30C0.00264643 32.2093 0.88147 34.3274 2.4437 35.8896C4.00593 37.4518 6.12401 38.3306 8.33333 38.3333H31.6667C33.876 38.3306 35.9941 37.4518 37.5563 35.8896C39.1185 34.3274 39.9974 32.2093 40 30V13.3333C39.9974 11.124 39.1185 9.00589 37.5563 7.44366C35.9941 5.88143 33.876 5.00261 31.6667 4.99996ZM8.33333 4.99996H12.5467C12.8044 4.99826 13.0592 5.05525 13.2917 5.16663L18.5517 7.79829C19.2453 8.14694 20.0103 8.33006 20.7867 8.33329H31.6667C32.6634 8.33492 33.6369 8.63441 34.4622 9.19331C35.2875 9.75222 35.9269 10.545 36.2983 11.47L3.33333 11.6566V9.99996C3.33333 8.67388 3.86012 7.40211 4.7978 6.46443C5.73548 5.52674 7.00725 4.99996 8.33333 4.99996ZM31.6667 35H8.33333C7.00725 35 5.73548 34.4732 4.7978 33.5355C3.86012 32.5978 3.33333 31.326 3.33333 30V14.99L36.6667 14.8016V30C36.6667 31.326 36.1399 32.5978 35.2022 33.5355C34.2645 34.4732 32.9927 35 31.6667 35Z"
          fill="#191D30"
        />
      </g>
      <defs>
        <clipPath id="clip0_3239_11611">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default FolderIconComponent;
