import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useCustomAiModules } from "../../Context/CustomAiModuleContext";
import { AiModuleBox, CustomPromptListGrid } from "../AiModules/AiModuleBox";

export const LibraryPageTopRatedCustomAIModulesLists = () => {
  const { publicCustomAiModules, userCustomAiModules } = useCustomAiModules();

  // for all public custom ai modules which are also added by user, add a flag "in_user_library"
  const publicCustomAiModulesInUserLibrary = publicCustomAiModules.map(
    (publicModule) => {
      const isAddedByUser = userCustomAiModules.some(
        (userModule) => userModule.uid === publicModule.uid
      );
      return { ...publicModule, in_user_library: isAddedByUser };
    }
  );

  const filterForTopRated = publicCustomAiModulesInUserLibrary.filter(
    (module) => module.moduleDocData?.isTopRated
  );

  if (filterForTopRated.length === 0) {
    return null;
  }

  return (
    <Flex mt="22px" flexDir="column" gap="16px">
      <Text fontSize="12px" color="primary">
        Top rated
      </Text>
      <CustomPromptListGrid>
        {filterForTopRated.map((prompt) => {
          return (
            <AiModuleBox
              key={prompt.uid}
              moduleData={prompt}
              isLoading={false}
              action={
                prompt.in_user_library ? "ALREADY_ADDED_INDICATOR" : "ADD"
              }
              actionAlwaysOn={true}
              actionOnlyOnIcon={true}
            />
          );
        })}
      </CustomPromptListGrid>
    </Flex>
  );
};
