import { Box, Divider, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { BiBookmark, BiInfoCircle, BiListUl } from "react-icons/bi";
import { FilteredTypesMenu } from "./FilteredTypesMenu";
import { ProjectPageSwitchBttn } from "./ProjectPageSwitchButtons";

const SwitchBttnType = {
  AllContent: "allContent",
  KeyInsights: "keyInsights",
};

/**
 * Updates the article type parameters in the URL search params and replaces the current history state.
 * @param {string} text - The article type to add or remove from the URL search params.
 * @param {boolean} state - The state of the article type switch (true for on, false for off).
 * @param {object} history - The history object from the react-router-dom package.
 */
export const updateArticleTypeParams = (text, state, history) => {
  const currentSearchParams = new URLSearchParams(location.search);
  const articleTypeParam = currentSearchParams.get("articleTypes");
  if (state) {
    currentSearchParams.set(
      "articleTypes",
      articleTypeParam ? `${articleTypeParam},${text}` : text
    );
  } else {
    const articleTypeArray = articleTypeParam?.split(",");
    const newArticleTypeArray = articleTypeArray?.filter(
      (item) => item !== text
    );
    if (newArticleTypeArray?.length) {
      currentSearchParams.set("articleTypes", newArticleTypeArray.join(","));
    } else {
      currentSearchParams.delete("articleTypes"); // Delete articleTypes param if it's empty
    }
  }

  history.replace(`?${currentSearchParams.toString()}`);
};

/**
 * Renders a button for selecting an article type.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display on the button.
 * @param {boolean} props.isSelected - Whether the button is currently selected.
 * @param {function} props.updateSelectedTypedArr - A function to call when the button is clicked.
 * @param {string} props.tooltip - Optional tooltip text to display when hovering over the button.
 *  If there is tooltip text, an info icon will be displayed next to the button.
 * @param {number} props.typeCount - The number of articles of this type.
 * @returns {JSX.Element} - The rendered component.
 */
export const ArticleTypeBttn = ({
  text,
  isSelected,
  updateSelectedTypedArr,
  tooltip,
  typeCount,
}: {
  text: string;
  isSelected: boolean;
  updateSelectedTypedArr: () => void;
  tooltip?: string;
  typeCount: number;
}) => {
  return (
    <Flex
      cursor="pointer"
      border="1px solid"
      borderColor={isSelected ? "transparent" : "#dddee0"}
      borderRadius="3xl"
      w="fit-content"
      px="4"
      boxSizing="border-box"
      py="2"
      h="24px"
      align="center"
      userSelect="none"
      bg={isSelected ? "#59808D" : "transparent"}
      gap={1}
      justify="center"
      transition={"all 0.3s ease-in-out"}
      onClick={() => {
        updateSelectedTypedArr();
      }}
    >
      {/* <Flex flexShrink={0}>
        <AiOutlineCheck
          color="white"
          fontSize="10px"
          style={{
            display: isSelected ? "block" : "none",
          }}
        />
      </Flex> */}
      <HStack gap="3px" align="center" flexShrink={0}>
        <Text
          color={isSelected ? "white" : "primary"}
          textAlign="center"
          fontSize="12px"
          noOfLines={1}
          textTransform="capitalize"
        >
          {text}
        </Text>
        <Text
          color={isSelected ? "white" : "black"}
          textAlign="center"
          fontSize="12px"
          noOfLines={1}
          fontWeight="900"
        >
          {typeCount.toLocaleString()}
        </Text>
      </HStack>

      {/* show info icon if there is a tooltip */}
      {tooltip ? (
        <Tooltip label={tooltip} placement="top">
          <Box>
            <BiInfoCircle color="gray" fontSize="14px" />
          </Box>
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export const HeaderSwitches = () => {
  // const [selectedArticleTypes, setSelectedArticleTypes] = useState([]);
  // const routerHistory = useHistory();
  // const routerSearchParams = routerHistory.location.search;

  //This will update the state of the articleTypes array and update the URL search params accordingly
  // const updateSelectedTypedArr = (type) => {
  //   if (selectedArticleTypes.includes(type)) {
  //     setSelectedArticleTypes(
  //       selectedArticleTypes.filter((item) => item !== type)
  //     );
  //     updateArticleTypeParams(type, false, routerHistory);
  //   } else {
  //     setSelectedArticleTypes([...selectedArticleTypes, type]);
  //     updateArticleTypeParams(type, true, routerHistory);
  //   }
  // };

  //This will fetch all the articleTypes if they exist and poupulate the state so the buttons can be highlighted accordingly
  // useEffect(() => {
  //   const currentSearchParams = new URLSearchParams(location.search);
  //   const articleTypeParam = currentSearchParams.get("articleTypes");
  //   const articleTypeArray = articleTypeParam?.split(",");
  //   if (articleTypeArray?.length) {
  //     setSelectedArticleTypes(articleTypeArray);
  //   }
  // }, [routerSearchParams]);

  return (
    <Flex mt="6" mb="2" align="center">
      <Flex gap="2">
        <ProjectPageSwitchBttn
          text="All Content"
          BttnIcon={BiListUl}
          type={SwitchBttnType.AllContent}
        />
        <ProjectPageSwitchBttn
          text="Saved Insights"
          BttnIcon={BiBookmark}
          type={SwitchBttnType.KeyInsights}
        />
      </Flex>
      <Divider
        orientation="vertical"
        ml="2"
        mr="2"
        h="24px"
        alignSelf="center"
        borderColor="#dadada"
      />
      <FilteredTypesMenu />
    </Flex>
  );
};
