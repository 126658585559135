import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const SUPPLEMENTARY_SENTIMENT_DATA = {
  forthcoming: {
    labelLeft: "Guarded",
    labelRight: "Forthcoming",
  },
  precise: {
    labelLeft: "Vague",
    labelRight: "Precise",
  },
};
/**
 *
 * A rounded rectange with a black vertical line at "score" position (could be 0-10, where 0 is left and 10 is right)
 * On the left and right of the line, there are labels.
 *
 * @param {Object} props
 * @param {string} props.labelLeft
 * @param {string} props.labelRight
 * @param {number} props.score
 */
export const SentimentGauge = ({ name, score }) => {
  return (
    <Flex direction="row" alignItems={"center"} gap="12px">
      <Text fontSize="10px" opacity={0.7}>
        {SUPPLEMENTARY_SENTIMENT_DATA[name].labelLeft}
      </Text>
      <Flex
        borderRadius="4px"
        backgroundColor="#E0E0E0"
        height="4px"
        width="80px"
        position="relative"
      >
        <Flex
          position="absolute"
          left={`${score * 10}%`}
          width="1px"
          height="8px"
          mt="-2px"
          backgroundColor="black"
        />
      </Flex>
      <Text fontSize="10px" opacity={0.7}>
        {SUPPLEMENTARY_SENTIMENT_DATA[name].labelRight}
      </Text>
    </Flex>
  );
};
