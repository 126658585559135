import React from "react";
import { Icon } from "@chakra-ui/react";

const LogoutIconComponent = (props) => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
          d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z"
          fill="#191D30"
        />
    </Icon>
  );
};

export default LogoutIconComponent;
