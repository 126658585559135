import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTemplateContext } from "../../../Context/TemplateContext";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { FloatingTemplateList } from "./FloatingTemplateList";

const customization = getCustomizationData();

export const AddToTemplate = ({
  highlightedText,
  onClickAway,
  sourceArticles,
}) => {
  const [showFloatingTemplateList, setShowFloatingTemplateList] =
    React.useState(false);
  const { templateList } = useTemplateContext();
  return (
    <Flex display={templateList.length > 0 ? "flex" : "none"} flexDir="column">
      <Flex
        cursor="pointer"
        bg="primary"
        p="3px"
        borderRadius="0 4px 4px 0"
        onMouseOver={() => {
          setShowFloatingTemplateList(true);
        }}
        fontWeight="bold"
        minW="97px"
        maxH="20px"
        justify="center"
      >
        <Text>{`Add to ${customization.template.name.toLowerCase()}`}</Text>
      </Flex>
      <FloatingTemplateList
        position="absolute"
        top="70%"
        w="100%"
        display={showFloatingTemplateList ? "flex" : "none"}
        highlightedText={highlightedText}
        onClickAway={onClickAway}
        sourceArticles={sourceArticles}
      />
    </Flex>
  );
};
