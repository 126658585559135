import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

export const RequestModuleForm = ({ userSubmittedRequest }) => {
  const [requestFormData, setRequestFormData] = useState({
    industry: "",
    goalsOverview: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequestFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    userSubmittedRequest(requestFormData);
    setRequestFormData({
      industry: "",
      goalsOverview: "",
    });
  };

  return (
    <Flex gap="24px" flexDir="column">
      <FormControl gap="16px" as={Flex} flexDir="column">
        <FormLabel
          mb="0"
          color="black"
          opacity="0.8"
          fontWeight="400"
          fontSize="16px"
        >
          Which industry or profession best describes your work?
        </FormLabel>
        <Input
          height="42px"
          fontSize={"14px"}
          border="1px solid #e3e4e6"
          _focusVisible={{
            border: "1px solid #e3e4e6",
            outline: "none",
          }}
          placeholder="Legal, B2B, Consulting"
          name="industry"
          value={requestFormData.industry}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl gap="16px" as={Flex} flexDir="column">
        <FormLabel
          mb="0"
          color="black"
          opacity="0.8"
          fontWeight="400"
          fontSize="16px"
        >
          Please provide a high-level overview of your goals.
        </FormLabel>
        <Textarea
          rows={5}
          fontSize={"14px"}
          border="1px solid #e3e4e6"
          _focusVisible={{
            border: "1px solid #e3e4e6",
            outline: "none",
          }}
          placeholder="I want to automate the process of extracting data from"
          name="goalsOverview"
          value={requestFormData.goalsOverview}
          onChange={handleChange}
        />
      </FormControl>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          bg="primary"
          color="white"
          fontSize="16px"
          fontWeight="500"
          px="12px"
          py="8.5px"
          w="fit-content"
          onClick={handleSubmit}
          isDisabled={
            !requestFormData.industry || !requestFormData.goalsOverview
          }
        >
          Request module
        </Button>
      </Flex>
    </Flex>
  );
};

export default RequestModuleForm;
