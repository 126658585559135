import { Button, Link } from "@chakra-ui/react";
import React from "react";
import { BsChatLeftFill } from "react-icons/bs";

export const BookChatBttn = () => {
  return (
    <Button
      position="absolute"
      right="3%"
      bottom="6%"
      rightIcon={<BsChatLeftFill />}
      bg="#08B2E3"
      fontSize="12px"
      borderRadius="200px"
      color="white"
      _focusVisible={{
        bg: "#08B2E3",
      }}
      as={Link}
      href="https://calendly.com/mosaiqlabs/intro-call"
      _hover={{
        textDecor: "none",
      }}
      target="_blank"
    >
      Book a chat to learn more
    </Button>
  );
};
