const ASSISTANT_MIN_WIDTH = 330;

/**
 * Custom hook for resizing the assistant.
 *
 * @param {Function} setAssistantWidth - The function to set the width of the assistant.
 * @returns {Object} - An object containing the `startResize` function.
 */
const useAssistantResize = (setAssistantWidth) => {
  const resize = (event) => {
    // offset of 3px here is needed to account for cursor being on the interaction zone (vertical line),
    // which is slightly to the right of the assistant border
    const newWidth = window.innerWidth - event.clientX + 3;
    if (newWidth > window.innerWidth * 0.55) {
      return;
    }
    if (newWidth < ASSISTANT_MIN_WIDTH) {
      setAssistantWidth(ASSISTANT_MIN_WIDTH);
      return;
    }
    setAssistantWidth(newWidth);
  };

  const startResize = () => {
    // viewer prevents mouse events from arriving, so we need to disable pointer events
    const webviewerElement = document.querySelector(".viewerBox");
    if (webviewerElement) {
      webviewerElement.style.pointerEvents = "none";
    }
    // resizing causes text selection glitches, so we disable it during resize
    document.body.style.userSelect = "none";
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", stopResize);
  };

  const stopResize = () => {
    // re-enable pointer events and text selection
    const webviewerElement = document.querySelector(".viewerBox");
    if (webviewerElement) {
      webviewerElement.style.pointerEvents = "";
    }
    // re-enable text selection
    document.body.style.userSelect = "";
    document.removeEventListener("mousemove", resize);
    document.removeEventListener("mouseup", stopResize);
  };

  return { startResize };
};

export default useAssistantResize;
