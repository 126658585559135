import { Icon } from "@chakra-ui/react";
import React from "react";

const GlobeIcon = (props) => {
  return (
    <Icon
      viewBox="0 0 12 12"
      opacity={0.54}
      strokeWidth={0.05}
      fill="#000000"
      {...props}
    >
      <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259972 4.80026 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.8841 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM10.3285 3.5H8.7155C8.35401 2.66175 7.87761 1.8779 7.3 1.171C8.57698 1.51788 9.66538 2.35489 10.3285 3.5ZM8.25 6C8.2464 6.50899 8.16668 7.01459 8.0135 7.5H3.9865C3.83332 7.01459 3.75361 6.50899 3.75 6C3.75361 5.491 3.83332 4.98541 3.9865 4.5H8.0135C8.16668 4.98541 8.2464 5.491 8.25 6ZM4.387 8.5H7.613C7.18831 9.33817 6.64506 10.1108 6 10.794C5.35494 10.1108 4.81169 9.33817 4.387 8.5ZM4.387 3.5C4.81169 2.66182 5.35494 1.88922 6 1.206C6.64506 1.88922 7.18831 2.66182 7.613 3.5H4.387ZM4.7 1.171C4.12253 1.87799 3.64613 2.66183 3.2845 3.5H1.6715C2.33463 2.35489 3.42302 1.51788 4.7 1.171ZM1.23 4.5H2.946C2.68468 5.48293 2.68468 6.51707 2.946 7.5H1.23C0.923348 6.52351 0.923348 5.47649 1.23 4.5ZM1.6715 8.5H3.2845C3.64599 9.33825 4.12239 10.1221 4.7 10.829C3.42302 10.4821 2.33463 9.64511 1.6715 8.5ZM7.3 10.829C7.87748 10.122 8.35387 9.33817 8.7155 8.5H10.3285C9.66538 9.64511 8.57698 10.4821 7.3 10.829ZM10.77 7.5H9.054C9.31532 6.51707 9.31532 5.48293 9.054 4.5H10.77C11.0767 5.47649 11.0767 6.52351 10.77 7.5Z" />
    </Icon>
  );
};

export default GlobeIcon;
