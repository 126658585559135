import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { FaUserCog } from "react-icons/fa";
import { WorkspacePermissionDoc } from "shared/workspaceTypes";
import { useAuth } from "src/Auth/AuthProvider";
import {
  getRetiredWorkspacePermissionsCollection,
  getWorkspacePermissionsCollection,
} from "src/db/workspace";
import {
  useActiveWorkspaceId,
  useActiveWorkspacePermission,
} from "src/stores/userWorkspaces";

const useChangeRoleMutation = (activeWorkspaceId) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: async ({
      permission,
      role,
    }: {
      permission: WorkspacePermissionDoc;
      role: WorkspacePermissionDoc["role"];
    }) => {
      if (!activeWorkspaceId) return;
      await updateDoc(
        doc(
          getWorkspacePermissionsCollection(activeWorkspaceId),
          permission.uid
        ),
        { role }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`workspacePermissionsData-${activeWorkspaceId}`],
      });
    },
    onError: (error: Error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
      });
    },
  });
};

const useRemoveMemberMutation = (activeWorkspaceId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { currentUser } = useAuth();

  return useMutation({
    mutationFn: async (permission: WorkspacePermissionDoc) => {
      if (!activeWorkspaceId) return;
      await deleteDoc(
        doc(
          getWorkspacePermissionsCollection(activeWorkspaceId),
          permission.uid
        )
      );
      await setDoc(
        doc(
          getRetiredWorkspacePermissionsCollection(activeWorkspaceId),
          permission.uid
        ),
        {
          ...permission,
          retiredAt: Date.now(),
          retiredBy: currentUser?.uid,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`workspacePermissionsData-${activeWorkspaceId}`],
      });
    },
    onError: (error: Error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
      });
    },
  });
};

const RoleMenu = ({ permission }: { permission: WorkspacePermissionDoc }) => {
  const activeWorkspaceId = useActiveWorkspaceId();
  const activeWorkspacePermission = useActiveWorkspacePermission();
  const { currentUser } = useAuth();

  const { mutate: changeRole } = useChangeRoleMutation(activeWorkspaceId);
  const { mutate: removeMember } = useRemoveMemberMutation(activeWorkspaceId);

  const isCurrentUser = currentUser?.uid === permission.uid;
  if (isCurrentUser) {
    return (
      <Tooltip label="Cannot modify your own membership" placement="top">
        <Button
          fontSize="12px"
          bg="transparent"
          _active={{ bg: "transparent" }}
          pl="0"
          _hover={{ bg: "transparent" }}
          cursor="not-allowed"
        >
          <Flex
            alignItems="center"
            color={permission.role === "owner" ? "#08B2E3" : "gray.600"}
          >
            {permission.role}
          </Flex>
        </Button>
      </Tooltip>
    );
  }

  if (activeWorkspacePermission?.role !== "owner") {
    return (
      <Tooltip label="Cannot modify workspace members" placement="top">
        <Button
          fontSize="12px"
          bg="transparent"
          _active={{ bg: "transparent" }}
          pl="0"
          _hover={{ bg: "transparent" }}
          cursor="not-allowed"
        >
          <Flex
            alignItems="center"
            color={permission.role === "owner" ? "#08B2E3" : "gray.600"}
          >
            {permission.role}
          </Flex>
        </Button>
      </Tooltip>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        fontSize="12px"
        rightIcon={<ChevronDownIcon color="#08B2E3" />}
        bg="transparent"
        _active={{ bg: "transparent" }}
        pl="0"
        _hover={{ bg: "transparent" }}
      >
        <Flex
          alignItems="center"
          color={permission.role === "owner" ? "#08B2E3" : "gray.600"}
        >
          {permission.role}
        </Flex>
      </MenuButton>
      <MenuList
        bg="white"
        border="none"
        p="16px"
        gap="10px"
        display="flex"
        flexDir="column"
      >
        <MenuItem
          fontSize="12px"
          px="0"
          color="#08B2E3"
          bg="white"
          onClick={() => changeRole({ permission, role: "owner" })}
        >
          <Flex gap="6px" align="center">
            <FaUserCog />
            Owner
          </Flex>
        </MenuItem>
        <MenuItem
          fontSize="12px"
          px="0"
          color="gray.600"
          bg="white"
          onClick={() => changeRole({ permission, role: "member" })}
        >
          Member
        </MenuItem>
        <MenuItem
          fontSize="12px"
          px="0"
          color="#F4989C"
          bg="white"
          onClick={() => removeMember(permission)}
        >
          Remove from workspace
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default RoleMenu;
