import { HStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { RiHistoryLine } from "react-icons/ri";
import { PERMISSION_ERROR_MSG } from "src/data/PermissionData";
import { v4 as uuidv4 } from "uuid";
import { useAssistantContext } from "../../Context/AssistantContext";
import { useUserPermission } from "../../Context/UserPermissionContext";
import { ViewMode } from "../../data/AssistantData";
import PermissionWrapper from "../HigherOrderComponents/PermissionWrapper";
import HeaderBoxMenuIcon from "../Icons/HeaderBoxMenuIcon";
import TemplateIconComponent from "../Icons/TemplateIconComponent";
import HeaderTab from "../SavedContents/HeaderTab";
import { getCustomizationData } from "../Utils/customizationUtils";
import AssistantHeaderWrapper from "./AssistantHeaderWrapper";

const customization = getCustomizationData();

const COMPACT_BREAKPOINT =
  process.env.REACT_APP_NEW_CUSTOM_PROMPTS_FLOW === "enable" ? 400 : 350;

const AssistantChatHeader = () => {
  const {
    viewMode,
    setViewMode,
    setSubscribeToConversationDoc,
    setMessages,
    setConversationId,
  } = useAssistantContext();

  const [isCompact, setIsCompact] = useState(false);
  const hStackRef = useRef(null);
  const { isReader } = useUserPermission();
  console.log("isReader() :>> ", isReader());

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      const { width } = entry.contentRect;
      setIsCompact(width < COMPACT_BREAKPOINT);
    });

    const hStackElement = hStackRef.current;
    if (hStackElement) {
      resizeObserver.observe(hStackElement);
    }

    return () => {
      if (hStackElement) {
        resizeObserver.unobserve(hStackElement);
      }
    };
  }, []);

  return (
    <AssistantHeaderWrapper
      backButtonHandler={() => setViewMode(ViewMode.CHAT)}
    >
      <HStack ref={hStackRef} gap={6} w="100%" justify={"flex-end"}>
        <HeaderTab
          handler={() => {
            setConversationId(uuidv4());
            setMessages([]);
            setViewMode(ViewMode.CHAT);
            setSubscribeToConversationDoc(false);
            console.log("New chat");
          }}
          icon={MdAdd}
          title={"New Chat"}
          boxSize={5}
          fontSize="xs"
          isCompact={isCompact}
        />
        {process.env.REACT_APP_NEW_CUSTOM_PROMPTS_FLOW === "enable" && (
          <HeaderTab
            handler={() => {
              setViewMode(ViewMode.CUSTOMAI);
            }}
            icon={HeaderBoxMenuIcon}
            title={"Custom AI"}
            boxSize={5}
            fontSize="xs"
            iconMt={1}
            color="#474959"
            infoTooltipType={"customAI"}
            isCompact={isCompact}
          />
        )}
        {
          customization.template.showTemplate && <PermissionWrapper>
          {(hasEditPermission) => (
            <HeaderTab
              icon={TemplateIconComponent}
              title={customization.template.namePlural}
              boxSize={5}
              fontSize="xs"
              handler={() => {
                setViewMode(ViewMode.TEMPLATE);
                setSubscribeToConversationDoc(false);
              }}
              infoTooltipType="templates"
              isCompact={isCompact}
              disabled={!hasEditPermission}
              disabledTooltip={
                !hasEditPermission ? PERMISSION_ERROR_MSG : ""
              }
            />
          )}
        </PermissionWrapper>
        }
        
        {viewMode === ViewMode.CHAT && (
          <HeaderTab
            handler={() => {
              setViewMode(ViewMode.HISTORY);
            }}
            icon={RiHistoryLine}
            title={"History"}
            boxSize={5}
            fontSize="xs"
            infoTooltipType="history"
            isCompact={isCompact}
          />
        )}
      </HStack>
    </AssistantHeaderWrapper>
  );
};

export default AssistantChatHeader;
