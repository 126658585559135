import { Icon } from "@chakra-ui/react";
import React from "react";

const StartFlagIcon = (props) => {
  return (
    <Icon width="48" height="42" viewBox="0 0 48 42" fill="#191D30" {...props}>
      <path d="M24.0921 19.6559L22.6286 25.1177C24.5951 26.1518 26.2474 27.4283 28.0523 28.4879L29.5179 23.0181C27.5573 21.9941 25.889 20.7133 24.0921 19.6559ZM44.6446 14.5898C41.552 15.1279 37.8236 15.5042 34.5754 14.6339C30.2846 13.4841 27.4795 9.74109 22.0738 8.29265C20.0688 7.7554 18.1857 7.62906 16.3622 7.79375C16.7436 7.26845 17.0177 6.66278 17.1581 5.97834C17.624 3.66191 16.2453 1.33258 13.9853 0.623866C11.4468 -0.168082 8.78732 1.31991 8.10608 3.86232C7.69777 5.38617 8.09865 6.93772 9.04454 8.05937L0.747195 39.0255C0.461376 40.0922 1.0896 41.1803 2.15629 41.4661L3.43952 41.81C4.50621 42.0958 5.59432 41.4676 5.88014 40.4009L7.90881 32.8298C10.4386 32.4675 13.4846 32.4241 17.5589 33.5158C21.8577 34.6676 24.6548 38.4085 30.0604 39.857C33.9262 40.8928 37.3643 40.4129 40.7642 39.2092C41.5909 38.915 42.2105 38.2386 42.4383 37.3885L47.6626 17.8913C48.1713 16.0247 46.5555 14.2594 44.6446 14.5898ZM15.2752 28.9497C13.1479 28.6118 11.0888 28.5328 9.01555 28.6993L10.5306 23.0451C12.8318 22.8622 14.6626 22.8628 16.8074 23.2312L15.2752 28.9497ZM41.7772 24.4891C39.6738 24.768 37.6447 25.0581 35.3606 24.8243L33.8327 30.5267C35.8948 30.787 38.2087 30.6849 40.2943 30.0231L38.7793 35.6774C36.4202 36.4292 34.3587 36.6161 32.294 36.2692L33.8327 30.5267C31.5877 30.2432 29.9588 29.6176 28.0502 28.4959L26.6017 33.9015C24.8439 32.7944 23.167 31.5457 21.1566 30.6115L22.6286 25.1177C21.1432 24.3414 19.5025 23.6955 16.8074 23.2312L18.3117 17.6171C16.4486 17.3843 14.5155 17.4767 11.9597 17.7116L13.4748 12.0574C16.3997 11.7924 17.9184 11.546 19.8504 11.8746L18.3117 17.6171C20.5567 17.9006 22.2215 18.5529 24.0921 19.6559L25.5406 14.2503C27.2823 15.3531 28.9672 16.604 30.9857 17.5402L29.5158 23.0261C31.3027 23.9605 33.1851 24.6024 35.3606 24.8243L36.9058 19.0578C39.1744 19.253 41.3926 19.0909 43.2922 18.8349L41.7772 24.4891Z" />
    </Icon>
  );
};

export default StartFlagIcon;
