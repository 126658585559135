import { extendTheme } from "@chakra-ui/react";
import { components } from "./components";
import { foundations } from "./foundations";
import { semanticTokens } from "./semantic-tokens";
import { styles } from "./styles";

export const storageKey = "chakra-ui-color-mode";
let colorModeInLocalStorage;
if (typeof window !== "undefined") {
  colorModeInLocalStorage = localStorage.getItem(storageKey);
}
const useSystemColorMode = !colorModeInLocalStorage;

const config = {
  useSystemColorMode,
  initialColorMode: "light",
  cssVarPrefix: "chakra",
};

const theme = extendTheme({
  components,
  config,
  ...foundations,
  semanticTokens,
  styles,
  breakpoints: {
    base: "0em", // 0px
    sm: "30em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "80em", // 1280px
    "2xl": "96em", // 1536px
    "3xl": "120em", // 1920px
  },
});

export default theme;
