export const styles = {
  global: {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "primary",
      borderRadius: "24px",
    },
  },
};
