import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { AiModuleBox, CustomPromptListGrid } from "./AiModuleBox";

export const CustomPromptsModuleSection = ({
  isAiModulesAdmin,
  getAction,
  userCustomAiModules,
  title,
  Icon,
  onClick,
  action,
}) => {
  if (userCustomAiModules.length === 0) return null;
  return (
    <Flex flexDir="column">
      <Flex flexDir="column">
        <Flex align="center" gap="6px">
          <Icon />
          <Text color="primary" fontSize="10px">
            {title}
          </Text>
        </Flex>
        <Box py="10px">
          <Divider orientation="horizontal" flex="1" />
        </Box>
      </Flex>
      <CustomPromptListGrid>
        {userCustomAiModules.map((prompt, index) => {
          return (
            <AiModuleBox
              key={index}
              moduleData={prompt}
              isLoading={false}
              action={action || getAction({ prompt, isAiModulesAdmin })}
              actionAlwaysOn={true}
              actionOnlyOnIcon={true}
              onClick={() => onClick(prompt)}
            />
          );
        })}
      </CustomPromptListGrid>
    </Flex>
  );
};
