import {
  collection,
  collectionGroup,
  getDoc,
  onSnapshot,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "../Auth/AuthProvider";
import { db, doc, q } from "../firebase";

const CustomAiModulesContext = createContext();

const CustomAiModulesProvider = ({ children }) => {
  const [publicCustomAiModules, setPublicCustomAiModules] = useState([]);
  const [loadingPublicCustomAiModules, setLoadingCustomAiModules] =
    useState(true);
  const [error, setError] = useState(null);
  const [publicModulesIsSubscribed, setPublicModulesIsSubscribed] =
    useState(false);

  const [userPrivateAiModules, setUserPrivateAiModules] = useState([]);
  const [userCustomAiModules, setUserCustomAiModules] = useState([]);
  const [loadingUserCustomAiModules, setLoadingUserCustomAiModules] =
    useState(true);
  const [userModulesIsSubscribed, setUserModulesIsSubscribed] = useState(false);

  const { currentUser } = useAuth();

  const loadPublicCustomAiModules = useCallback(async () => {
    if (!publicModulesIsSubscribed) {
      console.log("Subscribing to public custom AI modules");
      const unsubscribe = onSnapshot(
        q(collection(db, "aiModules"), where("inLibrary", "==", true)),
        async (snapshot) => {
          // Map over valid documents
          const publicModulesPromises = snapshot.docs.map(async (moduleDoc) => {
            const modulePresentationData = await getModulePresentationData(
              moduleDoc
            );
            return {
              ...modulePresentationData,
              uid: moduleDoc.id,
              accentColor: "accentSnippets",
              moduleDocData: moduleDoc.data(),
            };
          });

          // Wait for all promises to resolve
          const publicModules = await Promise.all(publicModulesPromises);

          // Sort alphabetically by title
          publicModules.sort((a, b) => a.title.localeCompare(b.title));
          setPublicCustomAiModules(publicModules);
          setLoadingCustomAiModules(false);
        },
        (err) => {
          setError(err.message);
          setLoadingCustomAiModules(false);
        }
      );

      setPublicModulesIsSubscribed(true);

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [publicModulesIsSubscribed]);

  const loadUserCustomAiModules = useCallback(() => {
    if (!userModulesIsSubscribed) {
      console.log("Subscribing to user custom AI modules");
      const unsubscribe = onSnapshot(
        q(
          collectionGroup(db, "aiModulePermissions"),
          where("userId", "==", currentUser.uid)
        ),
        async (snapshot) => {
          // console.log("aiModulePermissions snapshot :>> ", snapshot.docs);
          const userModulePromises = snapshot.docs.map(
            async (permissionDoc) => {
              // console.log("userModulePromises doc :>> ", doc);
              // retrive the actual module data
              const moduleIdRef = permissionDoc.ref.parent.parent;
              // retrieve the module data
              const moduleDoc = await getDoc(moduleIdRef);
              if (!moduleDoc.exists()) {
                return null;
              }
              const modulePresentationData = await getModulePresentationData(
                moduleDoc
              );

              return {
                ...modulePresentationData,
                uid: moduleDoc.id,
                permission: permissionDoc.data(),
              };
            }
          );
          // filter out null promises, which may exist when permission doc exists, but module doc does not
          // (not yet created, or deleted)
          const userModules = (await Promise.all(userModulePromises)).filter(
            (module) => module !== null
          );
          // console.log("private modules :>> ", privateModules);
          setUserPrivateAiModules(userModules);
          setLoadingUserCustomAiModules(false);
        },
        (err) => {
          setError(err.message);
          setLoadingUserCustomAiModules(false);
        }
      );

      setUserModulesIsSubscribed(true);

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [userModulesIsSubscribed, publicCustomAiModules]);

  /**
   * Add public modules (added by the user) to userCustomAiModules
   */
  useEffect(() => {
    userPrivateAiModules.sort((a, b) => a.title.localeCompare(b.title));

    // add a flag to indicate that the module is from the public library
    const userModules = userPrivateAiModules.map((module) => {
      const isPublic = publicCustomAiModules.some(
        (publicModule) => publicModule.uid === module.uid
      );

      return {
        ...module,
        isPublic,
        accentColor: isPublic ? "accentSnippets" : "accentHighlights",
      };
    });
    setUserCustomAiModules(userModules);
  }, [userPrivateAiModules]);

  return (
    <CustomAiModulesContext.Provider
      value={{
        publicCustomAiModules,
        loadingPublicCustomAiModules,
        loadPublicCustomAiModules,
        userCustomAiModules,
        loadingUserCustomAiModules,
        loadUserCustomAiModules,
        error,
      }}
    >
      {children}
    </CustomAiModulesContext.Provider>
  );
};

const useCustomAiModules = () => {
  const context = useContext(CustomAiModulesContext);
  if (!context) {
    throw new Error(
      "useCustomAiModules must be used within a CustomAiModulesProvider"
    );
  }

  const {
    publicCustomAiModules,
    loadPublicCustomAiModules,
    loadingPublicCustomAiModules,
    userCustomAiModules,
    loadUserCustomAiModules,
    loadingUserCustomAiModules,
    error,
  } = context;

  React.useEffect(() => {
    loadPublicCustomAiModules();
  }, [loadPublicCustomAiModules]);

  React.useEffect(() => {
    loadUserCustomAiModules();
  }, [loadUserCustomAiModules, publicCustomAiModules]);

  return {
    publicCustomAiModules,
    loadingPublicCustomAiModules,
    userCustomAiModules,
    loadingUserCustomAiModules,
    error,
  };
};

export { CustomAiModulesProvider, useCustomAiModules };

async function getModulePresentationData(moduleDoc) {
  const module = moduleDoc.data();
  const activeVersionId = module.activeVersionId;
  const modulePresentationDoc = doc(
    db,
    `aiModules/${moduleDoc.id}/versions/${activeVersionId}/moduleData/presentation`
  );
  const modulePresentationData = (await getDoc(modulePresentationDoc)).data();
  return modulePresentationData;
}
