import { Flex, Text } from "@chakra-ui/react";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../Auth/AuthProvider";
import { useAssistantContext } from "../../../Context/AssistantContext";
import { createSnippet } from "../../../db/snippet";
import { db } from "../../../firebase";
import { getConversationTitle } from "../../HigherOrderComponents/useTextSelection";

export const FloatingIndividualTemplateSection = ({
  highlightedText,
  templateId,
  sectionId,
  onClickAway,
  MouseEnteredTemplateSection,
  MouseLeftTemplateSection,
  sectionName,
  sourceArticles,
  displayTemplateSections,
}) => {
  const snippetId = uuidv4();
  const { conversationId } = useAssistantContext();
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const addSnippetToSection = async () => {
    // Create snippet
    const payload = {
      projectId: projectId,
      snippetId: snippetId,
      snippetType: "assistantInsight",
      title: await getConversationTitle(projectId, conversationId),
      text: highlightedText,
      authorId: currentUser?.uid,
      curated: true,
      sourceArticles: sourceArticles?.length > 0 ? sourceArticles : null,
    };
    createSnippet(payload);

    // Add snippet to section
    const sectionRef = doc(
      db,
      `/projects/${projectId}/templates/${templateId}/organizerSections/${sectionId}`
    );
    updateDoc(sectionRef, {
      snippets: arrayUnion({ snippetId, cardType: "addedByUser" }),
    });
    onClickAway();
  };
  return (
    <Flex
      maxW="97px"
      cursor="pointer"
      ml="1px"
      px={1}
      bg="primary"
      onMouseOver={() => {
        MouseEnteredTemplateSection();
      }}
      onMouseLeave={() => {
        MouseLeftTemplateSection();
      }}
      onClick={addSnippetToSection}
      maxH="20px"
      minH="20px"
      minW="97px"
      align="center"
      display={displayTemplateSections}
    >
      <Text noOfLines={1} fontWeight="bold">
        {sectionName}
      </Text>
    </Flex>
  );
};
