import { Box, Center, CircularProgress, Flex, Text } from "@chakra-ui/react";
import md5 from "md5";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";
import { useContentContext } from "../../Context/ContentContext";
import { MAX_FILE_SIZE } from "../../data/constants";
import { createArticle, getArticleById } from "../../db/article";
import { saveFile } from "../../services/firebaseStorageSrv";
import { removeUndefinedNull } from "../Utils/dbUtils.js";

const AudioUploadComponent = ({ onClose }) => {
  const inputRef = useRef(null);
  const handleClick = () => inputRef.current?.click();
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadTracker, setUploadTracker] = useState(0); // tracks the number of files uploaded
  const [totalFileUpload, setTotalFileUpload] = useState(0); // tracks the number of files uploaded
  const [articleId, setArticleId] = useState(null);
  // const history = useHistory();
  const [currentAction, setCurrentAction] = useState("Uploading");
  const { showToaster, content } = useContentContext();
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  /**
   * This is a defensive measure to let user know that if the file upload dialog does not appear,
   * they should restart their browser. This is a known issue with Chrome.
   */
  const [fileUploadDialogClickCounter, setFileUploadDialogClickCounter] =
    useState(0);

  useEffect(() => {
    if (content) {
      console.log("content :>> ", content);
    }
  }, [content]);

  const resetValues = () => {
    setFileIsUploading(false);
    setUploadTracker(0);
    setTotalFileUpload(0);
    setArticleId(null);
    setFileUploadDialogClickCounter(0);
    onClose();
  };

  const addArticleToProject = async (articleData) => {
    const createdAt = Date.now();
    const createdBy = currentUser.uid;
    const articleId = md5(articleData.url);
    const payload = {
      createdAt,
      createdBy,
      ...articleData,
      uid: articleId || md5(articleData.url),
    };
    removeUndefinedNull(payload);
    setArticleId(articleId);
    createArticle({ projectId, articleId, payload });
  };

  const handleFileUpload = async (e) => {
    try {
      const files = e.target.files;
      setTotalFileUpload(files.length);
      setFileIsUploading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > MAX_FILE_SIZE) {
          throw new Error("File size is too large");
        }
        const downloadUrl = await saveFile({
          file,
          path: `project_data/${projectId}/new`,
          isProfilePicture: false,
        });
        await addArticleToProject({
          url: downloadUrl,
          type: "voice",
          source: "local",
          title: file.name.replace(/\.[^/.]+$/, ""),
          indexingStatus: {
            status: "pending",
          },
        });
        setUploadTracker((prev) => prev + 1);
      }
      // setFileIsUploading(false);
      resetValues();
      setCurrentAction("Processing");
    } catch (err) {
      console.log(err);
      resetValues();
      showToaster("Error", err.message, "error");
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};
    if (articleId) {
      //listen to the article document update
      unsubscribe = getArticleById({
        projectId,
        articleId,
        callback: (article) => {
          if (article.isExtractionCompleted) {
            showToaster(
              "Success",
              "File successfully added to project",
              "success"
            );
            resetValues();
          }
          if (article.cantParse) {
            showToaster(
              "Error",
              `Unable to parse file. ${article.cantParse}`,
              "error"
            );
            resetValues();
          }
        },
      });
    }
    return () => {
      console.log("Unsubscribing from article update");
      unsubscribe();
    };
  }, [articleId]);

  return (
    <Box>
      <Box
        mt="8"
        rounded="md"
        border="1px dashed"
        borderColor="rgba(255, 255, 255, 0.5)"
      >
        {!fileIsUploading ? (
          <>
            <Flex
              onClick={() => {
                setFileUploadDialogClickCounter((prev) => prev + 1);
              }}
            >
              <input
                type="file"
                multiple={true}
                hidden
                accept="audio/*"
                ref={inputRef}
                onChange={handleFileUpload}
              />
            </Flex>
            <Box
              onClick={handleClick}
              py={16}
              fontSize="xs"
              textAlign="center"
              cursor="pointer"
            >
              <Text fontWeight="black">Click to upload recording</Text>
              <Text>(mp4, mp3)</Text>
              <Center>
                <Text
                  visibility={
                    fileUploadDialogClickCounter > 2 ? "visible" : "hidden"
                  }
                  fontSize="xs"
                  color="whiteAlpha.700"
                  pt={10}
                  position={"absolute"}
                  // display="block"
                >
                  If no file upload dialog appeared, please restart your browser
                  and try again.
                </Text>
              </Center>
            </Box>
          </>
        ) : (
          <Box py={16}>
            <Center fontSize="xs" textAlign="center">
              <CircularProgress size="28px" isIndeterminate color="white" />
            </Center>
            <Text mt="2" fontSize="xs" textAlign="center" fontWeight="black">
              {currentAction} {uploadTracker} / {totalFileUpload} ...
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AudioUploadComponent;
