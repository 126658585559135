import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webArticles: [],
  pdfs: [],
  activeArticle: null,
  articleSnippets: [],
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    saveWebArticles: (state, action) => {
      state.webArticles = action.payload;
    },
    savePdfs: (state, action) => {
      state.pdfs = action.payload;
    },
    updateActiveArticle: (state, action) => {
      state.activeArticle = action.payload;
    },
    // updateActiveArticle: (state, action) => {
    //   state.activeArticle = action.payload;
    // },
    saveActiveArticle: (state, action) => {
      state.activeArticle = action.payload;
    },
    saveArticleSnippets: (state, action) => {
      state.articleSnippets = action.payload;
    },
    updateWebArticle: (state, action) => {
      state.webArticles = state.webArticles.map((article) =>
        article.id == action.payload.id
          ? { ...article, Title: action.payload.title }
          : article
      );
    },
  },
});

export const {
  savePdfs,
  saveWebArticles,
  updateActiveArticle,
  saveActiveArticle,
  updateWebArticle,
  saveArticleSnippets,
} = projectSlice.actions;

export default projectSlice.reducer;
