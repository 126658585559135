import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { ProjectPermissionDoc } from "shared/projectTypes";
import {
  PERMISSIONS,
  PERMISSIONS_MAP,
  PERMISSION_TYPES,
} from "src/data/PermissionData";
import { getUserId } from "src/services/UserSrv";
import { useAuth } from "../../../Auth/AuthProvider";
import useNotification from "../../../hooks/useNotification";
import { formatClipboardCopy } from "../../Utils/urlUtils";
import { isValidEmail } from "./SharingSettingModal";
import { checkCannotAdd, createdUserSharedWithDoc } from "./sharingUtils";

interface InviteSectionProps {
  userPermissions: ProjectPermissionDoc[];
  setAuthorDataInProjectDoc: () => Promise<void>;
}

export const InviteSection: React.FC<InviteSectionProps> = ({
  userPermissions,
  setAuthorDataInProjectDoc,
}) => {
  const [inviteEmail, setInviteEmail] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [permission, setPermission] = React.useState(PERMISSION_TYPES.READ);
  const { notify } = useNotification();
  const { currentUser } = useAuth();
  const { projectId } = useParams();

  const handleGrantAccess = async () => {
    if (checkCannotAdd(inviteEmail, currentUser, userPermissions, notify)) {
      setSubmitting(false);
      return;
    }
    await setAuthorDataInProjectDoc();
    const uid = await getUserId(inviteEmail);
    await createdUserSharedWithDoc({
      uid,
      email: inviteEmail,
      projectId,
      permission,
    });
    setInviteEmail("");
    navigator.clipboard.writeText(
      formatClipboardCopy({
        url: window.location.href,
        source: "sharing",
      })
    );
    notify({
      title: "Success",
      description:
        "User has been granted access. Link to the project copied to clipboard. Share the link with the user.",
      status: "success",
      position: "top-right",
    });
    setSubmitting(false);
  };

  return (
    <Flex px="6" flexDir="column" position="relative">
      <FormControl
        bg="#282C3E"
        display="flex"
        flexDir="row"
        borderRadius="md"
        p="2"
        justifyContent="space-between"
      >
        <Text
          zIndex={12}
          position="absolute"
          bottom="0"
          color="red"
          fontSize="10px"
          fontWeight="regular"
          display={inviteEmail.length > 0 ? "block" : "none"}
        >
          {isValidEmail(inviteEmail) ? "" : "Invalid Email"}
        </Text>
        <Input
          color="white"
          _focusVisible={{ outline: "none" }}
          w="55%"
          border="none"
          placeholder="Email"
          p="0"
          pl="4"
          onChange={(e) => {
            setInviteEmail(e.target.value);
          }}
          value={inviteEmail}
        />
        <Menu gutter={-10}>
          <MenuButton
            p="0"
            fontWeight="500"
            fontSize="12px"
            as={Button}
            rightIcon={<ChevronDownIcon fontSize="15px" />}
          >
            {PERMISSIONS_MAP[permission]}
          </MenuButton>
          <MenuList
            css={{
              minWidth: "fit-content !important",
            }}
            fontWeight="500"
            bg="#282C3E"
            border="2px solid #191D30"
            p="2"
            borderRadius="md"
          >
            {PERMISSIONS.map((perm) => (
              <MenuItem
                bg="#282C3E"
                onClick={() => {
                  setPermission(perm);
                }}
                color="white"
                fontSize="12px"
                _hover={{ bg: "#191D30" }}
                borderRadius="md"
                key={perm}
              >
                {PERMISSIONS_MAP[perm]}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Button
          isLoading={submitting}
          p="0.7rem"
          fontWeight="500"
          fontSize="12px"
          w="17%"
          bg="#191D30"
          onClick={async () => {
            setSubmitting(true);
            await handleGrantAccess();
          }}
          isDisabled={isValidEmail(inviteEmail) ? false : true}
        >
          Invite
        </Button>
      </FormControl>
    </Flex>
  );
};

export default InviteSection;
