import { Box } from "@chakra-ui/react";
import React from "react";
import { useCustomAiModules } from "../../Context/CustomAiModuleContext";
import { AiModuleBox, CustomPromptListGrid } from "../AiModules/AiModuleBox";

export const LibraryPageCustomAIModulesLists = () => {
  const { publicCustomAiModules, userCustomAiModules } = useCustomAiModules();

  // for all public custom ai modules which are also added by user, add a flag "in_user_library"
  const publicCustomAiModulesInUserLibrary = publicCustomAiModules.map(
    (publicModule) => {
      const isAddedByUser = userCustomAiModules.some(
        (userModule) => userModule.uid === publicModule.uid
      );
      return { ...publicModule, in_user_library: isAddedByUser };
    }
  );

  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const selectedCategories = category?.split(",");

  const filterForCategory = publicCustomAiModulesInUserLibrary.filter(
    (module) => selectedCategories?.includes(module.moduleDocData?.category)
  );

  return (
    <Box pt="20px">
      <CustomPromptListGrid>
        {filterForCategory?.length > 0
          ? filterForCategory.map((prompt) => {
              return (
                <AiModuleBox
                  key={prompt.uid}
                  moduleData={prompt}
                  isLoading={false}
                  action={
                    prompt.in_user_library ? "ALREADY_ADDED_INDICATOR" : "ADD"
                  }
                  actionAlwaysOn={true}
                  actionOnlyOnIcon={true}
                />
              );
            })
          : publicCustomAiModulesInUserLibrary.map((prompt) => {
              return (
                <AiModuleBox
                  key={prompt.uid}
                  moduleData={prompt}
                  isLoading={false}
                  action={
                    prompt.in_user_library ? "ALREADY_ADDED_INDICATOR" : "ADD"
                  }
                  actionAlwaysOn={true}
                  actionOnlyOnIcon={true}
                />
              );
            })}
      </CustomPromptListGrid>
    </Box>
  );
};
