import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const members = [
  {
    id: 1,
    email: "Jonny.bravo@cosmos.com",
    lastSent: "Today at 4:20pm",
  },
  // Add more members as needed
];

const WorkspaceInvitedMemberTable = () => {
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortedMembers = [...members].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <Box maxW="1100px">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th onClick={() => handleSort("name")} cursor="pointer">
              <Flex
                alignItems="center"
                gap="8px"
                color="#666666"
                textTransform="capitalize"
              >
                Name
                {sortOrder === "asc" ? (
                  <IoMdArrowDropup color="black" fontSize="12px" />
                ) : (
                  <IoMdArrowDropdown color="black" fontSize="12px" />
                )}
              </Flex>
            </Th>
            <Th onClick={() => handleSort("joinDate")} cursor="pointer">
              <Flex
                alignItems="center"
                gap="8px"
                color="#666666"
                textTransform="capitalize"
              >
                Last sent
                {sortOrder === "asc" ? (
                  <IoMdArrowDropup color="black" fontSize="12px" />
                ) : (
                  <IoMdArrowDropdown color="black" fontSize="12px" />
                )}
              </Flex>
            </Th>
            <Th textTransform="capitalize">Revoke</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedMembers.map((member) => (
            <Tr key={member.id}>
              <Td>
                <Flex alignItems="center">
                  <Box>
                    <Text fontSize="14px" fontWeight="bold" color="primary">
                      {member.email}
                    </Text>
                  </Box>
                </Flex>
              </Td>
              <Td fontSize="12px" color="primary" fontWeight="400">
                {member.lastSent}
              </Td>
              <Td>
                <Button
                  bg="white"
                  _hover={{
                    background: "white",
                  }}
                  fontSize="12px"
                  w="fit-content"
                  h="fit-content"
                  p="8px"
                  color="primary"
                  fontWeight="bold"
                  border="1px solid"
                  borderColor="#E0E0E0"
                >
                  Revoke
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default WorkspaceInvitedMemberTable;
