import { Icon } from "@chakra-ui/react";
import React from "react";

const DiamondIcon = (props) => {
  return (
    <Icon width="48" height="43" viewBox="0 0 48 43" fill="#191D30" {...props}>
      <path d="M38.6791 0.335938H9.44833C9.11616 0.335938 8.8006 0.502022 8.6179 0.784365L0.3137 13.0082C0.0728782 13.3735 0.0977908 13.8552 0.37183 14.1874L23.2914 42.4881C23.69 42.9781 24.4374 42.9781 24.836 42.4881L47.7556 14.1874C48.0297 13.8469 48.0546 13.3735 47.8138 13.0082L39.5179 0.784365C39.3269 0.502022 39.0196 0.335938 38.6791 0.335938ZM37.0764 4.32196L42.333 12.294H36.6529L32.3596 4.32196H37.0764ZM20.2936 4.32196H27.8255L32.1188 12.294H16.0086L20.2936 4.32196ZM11.051 4.32196H15.7678L11.4746 12.294H5.79448L11.051 4.32196ZM7.48023 16.28H11.7486L17.4204 29.5668L7.48023 16.28ZM16.0253 16.28H32.1022L24.0637 36.4842L16.0253 16.28ZM30.7071 29.5668L36.3706 16.28H40.6389L30.7071 29.5668Z" />
    </Icon>
  );
};

export default DiamondIcon;
