import { Button, Flex, Icon, Text, Tooltip, useToast } from "@chakra-ui/react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BiBoltCircle } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useAuth } from "../../Auth/AuthProvider";
import { useArticle } from "../../Context/ArticleContext";
import { useOrganizerContext } from "../../Context/OrganizerContext";
import { useTemplateContext } from "../../Context/TemplateContext";
import { updateTemplate } from "../../db/organizer";
import { db } from "../../firebase";
import { InfoTooltip, getTooltipText } from "../InfoTooltip";
import { getCustomizationData } from "../Utils/customizationUtils";
import { FILL_TEMPLATE_STATUS } from "./SmartTemplate/AssistantSmartTemplate";

const customization = getCustomizationData();

function generateNewRequestDocRef(setRequestDocRef, setRequestTriger) {
  const requestId = v4();
  const requestDocRef = doc(db, `fillTemplateRequests/${requestId}`);
  setRequestDocRef(requestDocRef);
  setRequestTriger(false);
}

// const determineLoadingState = (data) => {
//   if (!data) {
//     return false;
//   }

//   if (data.ultimateDoneFlag) {
//     return false;
//   }
//   // sectionArticleStatus is a array of objects, each containing a field boolean "done"
//   // unusedInsightsStatus is a boolean, loading if false; but if doesn't exist, it's true
//   return (
//     (data.sectionArticleStatus
//       ? data.sectionArticleStatus.some((section) => !section.done)
//       : true) ||
//     (!data.unusedInsightsStatus ?? true)
//   );
// };

const FillTemplate = () => {
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const { currentTemplate } = useTemplateContext();
  const [requestDocRef, setRequestDocRef] = useState(null);
  const [requestDoc, setRequestDoc] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [requestTriger, setRequestTriger] = useState(false);
  const { setActiveSections } = useOrganizerContext();

  const fillTemplateLoading =
    currentTemplate.fillTemplateStatus?.status === FILL_TEMPLATE_STATUS.LOADING;

  const fillTemplateDone = !!currentTemplate.fillTemplateArticles?.length;

  const toast = useToast();
  const { selectedArticleIds } = useArticle();

  const handleRequest = async () => {
    const requestData = {
      projectId,
      templateId: currentTemplate.id,
      userId: currentUser.uid,
      sectionArticleStatus: [],
      unusedInsightsStatus: false,
      selectedArticleIds: selectedArticleIds,
    };
    console.log("Sending request to fill template");
    await setDoc(requestDocRef, requestData);
    console.log("Request sent");
    setRequestTriger(true);

    updateTemplate({
      projectId,
      templateId: currentTemplate.id,
      fillTemplateStatus: {
        status: FILL_TEMPLATE_STATUS.LOADING,
        message: "Retrieving data from your documents",
        progress: 5,
      },
    });
  };

  /**
   * Generate a new requestId and requestDocRef
   */
  useEffect(() => {
    generateNewRequestDocRef(setRequestDocRef, setRequestTriger);
  }, [currentTemplate.id]);

  /**
   * Subscribe to the requestDoc and listen for changes
   */
  useEffect(() => {
    console.log("requestDocRef useEffect");
    if (!requestDocRef) {
      return;
    }
    if (!requestTriger) {
      return;
    }
    const unsubscribe = onSnapshot(requestDocRef, (doc) => {
      setRequestDoc(doc.data());
      // setIsLoading(determineLoadingState(doc.data()));
    });
    return () => {
      console.log("Unsubscribing from requestDocRef");
      unsubscribe();
    };
  }, [requestTriger, requestDocRef]);

  /**
   * Handle display of errors coming from the backend
   */
  useEffect(() => {
    if (!requestDoc) {
      return;
    }
    if (requestDoc.error) {
      toast({
        title: "Error",
        description: requestDoc.error,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      generateNewRequestDocRef(setRequestDocRef, setRequestTriger);
    }
  }, [requestDoc?.error]);

  /**
   * Update doc ref once the request is done
   */
  useEffect(() => {
    if (!fillTemplateLoading) {
      if (requestTriger) {
        generateNewRequestDocRef(setRequestDocRef, setRequestTriger);
      }
    }
  }, [fillTemplateLoading]);

  if (fillTemplateLoading) {
    return null;
  }

  if (fillTemplateDone) {
    return (
      <Flex align="center" gap="3px">
        {<Icon as={FcCheckmark} color="primary" boxSize={5} />}
        <Text
          fontSize="xs"
          fontWeight="medium"
          px={0}
          noOfLines={1}
          color="primary"
          m={0}
          display="flex"
          loadingText="Working"
        >
          {`${customization.template.name} filled`}
        </Text>
        <InfoTooltip
          string={`${
            customization.template.name
          } was filled with the following documents: <br>${currentTemplate.fillTemplateArticles
            .map((article, index) => `${index + 1}. ${article.title}`)
            .join("<br />")}`}
        />
      </Flex>
    );
  }

  return (
    <Flex
      align="center"
      gap="3px"
      onClick={() => {
        handleRequest();
        setActiveSections([]);
      }}
      cursor="pointer"
    >
      {<Icon as={BiBoltCircle} color="primary" boxSize={5} />}
      <Tooltip label={getTooltipText("fillBrief")} placement="bottom" hasArrow>
        <Button
          fontSize="xs"
          fontWeight="medium"
          px={0}
          noOfLines={1}
          color="primary"
          m={0}
          display="flex"
          loadingText="Working"
        >
          {`Fill ${customization.template.name.toLowerCase()}`}
        </Button>
        {/* <InfoTooltip type="fillTemplate" /> */}
      </Tooltip>
    </Flex>
  );
};

export default FillTemplate;
