import { Button, Flex, Text, Spinner, Input } from "@chakra-ui/react";
import React, { useState } from "react";

export const EditDisplayName = ({
  displayName,
  closeEditDisplayName,
  updateNewDisplayName,
}) => {
  const [newDisplayName, setNewDisplayName] = useState(displayName);
  const [maxNameLengthReached, setMaxNameLengthReached] = useState(false);
  const [changeOfDisplayNameInProgress, setChangeOfDisplayNameInProgress] =
    useState(false);
  return (
    <Flex>
      <Flex flexDir="column">
        <Input
          onChange={(e) => {
            setNewDisplayName(e.target.value);
            if (e.target.value.length >= 29) {
              setMaxNameLengthReached(true);
            } else {
              setMaxNameLengthReached(false);
            }
          }}
          placeholder={displayName}
          value={newDisplayName}
          maxLength={30}
        />
        <Text
          fontSize="12px"
          color="red"
          display={maxNameLengthReached ? "block" : "none"}
        >
          Max length of 30 characters reached
        </Text>
      </Flex>

      <Flex gap={4} ml={2}>
        <Button
          bg="primary"
          fontSize="14px"
          w="fit-content"
          border="1px solid transparent"
          p={2}
          onClick={() => {
            updateNewDisplayName(newDisplayName);
            setChangeOfDisplayNameInProgress(true);
          }}
          leftIcon={changeOfDisplayNameInProgress && <Spinner size="sm" />}
        >
          Update
        </Button>
        <Button
          bg="transparent"
          color="primary"
          fontSize="14px"
          w="fit-content"
          border="1px solid #1D1D1D"
          onClick={closeEditDisplayName}
        >
          Close
        </Button>
      </Flex>
    </Flex>
  );
};
