import { Flex } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import SmartDragSection from "./SmartDragSection";

const SmartTemplateDetail = () => {
  const listRef = useRef(null);
  const { filteredSectionsData } = useOrganizerContext();

  return (
    <Flex backgroundColor="#FBFBFB" direction={"column"}>
      <div className="webkit-scrollbar-display-none" ref={listRef}>
        <Droppable droppableId="SECTIONS" type="SECTIONS">
          {(provided) => (
            <Flex ref={provided.innerRef} gap="8px" direction={"column"}>
              {Object.entries(filteredSectionsData)
                .sort((a, b) => a[1].order - b[1].order)
                .map(([key, section], index) => (
                  <SmartDragSection
                    title={section.name}
                    cards={section?.cards}
                    key={key}
                    id={key}
                    index={index}
                  />
                ))}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </div>
    </Flex>
  );
};

export default SmartTemplateDetail;
