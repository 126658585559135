import { Box, Button, Flex, HStack, Textarea } from "@chakra-ui/react";
import React from "react";
import { MdEdit } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import ResizeTextarea from "react-textarea-autosize";
import { VIEW_IN_SOURCE_ENABLED } from "../../data/SnippetTypeData";
import useSnippetEdit from "../../hooks/useSnippetEdit";
import { applyBold } from "../Utils/markdownUtils";
import style from "./InsightCardContent.module.css";

const getHighlightedText = (text, highlight) => {
  const statementStart = text.indexOf(highlight);
  const statementEnd = statementStart + highlight.length;
  return (
    <>
      <span style={{ color: "black" }}>
        {text.substring(0, statementStart)}
      </span>
      <span style={{ background: "yellow", color: "black" }}>
        {text.substring(statementStart, statementEnd)}
      </span>
      <span style={{ color: "black" }}>{text.substring(statementEnd)}</span>
    </>
  );
};

const InsightCardContent = ({
  activeHighlight,
  insightId,
  type,
  text,
  fullText,
  bold,
  projectId,
}) => {
  const {
    showEdit,
    setIsEditing,
    isEditing,
    setShowEdit,
    setUpdatedText,
    updatedText,
    updateSnippetText,
  } = useSnippetEdit(text);
  let finalText = text;
  finalText = applyBold(text, bold);

  if (activeHighlight === insightId && VIEW_IN_SOURCE_ENABLED.includes(type)) {
    return (
      <p style={{ fontSize: 12, lineHeight: 2 }}>
        {getHighlightedText(fullText || "", finalText)}
      </p>
    );
  }

  return (
    <>
      {isEditing ? (
        <Box p={4}>
          <Textarea
            fontSize="13px"
            minH="unset"
            w="100%"
            resize="none"
            minRows={3}
            as={ResizeTextarea}
            value={updatedText}
            onChange={(e) => setUpdatedText(e.target.value)}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
          />
          <HStack
            spacing={2}
            my={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={() => updateSnippetText(projectId, insightId)}
              bg="primary"
              size="sm"
            >
              Save
            </Button>

            <Button
              onClick={() => {
                setIsEditing(false);
                setShowEdit(false);
              }}
              variant="outline"
              size="sm"
              bg="red"
              color="white"
              _hover={{ bg: "red.500" }}
            >
              Cancel
            </Button>
          </HStack>
        </Box>
      ) : (
        <Flex
          direction="row"
          onMouseEnter={() => setShowEdit(true)}
          onMouseLeave={() => setShowEdit(false)}
        >
          <Box mr={4}>
            <ReactMarkdown className={style.reactMarkDown}>
              {finalText}
            </ReactMarkdown>
          </Box>
          {type === "image" && showEdit && (
            <MdEdit cursor="pointer" onClick={() => setIsEditing(true)} />
          )}
        </Flex>
      )}
    </>
  );
};

export default InsightCardContent;
