import moment from "moment";

/**
 * Returns a formatted date string in the format "MMM YYYY" from a given date.
 *
 * @param {string|Date} date - The input date as a string or Date object.
 * @returns {string} The formatted date string.
 *
 * @example
 * // returns "Jan 2022"
 * ReturnDate("2022-01-01");
 *
 * @example
 * // returns "Dec 2021"
 * ReturnDate(new Date("2021-12-31"));
 */
export const ReturnDate = (date) => {
  const dateVal = new Date(date).toDateString().split(" ");
  return `${dateVal[1]} ${dateVal[3]}`;
};

/**
 * Formats a given date into a human-readable string.
 *
 * @param {string} date - The date in "YYYY-MM-DD" format to be formatted to a relative date.
 * @returns {string} formattedDate - The formatted date string.
 *
 * @example
 * // Returns "Today"
 * formateDate("2022-01-01");
 *
 * @example
 * // Returns "5 Days Ago"
 * formateDate("2021-12-27");
 *
 * @example
 * // Returns "2021-11-01" (assuming today is "2022-01-01")
 * formateDate("2021-11-01");
 */
export const formatDateToRelative = (date) => {
  let formattedDate = "";
  const given = moment(date, "YYYY-MM-DD");
  const current = moment().startOf("day");
  const monthDuration = moment.duration(given.diff(current)).asMonths();
  const daysDuration = moment.duration(given.diff(current)).asDays();

  if (Math.abs(Math.round(daysDuration)) <= 1) {
    formattedDate = `Today`;
  } else if (Math.abs(monthDuration) > 0.99) {
    formattedDate = ReturnDate(date);
  } else if (Math.abs(Math.round(daysDuration)) <= 0) {
    formattedDate = `Today`;
  } else if (Math.abs(monthDuration) < 0.99) {
    formattedDate = `${Math.abs(Math.round(daysDuration))} Days Ago`;
  }
  return formattedDate;
};

/**
 * Format UNIX timestamp to YYYY-MM-DD format.
 * @param {number} timestamp - UNIX timestamp.
 * @returns {string} formattedDate - The formatted date string.
 * @example
 * // Returns "2021-12-31"
 * formatTimestamp(1640966400000);
 * @example
 * // Returns "2021-12-31"
 * formatTimestamp(1640966400);
 * @example
 * // Returns "2021-12-31"
 * formatTimestamp("1640966400");
 */
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export function formatDateStripe(subscriptionEndDate: number) {
  return new Date(subscriptionEndDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

/**
 *
 * @param createdAt Date as UNIX timestamp
 * @returns
 */
export const formatDate = (createdAt: number): string => {
  let articleDate = "";
  const given = moment(formatTimestamp(createdAt), "YYYY-MM-DD");
  const current = moment().startOf("day");
  const monthDuration = moment.duration(given.diff(current)).asMonths();
  const daysDuration = moment.duration(given.diff(current)).asDays();

  if (Math.abs(monthDuration) > 0.99) {
    articleDate = ReturnDate(formatTimestamp(createdAt));
  } else if (Math.abs(Math.round(daysDuration)) <= 0) {
    articleDate = "Today";
  } else if (Math.abs(monthDuration) < 0.99) {
    articleDate = `${Math.abs(Math.round(daysDuration))} Days Ago`;
  }
  return articleDate;
};
