import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Highlight, PdfHighlighter, PdfLoader } from "react-pdf-highlighter";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  isEmulatorUrl,
  isFirebaseStoragePDF,
} from "../Components/Utils/urlUtils";
import {
  getElementsInView,
  getPdfInViewPageNumber,
  scrollToPage,
} from "../Components/Utils/viewerUtils";
import ExtractTextModal from "../Components/Viewer/ExtractedTextModal";
import { useAssistantContext } from "../Context/AssistantContext";
import AiGeneratedHighlight from "./AiGeneratedHighlight";
import { PDFViewerLoading } from "../Components/Viewer/PdfViewerLoading";
import { HighlightSelectTools } from "../Components/Viewer/HighlightSelectTools";
import { PDFViewerHeader } from "../Components/Viewer/PdfViewerHeader";

// const { uuidv4 } = require("@firebase/util");

const FloatingDeleteBttn = ({ cordinates, projectId, selectedHighlight }) => {
  if (!cordinates) return null;

  return (
    <Flex
      w="fit-content"
      transform={`translate(${cordinates.x - 75}px, ${cordinates.y - 180}px)`}
      zIndex={1000}
    >
      <HighlightSelectTools
        alreadySavedHighlight={true}
        position={selectedHighlight.position}
        content={selectedHighlight.content}
        projectId={projectId}
        articleId={selectedHighlight.articleId}
        snippetId={selectedHighlight.id}
        curated={selectedHighlight.curated}
        selectedHighlight={selectedHighlight}
      />
    </Flex>
  );
};

const PdfExclusiveViewer = ({ url, manualHighlights, reduceHeading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [highlights, setHighlights] = useState([]);
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const { assistantWidth, assistantIsOpen } = useAssistantContext();

  const [
    currentSelectedHighlightCordinates,
    setCurrentSelectedHighlightCordinates,
  ] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPdfPage, setCurrentPdfPage] = useState(1);
  const [allowHighlight, setAllowHighlight] = useState(true);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  // const [viewerScrollTo, setViewerScrollTo] = useState(() => {});
  const viewerScrollTo = useRef(() => {
    console.log("viewerScrollTo is not");
  });
  const zoomValue = 0.25;
  const minScale = 0.5;
  const maxScale = 2.0;
  const { projectId, articleId } = useParams();
  const location = useLocation();

  const handleClickOnPdfParent = (event) => {
    const targetClass = event.target.className;
    if (targetClass !== "Highlight__part") {
      setCurrentSelectedHighlightCordinates(null);
    }
    currentSelectedHighlightCordinates === null &&
      setCurrentSelectedHighlightCordinates(null);
  };

  const handleHighlightTargetClick = (event, highlight) => {
    const mouseCordinates = {
      x: event.clientX,
      y: event.clientY,
    };
    setCurrentSelectedHighlightCordinates(mouseCordinates);
    setSelectedHighlight(highlight);
  };

  const [scale, setScale] = useState(1.0);
  const pdfContainerRef = useRef();

  const zoomIn = () => {
    if (scale >= maxScale) return;
    getElementsInView();
    setScale(Math.min(maxScale, scale + zoomValue));
  };

  const zoomOut = () => {
    getElementsInView();
    setScale(Math.max(minScale, scale - zoomValue));
  };

  const pdfElem = document.getElementsByClassName("PdfHighlighter");

  useEffect(() => {
    if (pdfElem[0]) {
      pdfElem[0].addEventListener("scroll", () => {
        const pdfInViewNum = getPdfInViewPageNumber();
        setCurrentPdfPage(pdfInViewNum);
      });
    }
  }, [totalPage, scale]);

  useEffect(() => {
    const pdfHighlights = [];
    manualHighlights.forEach((item) => {
      const highlightData = {
        content: {
          text: item.text,
        },
        position: item.position,
        id: item.uid,
        type: item.type,
        curated: item.curated,
      };
      pdfHighlights.push(highlightData);
    });

    const filteredToRemoveOldSnippetsWithoutPosition = pdfHighlights.filter(
      (item) => {
        return item.position && item.content;
      }
    );
    setHighlights(filteredToRemoveOldSnippetsWithoutPosition);
  }, [manualHighlights]);

  // viewerScrollTo useEffect

  useEffect(() => {
    // console.log(
    //   "A change in doc has been detected",
    //   window.location,
    //   "This are the new highlights",
    //   highlights
    // );
    if (pdfLoaded) {
      const highlightId = window.location.hash.replace("#highlight-", "");
      if (!highlightId) return;
      const highlightToScroll = highlights.find(
        (highlight) => highlight.id === highlightId
      );
      // console.log(highlightToScroll, "<=== this");
      // console.log(highlights, "<=== highlights");
      // console.log(highlightId, "<=== highlightId");
      setTimeout(() => {
        if (viewerScrollTo.current && highlightToScroll) {
          viewerScrollTo.current(highlightToScroll);
        }
      }, 100);
    }
  }, [pdfLoaded, window.location.hash, totalPage]);

  if (!url) {
    return null;
  }

  return (
    <Flex
      flexDir="column"
      w="100%"
      onClick={(event) => {
        handleClickOnPdfParent(event);
      }}
      onScrollCapture={() => {
        setCurrentSelectedHighlightCordinates(null);
      }}
    >
      <Flex
        w="100%"
        sx={{
          ".PdfHighlighter": {
            width: assistantIsOpen ? `calc(94% - ${assistantWidth}px)` : "90%",
            height: "80vh",
            zIndex: 0,
          },
          ".Highlight__part": {
            backgroundColor: "#DCD1A8",
          },
          ".textLayer ::selection": {
            backgroundColor: "accentHighlights",
          },
        }}
        flexDir="column"
      >
        <PDFViewerHeader
          currentPdfPage={currentPdfPage}
          updateCurrentPdfPage={(value) => {
            const number = parseInt(value);
            if (number > totalPage || number < 1) return;
            setCurrentPdfPage(number);
            scrollToPage(number);
          }}
          totalPage={totalPage}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          highlightAllowed={allowHighlight}
          scale={scale}
          onOpen={onOpen}
          toggleHighlight={() => {
            // clear selection
            window.getSelection().removeAllRanges();
            setAllowHighlight(!allowHighlight);
          }}
          style={{
            display: pdfLoaded ? "flex" : "none",
          }}
          reduceHeading={reduceHeading}
        />
        <Box ref={pdfContainerRef}>
          <PdfLoader
            url={
              isFirebaseStoragePDF(url) || isEmulatorUrl(url)
                ? url
                : `${process.env.REACT_APP_URL_VIEWER_DOMAIN}pdf-viewer?url=${url}`
            }
            beforeLoad={<PDFViewerLoading />}
          >
            {(pdfDocument) => {
              setTotalPage(pdfDocument.numPages);
              setPdfLoaded(true);
              return (
                <PdfHighlighter
                  pdfScaleValue={scale}
                  pdfDocument={pdfDocument}
                  enableAreaSelection={(event) => event.altKey}
                  highlights={highlights}
                  onSelectionFinished={(position, content) => {
                    return (
                      <HighlightSelectTools
                        position={position}
                        content={content}
                        projectId={projectId}
                        articleId={articleId}
                      />
                    );
                  }}
                  scrollRef={(scrollTo) => {
                    viewerScrollTo.current = scrollTo;
                  }}
                  onScrollChange={() => {
                    // clear URL hash
                    if (window.location.hash) {
                      window.location.hash = "";
                    }
                  }}
                  key={scale}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    /** This section is for image highlights -- do not remove in case we need this
                    in the future

                    const isTextHighlight = !(
                      highlight.content && highlight.content.image
                    );

                    const component = isTextHighlight ? (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                        onClick={(event) => {
                          handleHighlightTargetClick(event, highlight);
                        }}
                      />
                    ) : (
                      <AreaHighlight
                        isScrolledTo={isScrolledTo}
                        highlight={highlight}
                      />
                    );
                    */

                    // const component = (
                    //   <AiGeneratedHighlight
                    //     isScrolledTo={isScrolledTo}
                    //     position={highlight.position}
                    //     comment={highlight.comment}
                    //     onClick={(event) => {
                    //       handleHighlightTargetClick(event, highlight);
                    //     }}
                    //     key={highlight.id}
                    //   />
                    // );

                    if (highlight.type === "fillTemplateInsight") {
                      return (
                        <AiGeneratedHighlight
                          position={highlight.position}
                          comment={highlight.comment}
                          isScrolledTo={isScrolledTo}
                        />
                      );
                    }

                    return (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                        onClick={(event) => {
                          handleHighlightTargetClick(event, highlight);
                        }}
                      />
                    );
                    // return (
                    //   <Popup
                    //     popupContent={<HighlightPopup {...highlight} />}
                    //     onMouseOver={(popupContent) =>
                    //       setTip(highlight, () => popupContent)
                    //     }
                    //     onMouseOut={hideTip}
                    //     key={index}
                    //     // eslint-disable-next-line react/no-children-prop
                    //     children={component}
                    //   />
                    // );
                  }}
                />
              );
            }}
          </PdfLoader>
        </Box>
      </Flex>
      <ExtractTextModal
        isOpen={isOpen}
        manualHighlights={manualHighlights.filter(
          (item) => item.type === "manualHighlight"
        )}
        onClose={() => {
          onClose();
        }}
        title={
          location.state?.articleData.title || location.state?.articleData.url
        }
      />
      {selectedHighlight && (
        <FloatingDeleteBttn
          cordinates={currentSelectedHighlightCordinates}
          highlightList={highlights}
          selectedHighlight={selectedHighlight}
          projectId={projectId}
          articleId={articleId}
        />
      )}
    </Flex>
  );
};

export default PdfExclusiveViewer;
