import React from "react";
import { Icon } from "@chakra-ui/react";

const NumberIconComponent = (props) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.06669 10C3.95113 10 3.85558 9.96222 3.78002 9.88667C3.70446 9.81111 3.66669 9.71556 3.66669 9.6V6.8H3.06669C2.95113 6.8 2.85558 6.76222 2.78002 6.68667C2.70446 6.61111 2.66669 6.51556 2.66669 6.4C2.66669 6.28444 2.70446 6.18889 2.78002 6.11333C2.85558 6.03778 2.95113 6 3.06669 6H3.96669C4.10835 6 4.2271 6.04792 4.32294 6.14375C4.41877 6.23958 4.46669 6.35833 4.46669 6.5V9.6C4.46669 9.71556 4.42891 9.81111 4.35335 9.88667C4.2778 9.96222 4.18224 10 4.06669 10ZM6.36669 10C6.22502 10 6.10627 9.95208 6.01044 9.85625C5.9146 9.76042 5.86669 9.64167 5.86669 9.5V8.16667C5.86669 8 5.91946 7.86389 6.02502 7.75833C6.13058 7.65278 6.26669 7.6 6.43335 7.6H8.06669V6.8H6.26669C6.15113 6.8 6.05558 6.76222 5.98002 6.68667C5.90446 6.61111 5.86669 6.51556 5.86669 6.4C5.86669 6.28444 5.90446 6.18889 5.98002 6.11333C6.05558 6.03778 6.15113 6 6.26669 6H8.30002C8.46669 6 8.6028 6.05278 8.70835 6.15833C8.81391 6.26389 8.86669 6.4 8.86669 6.56667V7.83333C8.86669 8 8.81391 8.13611 8.70835 8.24167C8.6028 8.34722 8.46669 8.4 8.30002 8.4H6.66669V9.2H8.46669C8.58224 9.2 8.6778 9.23778 8.75335 9.31333C8.82891 9.38889 8.86669 9.48444 8.86669 9.6C8.86669 9.71556 8.82891 9.81111 8.75335 9.88667C8.6778 9.96222 8.58224 10 8.46669 10H6.36669ZM10.4 10C10.2845 10 10.1889 9.96222 10.1134 9.88667C10.0378 9.81111 10 9.71556 10 9.6C10 9.48444 10.0378 9.38889 10.1134 9.31333C10.1889 9.23778 10.2845 9.2 10.4 9.2H12.2V8.4H11.0667C10.96 8.4 10.8667 8.36 10.7867 8.28C10.7067 8.2 10.6667 8.10667 10.6667 8C10.6667 7.89333 10.7067 7.8 10.7867 7.72C10.8667 7.64 10.96 7.6 11.0667 7.6H12.2V6.8H10.4C10.2845 6.8 10.1889 6.76222 10.1134 6.68667C10.0378 6.61111 10 6.51556 10 6.4C10 6.28444 10.0378 6.18889 10.1134 6.11333C10.1889 6.03778 10.2845 6 10.4 6H12.4334C12.6 6 12.7361 6.05278 12.8417 6.15833C12.9472 6.26389 13 6.4 13 6.56667V9.43333C13 9.6 12.9472 9.73611 12.8417 9.84167C12.7361 9.94722 12.6 10 12.4334 10H10.4Z"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default NumberIconComponent;
