import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCustomizationData } from "../Utils/customizationUtils";

const customization = getCustomizationData();

export const ToggleArticleDetailsBttn = ({ bttnText, id, icon, display }) => {
  const Icon = icon;
  const currentSearchParams = new URLSearchParams(location.search);
  const defaultSelect = customization.articleDetailDefaultTab;
  const [isSelected, setIsSelected] = useState(defaultSelect === id);
  const history = useHistory();

  const handleUpdateDetailType = (id) => {
    currentSearchParams.set("detail", id);
    history.replace(`?${currentSearchParams.toString()}`);
  };

  useEffect(() => {
    const detailParams = currentSearchParams.get("detail");
    if (!detailParams) {
      currentSearchParams.set("detail", defaultSelect);
      history.replace(`?${currentSearchParams.toString()}`);
    }
    setIsSelected(detailParams === id);
  }, [currentSearchParams]);
  return (
    <Button
      fontWeight="500"
      color={isSelected ? "white" : "primary"}
      bg={isSelected && "primary"}
      fontSize="12px"
      h="24px"
      py="1"
      px="1"
      pr="2"
      leftIcon={<Icon fontSize="12px" style={{ marginTop: "-1px" }} />}
      onClick={() => handleUpdateDetailType(id)}
      display={display}
    >
      {bttnText}
    </Button>
  );
};
