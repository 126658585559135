import { Button, Spinner, Text } from "@chakra-ui/react";
import React from "react";

export const LoginButton = ({ showSpinner, icon, text, onClick }) => {
  const Icon = icon;
  return (
    <Button
      w="100%"
      leftIcon={
        showSpinner ? (
          <Spinner size="sm" display={showSpinner ? "block" : "none"} />
        ) : (
          <Icon fontSize="16px" />
        )
      }
      color="tertiary"
      fontWeight="600"
      fontSize="14px"
      border="1px solid rgba(0, 0, 0, 0.12)"
      onClick={onClick}
    >
      <Text>{text}</Text>
    </Button>
  );
};
