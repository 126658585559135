import { Icon } from "@chakra-ui/react";
import React from "react";

const AssistantInsightIcon = () => {
  // remove boxSize, pt, pl
  return (
    <Icon viewBox="0 0 55 55">
      <circle cx="27.5" cy="27.5" r="25.5" fill="#191D30" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0122 6.4C28.0122 2.86538 25.1468 8.19447e-07 21.6122 6.64946e-07L6.4 0C2.86538 -1.54503e-07 8.19451e-07 2.86538 6.64946e-07 6.4L0 21.6121C-1.54505e-07 25.1468 2.86538 28.0121 6.4 28.0121H21.0091V28.7027C21.0091 30.47 22.4418 31.9027 24.2091 31.9027H30.259C32.0263 31.9027 33.459 30.47 33.459 28.7027V26.5434C33.459 24.7761 32.0263 23.3434 30.259 23.3434H27.7753C27.9297 22.7928 28.0122 22.2121 28.0122 21.6121V6.4Z"
        fill="url(#paint0_linear_10644_5808)"
        transform="translate(10, 10)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10644_5808"
          x1="4.27964"
          y1="26.4559"
          x2="26.4559"
          y2="1.94524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59808D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default AssistantInsightIcon;
