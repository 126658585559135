import { Icon } from "@chakra-ui/react";
import React from "react";

const ThumbDownIcon = (props) => {
  return (
    <Icon viewBox="0 0 18 18" fill="none" stroke="#191D30" {...props}>
      <path
        d="M13.1667 9.8335L9.83337 17.3335C9.17033 17.3335 8.53445 17.0701 8.06561 16.6013C7.59677 16.1324 7.33337 15.4965 7.33337 14.8335V11.5002H2.61671C2.37512 11.5029 2.13582 11.4531 1.9154 11.3541C1.69497 11.2552 1.4987 11.1096 1.34016 10.9272C1.18162 10.7449 1.06462 10.5303 0.997258 10.2983C0.929895 10.0663 0.913785 9.82237 0.950041 9.5835L2.10004 2.0835C2.16031 1.68608 2.36218 1.32382 2.66845 1.06349C2.97473 0.803159 3.36477 0.662286 3.76671 0.66683H13.1667M13.1667 9.8335V0.66683M13.1667 9.8335H15.6667C16.1087 9.8335 16.5327 9.6579 16.8452 9.34534C17.1578 9.03278 17.3334 8.60886 17.3334 8.16683V2.3335C17.3334 1.89147 17.1578 1.46755 16.8452 1.15499C16.5327 0.842425 16.1087 0.66683 15.6667 0.66683H13.1667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ThumbDownIcon;
