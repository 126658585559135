import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BookChatBttn } from "src/Components/onboardingFlow/components/BookChatBttn";
import { ForwardBttn } from "src/Components/onboardingFlow/components/ForwardBttn";
import { LinearBgFooter } from "src/Components/onboardingFlow/components/LinearBgFooter";
import { StepProgressCounter } from "src/Components/onboardingFlow/components/StepProgressCounter";
import { usersCollection } from "src/db/user";
import { useAuth } from "../../Auth/AuthProvider";
import { BackwardBttn } from "../../Components/onboardingFlow/components/BackwardBttn";
import { updatedOnboardingFlowData } from "../../Components/onboardingFlow/onboardingFlowData";
import { VideoModal } from "../../Components/onboardingFlow/VideoModal";
import { updateOnboardingStep } from "../../db/projectTour";

const updateOnboardingResponses = async ({
  formKey,
  userId,
  formData,
}: {
  formKey: string;
  userId: string;
  formData: Record<string, string | boolean>;
}) => {
  const userDocRef = doc(usersCollection, userId);
  await updateDoc(userDocRef, {
    [`onboardingResponses.${formKey}`]: formData,
  });
};

export const UpdatedOnboardingFlow = () => {
  const [allowForwardNav, setAllowForwardNav] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  let step = query.get("step") || 1;
  const paymentStatus = query.get("payment");
  const { currentUser, userDoc } = useAuth();
  const [formData, setFormData] = useState<
    | {
        formKey: string;
        checkedOptions: Record<string, string | boolean>;
      }
    | undefined
  >(undefined);
  const handleArrowClicks = (type) => {
    if (type === "left") {
      if (Number(step) === 1) return;
      step = Number(step) - 1;
    } else if (type === "right") {
      if (
        Number(step) === updatedOnboardingFlowData.length ||
        allowForwardNav === false
      )
        return;
      step = Number(step) + 1;
    }
    history.replace(`/welcome?step=${step}`);
    updateOnboardingStep(currentUser?.uid, Number(step));
  };

  useEffect(() => {
    step = Number(userDoc.onboardingProcess?.step || "1");
    let updatedUrl = "";
    if (step === 5 && paymentStatus === "success") {
      updatedUrl = `/welcome?step=${step + 1}&payment=${paymentStatus}`;
    } else {
      if (step) {
        updatedUrl = `/welcome?step=${step}`;
      }
    }
    history.replace(updatedUrl);
  }, [currentUser, history]);

  const currentStepData = updatedOnboardingFlowData.find(
    (data) => data.step === Number(step)
  );

  if (!currentStepData) {
    return <Box>Step not found</Box>;
  }

  const StepComponent = (currentStepData?.content ||
    (() => <Box>Step not found</Box>)) as React.FC<{
    setAllowForwardNav: (allow: boolean) => void;
    onOpen: () => void;
    onFormChange: ({
      formKey,
      checkedOptions,
    }: {
      formKey: string;
      checkedOptions: Record<string, string | boolean>;
    }) => void;
  }>;

  return (
    <Flex h="100vh" pos="relative" justify="center" userSelect="none">
      <Flex
        w="100%"
        px="50px"
        pb="30px"
        align="center"
        pos="relative"
        bg="#F8F8F8"
        justifyContent="center"
      >
        <BackwardBttn
          setAllowForwardNav={setAllowForwardNav}
          handleArrowClicks={handleArrowClicks}
          step={Number(step)}
        />
        <Flex h="auto">
          <StepComponent
            setAllowForwardNav={setAllowForwardNav}
            onOpen={onOpen}
            onFormChange={setFormData}
          />
        </Flex>
        <ForwardBttn
          allowForwardNav={allowForwardNav}
          setAllowForwardNav={setAllowForwardNav}
          handleArrowClicks={handleArrowClicks}
          showText={Number(step) === 1}
          endOfSteps={Number(step) === updatedOnboardingFlowData.length}
          onClick={async () => {
            if (!formData) return;
            await updateOnboardingResponses({
              formKey: formData.formKey,
              userId: currentUser.uid,
              formData: formData.checkedOptions,
            });
          }}
        />
      </Flex>
      <LinearBgFooter />
      <StepProgressCounter
        currentStep={Number(step)}
        totalSteps={updatedOnboardingFlowData.length}
      />
      <BookChatBttn />
      <VideoModal
        isOpen={isOpen}
        onClose={onClose}
        videoUrl={currentStepData?.videoUrl}
      />
    </Flex>
  );
};

export default UpdatedOnboardingFlow;
