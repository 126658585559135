import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Circle,
  Divider,
  Flex,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useOrganizerContext } from "../../../Context/OrganizerContext";
import TemplateInsightsIcon from "../../Icons/TemplateInsightsIcon";
import { getCustomizationData } from "../../Utils/customizationUtils";
import { AccordionTitle } from "./AccordionTitle";

const customization = getCustomizationData();

const TemplateInsights = () => {
  return (
    <Box p={0} borderRadius="4px" backgroundColor="#ffffff">
      <Accordion
        pos="relative"
        _before={{
          //Add a stripe
          content: '""',
          display: "block",
          position: "absolute",
          top: "0",
          left: "0",
          width: "4px",
          height: "100%",
          backgroundColor: "accentSnippets",
          borderRadius: "4px 0 0 4px",
        }}
        borderColor="white"
        allowToggle
      >
        <AccordionItem border={0}>
          <h2>
            <AccordionButton>
              <Flex flex="1" textAlign="left" gap="4px" align="center">
                <AccordionTitle
                  title={`${customization.template.name} Insights`}
                  icon={TemplateInsightsIcon}
                />
                {/* <InfoTooltip type="sentimentCenter" /> */}
              </Flex>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0} pb="16px">
            <Flex direction="column" px="16px" gap="16px" pt="0px">
              <Items />
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export const TEMPLATE_CARD_TYPES: {
  [key: string]: {
    label: string;
    color: string;
    borderColor?: string;
    cardOpacity?: number;
    showReAdd: boolean;
  };
} = {
  aiGenerated: {
    label: "AI-Generated",
    color: "#FFFFFF",
    borderColor: "rgba(0, 0, 0, 0.12)",
    showReAdd: false,
  },
  addedByUser: {
    label: "Added by user",
    color: "rgba(168, 220, 217, 0.18)",
    showReAdd: false,
    // cardOpacity: 0.18,
  },
  removedByUser: {
    label: "Removed by user",
    color: "#F9EAEB",
    showReAdd: true,
  },
};

const Items = () => {
  return (
    <Flex direction="column" pt="4px">
      {Object.entries(TEMPLATE_CARD_TYPES).map(([key, item], index) => (
        <React.Fragment key={key}>
          <TemplateInsightItem
            cardType={key}
            label={item.label}
            color={item.color}
            borderColor={item.borderColor}
          />
          {index < Object.keys(TEMPLATE_CARD_TYPES).length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Flex>
  );
};

function TemplateInsightItem({
  cardType,
  label,
  color,
  borderColor,
}: {
  cardType: string;
  label: string;
  color: string;
  borderColor?: string;
}) {
  const { cardTypeFilters, toggleCardTypeFilter } = useOrganizerContext();
  const isActive = cardTypeFilters.find(
    (filter) => filter.cardType === cardType
  )
    ? true
    : false;
  return (
    <Flex alignItems="center" py={2}>
      <Circle
        size="8px"
        bg={color}
        border={borderColor && "1px solid"}
        borderColor={borderColor}
        opacity={1}
      />
      <Text
        fontSize="12px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="normal"
        opacity={0.54}
        pl="8px"
      >
        {label}
      </Text>
      <Spacer />
      <Checkbox
        isChecked={isActive}
        onChange={() => {
          toggleCardTypeFilter({ cardType });
        }}
      />
    </Flex>
  );
}

export default TemplateInsights;
