import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAssistantContext } from "../../../Context/AssistantContext";

export const FullScreenBttn = ({ expanded }) => {
  const { setAssistantIsFullScreen } = useAssistantContext();
  let { projectId } = useParams();
  const history = useHistory();

  return (
    <Flex align={"center"}>
      <Flex
        // bg="black"
        borderRadius="4"
        cursor="pointer"
        onClick={() => {
          setAssistantIsFullScreen(!expanded);
          if (expanded) {
            history.push(`/${projectId}`);
          } else {
            history.push(`/${projectId}/assistant`);
          }
        }}
      >
        <Icon
          as={expanded ? AiOutlineShrink : AiOutlineExpandAlt}
          // color="white"
          onClick={() => {}}
          cursor={"pointer"}
          boxSize={"20px"}
        />
      </Flex>
    </Flex>
  );
};
